import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";

import DataTable from "./DataTable";

const Bookings = () => {
  const [tripsData, setTripsData] = useState([]);
  const [isRefreshData, setIsRefereshData] = useState(true);
  const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
  const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));
  const [selectedCp, setSelectedCp] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar visibility
  const [isError, setIsError] = useState(false); // Error state for API failures
  const isSuperAdmin = localStorage.getItem("roleId");

  const tripsDataResponse = (data) => {
    setTripsData(data.TripList);

    setIsRefereshData(false);
    setSnackbarOpen(false); // Hide snackbar on successful fetch
  };

  const { isLoading, error, sendRequest } = useHttp();

  // useEffect(() => {
  //   if (isRefreshData)
  //     sendRequest(
  //       {
  //         url: "/api/v1/Trips/GetAllTrips",
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: {
  //           emailID: localStorage.getItem("user"),
  //           // corporateID:
  //           //   localStorage.getItem("roleId") == "1"
  //           //     ? ""
  //           //     : localStorage.getItem("corpId"),
  //         corporateID: selectedCp?.cpId ? selectedCp.cpId : "",

  //           roleID: localStorage.getItem("roleId"),
  //           staffMobileNumber: "",
  //           departmentID: "",
  //           fromDate: `${startDateValue.$d.getFullYear()}/${
  //             startDateValue.$d.getMonth() + 1
  //           }/${startDateValue.$d.getDate()}`,
  //           toDate: `${endDateValue.$d.getFullYear()}/${
  //             endDateValue.$d.getMonth() + 1
  //           }/${endDateValue.$d.getDate()}`,
  //         },
  //       },

  //       tripsDataResponse

  //     );
  // }, [sendRequest, isRefreshData, isSuperAdmin]);

  useEffect(() => {
    if (isRefreshData) {
      sendRequest(
        {
          url: "/api/v1/Trips/GetAllTrips",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: selectedCp?.cpId ? selectedCp.cpId : "",
            roleID: localStorage.getItem("roleId"),
            staffMobileNumber: "",
            departmentID: "",
            fromDate: `${startDateValue.$d.getFullYear()}/${
              startDateValue.$d.getMonth() + 1
            }/${startDateValue.$d.getDate()}`,
            toDate: `${endDateValue.$d.getFullYear()}/${
              endDateValue.$d.getMonth() + 1
            }/${endDateValue.$d.getDate()}`,
          },
        },
        tripsDataResponse
      );
    }
  }, [sendRequest, isRefreshData, isSuperAdmin]);
  useEffect(() => {
    if (error) {
      setSnackbarOpen(true);
      setIsError(true); // Set error flag
    }
  }, [error]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleReload = () => {
    setIsRefereshData(true);
    setSnackbarOpen(false);
  };

  return (
    <div>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1.5%",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "17px",
            textTransform: "uppercase",
          }}
        >
          Trips
        </span>
      </header>
      <DataTable
        setIsRefereshData={setIsRefereshData}
        tripsData={tripsData}
        dataLoading={isLoading}
        setStartDateValue={setStartDateValue}
        setEndDateValue={setEndDateValue}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        setTripsData={setTripsData}
        isSuperAdmin={isSuperAdmin}
        selectedCorporateDetails={selectedCp}
        setSelectedCorporateDetails={setSelectedCp}
      />
      {/* Snackbar for Error Handling */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="error"
        >
          Error occurred. Please try again.
          <br />
          <Button onClick={handleReload} color="inherit" size="small">
            Please Reload
          </Button>
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default Bookings;
