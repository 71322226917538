import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  IconButton,
  Typography,
  withStyles,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicCar from "../../Assets/basic_car.png";
import ComfortCar from "../../Assets/comfort_car.png";
import ComfortPlusCar from "../../Assets/comfort_plus_car.png";
import AddIcon from "@mui/icons-material/Add";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for hours
import LocationOnIcon from "@mui/icons-material/LocationOn"; // Icon for kilometers
import { ReactComponent as Clock } from "../../Assets/Clock.svg";
import { ReactComponent as Distance } from "../../Assets/Kilometer.svg";
import { ReactComponent as AddSign } from "../../Assets/Plus_sign.svg";
import { ReactComponent as MinusSign } from "../../Assets/Minus_sign.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const AddPricingStructure = () => {
  const [slabs, setSlabs] = useState([
    { hr: "", km: "", Basic: "", Comfort: "", ComfortPlus: "" },
  ]);
  const [pricing, setPricing] = useState({
    basic: { RsKm: "", RsMin: "", DA: "" },
    comfort: { RsKm: "", RsMin: "", DA: "" },
    comfortPlus: { RsKm: "", RsMin: "", DA: "" },
  });

  const handleAddSlab = () => {
    setSlabs([
      ...slabs,
      { hr: "", km: "", Basic: "", Comfort: "", ComfortPlus: "" },
    ]);
  };

  const handleDeleteSlab = (index) => {
    setSlabs(slabs.filter((_, slabIndex) => slabIndex !== index));
  };

  const handleChange = (index, field, value) => {
    const newSlabs = slabs.map((slab, slabIndex) =>
      slabIndex === index ? { ...slab, [field]: value } : slab
    );
    setSlabs(newSlabs);
  };

  const handlePricingChange = (category, field, value) => {
    setPricing({
      ...pricing,
      [category]: { ...pricing[category], [field]: value },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      slabs,
      pricing,
    };
    console.log("Payload:", payload);
  };

  const carInfo = {
    Basic: "Wagonar, Micra, Celerio - Affordable AC cab for city rides",
    Comfort: "Dzire, Excent, Etios - Comfortable sedans with extra legroom",
    ComfortPlus: "Ertiga, Innova - Enjoy Comfortable SUVs for group travel",
  };
  return (
    <form onSubmit={handleSubmit} style={{ width: "95%", margin: "2% auto" }}>
      <TableContainer
        component={Paper}
        style={{
          padding: "16px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h6"
          align="left"
          gutterBottom
          style={{ fontWeight: "bold", fontSize: "17px", marginLeft: "8px" }}
        >
          Pricing Structure
        </Typography>
        <div
          style={{
            border: "1px solid",
            borderColor: "lightgray",
            margin: "8px",
            padding: "4px",
            borderRadius: "8px",
          }}
        >
          <Table
            sx={{
              border: "none", // Remove default border
              "& td": { border: 0 }, // Remove border for table cells
            }}
          >
            <TableHead
              sx={{
                "& th": {
                  color: "rgba(96, 96, 96)",
                  backgroundColor: "white",
                  border: "none",
                },
              }}
            >
              <TableRow>
                <TableCell>
                  <div style={{ paddingLeft: "40px" }}>
                    <Clock style={{ width: "40", height: "40" }} />

                    <div>
                      <Typography
                        variant="caption"
                        style={{
                          marginLeft: "8px",
                          fontWeight: "bold",
                          lineHeight: "1.5rem",
                        }}
                      >
                        Hours
                      </Typography>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    {/* <LocationOnIcon /> */}
                    <div style={{ marginLeft: "10%" }}>
                      <Distance style={{ width: "40", height: "40" }} />
                    </div>
                    <div>
                      <Typography
                        variant="caption"
                        style={{
                          marginLeft: "8px",
                          fontWeight: "bold",
                          lineHeight: "1.5rem",
                        }}
                      >
                        Kilometers
                      </Typography>
                    </div>
                  </div>
                </TableCell>

                <TableCell>
                  <div>
                    <img src={BasicCar} alt="Basic" style={{ width: "60%" }} />
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginLeft: "24px",
                      }}
                    >
                      <div>
                        <span className="cab-type">Basic</span>
                      </div>
                      <div style={{ marginTop: "1%" }}>
                        <Tooltip title={carInfo.Basic}>
                          <InfoOutlinedIcon style={{ fontSize: 18 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <img
                      src={ComfortCar}
                      alt="Comfort"
                      style={{ width: "60%" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        marginLeft: "24px",
                      }}
                    >
                      <div>
                        <span className="cab-type">Comfort</span>
                      </div>
                      <div style={{ marginTop: "1%" }}>
                        <Tooltip title={carInfo.Comfort}>
                          <InfoOutlinedIcon style={{ fontSize: 18 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <img
                      src={ComfortPlusCar}
                      alt="Comfort Plus"
                      style={{ width: "60%" }}
                    />
                    <div
                      style={{ display: "flex", gap: "5px", marginLeft: "8px" }}
                    >
                      <div>
                        <span className="cab-type">ComfortPlus</span>
                      </div>
                      <div style={{ marginTop: "1%" }}>
                        <Tooltip title={carInfo.ComfortPlus}>
                          <InfoOutlinedIcon style={{ fontSize: 18 }} />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <h4 style={{ marginTop: "revert", marginLeft: "7%" }}>Slabs</h4> */}
              {slabs.map((slab, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label="Hrs"
                      type="number"
                      value={slab.hr}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handleChange(index, "hr", e.target.value)
                      }
                      margin="normal"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label="Km"
                      type="number"
                      value={slab.km}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handleChange(index, "km", e.target.value)
                      }
                      margin="normal"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label="₹"
                      type="number"
                      value={slab.Basic}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handleChange(index, "Basic", e.target.value)
                      }
                      margin="normal"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label="₹"
                      type="number"
                      value={slab.Comfort}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handleChange(index, "Comfort", e.target.value)
                      }
                      margin="normal"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="standard"
                      label="₹"
                      type="number"
                      value={slab.ComfortPlus}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={(e) =>
                        handleChange(index, "ComfortPlus", e.target.value)
                      }
                      margin="normal"
                      fullWidth
                    />
                  </TableCell>
                  <TableCell style={{ width: "50px", textAlign: "center" }}>
                    {index === slabs.length - 1 ? (
                      <IconButton onClick={handleAddSlab}>
                        {/* <AddIcon /> */}
                        <AddSign />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleDeleteSlab(index)}>
                        {/* <DeleteIcon /> */}
                        <MinusSign />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell rowSpan={3} align="top">
                  <h4 style={{ marginBottom: "150%" }}>Extra Expenses</h4>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" style={{ color: "#1D85C6" }}>
                    Rate/Km
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.basic.RsKm}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("basic", "RsKm", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>

                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfort.RsKm}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("comfort", "RsKm", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfortPlus.RsKm}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("comfortPlus", "RsKm", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography
                    variant="body1"
                    style={{ color: "#1D85C6", fontWeight: "500" }}
                  >
                    Rate/Min
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.basic.RsMin}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("basic", "RsMin", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfort.RsMin}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("comfort", "RsMin", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfortPlus.RsMin}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange(
                        "comfortPlus",
                        "RsMin",
                        e.target.value
                      )
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
              </TableRow>
              <TableRow style={{ borderBottom: "none" }}>
                <TableCell>
                  <Typography variant="body1" style={{ color: "#1D85C6" }}>
                    Daily Allowances
                  </Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.basic.DA}
                    onChange={(e) =>
                      handlePricingChange("basic", "DA", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfort.DA}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("comfort", "DA", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    label="₹"
                    type="number"
                    value={pricing.comfortPlus.DA}
                    InputProps={{ inputProps: { min: 0 } }}
                    onChange={(e) =>
                      handlePricingChange("comfortPlus", "DA", e.target.value)
                    }
                    margin="normal"
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <Button   variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#44B150",
              color: "white",
              ":hover": {
                backgroundColor: "#44B150",
                opacity: 0.8,
              },
            }}>
            Submit
          </Button>
        </div>
      </TableContainer>
    </form>
  );
};

export default AddPricingStructure;
