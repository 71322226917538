import React from "react";
import {
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          // color: "#ccc"
          color: "#A9A9A9", //red color
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#44B150", //green color
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#A9A9A9",

          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#9CD3A4", //light green for the background
          },
        },
      },
    },
  },
});

const CustomSwitch = ({
  stylingFormControlLabel,
  label,
  checked,
  onChange,
  
}) => {
  const defaultStyling = {
    width: "50%",
    justifyContent: "space-between",
    margin: "auto",
  };
  return (
    <FormControlLabel
      style={stylingFormControlLabel ? stylingFormControlLabel : defaultStyling}
      control={
        <>
          <Typography variant="body1">{label}</Typography>
          <ThemeProvider theme={theme}>
            <Switch
              checked={checked}
              onChange={onChange}
              sx={{
                transform: "scale(0.8)", // Adjust the scale as needed
              }}
            />
          </ThemeProvider>
        </>
      }
    />
  );
};

export default CustomSwitch;
