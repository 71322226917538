import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import useHttp from "../../Hooks/use-http";
import { toast } from "react-toastify";

const ChangeVehicle = ({
  driverData,
  onCloseModal,
  setIsApiCall,
  setAnchorEl,
}) => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [availableVehicles, setAvailableVehicles] = useState([]);
  const { isLoading, sendRequest } = useHttp();
  const [partnerDetails, setPartnerDetails] = useState([]);
  const [plateDetail, setPlateDetail] = useState(null);

  // Handle partner selection
  const handlePartnerChange = (event, newValue) => {
    if (!newValue) {
      // If the partner is cleared, reset partner, available vehicles, and plateDetail
      setSelectedPartner(null);
      setAvailableVehicles([]); // Clear the vehicle list
      setPlateDetail(null); // Clear selected vehicle
    } else {
      // const partner = partnerDetails?.find((p) => p?.PartnerEmailId === newValue);
      // setSelectedPartner(partner);
      // setAvailableVehicles(partner ? partner?.Vehicles : []); // Update vehicles based on selected partner
      // setPlateDetail(null); // Clear previously selected vehicle when changing partner
      setSelectedPartner(newValue);
      setAvailableVehicles(newValue?.Vehicles || []); // Update vehicles based on selected partner
      setPlateDetail(null);
    }
  };

  // Handle vehicle selection (if needed)
  const handlePlateChange = (event, newValue) => {
    setPlateDetail(newValue?.PlateNumber); // If vehicle is cleared, reset plateDetail
  };

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/DriverList/GetPartnersWithVehicles",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          roleID: localStorage.getItem("roleId"),
        },
      },
      corporateLists
    );
  }, [sendRequest]);

  const corporateLists = (data) => {

    setPartnerDetails(data?.Partners); // Ensure you're setting it only once
  };
  // Handle Change button click
  const handleChangeVehicle = () => {
    // Ensure selectedPartner and plateDetail are correctly populated
    if (selectedPartner && plateDetail) {
      sendRequest(
        {
          url: "/api/v1/DriverList/GetAssignVehicleToDriver",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            driverEmailID: driverData?.DriverEmailID,
            partnerEmailID: selectedPartner?.PartnerEmailId, // Ensure correct key names
            plateNumber: plateDetail,
            roleID: localStorage.getItem("roleId"),
          },
        },
        handleAssignResponse
      );
    } else {
      console.log("Missing selectedPartner or plateDetail");
    }
  };

  // Handle API response
  const handleAssignResponse = (data) => {
    if (data?.Status?.toLowerCase() === "000") {
      toast.success("Vehicle assigned to driver successfully!");
      setIsApiCall(true);
      setAnchorEl(null);
      setSelectedPartner(null);
      onCloseModal(); // Close modal
    } else {
      toast.error("Something went wrong. Please try again.");
    }
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "rgba(34, 137, 203, 255)",
          color: "white",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "-24px",
          flexDirection: "row",
        }}
      >
        <Typography variant="h7" component="div">
          CHANGE VEHICLE
        </Typography>
      </Box>
      <Card sx={{ display: "flex", marginTop: "10%" }}>
        <CardMedia
          component="img"
          style={{
            height: "100px",
            width: "24%",
            borderRadius: "50%",
            padding: "4px",
          }}
          image={driverData?.DriverImage}
          alt="Driver Image"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Typography component="div" variant="h6">
              {driverData?.DriverName}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {driverData?.DriverEmailID}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              {driverData?.CarModel} {driverData?.CarNumber}
            </Typography>
          </CardContent>
        </Box>
      </Card>

      {/* Show loading spinner if data is still loading */}
      {isLoading ? (
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Autocomplete for selecting partner */}
          <Autocomplete
            options={partnerDetails}
            getOptionLabel={(option) => (
              `${option?.PartnerName || ''} (${option?.PartnerEmailId || ''})`
            )}
            renderOption={(props, option) => (
              <li {...props} style={{ padding: '8px' }}>
                <div style={{display:'flex', flexDirection:'column'}}>
                <div>
                  {option?.PartnerName || ''}
                </div>  
                <div style={{ color: 'grey', fontSize: '0.875rem' }}>
                  {option?.PartnerEmailId || ''}
                </div>
                </div>
                
              </li>
            )}
            filterOptions={(options, state) => 
              options.filter(option => 
                (option?.PartnerName?.toLowerCase() || '').includes(state.inputValue.toLowerCase()) ||
                (option?.PartnerEmailId?.toLowerCase() || '').includes(state.inputValue.toLowerCase())
              )
            }
            sx={{ width: "100%", marginTop: "20px" }}
            onChange={handlePartnerChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search Partner"
                label="Partner Name"
              />
            )}
          />

          {/* Autocomplete for selecting vehicle based on partner */}
          {selectedPartner && (
            <Autocomplete
            options={availableVehicles || []}
            getOptionLabel={(option) => option.PlateNumber} // Label is just the PlateNumber
            renderOption={(props, option) => (
              <li {...props}>
                <div style={{display:'flex', flexDirection: 'column'}}>
                <div>
                  {option?.PlateNumber}
                </div>
                <div style={{ color: 'grey', fontSize: '0.875rem' }}>
                  {option?.Make} {option?.Model}
                </div>
                  </div>
                
              </li>
            )}
              sx={{ width: "100%", marginTop: "20px" }}
              // onChange={handleVehicleChange}
              onChange={
                handlePlateChange // Only send PlateNumber to the handler
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Search Vehicle"
                  label="Vehicle"
                />
              )}
            />
          )}
        </>
      )}

      <button
        style={{
          backgroundColor: "#88BCDF",
          color: "white",
          border: "none",
          borderRadius: "4px",
          padding: "8px 14px",
          fontSize: "12px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          marginTop: "10px",
          marginLeft: "80%",
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#2289CB")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#88BCDF")}
        onClick={handleChangeVehicle}
        disabled={!selectedPartner || !plateDetail}
      >
        CHANGE
      </button>
    </div>
  );
};

export default ChangeVehicle;
