// const dateViewFormat = {
//     year: "numeric",
//     month: "short",
//     day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//     // second: "2-digit",
//   }
//   // Input: 2024-09-04T01:27:41.527
//  export const FormatDateTime = (timestamp) => {
//     const date = new Date(timestamp);
//     return date.toLocaleString("en-US", dateViewFormat);
//   };
// export const FormatDateTime = (timestamp) => {
//     const date = new Date(timestamp);
  
//     // Extract date components
//     const month = date.getMonth() + 1; // Months are 0-based, so add 1
//     const day = date.getDate();
//     const year = date.getFullYear();
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
  
//     // Pad single-digit months, days, hours, and minutes with a leading zero
//     const paddedMonth = month.toString().padStart(2, '0');
//     const paddedDay = day.toString().padStart(2, '0');
//     const paddedHours = hours.toString().padStart(2, '0');
//     const paddedMinutes = minutes.toString().padStart(2, '0');
  
//     // Format as MM-DD-YYYY HH:MM
//     return `${paddedMonth}-${paddedDay}-${year} ${paddedHours}:${paddedMinutes}`;
//   };
  
export const FormatDateTime = (timestamp) => {
    let date = new Date(timestamp);
    
    // Check if the timestamp contains a 'T' and adjust for IST if necessary
    if (timestamp.includes('T')) {
      // Convert to IST (UTC+5:30)
      const istOffset = 0 // IST is UTC+5:30 in minutes
      const utcOffset = date.getTimezoneOffset(); // Get the local time zone offset in minutes
      date = new Date(date.getTime() + (istOffset - utcOffset) * 60000);
    }
  
    // Extract date components
    const month = date.getMonth() + 1; // Months are 0-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
  
    // Pad single-digit months, days, hours, and minutes with a leading zero
    const paddedMonth = month.toString().padStart(2, '0');
    const paddedDay = day.toString().padStart(2, '0');
    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
  
    // Format as MM-DD-YYYY HH:MM
    return `${paddedMonth}-${paddedDay}-${year} ${paddedHours}:${paddedMinutes}`;
  };
  