import React, { useRef, useState } from "react";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import Button from "@mui/material/Button";

import Records from "./Records";
import useHttp from "../../../Hooks/use-http";
import Modal from "../../../GeneratePDF/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DateFilter from "../../CommonComponent/DateFilterByMUI";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonTablePagination from "../../CommonComponent/CommonTablePagination";

const SHIFT_TITLE = [
  "Booking Id",
  "Corporate Name",
  "Guest Details",
  "Pickup Date",
  "Drop Date",
  "Booking Type",
  "Vehicle Type",
  "Trip Cost",
  "Status",
];

let myClick = false;
let prev_id = "1";
let total_shift_data = "";
let today = new Date()
  .getFullYear()
  .toString()
  .concat("-", new Date().getMonth() + 1, "-", new Date().getDate());
let startDate = today;
let endDate = today;

function PreviousBookings(props) {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(7);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [filteredData, setFilteredData] = useState([]);
  const [isDataFiltered, setIsDataFiltered] = useState(true);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
  const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));
  const startDateRef = useRef();
  const endDateRef = useRef();
  const history = useHistory();

  const authenticateUser = (data) => {
    let booking_list = [];
    if (data?.RequestBookingDetails) {
      for (let i = 0; i < data?.RequestBookingDetails.length; i++) {
        booking_list.push({
          id: i + 1,
          bookingId: data?.RequestBookingDetails[i].BookingID,
          guestName: data?.RequestBookingDetails[i].GuestName,
          guestMobile: data?.RequestBookingDetails[i].GuestMobileNumber,
          bookingDate: data?.RequestBookingDetails[i].BookingDateTime?.replace(
            "T",
            " "
          ),
          pickupDate:
            data?.RequestBookingDetails[i].PickupDateTime?.split(" ")[0],
          pickupTime:
            data?.RequestBookingDetails[i].PickupDateTime?.split(" ")[1],
          dropDate: data?.RequestBookingDetails[i].EndedOn?.split(" ")[0],
          dropTime: data?.RequestBookingDetails[i].EndedOn?.split(" ")[1],
          pickupLocation: data?.RequestBookingDetails[i].PickupAddress,
          dropLocation: data?.RequestBookingDetails[i].DropoffAddress,
          vehicleType: data?.RequestBookingDetails[i].VehicleType,
          status: data?.RequestBookingDetails[i].Status,
          driverName: data?.RequestBookingDetails[i].DriverName,
          driverCarModel: data?.RequestBookingDetails[i].carModel,
          driverCarNumber: data?.RequestBookingDetails[i].carNumber,
          cancelNotes: data?.RequestBookingDetails[i].CancelNotes,
          bookingType: data?.RequestBookingDetails[i].Justification,
          corporateName: data?.RequestBookingDetails[i].CorporateName,
          coprorateId: data?.RequestBookingDetails[i].AdminDepartmentID,
          driverCost: data?.RequestBookingDetails[i].DriverCost,
          driverEmailId: data?.RequestBookingDetails[i].DriverEmailId,
          companyCost: data?.RequestBookingDetails[i].CompanyCost,
          tripOtp: {
            isOtpEnabled: data?.RequestBookingDetails[i].isOTPEnabled,
            startOtp: data?.RequestBookingDetails[i].StartTripOTP,
            endOtp: data?.RequestBookingDetails[i].EndTripOTP,
          },
        });
      }
    }
    total_shift_data = booking_list;
    setFilteredData(booking_list);
    setIsDataFiltered(false);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (isDataFiltered) {
      setFilteredData([]);
      sendRequest(
        {
          url: "/api/v1/ScheduleBooking/GetBookingRequestDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: localStorage.getItem("corpId"),
            startDate: `${startDateValue.$d.getFullYear()}/${
              startDateValue.$d.getMonth() + 1
            }/${startDateValue.$d.getDate()}`,
            endDate: `${endDateValue.$d.getFullYear()}/${
              endDateValue.$d.getMonth() + 1
            }/${endDateValue.$d.getDate()}`,
            roleID: localStorage.getItem("roleId"),
          },
        },
        authenticateUser
      );
    }
  }, [sendRequest, isDataFiltered]);

  const nPages = Math.ceil(filteredData.length / rowsPerPage);

  let fromRecords = 0;
  if (page === 0) fromRecords = 1;
  else fromRecords = page * rowsPerPage;
  let toRecords = 0;
  if (
    (myClick
      ? page * rowsPerPage - (filteredData.length % rowsPerPage)
      : page * rowsPerPage +
        rowsPerPage -
        (filteredData.length % rowsPerPage)) > filteredData.length
  )
    toRecords = filteredData.length;
  else toRecords = page * rowsPerPage;
  if (toRecords === 0) fromRecords = 0;
  if (page === nPages) toRecords = filteredData.length;

   // Calculate pagination
   const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
   const indexOfFirstRecord = page * rowsPerPage;
   const currentRecords = filteredData.slice(
     indexOfFirstRecord,
     indexOfLastRecord
   );
 
  // const filterButtonClickHandler = (e) => {
  //   if (prev_id !== e.target.id) {
  //     startDateRef.current.value = "";
  //     endDateRef.current.value = "";
  //     document.getElementById(e.target.id)?.classList.add("selected");
  //     document.getElementById(prev_id)?.classList.remove("selected");
  //     prev_id = e.target.id;

  //     setCurrentPage(1);
  //     setIsDataFiltered(true);
  //     myClick = true;

  //     if (e.target.innerText === "Today") {
  //       startDate = new Date()
  //         .getFullYear()
  //         .toString()
  //         .concat("-", +new Date().getMonth() + 1, "-", new Date().getDate());
  //       endDate = today;
  //     };
  //   }
  // }

  // const dateChangeHandler = () => {
  //   if (startDateRef.current.value && endDateRef.current.value) {
  //     startDate = startDateRef.current.value;
  //     endDate = endDateRef.current.value;
  //     document.getElementById(prev_id)?.classList.remove("selected");
  //     prev_id = null;
  //     setIsDataFiltered(true);
  //     setCurrentPage(1);
  //   }
  // };

  // const inputFromDateBlurHandler = (e) => {
  //   e.target.type = "text";
  //   if (startDateRef.current.value)
  //     startDateRef.current.value = formatToMMDDYYYYfromYYYYMMDD(
  //       startDateRef.current.value
  //     );
  // };

  // const inputToDateBlurHandler = (e) => {
  //   e.target.type = "text";
  //   if (endDateRef.current.value)
  //     endDateRef.current.value = formatToMMDDYYYYfromYYYYMMDD(
  //       endDateRef.current.value
  //     );
  // };

  return (
    <div className="trips-details" id="trip-table">
      <div
        className="title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>Schedule Booking</p>
        <Button
          onClick={() => history.push("/schedule-booking/new booking")}
          variant="contained"
          size="small"
          sx={{
            boxShadow: "none",
            marginRight: "10px",
            fontFamily: "Montserrat",
          }}
        >
          Add New Booking
        </Button>
      </div>
      <div className="table-container" >
        <div className="header" style={{justifyContent:"end"}}>
          
          <div style={{display:"flex", gap:'25px'}}>
            {/* <div onChange={dateChangeHandler} className="datepicker">
              <input
                placeholder="From Date"
                type="text"
                ref={startDateRef}
                onBlur={inputFromDateBlurHandler}
                onFocus={(e) => (e.target.type = "date")}
              />
              <input
                placeholder="To Date"
                type="text"
                ref={endDateRef}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={inputToDateBlurHandler}
              />
            </div> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DateFilter
              startDateValue={startDateValue}
              setStartDateValue={setStartDateValue}
              endDateValue={endDateValue}
              setEndDateValue={setEndDateValue}
              setIsRefereshData={setIsDataFiltered}
              loading={isLoading}
              />
              
              </LocalizationProvider>
              {/* <span
              
              className="export_csv"
              style={{ cursor: "pointer" }}
              onClick={() => setIsExportButtonClicked(true)}
            >
              Export
            </span> */}
              <Button
              variant="outlined"
              sx={{
                color: "#f15821",
                border: "1px solid #f15821",
                alignSelf: "center",
                ":hover": { border: "1px solid #f15821" },
              }}
              size="small"
              onClick={() => setIsExportButtonClicked(true)}
            >
              Export
            </Button>
          </div>
        </div>
        <Records
          data={currentRecords}
          headers={SHIFT_TITLE}
          isLoading={isLoading}
        />
        <div>
        
            <CommonTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              filteredData={filteredData}
              styling={{ display: "flex", justifyContent: "flex-end" }}
            />
        </div>
      </div>
      {isExportButtonClicked && (
        <Modal
          setIsExportButtonClicked={setIsExportButtonClicked}
          type="bookingRequests"
        />
      )}
      {isExportButtonClicked && <div className="add-route-fullcontainer"></div>}
    </div>
  );
}

export default PreviousBookings;
