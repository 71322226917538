import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const CreateAdminModal = (props) => {
  //   const [corporate, setCorporate] = useState(props?.corporateData?.CorporateID);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [department, setDepartment] = useState("");
  let corporate = props?.corporateData?.CorporateID;

  //   const parameterJSON = props?.corporateData?.ParameterJSON
  //     ? JSON.parse(props.corporateData.ParameterJSON)
  //     : {};

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });

  const handleSubmit = () => {
    let valid = true;
    let newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
    };

    if (!firstName) {
      valid = false;
      newErrors.firstName = "First Name is required";
    }
    if (!lastName) {
      valid = false;
      newErrors.lastName = "Last Name is required";
    }
    if (!email) {
      valid = false;
      newErrors.email = "Email ID is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      valid = false;
      newErrors.email = "Email ID is invalid";
    }
    if (!mobile) {
      valid = false;
      newErrors.mobile = "Mobile Number is required";
    } else if (mobile.length !== 10) {
      valid = false;
      newErrors.mobile = "Mobile Number must be exactly 10 digits";
    }
    if (!password) {
      valid = false;
      newErrors.password = "Password is required";
    }
    if (!confirmPassword) {
      valid = false;
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (password !== confirmPassword) {
      valid = false;
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);

    if (valid) {
      console.log("On Submit", {
        corporate,
        firstName,
        lastName,
        email,
        mobile,
        password,
        confirmPassword,
        department,
      });
      // handleClose();
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobile(value);
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setTimeout(()=>{
        setErrors((prevErrors) => ({
            ...prevErrors,
            confirmPassword: password !== value ? "Passwords do not match" : "",
          }));
    }, 1000)

      // Clear any existing timeout to avoid multiple validations
//   if (this.confirmPasswordTimeout) {
//     clearTimeout(this.confirmPasswordTimeout);
//   }

//   // Set a new timeout for 3 seconds before performing validation
//   this.confirmPasswordTimeout = setTimeout(() => {
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       confirmPassword: password !== value ? "Passwords do not match" : "",
//     }));
//   }, 3000);
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ p: 0 }}>
      <Box
        sx={{
          backgroundColor: "rgba(34, 137, 203, 255)",
          color: "white",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "-24px",
          flexDirection: "row",
        }}
      >
        <Typography variant="h7" component="div">
          CREATE ADMIN
        </Typography>
      </Box>
      
      <div style={{ marginTop: "4%" }}>
      <div style={{display:'flex', gap:'20px'}}>
      <TextField
          label="Corporate"
          //   value={props?.Name}
          defaultValue={props?.corporateData?.Name}
          variant="standard"
          //   onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          inputProps={{
            readOnly: true,
            disabled: true,
          }}
        />

        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>Department</InputLabel>
          <Select
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            label="Department"
          >
            {props?.corporateData?.Departments?.map((dept) => (
              <MenuItem key={dept?.CorporateID} value={dept?.CorporateID}>
                {dept?.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </div>
        <div style={{display:'flex', gap:'20px'}}>
        <TextField
          label="First Name"
          value={firstName}
          variant="standard"
          onChange={(e) => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
        <TextField
          label="Last Name"
          value={lastName}
          variant="standard"
          onChange={(e) => setLastName(e.target.value)}
          fullWidth
          margin="normal"
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
        </div>
      
       <div style={{display:'flex', gap:'20px'}}>
       <TextField
          label="Email ID"
          value={email}
          variant="standard"
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
         <TextField
          label="Mobile Number"
          value={mobile}
          variant="standard"
          onChange={handleMobileChange}
          fullWidth
          margin="normal"
          error={!!errors.mobile}
          helperText={errors.mobile}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
       </div>
       
        <div style={{display:'flex', gap:'20px'}}>
        <TextField
          label="Password"
          type="password"
          value={password}
          variant="standard"
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          error={!!errors.password}
          helperText={errors.password}
        />
        <TextField
          label="Confirm Password"
          type="password"
          value={confirmPassword}
          variant="standard"
          onChange={handleConfirmPasswordChange}
          fullWidth
          margin="normal"
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
        </div>
     
       
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#44B150",
              justifyContent: "flex-end",
              color: "white",
              ":hover": {
                backgroundColor: "#44B150",
                opacity: 0.8,
              },
              width: "auto",
            }}
          >
            Submit
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default CreateAdminModal;
