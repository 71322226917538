import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

const InvoiceDetails = ({
  isNextClicked,
  setActiveStep,
  setIsNextClicked,
  setAllData,
  allData,
}) => {
  const [isError, setIsError] = useState();
 const [formData, setFormData] = useState({
    privateTotalKm: "",
    privateNoOfVehicle: "",
    privateVehicleType: "",
    privatePrice: "",
    privateTax: "",
    privateFilter: "",
    scheduleTotalKm: "",
    scheduleNoOfVehicle: "",
    scheduleVehicleType: "",
    schedulePrice: "",
    scheduleTax: "",
    scheduleFilter: "",
    shuttleTotalKm: "",
    shuttleNoOfVehicle: "",
    shuttleVehicleType: "",
    shuttlePrice: "",
    shuttleTax: "",
    shuttleFilter: "",
    invoiceName: "",
    invoiceEmail: "",
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData({ ...allData.invoiceDetails });
  }, []);

  useEffect(() => {
    if (isNextClicked) {
      Object.keys(formData).map((val, i) => {
        if (!String(formData[val])) {
          setIsError(true);
          setIsNextClicked(false);
          return;
        } else if (i === Object.keys(formData).length - 1) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setAllData((prev) => ({ ...prev, invoiceDetails: formData }));
        }
      });
      setIsNextClicked(false);
    }
  }, [isNextClicked]);

  return (
    <>  
      <div
      style={{
        display: "flex",
        gap: "30px",
        padding: "10px",
        alignSelf: "flex-start",
      }}
    >
      <TextField
        id="standard-basic"
        name="invoiceName"
        value={formData.invoiceName}
        onChange={handleInputChange}
        label="Name"
        variant="standard"
        sx={{ width: "250px" }}
      />
      <TextField
        id="standard-basic"
        name="invoiceEmail"
        value={formData.invoiceEmail}
        onChange={handleInputChange}
        label="Email"
        variant="standard"
        sx={{ width: "250px" }}
      />
    </div>

    
    <div  style={{
            display: "flex",
            gap: "30px",
            padding: "10px",
            alignSelf: "flex-start",
          }}>
      {/* {selectedModules.includes("private") && ( */}
        <div
        
        >
          <h3>Private Form</h3>
          <div style={{ display: "flex", gap: "30px", padding: "10px" }}>
            <TextField
              name="privateTotalKm"
              value={formData.privateTotalKm}
              onChange={handleInputChange}
              label="Total km"
              variant="standard"
              sx={{ width: "250px" }}
            />
            {/* Add other fields for Private */}
          </div>
        </div>
    {/* //   )} */}

      {/* {selectedModules.includes("schedule") && ( */}
        <div>
          <h3>Schedule Form</h3>
          <div style={{ display: "flex", gap: "30px", padding: "10px" }}>
            <TextField
              name="scheduleTotalKm"
              value={formData.scheduleTotalKm}
              onChange={handleInputChange}
              label="Total km"
              variant="standard"
              sx={{ width: "250px" }}
            />
            {/* Add other fields for Schedule */}
          </div>
        </div>
      {/* )} */}

      {/* {selectedModules.includes("shuttle") && ( */}
        <div>
          <h3>Shuttle Form</h3>
          <div style={{ display: "flex", gap: "30px", padding: "10px" }}>
            <TextField
              name="shuttleTotalKm"
              value={formData.shuttleTotalKm}
              onChange={handleInputChange}
              label="Total km"
              variant="standard"
              sx={{ width: "250px" }}
            />
            {/* Add other fields for Shuttle */}
          </div>
        </div>
      {/* )} */}
    </div>
    </>

  );
};

export default InvoiceDetails;
