import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomSwitch from "../CommonComponent/CustomSwitch";
import InfoIcon from "@mui/icons-material/Info";
import CustomInfoIcon from "../CommonComponent/CustomInfoIcon";
import useHttp from "../../Hooks/use-http";
import { toast } from "react-toastify";

const Config = (props) => {
  const parameterJSON = props?.corporateData?.ParameterJSON
    ? JSON.parse(props.corporateData.ParameterJSON)
    : {};

  const initialStartLocation =
    parameterJSON?.garageDetails?.garageLocation || "";
  // console.log("Initial Start Location:", initialStartLocation);
  const [overtime, setOvertime] = useState(parameterJSON?.shiftOvertime || 0);
  const [allowEditBefore, setAllowEditBefore] = useState(
    parameterJSON?.allowEditBefore || 0
  );
  const [shiftStartLocation, setShiftStartLocation] = useState(
    parameterJSON?.shiftStartOTP || false
  );
  const [shiftEndLocation, setShiftEndLocation] = useState(
    parameterJSON?.shiftEndOTP || false
  );
  const [garageLocation, setGarageLocation] = useState(
    parameterJSON?.garage || false
  );
  const [startLocation, setStartLocation] = useState(initialStartLocation);
  const [endLocation, setEndLocation] = useState(props?.corporateData?.Address);
  const [startLocationLatLng, setStartLocationLatLng] = useState(
    parameterJSON?.garageDetails?.garageLatLng || null
  );
  const [endLocationLatLng, setEndLocationLatLng] = useState({
    lat: parseFloat(props?.corporateData?.CorporateLatlong?.split(",")[0]),
    lng: parseFloat(props?.corporateData?.CorporateLatlong?.split(",")[1]),
  });
  const [km, setKm] = useState(parameterJSON?.garageDetails?.garageKm || 0);
  const [endLocationError, setEndLocationError] = useState("");
  const [startLocationError, setStartLocationError] = useState("");

  const endLocationInputRef = useRef(null);
  const startLocationInputRef = useRef(initialStartLocation || null);
  const [startLocationInputReady, setStartLocationInputReady] = useState(false);
  const [endLocationInputReady, setEndLocationInputReady] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (startLocationInputReady || endLocationInputReady) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places,geometry&v=weekly`;
      script.async = true;
      script.onload = initAutocomplete;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [startLocationInputReady, endLocationInputReady]);

  useEffect(() => {
    if (startLocationLatLng && endLocationLatLng) {
      calculateDistance();
    }
  }, [startLocationLatLng, endLocationLatLng]);

  const initAutocomplete = () => {
    const startInput = document.getElementById("pac-input50");
    const endInput = document.getElementById("end-location-input");

    // const startInput = startLocationInputRef.current;
    if (startInput) {
      const startAutocomplete = new window.google.maps.places.Autocomplete(
        startInput,
        {
          componentRestrictions: { country: ["in"] },
        }
      );
      startAutocomplete.addListener("place_changed", () => {
        const place = startAutocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.error("Start place not found");
          setStartLocationError("Start location not found");
          return;
        }
        setStartLocationLatLng(place.geometry.location.toJSON());
        setStartLocation(place.formatted_address);
        setStartLocationError("");
      });
    }

    // const endInput = endLocationInputRef.current;
    if (endInput) {
      const endAutocomplete = new window.google.maps.places.Autocomplete(
        endInput,
        {
          componentRestrictions: { country: ["in"] },
        }
      );
      endAutocomplete.addListener("place_changed", () => {
        const place = endAutocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.error("End place not found");
          setEndLocationError("End location not found");
          return;
        }
        setEndLocationLatLng(place.geometry.location.toJSON());
        setEndLocation(place.formatted_address);
        setEndLocationError("");
      });
    }
    setTimeout(() => {
      const autocompleteContainers =
        document.getElementsByClassName("pac-container");
      for (let i = 0; i < autocompleteContainers.length; i++) {
        autocompleteContainers[i].style.zIndex = "2000";
      }
    }, 500);
  };

  const calculateDistance = () => {
    if (
      startLocationLatLng &&
      endLocationLatLng &&
      window.google?.maps.DistanceMatrixService
    ) {
      const origin = new window.google.maps.LatLng(
        startLocationLatLng?.lat,
        startLocationLatLng?.lng
      );
      const destination = new window.google.maps.LatLng(
        endLocationLatLng?.lat,
        endLocationLatLng?.lng
      );

      const service = new window.google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: window.google?.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (
            status === "OK" &&
            response?.rows[0]?.elements[0]?.status === "OK"
          ) {
            const distance =
              response?.rows[0]?.elements[0]?.distance?.value / 1000; // Convert to km
            setKm(distance);
          } else {
            console.error("Error calculating distance", status, response);
          }
        }
      );
    }
  };

  // const handleGarageLocationChange = (event) => {
  //   setGarageLocation(event.target.checked);
  //   if (!event.target.checked) {
  //     setStartLocation("");
  //     setStartLocationLatLng(null);
  //     setEndLocation("");
  //     setEndLocationLatLng(null);
  //     setKm(0);
  //   }
  // };
  const handleGarageLocationChange = (event) => {
    if (!props?.corporateData?.Address) {
      setOpenDialog(true);
      return;
    }
    setGarageLocation(event.target.checked);
    if (!event.target.checked) {
      setStartLocation("");
      setStartLocationLatLng(null);
      setKm(0);
    } else {
      setEndLocation(props?.corporateData?.Address);
      setEndLocationLatLng({
        lat: parseFloat(props?.corporateData?.CorporateLatlong?.split(",")[0]),
        lng: parseFloat(props?.corporateData?.CorporateLatlong?.split(",")[1]),
      });
    }
  };

  const handleSubmit = () => {
    if (!endLocation) {
      setEndLocationError("End Location is required");

      return;
    }

    const formData = {
      shiftOvertime: overtime,
      allowEditBefore,
      shiftStartOTP: shiftStartLocation,
      shiftEndOTP: shiftEndLocation,
      garage: garageLocation,
      // garageLocation:startLocation,
      // corporateLocation:endLocation,
      // garageKm:km,
      // garageLatLng: startLocationLatLng
      //   ? `${startLocationLatLng.lat},${startLocationLatLng.lng}`
      //   : "",
      // corporateLatLng: endLocationLatLng
      //   ? `${endLocationLatLng.lat},${endLocationLatLng.lng}`
      //   : "",
    };
    if (garageLocation) {
      formData.garageDetails = {
        garageKm: km,
        garageLocation: startLocation,
        corporateLocation: endLocation,
        garageLatLng: startLocationLatLng
          ? `${startLocationLatLng.lat},${startLocationLatLng.lng}`
          : "",
        corporateLatLng: endLocationLatLng
          ? `${endLocationLatLng.lat},${endLocationLatLng.lng}`
          : "",
      };
    }
    // Handle form submission with lat/lng
    // console.log("Form submitted with:", {
    //   overtime,
    //   allowEditBefore,
    //   shiftStartLocation,
    //   shiftEndLocation,
    //   garageLocation,
    //   startLocation,
    //   endLocation,
    //   km,
    //   startLocationLatLng: startLocationLatLng
    //     ? `${startLocationLatLng.lat},${startLocationLatLng.lng}`
    //     : "",
    //   endLocationLatLng: endLocationLatLng
    //     ? `${endLocationLatLng.lat},${endLocationLatLng.lng}`
    //     : "",
    // });
    console.log("Form sumitted with:", formData);
    let corporateParameterJSON = {};
    try {
      corporateParameterJSON = JSON.parse(props.corporateData.ParameterJSON);
    } catch (error) {
      console.error("Error parsing ParameterJSON:", error);
    }

    // Merge formData with parsed corporateParameterJSON
    const mergedData = {
      ...corporateParameterJSON,
      ...formData,
    };

    sendRequest(
      {
        url: "/api/v1/Corporate/AddEditCorporateParameter",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          corporateID: props?.corporateData?.CorporateID, // Assuming you have this prop
          emailID: localStorage.getItem("user"),
          parameterJSON: JSON.stringify(mergedData),
        },
      },
      (data) => {
        // console.log("Configuration saved:", data);
        toast.success("Data has been Added Successfully");


        // After get successfull call the getCorporates
         // After successful update, fetch updated corporate list
         sendRequest(
          {
            url: "/api/v1/Corporate/GetCorporates",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              emailID: localStorage.getItem("user"),
              roleID: localStorage.getItem("roleID"),
              corporateID: localStorage.getItem("corporateID"),
            },
          },
          (updatedCorporateList) => {
            // Assuming setCorporateList is a function to update the corporate list state
            props.setCorporateData(updatedCorporateList?.CorporateList);
          },
          (error) => {
            toast.error(error.message || "Failed to fetch updated corporate list");
          }
        );


        props.onCloseModal();
      },
      (error) => {
        toast.error(error.message || "Failed to save configuration");
      }
    );
    // props.onCloseModal();
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1500, // Ensure it's above other content
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : null}
      <Box component="form" noValidate autoComplete="off" sx={{ p: 0 }}>
        <Box
          sx={{
            backgroundColor: "rgba(34, 137, 203, 255)",
            color: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: isMobile ? "-16px" : "-24px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Typography variant="h7" component="div">
            CONFIGURATION
          </Typography>
        </Box>
        <Grid container alignItems="center" spacing={isMobile ? 2 : 5}>
          <Grid item xs={12} sm={6} style={{ display: "flex" }}>
            <TextField
              label="Overtime (minutes)"
              type="number"
              variant="standard"
              InputProps={{ inputProps: { min: 0 } }}
              value={overtime}
              // onChange={(e) => setOvertime(Math.max(0, e.target.value))}
              onChange={(e) =>
                setOvertime(
                  e.target.value === "" ? "" : Math.max(0, e.target.value)
                )
              }
              fullWidth
              margin="normal"
            />

            <CustomInfoIcon
              message={"Enter the number of minutes allowed for overtime."}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: "flex" }}>
            <TextField
              label="Allow Edit Before time (minutes)"
              type="number"
              variant="standard"
              InputProps={{ inputProps: { min: 0 } }}
              value={allowEditBefore}
              // onChange={(e) => setAllowEditBefore(Math.max(0, e.target.value))}
              onChange={(e) =>
                setAllowEditBefore(
                  e.target.value === "" ? "" : Math.max(0, e.target.value)
                )
              }
              fullWidth
              margin="normal"
            />

            <CustomInfoIcon
              message={"Enter the number of minutes allowed for allow time."}
            />
          </Grid>
        </Grid>

        <Grid container alignItems="center" spacing={isMobile ? 2 : 5}>
          <Grid item xs={12} sm={6}>
            <CustomSwitch
              stylingFormControlLabel={{
                width: isMobile ? "100%" : "55%",
                justifyContent: "space-between",
                margin: "auto",
              }}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {"Shift Start Location"}
                  {
                    <CustomInfoIcon
                      message={"Enable allowed for shift start location."}
                    />
                  }
                </div>
              }
              checked={shiftStartLocation}
              onChange={(e) => setShiftStartLocation(e.target.checked)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSwitch
              stylingFormControlLabel={{
                width: isMobile ? "100%" : "53%",
                justifyContent: "space-between",
                margin: "auto",
              }}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {"Shift End Location"}
                  {
                    <CustomInfoIcon
                      message={"Enable allowed for shift end location."}
                    />
                  }
                </div>
              }
              checked={shiftEndLocation}
              onChange={(e) => setShiftEndLocation(e.target.checked)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={1}
          style={{ marginLeft: "4px" }}
        >
          <Grid item xs={12} sm={6}>
            <CustomSwitch
              stylingFormControlLabel={{
                width: isMobile ? "100%" : "50%",
                justifyContent: "space-between",
              }}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {"Garage Location"}
                  {
                    <Tooltip
                      title="Enter the location of Garage location or base location."
                      placement="top"
                      style={{ marginTop: "auto" }}
                    >
                      <IconButton sx={{ p: 0.5 }}>
                        <InfoIcon fontSize="small" style={{ width: "0.8em" }} />
                      </IconButton>
                    </Tooltip>
                  }
                </div>
              }
              checked={garageLocation}
              onChange={handleGarageLocationChange}
            />
          </Grid>
          {garageLocation && (
            <Grid item xs={12} sm={6}>
              {/* <Typography variant="body1">Distance: {km} km</Typography> */}
              <TextField
                label="Total Distance (KM)"
                type="number"
                variant="standard"
                InputProps={{
                  readOnly: true,
                  margin: "auto",
                }}
                value={km.toFixed(2)}
                // onChange={(e) => setAllowEditBefore(Math.max(0, e.target.value))}
                // onChange={(e) =>
                //   setAllowEditBefore(
                //     e.target.value === "" ? "" : Math.max(0, e.target.value)
                //   )
                // }
                fullWidth
                margin="normal"
              />
            </Grid>
          )}
        </Grid>
        {garageLocation && (
          <>
            <Grid
              container
              alignItems="center"
              spacing={isMobile ? 2 : 5}
              sx={{ mb: 2 }}
            >
              <Grid item xs={12} sm={5}>
                <TextField
                  label="Garage Location"
                  inputRef={startLocationInputRef}
                  id="pac-input50"
                  variant="standard"
                  error={startLocationError ? true : false}
                  helperText={startLocationError}
                  fullWidth
                  margin="normal"
                  value={startLocation}
                  onChange={(e) => {
                    setStartLocation(e.target.value);
                    setStartLocationInputReady(e.target.value.length > 0);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={1}
                sx={{ textAlign: "center", marginTop: "40px" }}
              >
                <ArrowForwardIcon />
              </Grid>
              <Grid item xs={12} sm={5}>
                <TextField
                  className="standard-basic"
                  inputRef={endLocationInputRef}
                  label="Corporate Location"
                  variant="standard"
                  id="end-location-input"
                  autoComplete="off"
                  error={endLocationError ? true : false}
                  helperText={endLocationError}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true, // Make the input field read-only
                  }}
                  value={endLocation}
                  onChange={(e) => {
                    setEndLocation(e.target.value);
                    setEndLocationInputReady(e.target.value.length > 0);
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container alignItems="center" textAlign="end" spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                backgroundColor: "#44B150",
                color: "white",
                ":hover": {
                  backgroundColor: "#44B150",
                  opacity: 0.8,
                },
                width: isMobile ? "100%" : "auto",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>{"Corporate Address Not Provided"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide the corporate address before enabling the Garage Location switch.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </>
  );
};

export default Config;
