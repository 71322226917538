// Utility function to safely convert lat,lng string to object
export const convertLatLng = (latLngString) => {
    if (!latLngString || typeof latLngString !== 'string') {
      return null; // Return null if the input is invalid
    }
    const [lat, lng] = latLngString.split(",").map(Number);
    if (isNaN(lat) || isNaN(lng)) {
      return null; // Return null if conversion fails
    }
    return { lat, lng };
  };
  