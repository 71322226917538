import { Button } from '@mui/material'
import React from 'react'

const ExportFilter = ({onclick}) => {
  return (
    <div>
        <Button
              variant="outlined"
              sx={{
                color: "#f15821",
                border: "1px solid #f15821",
                alignSelf: "center",
                ":hover": { border: "1px solid #f15821" },
              }}
              size="small"
              onClick={onclick}
            >
              Export
            </Button>
    </div>
  )
}

export default ExportFilter
