import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import "./UploadBulkStaffData.css";
import useHttp from '../../Hooks/use-http';


const uploadBulkStaffSampleData = [
    {
        "First Name": "",
        "Last Name": "",
        "Mobile Number": "",
        "Address": "",
        "Pincode": "",
        "Area": "",
        "City": "",
        "State": "",
        "Pickup Location": "",
        "Drop Location": "",
        "Pickup LatLng": "",
        "Drop LatLng": ""
    }
]
let tempDataForStaffMobile = new Map();
let problematicStaffData = [];
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadBulkStaffData = (props) => {
    const [isFileParsingError, setIsFileParsingError] = useState({ status: false, message: "" });
    const [isUploadClicked, setIsUploadClicked] = useState(false);
    const [staffData, setStaffData] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);

    const addStaffDataResponse = (data) => {
        if (data.ExistsTripList) {
            for (let i = 0; i < data.ExistsTripList.length; i++) {
                problematicStaffData.push({
                    "Reason of Rejection": "Mobile Number already registered",
                    "First Name": data.ExistsTripList[i].StaffFirstName,
                    "Last Name": data.ExistsTripList[i].StaffLastName,
                    "Mobile Number": data.ExistsTripList[i].StaffMobileNumber,
                    "Address": "",
                    "Pincode": "",
                    "Area": "",
                    "City": "",
                    "State": "",
                    "Pickup Location": "",
                    "Drop Location": "",
                    "Pickup LatLng": "",
                    "Drop LatLng": ""
                });
            }
            setIsFileParsingError({ status: true, message: "Some data are already registered. Please change the mobile number and then upload again", isAttachementAvailable: true })
        } else {
            setIsSuccess(true);
            props.setIsUploadFileClicked(false);
        }
        props.setIsRequest(true);
        setIsUploadClicked(false);
    }

    const { isLoading, sendRequest } = useHttp();

    useEffect(() => {
        if (isUploadClicked) {
            let temp = [];
            for (let i = 1; i < staffData.length; i++) {
                temp.push({
                    CorporateID: localStorage.getItem("corpId"),
                    CorporateName: localStorage.getItem("cpName"),
                    StaffFirstName: staffData[i][0],
                    StaffLastName: staffData[i][1],
                    Classstandards: "",
                    StaffImage: "",
                    ParentFirstName: "",
                    ParentLastName: "",
                    ParentsMobileNumber: "",
                    ParentEmailAddress: "",
                    Address: staffData[i][3] ?? "",
                    PickupLL: staffData[i][10] ?? "",
                    DropLL: staffData[i][11] ?? "",
                    StaffMobileNumber: "91" + staffData[i][2],
                    Pincode: staffData[i][4] ?? "",
                    Area: staffData[i][5] ?? "",
                    City: staffData[i][6] ?? "",
                    State: staffData[i][7] ?? "",
                    EmailID: "",
                    PickupStop: staffData[i][8] ?? "",
                    DropStop: staffData[i][9] ?? "",
                    CorporateType: "cp"
                })
            }
            sendRequest({
                url: "/api/v1/Staff/AddEditStafV2",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    emailID: localStorage.getItem("user"),
                    detailsRecord: JSON.stringify(temp)
                }
            }, addStaffDataResponse, 6001000);
        }
    }, [isUploadClicked]);

    const fileChangeHandler = (e) => {
        e.preventDefault();

        var files = e.target.files, f = files[0];

        let temp = files[0].name.split(".");
        if (!["xlsx", "csv", "xls"].includes(temp[temp.length - 1])) {
            setIsFileParsingError({ status: true, message: "Please upload file that containes xlsx, xls or csv format", isAttachementAvailable: false });
            return;
        }
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            let flag = false;
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            problematicStaffData = [];
            let tempKeys = Object.keys(uploadBulkStaffSampleData[0]);
            tempKeys.map((val, index) => {
                if (val.toLowerCase().trim() !== dataParse[0][index].toLowerCase().trim()) flag = true;
            })
            if (flag) {
                setIsFileParsingError({ status: true, message: "File headers are not correct. Please match it with the sample excel file", isAttachementAvailable: false })
                return;
            }
            flag = false;
            for (let i = 1; i < dataParse.length; i++) {
                if (!(dataParse[i][0] && dataParse[i][1] && dataParse[i][2])) {
                    problematicStaffData.push({
                        "Reason of Rejection": "Missing data",
                        "First Name": dataParse[i][0] ?? "",
                        "Last Name": dataParse[i][1] ?? "",
                        "Mobile Number": dataParse[i][2] ?? "",
                        "Address": dataParse[i][3] ?? "",
                        "Pincode": dataParse[i][4] ?? "",
                        "Area": dataParse[i][5] ?? "",
                        "City": dataParse[i][6] ?? "",
                        "State": dataParse[i][7] ?? "",
                        "Pickup Location": dataParse[i][8] ?? "",
                        "Drop Location": dataParse[i][9] ?? "",
                        "Pickup LatLng": dataParse[i][10] ?? "",
                        "Drop LatLng": dataParse[i][11] ?? ""
                    });
                    flag = true;
                }
            }
            if (flag) {
                setIsFileParsingError({ status: true, message: "There are some data with missing mobile number or first/last name", isAttachementAvailable: true });
                return;
            }
            setStaffData([...dataParse]);
            setIsFileParsingError({ status: false, message: "", isAttachementAvailable: "" });
        }
        reader.readAsBinaryString(f);
    }

    const submitDataHandler = () => {
        setIsUploadClicked(true);
    }

    return (
        <>
            <Snackbar open={isSuccess} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={() => setIsSuccess(false)} >
                <Alert onClose={() => setIsSuccess(false)} severity="error" sx={{ width: '100%', backgroundColor: "rgba(42, 149, 69, 255)" }}>
                    Data registered successfully
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: 'rgba(34, 137, 203, 255)', zIndex: 99 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={props.isUploadFileClicked}
                sx={{ minWidth: "40%", zIndex: 10 }}
            >
                <DialogTitle>Upload Staff Data</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <CSVLink style={{ cursor: "pointer" }} data={uploadBulkStaffSampleData} filename={"sample-data.csv"} >
                            Click here to download sample Excel file
                        </CSVLink>
                        {isFileParsingError.status && <div style={{ color: "red", marginTop: "10px" }}>{isFileParsingError.message + " "}{isFileParsingError.isAttachementAvailable && <CSVLink data={problematicStaffData} filename={"error-data.csv"} >
                            Click here to download error data
                        </CSVLink>
                        }
                        </div>
                        }
                        <TextField sx={{ marginTop: "10px" }} className='staff-data-file-input' type="file" onChange={fileChangeHandler} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        props.setIsUploadFileClicked(false);
                        setIsFileParsingError({ status: false, message: "", isAttachementAvailable: "" });
                    }}>Cancel</Button>
                    <Button type="submit" onClick={submitDataHandler} disabled={isFileParsingError.status} >Upload</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UploadBulkStaffData;