import { TablePagination } from '@mui/material'
import React from 'react'

const CommonTablePagination = ({filteredData, styling, page, rowsPerPage, setPage, setRowsPerPage}) => {
    // const [page, setPage] = React.useState(0);, 
    // const [rowsPerPage, setRowsPerPage] = React.useState(7);

    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
       <TablePagination
              rowsPerPageOptions={[7, 15, 20]}
              component="div"
              count={filteredData?.length ?? "1"}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={styling}
            />
    </>
  )
}

export default CommonTablePagination
