import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import "./ShiftCreation.css";
import useHttp from "../../../Hooks/use-http";
import { GrFormNext } from "react-icons/gr";
import DatePicker from "react-multi-date-picker";
import { MdEdit } from "react-icons/md";
import transition from "react-element-popper/animations/transition";
import ShiftMap from "./ShiftMap";
import loadingGif from "../../../Assets/loading-gif.gif";
import Message from "../../../Modal/Message";
import { AiOutlineConsoleSql, AiOutlineDelete } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useHistory } from "react-router-dom";

import {
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  CORPORATEIDFROMLOCALSTORAGE,
  DEPARTMENTIDFROMLOCALSTORAGE,
  ROLEIDFROMLOCALSTORAGE,
  USEREMAILIDFROMLOCALSTORAGE,
  svgMarkerForEndLocation,
  svgMarkerForStartLocation,
} from "../../../Constant";
import { ExtractLatLng } from "../../CommonComponent/ExtractLatLng";

let corporates;
// let drivers;
let selectedDepartment = {};
let selectedDriver = {};
// var autocomplete = "";
var autocomplete = [];
let shiftData = [];
let marker;
let map;
let shiftStartOtpFromConfig;
let shiftEndOtpFromConfig;
let errorFileds = {
  coprorateNameError: "",
  departmentNameError: "",
  driverNameError: "",
  roMobileError: "",
  rpLocationError: "",
  shiftTimingsError: "",
};
let shiftId = "";
let startLocationMarker;
let endLocationMarker;
let startLatLng;
let endLatLng;

const ShiftCreation = () => {
  const corporateNameRef = useRef();
  const [driverDetails, setDriverDetails] = useState([]); //Data of Drivers from the api

  const departmentNameRef = useRef();
  const driverNameRef = useRef();
  const reportingOfficerMobileInputRef = useRef();
  const rpLocationInputRef = useRef();
  const endLocationInputRef = useRef();

  const [filteredCorporates, setIsFilteredCorporates] = useState([]);
  const [filteredDrivers, setIsFilteredDrivers] = useState([]);
  // const [isGetDriverData, setIsGetDriverData] = useState(localStorage.getItem("roleId") === "1" ? false : true);
  const [isGetDriverData, setIsGetDriverData] = useState(true);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [dateValues, setDateValues] = useState([]);
  const [shiftTimings, setShiftTimings] = useState([]);
  const [isShiftSaveClicked, setIsShiftSaveClicked] = useState(false);
  const [isShiftCreationSuccess, setIsShiftCreationSuccess] = useState(false);
  const [isShiftDataCollected, setIsShiftDataCollected] = useState(false);
  const [formError, setFormError] = useState(errorFileds);
  const shiftStartTimeInputRef = useRef();
  const shiftEndTimeInputRef = useRef();
  const history = useHistory();

  const [time, setTime] = useState({ hours: 0, minutes: 0 });

  const [endLocation, setEndLocation] = useState("");
  const [endLocationError, setEndLocationError] = useState("");
  // const [IsStartShiftOtpEnable, setIsStartShiftOtpEnable] = useState(false);
  const [IsStartShiftOtpEnable, setIsStartShiftOtpEnable] = useState(() => {
    const roleId = localStorage.getItem("roleId");
    return (
      roleId != 1 &&
      localStorage.getItem("shiftStartOTPFromLocalStorage") == "true"
    );
  });

  const [IsEndShiftOtpEnable, setIsEndShiftOtpEnable] = useState(() => {
    const roleId = localStorage.getItem("roleId");
    return (
      roleId != 1 &&
      localStorage.getItem("shiftEndOTPFromLocalStorage") == "true"
    );
  });

  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [parentCorporateId, setParentCorporateId] = useState("");
  const [selectedDriverEmail, setSelectedDriverEmail] = useState(null);
  const [corporateId, setCorporateId] = useState(""); // State to hold CorporateId
  const [departmentId, setDepartmentId] = useState(""); // State to hold CorporateId

  const [parameterJSON, setParameterJSON] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  shiftId = queryParams.get("shiftId");

  // This getShiftDetails is coming from the api and it is used to change in the edit-----
  const getShiftDetails = (responseData) => {
    // debugger;
    try {
      if (responseData.DriverShiftList) {
        driverNameRef.current.value =
          responseData.DriverShiftList[0]?.DriverName !== null
            ? responseData?.DriverShiftList[0]?.DriverName
            : null;
        setSelectedDriverEmail(responseData?.DriverShiftList[0]?.DriverEmailID);
        // startLatLng = {
        //   lat: +responseData?.DriverShiftList[0]?.ReportingLL?.split(",")[0],
        //   lng: +responseData?.DriverShiftList[0]?.ReportingLL?.split(",")[1],
        // };
        // endLatLng = {
        //   lat: +responseData?.DriverShiftList[0]?.ShiftEndLL?.split(",")[0],
        //   lng: +responseData?.DriverShiftList[0]?.ShiftEndLL?.split(",")[1],
        // };
        // Use the new utility function for LatLng extraction
        startLatLng = ExtractLatLng(
          responseData?.DriverShiftList[0]?.ReportingLL
        );
        endLatLng = ExtractLatLng(responseData?.DriverShiftList[0]?.ShiftEndLL);
        corporateNameRef.current.value =
          responseData.DriverShiftList[0]?.ParentCorporateName !== null
            ? responseData?.DriverShiftList[0]?.ParentCorporateName
            : "";
        setCorporateId(responseData.DriverShiftList[0]?.ParentCorporateID); // Set CorporateId state
        setDepartmentId(responseData.DriverShiftList[0]?.CorporateID);

        if (responseData.DriverShiftList[0]?.ReportingOfficer?.length > 10)
          reportingOfficerMobileInputRef.current.value =
            responseData.DriverShiftList[0]?.ReportingOfficer.slice(2);
        else
          reportingOfficerMobileInputRef.current.value =
            responseData.DriverShiftList[0]?.ReportingOfficer;

        rpLocationInputRef.current.value =
          responseData.DriverShiftList[0]?.ReportingLocaiton !== null
            ? responseData.DriverShiftList[0]?.ReportingLocaiton
            : null;

        endLocationInputRef.current.value =
          responseData.DriverShiftList[0]?.ShiftEndLocation !== null
            ? responseData.DriverShiftList[0]?.ShiftEndLocation
            : null;

        setIsStartShiftOtpEnable(
          responseData?.DriverShiftList[0]?.IsStartShiftOtpEnable === 1
            ? true
            : false
        );

        setIsEndShiftOtpEnable(
          responseData?.DriverShiftList[0]?.IsEndShiftOtpEnable === 1
            ? true
            : false
        );

        setSelectedTasks(responseData?.DriverShiftList[0]?.TaskIds);

        // selectedDriver.name =
        //   responseData.DriverShiftList[0]?.DriverName !== null
        //     ? responseData.DriverShiftList[0]?.DriverName
        //     : null;
        // selectedDriver.email = responseData.DriverShiftList[0]?.DriverEmailID;

        // document.getElementById("shiftNext").click();
        // debugger;

        // map.setCenter(startLatLng);
        // map?.setZoom(18);
        // startLocationMarker?.setVisible(true);
        // startLocationMarker?.setPosition(startLatLng);
        // endLocationMarker?.setVisible(true);
        // endLocationMarker?.setPosition(startLatLng);

        if (
          startLatLng &&
          !isNaN(startLatLng.lat) &&
          !isNaN(startLatLng.lng) &&
          startLatLng.lat !== 0 &&
          startLatLng.lng !== 0
        ) {
          map.setCenter(startLatLng);
          map.setZoom(18);
          startLocationMarker.setVisible(true);
          startLocationMarker.setPosition(startLatLng);

          if (
            endLatLng &&
            !isNaN(endLatLng.lat) &&
            !isNaN(endLatLng.lng) &&
            endLatLng.lat !== 0 &&
            endLatLng.lng !== 0
          ) { 
            endLocationMarker.setVisible(true);
            endLocationMarker.setPosition(endLatLng);
          } else {
            endLocationMarker.setVisible(false);
          }
        } else {
          startLocationMarker.setVisible(false);
          endLocationMarker.setVisible(false);
        }
        const a = [{}];
        const startDate =
          responseData.DriverShiftList[0]?.StartTime.split(" ")[0];
        const endDate = responseData.DriverShiftList[0]?.EndTime.split(" ")[0];
        a[0].startDate = startDate.replace(
          /(\d\d)\-(\d\d)\-(\d{4})/,
          "$3-$1-$2"
        );
        a[0].startTime =
          responseData.DriverShiftList[0]?.StartTime.split(" ")[1];
        a[0].endDate = endDate.replace(/(\d\d)\-(\d\d)\-(\d{4})/, "$3-$1-$2");
        a[0].endTime = responseData.DriverShiftList[0]?.EndTime.split(" ")[1];

        setShiftTimings(a);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // ----------------------------------------------------------------------------------------
  useEffect(() => {
    if (shiftId) {
      rpLocationInputRef.current.value = "Location";
      reportingOfficerMobileInputRef.current.value = "00";
      driverNameRef.current.value = "Name";
      corporateNameRef.current.value = "select";
      sendRequest(
        {
          url: "/api/v1/DriverShift/GetDriverShiftDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID:
              localStorage.getItem("roleId") == "1"
                ? ""
                : localStorage.getItem("adminDepartmentID"),
            startDate: "",
            endDate: "",
            shiftID: shiftId,
            roleId: ROLEIDFROMLOCALSTORAGE,
          },
        },
        getShiftDetails
      );
    }
  }, []);

  useEffect(() => {
    // if (!isNextClicked) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
    script.async = true;

    document.body.appendChild(script);
    // }
  }, [isNextClicked]);

  function initMap() {
    // console.log("init");
    map = new window.google.maps.Map(document.getElementById("shift-map"), {
      center: { lat: 23.0225, lng: 72.5714 },
      zoom: 11,
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
    });
    // background-color: rgb(226, 44, 29);

    startLocationMarker = new window.google.maps.Marker({
      map: map,
      animation: window.google.maps.Animation.DROP,
      icon: svgMarkerForStartLocation,
      // icon: LocationOnIcon,
    });

    endLocationMarker = new window.google.maps.Marker({
      map: map,
      animation: window.google.maps.Animation.DROP,
      icon: svgMarkerForEndLocation,
    });

    let inputs = [
      document.getElementById("pac-input"),
      document.getElementById("end-location-input"),
    ];
    let autocomplete = inputs?.map((input) => {
      return new window.google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: ["in"] },
      });
    });
    autocomplete?.forEach((autocomp, index) => {
      autocomp.bindTo("bounds", map);
      autocomp.addListener("place_changed", function () {
        let place = autocomp.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.log("Place not found");
          return;
        }
        if (index === 0) {
          startLocationMarker.setPosition(place.geometry.location);
          startLocationMarker?.setVisible(true);
          // startLocationMarker.setIcon(
          //   "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          // );
        } else {
          endLocationMarker.setPosition(place.geometry.location);
          endLocationMarker?.setVisible(true);
        }
        // debugger;

        // adjust the zoom so that both the start and end point cover---
        if (
          startLocationMarker?.getPosition()?.lat() &&
          endLocationMarker?.getPosition()?.lat()
        ) {
          var bounds = new window.google.maps.LatLngBounds();

          startLatLng = new window.google.maps.LatLng(
            startLocationMarker?.getPosition()?.lat(),
            startLocationMarker?.getPosition()?.lng()
          );
          endLatLng = new window.google.maps.LatLng(
            endLocationMarker?.getPosition()?.lat(),
            endLocationMarker?.getPosition()?.lng()
          );

          bounds.extend(startLatLng);
          bounds.extend(endLatLng);
          map.fitBounds(bounds);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(18);
        }
        // --------------------
      });
    });
  }
  window.initMap = initMap;

  const getCorporateList = (data) => {
    corporates = data?.CorporateList;
  };

  const getDriverList = (data) => {
    setDriverDetails(data?.DriverList);
    // drivers = data.DriverList;
    setIsGetDriverData(false);
  };

  const shiftCreationResponse = (data) => {
    // debugger;
    // console.log(data);
    setIsShiftCreationSuccess(
      data.Message.toLowerCase() === "success" ? "success" : data.SystemMessage
    );
    setIsShiftSaveClicked(false);
  };

  useEffect(() => {
    if (isGetDriverData) {
      sendRequest(
        {
          url: "/api/v1/DriverList/GetDriverList",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: localStorage.getItem("adminDepartmentID"),
            roleID: localStorage.getItem("roleId"),
          },
        },
        getDriverList
      );
    }
    if (isShiftSaveClicked) {
      let detailsRecord = [];
      if (shiftTimings.length === 0) {
        setFormError((prev) => ({
          ...prev,
          shiftTimingsError: "Please select atleast one shift timing",
        }));
        return;
      }
      for (let i = 0; i < shiftTimings?.length; i++) {
        detailsRecord.push({
          StartTime:
            shiftTimings[i]?.startDate + " " + shiftTimings[i]?.startTime,
          EndTime: shiftTimings[i]?.endDate + " " + shiftTimings[i]?.endTime,
        });
      }
      // console.log("check", startLocationMarker?.getPosition()?.lat());
      sendRequest(
        {
          url: "/api/v1/DriverShift/AddEditDriverShift",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: departmentId
              ? departmentId
              : selectedDepartment.dptId
              ? selectedDepartment.dptId
              : corporateId
              ? corporateId
              : localStorage.getItem("adminDepartmentID"),
            // corporateID: corporateId,
            // parentCorporateID: parentCorporateId
            //   ? parentCorporateId
            //   : CORPORATEIDFROMLOCALSTORAGE,
            parentCorporateID: corporateId
              ? corporateId
              : parentCorporateId
              ? parentCorporateId
              : CORPORATEIDFROMLOCALSTORAGE,
            detailsRecord: JSON.stringify(detailsRecord),
            // driverEmailID: selectedDriver.email,
            driverEmailID:
              selectedDriver.email !== undefined || null
                ? selectedDriver.email
                : selectedDriverEmail,

            reportingMobileNo:
              "91" + reportingOfficerMobileInputRef.current.value,
            // reportingLL: shiftId ? (startLatLng.lat + "," + startLatLng.lng) : (marker.getPosition().lat() + "," + marker.getPosition().lng()),
            // reportingLL:
            //   (startLocationMarker?.getPosition()?.lat()
            //     ? startLocationMarker?.getPosition()?.lat()
            //     : startLatLng?.lat) +
            //   "," +
            //   (startLocationMarker?.getPosition()?.lng()
            //     ? startLocationMarker?.getPosition()?.lng()
            //     : startLatLng?.lng),
            reportingLL:
              (startLocationMarker?.getPosition()?.lat() || startLatLng?.lat) &&
              (startLatLng?.lng || startLocationMarker?.getPosition()?.lng())
                ? (startLocationMarker?.getPosition()?.lat()
                    ? startLocationMarker?.getPosition()?.lat()
                    : startLatLng?.lat) +
                  "," +
                  (startLocationMarker?.getPosition()?.lng()
                    ? startLocationMarker?.getPosition()?.lng()
                    : startLatLng?.lng)
                : "",

            ShiftEndLL:
              (endLocationMarker?.getPosition()?.lat() || endLatLng?.lat) &&
              (endLatLng?.lng || endLocationMarker?.getPosition()?.lng())
                ? (endLocationMarker?.getPosition()?.lat()
                    ? endLocationMarker?.getPosition()?.lat()
                    : endLatLng?.lat) +
                  "," +
                  (endLocationMarker?.getPosition()?.lng()
                    ? endLocationMarker?.getPosition()?.lng()
                    : endLatLng?.lng)
                : "",
            IsStartShiftOtpEnable: IsStartShiftOtpEnable === true ? "1" : "0",
            IsEndShiftOtpEnable: IsEndShiftOtpEnable === true ? "1" : "0",
            TaskIds: selectedTasks,
            reportingLocaiton: rpLocationInputRef.current.value,
            shiftEndLocation: endLocationInputRef.current.value,
            shiftID: shiftId ? shiftId : "",
          },
        },

        shiftCreationResponse
      );
    }
  }, [isGetDriverData, isShiftSaveClicked]);

  useEffect(() => {
    if (localStorage.getItem("roleId") === "1") {
      sendRequest(
        {
          url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
          },
        },
        getCorporateList
      );
    } else {
      corporateNameRef.current.value = localStorage.getItem("cpName");
      corporateNameRef.current.disabled = true;
      departmentNameRef.current.disabled = true;
    }
    departmentNameRef.current.value = "Admin Department";
  }, []);

  const { isLoading, sendRequest } = useHttp();

  const driverSearchHandler = (e) => {
    if (e.target.value)
      setIsFilteredDrivers(
        driverDetails?.filter(
          (data) =>
            data.DriverName.toLowerCase().includes(
              e.target.value.toLowerCase()
            ) || data.Model.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    else setIsFilteredDrivers([]);
  };

  const corporateSearchHandler = (e) => {
    if (e.target.value)
      setIsFilteredCorporates(
        corporates?.filter((data) =>
          data.CorporateName.toLowerCase().includes(e.target.value)
        )
      );
    // setParameterJSON(corporates?.filter((data)=>data.))
    else setIsFilteredCorporates([]);
  };

  // const corporateSelectHandler = (
  //   cpName,
  //   dptNames,
  //   dptIds,
  //   cpId,
  //   parameterjson
  // ) => {
  //   shiftStartOtpFromConfig = JSON.parse(parameterjson)?.shiftStartOTP;
  //   shiftEndOtpFromConfig = JSON.parse(parameterjson)?.shiftEndOTP;

  //   setIsStartShiftOtpEnable(shiftStartOtpFromConfig || false);
  //   setIsEndShiftOtpEnable(shiftEndOtpFromConfig || false);

  //   //  debugger;

  //   corporateNameRef.current.value = cpName;
  //   if (dptNames.toLowerCase().includes("admin")) {
  //     let departmets = dptNames.toLowerCase().split(",");
  //     let indexOf = departmets.indexOf("admin department");
  //     setParentCorporateId(cpId);
  //     selectedDepartment.name = departmets[indexOf];
  //     selectedDepartment.dptId = dptIds.split(",")[indexOf];
  //   } else {
  //     let departmets = dptNames.toLowerCase().split(",");
  //     let indexOf = departmets.indexOf("imitation department");
  //     selectedDepartment.name = departmets[indexOf];
  //     selectedDepartment.dptId = dptIds.split(",")[indexOf];
  //     setParentCorporateId(cpId);
  //   }
  //   setFormError((prev) => ({ ...prev, coprorateNameError: "" }));
  //   departmentNameRef.current.value = selectedDepartment.name;
  //   setIsFilteredCorporates([]);
  // };

  // Corporate select handler for roleId == 1 (Admin)
  const corporateSelectHandler = async (
    cpName,
    dptNames,
    dptIds,
    cpId,
    parameterjson
  ) => {
    shiftStartOtpFromConfig = JSON.parse(parameterjson)?.shiftStartOTP;
    shiftEndOtpFromConfig = JSON.parse(parameterjson)?.shiftEndOTP;

    setIsStartShiftOtpEnable(shiftStartOtpFromConfig || false);
    setIsEndShiftOtpEnable(shiftEndOtpFromConfig || false);

    corporateNameRef.current.value = cpName;

    if (dptNames.toLowerCase().includes("admin")) {
      let departmets = dptNames.toLowerCase().split(",");
      let indexOf = departmets.indexOf("admin department");
      setParentCorporateId(cpId);
      selectedDepartment.name = departmets[indexOf];
      selectedDepartment.dptId = dptIds.split(",")[indexOf];
    } else {
      let departmets = dptNames.toLowerCase().split(",");
      let indexOf = departmets.indexOf("imitation department");
      selectedDepartment.name = departmets[indexOf];
      selectedDepartment.dptId = dptIds.split(",")[indexOf];
      setParentCorporateId(cpId);
    }

    // Reset the selected tasks when corporate is changed
    setSelectedTasks(null);
    setTasks([]); // Reset the task list
    setFormError((prev) => ({ ...prev, coprorateNameError: "" }));
    departmentNameRef.current.value = selectedDepartment.name;
    setIsFilteredCorporates([]);

    const roleID = localStorage.getItem("roleId");

    // Call API only if roleId is 1 (Admin)
    if (roleID === "1") {
      const emailID = localStorage.getItem("user");
      const corporateID = cpId; // Use selected corporate ID
      const departmentID = selectedDepartment.dptId;

      try {
        sendRequest(
          {
            url: "/api/v1/ShiftTask/GetShiftTask",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              emailID,
              corporateID,
              departmentID,
              roleID,
              taskID: "",
            },
          },
          (data) => {
            setTasks(data?.TaskList); // Update tasks state with fetched data
          },
          (error) => {
            console.error("Error fetching tasks:", error);
          }
        );
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    }
  };

  // API call for roleId == 2 (Non-Admin) on page load
  useEffect(() => {
    const roleID = localStorage.getItem("roleId");

    if (roleID === "2") {
      const emailID = localStorage.getItem("user");
      const corporateID = localStorage.getItem("corpId"); // Use corporate ID from localStorage
      const departmentID = "";

      try {
        sendRequest(
          {
            url: "/api/v1/ShiftTask/GetShiftTask",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              emailID,
              corporateID,
              departmentID,
              roleID,
              taskID: "",
            },
          },
          (data) => {
            setTasks(data?.TaskList); // Update tasks state with fetched data
          },
          (error) => {
            console.error("Error fetching tasks:", error);
          }
        );
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    }
  }, []); // Call only once when the page is loaded

  const driverSelectHandler = (drName, drEmail) => {
    selectedDriver.name = drName;
    selectedDriver.email = drEmail;
    driverNameRef.current.value = drName;
    setFormError((prev) => ({ ...prev, driverNameError: "" }));
    setIsFilteredDrivers([]);
  };

  const calenderCloseHandler = (data) => {
    // let newDetails = structuredClone(dateValues);
    // debugger;
    shiftData = [];
    for (let i = 0; i < data?.length; i++) {
      if (!shiftData[i]) shiftData[i] = {};
      shiftData[i].startDate =
        data[i].year +
        "-" +
        (data[i].month.toString()?.length === 1
          ? "0" + data[i].month
          : data[i].month) +
        "-" +
        (data[i].day.toString()?.length === 1
          ? "0" + data[i].day
          : data[i].day);
      shiftData[i].endDate =
        +data[i].year +
        "-" +
        (data[i].month.toString()?.length === 1
          ? "0" + data[i].month
          : data[i].month) +
        "-" +
        (data[i].day.toString()?.length === 1
          ? "0" + data[i].day
          : data[i].day);
    }
  };

  //   Start Time----------
  const startTimePickerCloseHandler = () => {
    for (let i = 0; i < dateValues?.length; i++) {
      shiftData[i].startTime = shiftStartTimeInputRef?.current?.value;
    }
    const startTimeForTotalMinutes = shiftStartTimeInputRef?.current?.value;
    const endTimeForTotalMinutes = shiftEndTimeInputRef?.current?.value;
    calculateTotalTime(startTimeForTotalMinutes, endTimeForTotalMinutes);
  };

  //   End Time
  const endTimePickerCloseHandler = (e) => {
    for (let i = 0; i < dateValues?.length; i++) {
      shiftData[i].endTime = shiftEndTimeInputRef?.current?.value;
    }
    const startTimeForTotalMinutes = shiftStartTimeInputRef?.current?.value;
    const endTimeForTotalMinutes = shiftEndTimeInputRef?.current?.value;
    calculateTotalTime(startTimeForTotalMinutes, endTimeForTotalMinutes);
  };

  // Calculated total time ---------------
  const calculateTotalTime = (startDate, endDate) => {
    if (startDate && endDate) {
      const calculateTotalMinutes = (startTime, endTime) => {
        // Split the time strings into hours and minutes
        const [startHour, startMinute] = startTime.split(":")?.map(Number);
        const [endHour, endMinute] = endTime.split(":")?.map(Number);

        // Convert hours and minutes into total minutes
        const startTotalMinutes = startHour * 60 + startMinute;
        const endTotalMinutes = endHour * 60 + endMinute;

        // Calculate the difference in total minutes

        const totalMinutesIfEndTimeIsLessThanStartTime =
          endTotalMinutes - startTotalMinutes + 1440; // add 1440 minutes if the endTime is less than startTime

        const totalMinutesIfEndTimeIsMoreThanStartTime =
          endTotalMinutes - startTotalMinutes;

        return endTotalMinutes < startTotalMinutes
          ? totalMinutesIfEndTimeIsLessThanStartTime
          : totalMinutesIfEndTimeIsMoreThanStartTime;
      };

      const totalMinutes = calculateTotalMinutes(startDate, endDate);
      // Convert totalMinutes into hours and remaining minutes
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      // Update the state with the new time
      setTime({ hours, minutes });
    }
  };

  const shiftRowCancelHandler = (i) => {
    shiftData?.filter((val, index) => index !== i);
    setShiftTimings((prev) => prev?.filter((val, index) => index !== i));
    setDateValues((prev) => prev?.filter((val, index) => index !== i));
  };

  const shiftRowValueChangeHandler = (e, i) => {
    // debugger;
    let a = structuredClone(shiftTimings);
    a[i][e.target.name] = e.target.value;
    // console.log(a);
    setShiftTimings(a);
    shiftData = a;
    // console.log(e.targetIndex);
  };

  const addShiftDetailsClickHandler = () => {
    for (let i = 0; i < shiftData?.length; i++) {
      if (
        !shiftData[i].startDate ||
        !shiftData[i].startTime ||
        !shiftData[i].endDate ||
        !shiftData[i].endTime
      ) {
        return;
      }

      // Extract start and end times
      const startTime = shiftData[i].startTime;
      const endTime = shiftData[i].endTime;

      // Check if start time is less than end time
      const startTimeLessThanEndTime = startTime < endTime;

      if (!startTimeLessThanEndTime) {
        // Increment end date by 1 day

        // const nextDay = new Date(shiftData[i].endDate);

        const nextDay = new Date(shiftData[i].startDate);
        nextDay.setDate(nextDay.getDate() + 1);
        shiftData[i].endDate = nextDay.toISOString().slice(0, 10);
      }
    }

    let clonedShiftData = structuredClone(shiftData);
    setFormError((prev) => ({ ...prev, shiftTimingsError: "" }));
    setShiftTimings(clonedShiftData);
    // Clone the existing shiftData and append the new data
    // const updatedShiftData = [...shiftData, ...shiftTimings];

    // setFormError((prev) => ({ ...prev, shiftTimingsError: "" }));
    // setShiftTimings(updatedShiftData);
  };

  const corporateNameChangeHandler = () => {
    if (corporateNameRef.current.value) {
      setFormError((prev) => ({ ...prev, coprorateNameError: "" }));
    }
  };

  const departmentNameChangeHandler = () => {
    if (departmentNameRef.current.value) {
      setFormError((prev) => ({ ...prev, departmentNameError: "" }));
    }
  };

  const roMobileNumberChangeHandler = () => {
    if (reportingOfficerMobileInputRef.current.value) {
      setFormError((prev) => ({ ...prev, roMobileError: "" }));
    }
  };

  const rpLocationChangeHandler = () => {
    if (rpLocationInputRef.current.value) {
      setFormError((prev) => ({ ...prev, rpLocationError: "" }));
    }
  };
  // Handler function for end location change
  const endLocationChangeHandler = () => {
    if (endLocationInputRef.current.value) {
      setFormError((prev) => ({ ...prev, endLocationError: "" }));
    }
  };

  const nextSlideClickHandler = (isNextClicked) => {
    // debugger;
    if (isNextClicked) {
      setTimeout(() => {
        // if (marker?.getPosition()?.lat())
        //     startLatLng.lat = marker.getPosition().lat();
        // if (marker?.getPosition()?.lng())
        //     startLatLng.lng = marker.getPosition().lng();
        map.setCenter(
          shiftId
            ? { lat: startLatLng?.lat, lng: startLatLng?.lng }
            : {
                lat: marker?.getPosition()?.lat(),
                lng: marker?.getPosition()?.lng(),
              }
        );
        map?.setZoom(18);
        marker?.setVisible(true);
        marker?.setPosition(
          shiftId
            ? { lat: startLatLng.lat, lng: startLatLng.lng }
            : {
                lat: marker?.getPosition()?.lat(),
                lng: marker?.getPosition()?.lng(),
              }
        );
      }, 1000);
    }
    if (!corporateNameRef.current.value) {
      setFormError((prev) => ({
        ...prev,
        coprorateNameError: "Invalid Corporate Name",
      }));
    }
    if (!departmentNameRef.current.value) {
      setFormError((prev) => ({
        ...prev,
        departmentNameError: "Invalid Department Name",
      }));
    }
    if (
      !reportingOfficerMobileInputRef.current.value ||
      reportingOfficerMobileInputRef.current.value?.length !== 10
    ) {
      setFormError((prev) => ({
        ...prev,
        roMobileError: "Invalid Mobile Number",
      }));
    }
    if (!rpLocationInputRef.current.value) {
      setFormError((prev) => ({
        ...prev,
        rpLocationError: "Invalid Reporting Location",
      }));
    }
    if (!driverNameRef.current.value) {
      setFormError((prev) => ({
        ...prev,
        driverNameError: "Invalid Driver Name",
      }));
    }
    if (
      corporateNameRef.current.value &&
      departmentNameRef.current.value &&
      reportingOfficerMobileInputRef?.current?.value?.length === 10 &&
      // rpLocationInputRef.current.value &&
      driverNameRef.current.value
    ) {
      setIsNextClicked((prev) => !prev);
    }
  };

  // trying new api calling using usehttps
  // useEffect(() => {
  //   const emailID = USEREMAILIDFROMLOCALSTORAGE;
  //   // let corporateID = CORPORATEIDFROMLOCALSTORAGE;
  //   const corporateID = parentCorporateId
  //     ? parentCorporateId
  //     : CORPORATEIDFROMLOCALSTORAGE;
  //   const departmentID = "";
  //   const roleID = ROLEIDFROMLOCALSTORAGE;

  //   const fetchData = async () => {
  //     try {
  //       sendRequest(
  //         {
  //           url: "/api/v1/ShiftTask/GetShiftTask",
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: {
  //             emailID,
  //             corporateID,
  //             departmentID,
  //             roleID,
  //             taskID: "",
  //           },
  //         },
  //         (data) => {
  //           setTasks(data?.TaskList); // Update tasks state with fetched data
  //         },
  //         (error) => {
  //           console.error("Error fetching tasks:", error); // Log error if request fails
  //         }
  //       );
  //     } catch (error) {
  //       console.error("Error fetching tasks:", error); // Log error if request fails
  //     }
  //   };

  //   fetchData();
  // }, [filteredCorporates]);

  const handleTaskChange = (event, newValue) => {
    // const selectedTaskIds = newValue
    //   ?.map((taskName) => getTaskIdFromName(taskName))
    //   .filter(Boolean)
    //   .join(",");
    // setSelectedTasks(selectedTaskIds);
    if (!newValue || newValue.length === 0) {
      setSelectedTasks(null);
    } else {
      const selectedTaskIds = newValue
        ?.map((taskName) => getTaskIdFromName(taskName))
        .filter(Boolean)
        .join(",");
      setSelectedTasks(selectedTaskIds);
    }
  };
  // Function to get TaskID from selected TaskName
  const getTaskIdFromName = (taskName) => {
    const selectedTask = tasks?.find((task) => task.TaskName === taskName);
    return selectedTask ? selectedTask.TaskID : null;
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          textTransform: "uppercase",
          fontWeight: "bold",
          padding: "1% 2%",
          fontSize: "18px",
        }}
      >
        Private Driver Shift Creation
      </div>
      <div className="shift-container">
        <div className="shift-general-details">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "1%",
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div style={{ position: "relative" }}>
              <TextField
                className="standard-basic"
                error={formError.coprorateNameError ? true : false}
                helperText={formError.coprorateNameError}
                label="Corporate"
                variant="standard"
                inputRef={corporateNameRef}
                onChange={corporateSearchHandler}
                autoComplete="off"
              />
              {filteredCorporates && (
                <div className="searchedDriverList">
                  {filteredCorporates?.map((cp) => (
                    <p
                      onClick={() =>
                        corporateSelectHandler(
                          cp.CorporateName,
                          cp.DepartmentName,
                          cp.DepartmentID,
                          cp.CorporateID,
                          cp.ParameterJSON
                        )
                      }
                    >
                      {cp.CorporateName}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <TextField
              className="standard-basic"
              onChange={departmentNameChangeHandler}
              error={formError.departmentNameError ? true : false}
              helperText={formError.departmentNameError}
              label="Department"
              variant="standard"
              inputRef={departmentNameRef}
              autoComplete="off"
            />
            <div style={{ position: "relative" }}>
              <TextField
                className="standard-basic"
                error={formError.driverNameError ? true : false}
                helperText={formError.driverNameError}
                label="Driver Details"
                variant="standard"
                onChange={driverSearchHandler}
                inputRef={driverNameRef}
                autoComplete="off"
              />
              {filteredDrivers?.length > 0 && (
                <div className="searchedDriverList">
                  {filteredDrivers?.map((dr) => (
                    <p
                      onClick={() =>
                        driverSelectHandler(dr?.DriverName, dr?.DriverEmailID)
                      }
                    >
                      {dr?.DriverName + " (" + dr?.Model + " )"}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <TextField
              className="standard-basic"
              onChange={roMobileNumberChangeHandler}
              error={formError?.roMobileError ? true : false}
              type="number"
              inputProps={{ maxLength: 10 }}
              helperText={formError?.roMobileError}
              label="Reporting Officer Mobile Number"
              variant="standard"
              inputRef={reportingOfficerMobileInputRef}
              autoComplete="off"
            />
            <TextField
              className="standard-basic"
              onChange={rpLocationChangeHandler}
              // error={formError.rpLocationError ? true : false}
              // helperText={formError.rpLocationError}
              label="Reporting Location"
              id="pac-input"
              variant="standard"
              inputRef={rpLocationInputRef}
              autoComplete="off"
            />

            {/*add checkbox and an extra endlocation as well one select tag  */}

            <FormControlLabel
              control={
                <Checkbox
                  name="reportingLocation"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 15, color: "#ccc" } }}
                  // value={IsStartShiftOtpEnable}
                  checked={IsStartShiftOtpEnable}
                  onChange={() =>
                    setIsStartShiftOtpEnable(!IsStartShiftOtpEnable)
                  }
                />
              }
              label={
                <span style={{ color: "gray", opacity: "0.75" }}>
                  Enable Report Location
                </span>
              }
              style={{ marginTop: "-10px" }}
            />
            <TextField
              className="standard-basic"
              onChange={endLocationChangeHandler}
              error={endLocationError ? true : false}
              helperText={endLocationError}
              inputRef={endLocationInputRef}
              label="End Location"
              variant="standard"
              id="end-location-input"
              autoComplete="off"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="endLocationEnabled"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 15, color: "#ccc" } }}
                  // value={IsEndShiftOtpEnable}
                  checked={IsEndShiftOtpEnable}
                  onChange={() => setIsEndShiftOtpEnable(!IsEndShiftOtpEnable)}
                />
              }
              label={
                <span style={{ color: "gray", opacity: "0.75" }}>
                  Enable End Location
                </span>
              }
              style={{ marginTop: "-10px", fontSize: "small" }}
            />
            {/* Checkbox for additional options */}

            {/* If tasks are there then only show the input of tasks other wise do not show the input of task */}
            {tasks && tasks.length > 0 && (
              <Autocomplete
                multiple
                // options={tasks?.map((task) => task?.TaskName)}
                options={tasks?.map((task) => task?.TaskName) || []}
                value={selectedTasks
                  ?.split(",")
                  ?.map(
                    (taskId) =>
                      tasks.find((task) => task?.TaskID === Number(taskId))
                        ?.TaskName || ""
                  )}
                onChange={(event, newValue) =>
                  handleTaskChange(event, newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="standard-basic"
                    label="Task Name"
                    variant="standard"
                    autoComplete="off"
                  />
                )}
              />
            )}
          </div>
          {isNextClicked && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "whitesmoke",
                top: "0",
                left: "0",
                width: "100%",
                height: "80%",
                opacity: "0.3",
                cursor: "not-allowed",
              }}
            ></div>
          )}
          <div style={{ alignSelf: "flex-end", marginTop: "30px" }}>
            <button
              id="shiftNext"
              style={{
                backgroundColor: "rgba(34, 137, 203, 255)",
                width: "80px",
                color: "white",
                border: "1px solid rgba(34, 137, 203, 255)",
                padding: "7px 15px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => nextSlideClickHandler(isNextClicked)}
            >
              {isNextClicked ? "BACK" : "NEXT"}
            </button>
          </div>
        </div>
        <div className="shift-timing-details">
          {!isNextClicked ? (
            <ShiftMap />
          ) : (
            <div
              style={{
                backgroundColor: "#f3f6f9",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 2%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {/* Start Date -------------------- */}
                  <label htmlFor="date" style={{ fontSize: "14px" }}>
                    Date:
                  </label>
                  <DatePicker
                    multiple
                    format="YYYY-MM-DD"
                    onChange={(data) => {
                      setDateValues(data);
                      if (shiftTimings.length > 0) {
                        shiftStartTimeInputRef.current.value = "";
                        shiftEndTimeInputRef.current.value = "";
                      }
                      calenderCloseHandler(data);
                    }}
                    sort
                    // onClose={calenderCloseHandler}
                    // inputClass="custom-input"
                    style={{
                      border: "none",
                      height: "30px",
                      paddingLeft: "7px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                    editable={false}
                    placeholder="Select Date"
                  />
                  {/* Start Date ----------------*/}
                  <label htmlFor="startTime" style={{ fontSize: "14px" }}>
                    Start Time:
                  </label>
                  <input
                    className="jay"
                    style={{
                      border: "none",
                      height: "30px",
                      paddingLeft: "7px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                    type="text"
                    placeholder="Start Time"
                    onChange={startTimePickerCloseHandler}
                    ref={shiftStartTimeInputRef}
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  {/* End Date ------------------*/}
                  <label htmlFor="endTIme" style={{ fontSize: "14px" }}>
                    End Time:
                  </label>
                  <input
                    className="jay"
                    style={{
                      border: "none",
                      height: "30px",
                      paddingLeft: "7px",
                      borderRadius: "5px",
                      width: "100px",
                    }}
                    type="text"
                    placeholder="End Time"
                    onChange={endTimePickerCloseHandler}
                    ref={shiftEndTimeInputRef}
                    onFocus={(e) => (e.target.type = "time")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </div>
                <p style={{ marginTop: "7px", fontSize: "14px" }}>
                  Total Time: {time?.hours} Hr {time?.minutes} Min
                </p>
                <button
                  className="addShiftDetails"
                  onClick={addShiftDetailsClickHandler}
                >
                  Add Details
                </button>
              </div>
              <div
                style={{
                  background: "white",
                  height: "90%",
                  margin: "2%",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ height: "auto" }}>
                  <br />
                  <div
                    className="shiftDetailsHeader"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      height: "40px",
                      alignItems: "center",
                      backgroundColor: "rgba(235, 237, 240, 0.34)",
                      margin: "0 2%",
                      borderRadius: "10px",
                    }}
                  >
                    <div style={{ flexBasis: "20%" }}>StartDate</div>
                    <div style={{ flexBasis: "20%" }}>StartTime</div>
                    <div style={{ flexBasis: "20%" }}>EndDate</div>
                    <div style={{ flexBasis: "20%" }}>EndTime</div>
                    <div style={{ flexBasis: "20%" }}>Total Time</div>
                  </div>
                  <div className="shiftTimingsContainer">
                    {shiftTimings?.map((val, index) => {
                      const startDateTime = new Date(
                        `${val?.startDate} ${val?.startTime}`
                      );
                      const endDateTime = new Date(
                        `${val?.endDate} ${val?.endTime}`
                      );
                      const timeDiff = Math.abs(endDateTime - startDateTime);
                      const totalHours = Math.floor(
                        timeDiff / (1000 * 60 * 60)
                      );
                      const totalMinutes = Math.floor(
                        (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
                      );
                      const totalTime = `${totalHours}hr ${totalMinutes}min`;
                      return (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              className="shiftDetailsRow"
                              style={{ width: "20%" }}
                              id={index + "date"}
                              onChange={(e) =>
                                shiftRowValueChangeHandler(e, index)
                              }
                            >
                              <input
                                name="startDate"
                                type="date"
                                value={val.startDate}
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div
                              className="shiftDetailsRow"
                              style={{ width: "20%" }}
                              id={index + "time"}
                              onChange={(e) =>
                                shiftRowValueChangeHandler(e, index)
                              }
                            >
                              <input
                                name="startTime"
                                value={val.startTime}
                                style={{
                                  color: "rgba(42, 149, 69, 255)",
                                  backgroundColor: "rgba(42, 149, 69, 0.14)",
                                  padding: "7px 10px",
                                  borderRadius: "10px",
                                  width: "100%",
                                }}
                                type="text"
                                onFocus={(e) => (e.target.type = "time")}
                                onBlur={(e) => (e.target.type = "text")}
                              />
                            </div>
                            <div
                              className="shiftDetailsRow"
                              style={{ width: "20%" }}
                              id={index + "endDate"}
                              onChange={(e) =>
                                shiftRowValueChangeHandler(e, index)
                              }
                            >
                              <input
                                name="endDate"
                                value={val.endDate}
                                type="date"
                                style={{ width: "100%" }}
                              />
                            </div>
                            <div
                              className="shiftDetailsRow"
                              style={{ width: "20%" }}
                              id={index + "endTime"}
                              onChange={(e) =>
                                shiftRowValueChangeHandler(e, index)
                              }
                            >
                              <input
                                name="endTime"
                                value={val.endTime}
                                style={{
                                  color: "rgb(226, 44, 29)",
                                  backgroundColor: "rgba(226, 44, 29, 0.14)",
                                  padding: "7px 10px",
                                  borderRadius: "10px",
                                  width: "100%",
                                }}
                                type="text"
                                onFocus={(e) => (e.target.type = "time")}
                                onBlur={(e) => (e.target.type = "text")}
                              />
                            </div>
                            <div
                              style={{ flexBasis: "20%", textAlign: "center" }}
                            >
                              <p
                                style={{
                                  padding: "4px",
                                  borderRadius: "10px",
                                  marginTop: "15px",
                                  marginLeft: "5px",
                                }}
                              >
                                {totalTime}
                              </p>
                            </div>
                            <AiOutlineDelete
                              style={{
                                marginTop: "10px",
                                marginRight: "20px",
                                cursor: "pointer",
                              }}
                              onClick={() => shiftRowCancelHandler(index)}
                            />
                          </div>
                          <hr style={{ margin: "2%", borderTop: "#f3f6f9" }} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                  {formError.shiftTimingsError && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "left",
                        padding: "20px",
                      }}
                    >
                      {"*" + formError.shiftTimingsError}
                    </p>
                  )}
                </div>
                <button
                  className="shiftSaveButton"
                  onClick={() => setIsShiftSaveClicked(true)}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLoading && (isShiftSaveClicked || shiftId) && (
        <img src={loadingGif} className="loading-gif" />
      )}
      {isShiftCreationSuccess && (
        <Message
          type={isShiftCreationSuccess}
          driveErrorMessage={
            isShiftCreationSuccess?.toLowerCase()?.includes("greater")
              ? "Shift End Time should be greater than Current Time/ Start Time"
              : ""
          }
          message={
            driverNameRef.current.value +
            "'s shift has been created Successfully"
          }
          onAddAnother={() => window.location.reload()}
          // Pass a custom function for the "OK" button
          onOk={() => history.push("/privatedrive/shifts")}
          showAddAnotherButton={true}
          showAddAnotherButtonText={"Add Another Shift"}
        />
      )}
    </div>
  );
};

export default ShiftCreation;
