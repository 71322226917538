import React, { useEffect, useRef, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DoNotDisturbOffIcon from "@mui/icons-material/DoNotDisturbOff";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "./Stops.css";
import useHttp from "../../../Hooks/use-http";

let lat, lng;
let marker, map, infowindow;
const label = { inputProps: { "aria-label": "Size switch demo" } };
const Stops = () => {
  const [corporateList, setCorporateList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState({
    name: "",
    id: "",
  });
  const [stops, setStops] = useState([]);
  const [isAddStopClicked, setIsAddStopClicked] = useState(false);
  const [changeActiveStopID, setChangeActiveStopID] = useState();
  const [editStopDetails, setEditedStopDetails] = useState(false);
  const inputStopRef = useRef();
  const nameRef=useRef()

  const corporateListsResponse = (data) => {
    let temp = data.CorporateList.filter((cp) =>
      cp.EnabledModule?.toLowerCase().includes("shuttle")
    );
    setCorporateList(temp);
  };

  const corporateStopDetails = (data) => {
    let temp = [];
    for (let i = 0; i < data.ShuttleStopList?.length; i++) {
      temp.push({
        id: data.ShuttleStopList[i].ShuttlestopID,
        name: data.ShuttleStopList[i].StopName,
        latLng: data.ShuttleStopList[i].StopLatlong,
        isActive: data.ShuttleStopList[i].IsActive,
      });
    }
    setStops(temp);
  };

  const addStopResponseHandler = (data) => {
    if (data.Message.toLowerCase() === "success") {
      let temp = [...stops];
      const namePrefix = nameRef.current.value ? `(${nameRef.current.value})- ` : "";
      temp.push({
        // name: inputStopRef.current.value,
        name: `${namePrefix}${inputStopRef.current.value}`,
        latLng: lat + "," + lng,
        isActive: true,
      });
      setStops(temp);
      inputStopRef.current.value = "";
      nameRef.current.value = "";
      lat = null;
      lng = null;
      marker.setVisible(false);
      map.setCenter({ lat: 23.0225, lng: 72.5714 });
      map.setZoom(11);
      infowindow.close();
    }
    setIsAddStopClicked(false);
  };

  const editStopResponseHandler = (data) => {
    // console.log(data);
    setEditedStopDetails(false);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (editStopDetails?.id)
      sendRequest(
        {
          url: "/api/v1/Corporate/AddEditShuttleStop",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: selectedCorporate.id,
            isActive: editStopDetails.status,
            stopLatlong: "",
            stopName: "",
            shuttlestopID: editStopDetails.id,
          },
        },
        editStopResponseHandler
      );
  }, [editStopDetails?.id]);

  useEffect(() => {
    if (isAddStopClicked) {
      const namePrefix = nameRef.current.value ? `(${nameRef.current.value})- ` : "";
      sendRequest(
        {
          url: "/api/v1/Corporate/AddEditShuttleStop",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: selectedCorporate.id || localStorage.getItem("corpId"),
            isActive: true,
            stopLatlong: lat + "," + lng,
            // stopName: inputStopRef.current.value,
            stopName:  `${namePrefix}${inputStopRef.current.value}`,
            shuttlestopID: "",
          },
        },
        addStopResponseHandler
      );
    }
  }, [isAddStopClicked]);

  useEffect(() => {
    if (selectedCorporate.id || localStorage.getItem("roleId") === "2") {
      sendRequest(
        {
          url: "/api/v1/Corporate/GetShuttleStop",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID: selectedCorporate.id || localStorage.getItem("corpId"),
          },
        },
        corporateStopDetails
      );
    }
  }, [sendRequest, selectedCorporate]);

  useEffect(() => {
    if (localStorage.getItem("roleId") === "1") {
      sendRequest(
        {
          url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
          },
        },
        corporateListsResponse
      );
    }
  }, [sendRequest]);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src =
  //     "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
  //   script.async = true;

  //   document.body.appendChild(script);
  // }, []);

  // function initMap() {
  //   map = new window.google.maps.Map(document.getElementById("map"), {
  //     center: { lat: 23.0225, lng: 72.5714 },
  //     zoom: 11,
  //     disableDefaultUI: true,
  //     fullscreenControl: true,
  //     zoomControl: true,
  //   });
  //   var input1 = document.getElementById("pac-input1");

  //   var autocomplete = [];
  //   autocomplete = new window.google.maps.places.Autocomplete(input1);
  //   var geocoder = new window.google.maps.Geocoder();

  //   autocomplete.bindTo("bounds", map);
  //   infowindow = new window.google.maps.InfoWindow();
  //   var infowindowContent = document.getElementById("infowindow-content");
  //   infowindow.setContent(infowindowContent);
  //   marker = new window.google.maps.Marker({
  //     position: "",
  //     map: map,
  //     draggable: true,
  //     animation: window.google.maps.Animation.DROP,
  //     anchorPoint: new window.google.maps.Point(0, -29),
  //   });

  //   window.google.maps.event.addListener(marker, "dragend", function (marker) {
  //     geocoder.geocode(
  //       {
  //         latLng: marker.latLng,
  //       },
  //       function (value) {
  //         infowindowContent.children["place-address"].textContent =
  //           value[0].formatted_address;
  //         infowindowContent.children["place-name"].textContent = "";
  //         if (inputStopRef.current.value) {
  //           inputStopRef.current.value = value[0].formatted_address;
  //         }
  //       }
  //     );
  //     lat = marker.latLng.lat();
  //     lng = marker.latLng.lng();
  //   });

  //   autocomplete.addListener("place_changed", function () {
  //     infowindow.close();
  //     marker.setVisible(false);
  //     var place = autocomplete.getPlace();
  //     if (!place.geometry || !place.geometry.location) {
  //       window.alert("No details available for input: '" + place.name + "'");
  //       return;
  //     }
  //     if (place.geometry.viewport) {
  //       // console.log(place.geometry.location);
  //       map.setCenter(place.geometry.location);
  //       map.setZoom(18);
  //     } else {
  //       map.setCenter(place.geometry.location);
  //       map.setZoom(13);
  //     }
  //     marker.setPosition(place.geometry.location);
  //     marker.setVisible(true);
  //     lat = marker.getPosition().lat();
  //     lng = marker.getPosition().lng();
  //     infowindowContent.children["place-name"].textContent = place.name;
  //     infowindowContent.children["place-address"].textContent =
  //       place.formatted_address;
  //     infowindow.open(map, marker);
  //   });
  // }
  // window.initMap = initMap;
  useEffect(() => {
    const initMap = () => {
      map = new window.google.maps.Map(document.getElementById("map"), {
        center: { lat: 23.0225, lng: 72.5714 },
        zoom: 11,
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
      });
      var input1 = document.getElementById("pac-input1");
  
      var autocomplete = new window.google.maps.places.Autocomplete(input1);
      var geocoder = new window.google.maps.Geocoder();
  
      autocomplete.bindTo("bounds", map);
      infowindow = new window.google.maps.InfoWindow();
      var infowindowContent = document.getElementById("infowindow-content");
      infowindow.setHeaderContent(infowindowContent);
      marker = new window.google.maps.Marker({
        map: map,
        draggable: true,
        animation: window.google.maps.Animation.DROP,
        anchorPoint: new window.google.maps.Point(0, -29),
      });
  
      window.google.maps.event.addListener(marker, "dragend", function (event) {
        geocoder.geocode({ latLng: event.latLng }, function (results) {
          if (results[0]) {
            infowindowContent.children["place-address"].textContent =
              results[0].formatted_address;
            infowindowContent.children["place-name"].textContent = "";
            if (inputStopRef.current.value) {
              inputStopRef.current.value = results[0].formatted_address;
            }
          }
        });
        lat = event.latLng.lat();
        lng = event.latLng.lng();
      });
  
      autocomplete.addListener("place_changed", function () {
        infowindow.close();
        marker.setVisible(false);
        var place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(13);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        lat = marker.getPosition().lat();
        lng = marker.getPosition().lng();
        infowindowContent.children["place-name"].textContent = place.name;
        infowindowContent.children["place-address"].textContent = place.formatted_address;
        infowindow.open(map, marker);
      });
    };
  
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
    script.async = true;
    script.onload = initMap;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  

  const handleCorporateSelection = (e) => {
    const selectedValue = e.target.value;
    const selectedObject = corporateList.find(
      (corporate) => corporate.CorporateName === selectedValue
    );
    setSelectedCorporate({
      name: selectedValue,
      id: selectedObject.CorporateID,
    });
  };

  const addStopHandler = () => {
    if (inputStopRef.current.value) setIsAddStopClicked(true);
  };

  const locateToStopHandler = (i) => {
    let desiredStop = stops[i];
    map.setCenter({
      lat: +desiredStop.latLng.split(",")[0],
      lng: +desiredStop.latLng.split(",")[1],
    });
    marker.setVisible(true);
    marker.setPosition({
      lat: +desiredStop.latLng.split(",")[0],
      lng: +desiredStop.latLng.split(",")[1],
    });
    map.setZoom(18);
    infowindow.open(map, marker);
    infowindow.setHeaderContent(desiredStop.name);
  };

  const editStopHandler = () => {
    setEditedStopDetails({
      id: changeActiveStopID,
      status: !stops.filter((data) => data.id === changeActiveStopID)[0]
        .isActive,
    });
    stops.map((data) => {
      if (data.id === changeActiveStopID) data.isActive = !data.isActive;
    });
    setChangeActiveStopID(false);
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "rgba(34, 137, 203, 255)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="stops-container">
        <div className="stops-container__stopDetails">
          {localStorage.getItem("roleId") === "1" && (
            <FormControl variant="standard" sx={{ m: 1, width: 250 }}>
              <InputLabel id="country-select-standard-label">
                Corporate
              </InputLabel>
              <Select
                labelId="country-select-standard-label"
                id="country-select-standard"
                name="Corporate"
                value={selectedCorporate?.name}
                onChange={handleCorporateSelection}
                label="Corporate"
              >
                {corporateList.map((val) => (
                  <MenuItem key={val.CorporateID} value={val?.CorporateName}>
                    {val?.CorporateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <h3 style={{ margin: "10px 0" }}>Stops :</h3>
          <div style={{ height: "75%", overflowY: "auto" }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sn.</TableCell>
                    <TableCell>Stop Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stops.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "70%",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Locate Stop" arrow>
                            <MyLocationIcon
                              sx={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => locateToStopHandler(i)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              (row.isActive ? "Disable" : "Enable") + " Stop"
                            }
                            arrow
                          >
                            <Switch
                              {...label}
                              checked={row.isActive}
                              size="small"
                              onChange={() => setChangeActiveStopID(row.id)}
                            />
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {stops.length === 0 && (
                    <tr style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "40%",
                          top: "10px",
                        }}
                      >
                        No data Available
                      </div>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="stops-container__inputStops">
          <div className="stop-input-container" style={{display:"flex", gap:'10px'}}>
          <TextField
              // id="pac-input1"
              label="Name"
              variant="standard"
              // className="stopInputSearch"
              placeholder="Enter the Name"
              inputRef={nameRef}
              sx={{ width: "85%" }}
            />
            <TextField
              id="pac-input1"
              label="Stop Name"
              variant="standard"
              className="stopInputSearch"
              inputRef={inputStopRef}
              sx={{ width: "85%" }}
            />
            <Button
              variant="contained"
              size="small"
              color="success"
              onClick={addStopHandler}
            >
              Add
            </Button>
          </div>
          <div>
            <div id="map"></div>
            <div id="infowindow-content">
              <span id="place-name" className="title"></span>
              <br />
              <span id="place-address"></span>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={changeActiveStopID}
        onClose={() => setChangeActiveStopID(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Riders will be removed from the stop if you disable. Do you really
            want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeActiveStopID(false)}>Cancel</Button>
          <Button onClick={editStopHandler} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Stops;
