// import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
// import Accordian from "./Accordian";

// import editIcon from "../../Assets/editIcon.png";
// import tripsIcon from "../../Assets/tripsIcon.png";
// import statementIcon from "../../Assets/statementIcon.png";
// import staffIconNew from "../../Assets/staffIconNew.png";
// import adminIcon from "../../Assets/adminIcon.png";

// import "./Records.css";
// import Loading from "../../Loading/Loading";

// const Records = ({ isLoading, data, headers }) => {
//   const history = useHistory();
//   const func = (val) => {
//     if (val) {
//       document.getElementById(val).click();
//     }
//   };
//   const subListClickHandler = (e) => {
//     let dptId = e.target.parentElement.id;
//     console.log(e.target.alt !== "statement" && dptId);
//     if (e.target.alt !== "statement" && dptId)
//       history.push(`${e.target.alt}?departmentId=${dptId}`);
//   };
//   return (
//     <React.Fragment>
//       {}
//       {data[0] ? (
//         <table className="table" id="my-table">
//           <thead>
//             <tr>
//               {headers.map((data) => (
//                 <th>{data}</th>
//               ))}
//               {localStorage.getItem("userType") !== "AccountManager" && (
//                 <th>Actions</th>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((myData) => (
//               <tr>
//                 <td>{myData.department_name}</td>
//                 <td>{myData.admin_name}</td>
//                 <td>{myData.admin_email}</td>
//                 <td>{myData.vehicle_category}</td>
//                 {localStorage.getItem("userType") !== "AccountManager" && (
//                   <td
//                     id={myData.id}
//                     onClick={subListClickHandler}
//                     className="department-data"
//                   >
//                     <img
//                       src={editIcon}
//                       alt="edit"
//                       title="Click to edit department Details"
//                     />
//                     {/* <img src={tripsIcon} alt="trips" title="Click to see Trips Details" /> */}
//                     <img
//                       src={statementIcon}
//                       alt="statement"
//                       title="Click to see Statement"
//                     />
//                     <img
//                       src={staffIconNew}
//                       alt="staff"
//                       title="Click to see Staff Details"
//                     />
//                     <img
//                       src={adminIcon}
//                       alt="admins"
//                       title="Click to see Admin Details"
//                     />
//                   </td>
//                 )}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//         <React.Fragment>
//           <table className="table" id="my-table">
//             <thead>
//               <tr>
//                 {headers.map((data) => (
//                   <th>{data}</th>
//                 ))}
//                 <th>Actions</th>
//               </tr>
//             </thead>
//           </table>
//           {isLoading ? (
//             <Loading datatable="true" />
//           ) : (
//             <div style={{ textAlign: "center", marginTop: "10px" }}>
//               No Data Available
//             </div>
//           )}
//         </React.Fragment>
//       )}
//     </React.Fragment>
//   );
// };

// export default Records;

//git push origin HEAD:refs/heads/<origin>

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import Loading from "../../Loading/Loading";

import "./Records.css";

import editIcon from "../../Assets/editIcon.png";
import tripsIcon from "../../Assets/tripsIcon.png";
import statementIcon from "../../Assets/statementIcon.png";
import staffIconNew from "../../Assets/staffIconNew.png";
import adminIcon from "../../Assets/adminIcon.png";
const Records = ({ isLoading, data, headers }) => {
  const history = useHistory();
  const [orderBy, setOrderBy] = useState("Department Name");
  const [order, setOrder] = useState("asc");

  const handleSort = (column) => {
    if (orderBy === column && order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(column);
  };

  const sortedData = data.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  const subListClickHandler = (e) => {
    let dptId = e.target.parentElement.id;
    // console.log(e.target.alt !== "statement" && dptId);
    if (e.target.alt !== "statement" && dptId)
      history.push(`${e.target.alt}?departmentId=${dptId}`);
  };
  return (
    <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
      <Table className="table" id="my-table" sx={{ maxWidth: "100%" }}>
        <TableHead>
          <TableRow>
            {headers?.map((header) => (
              <TableCell
                key={header}
                sx={{
                  py: 0,
                  px: 10,
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                <TableSortLabel
                  active={orderBy === header}
                  direction={orderBy === header ? order : "asc"}
                  onClick={() => handleSort(header)}
                  sx={{ fontSize: "0.8rem" }}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell
              sx={{
                py: 0,
                px: 5,
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                <Loading datatable="true" />
              </TableCell>
            </TableRow>
          ) : sortedData.length > 0 ? (
            sortedData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 10 }}>
                  {row.department_name}
                </TableCell>
                <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 10 }}>
                  {row.admin_name}
                </TableCell>
                <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 10 }}>
                  {row.admin_email}
                </TableCell>
                <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 10 }}>
                  {row.vehicle_category}
                </TableCell>
                {localStorage.getItem("userType") !== "AccountManager" && (
                  <TableCell
                    id={row.id}
                    onClick={subListClickHandler}
                    className="department-data"
                  >
                    <img
                      src={editIcon}
                      alt="edit"
                      title="Click to edit department Details"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={statementIcon}
                      alt="statement"
                      title="Click to see Statement"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={staffIconNew}
                      alt="staff"
                      title="Click to see Staff Details"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={adminIcon}
                      alt="admins"
                      title="Click to see Admin Details"
                      sx={{ fontSize: "0.8rem" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                <div className="no-data">No Data Available</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Records;
