import React from "react";
import studentDropImage from "../../Assets/student_dummy_photo.png";
// import studentDummyImage from "../../Assets/student_dummy_photo.png";
import studentDummyImage from "../../Assets/new_student_marker.png";
import startPoint from "../../Assets/Pin_icon_green50.png";
import endPoint from "../../Assets/Pin_icon50.png";
import { convertLatLng } from "../CommonComponent/ConvertLatLng";
// import dummy from "../../Assets/greenNew.png";

let minBounds = 1;
let maxBounds = 500;
let dividend = 10;
const TripInfoMap = ({ RIDER_DATA, driverPath }) => {
  console.log("🚀 ~ TripInfoMap ~ RIDER_DATA:", RIDER_DATA)
  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=myInitMap&libraries=places&v=weekly";
  script.async = true;
  document.body.appendChild(script);

  function myInitMap() {
    // debugger;
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 11,
      center:
        RIDER_DATA?.TripStatus?.toLowerCase() !== "ended"
          ? {
              lat: +RIDER_DATA?.PickupLatLng?.split(",")[0],
              lng: +RIDER_DATA?.PickupLatLng?.split(",")[1],
            }
          : {
              lat: RIDER_DATA?.ActualPickupLatLng?.split(",")[0],
              lng: RIDER_DATA?.ActualPickupLatLng?.split(",")[1],         
            },
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
    });
    // if (driverPath) {
    //   let currentBounds = Math.ceil(driverPath.length / dividend);
    //   while (!(currentBounds < maxBounds && currentBounds >= minBounds)) {
    //     if (currentBounds < minBounds)
    //       currentBounds = Math.ceil(
    //         driverPath?.length / (Math.ceil(dividend) / 2)
    //       );
    //     else if (currentBounds > maxBounds)
    //       currentBounds = Math.ceil(
    //         driverPath?.length / (Math.ceil(dividend) * 2)
    //       );
    //   }
    //   var bounds = new window.google.maps.LatLngBounds();
    //   for (let i = 0; i < driverPath?.length; i = i + currentBounds) {
    //     bounds.extend(
    //       new window.google.maps.LatLng(driverPath[i]?.LL?.split(",")[0], driverPath[i]?.LL?.split(",")[1])
    //     );
    //   }
    //   bounds.extend(
    //     new window.google.maps.LatLng(
    //       driverPath[driverPath?.length - 1]?.LL?.split(",")[0],
    //       driverPath[driverPath?.length - 1]?.LL?.split(",")[1]
    //     )
    //   );
    //   map.fitBounds(bounds);
    // }
    if (driverPath) {
      let currentBounds = Math.ceil(driverPath.length / dividend);
      while (!(currentBounds < maxBounds && currentBounds >= minBounds)) {
        if (currentBounds < minBounds)
          currentBounds = Math.ceil(driverPath?.length / (Math.ceil(dividend) / 2));
        else if (currentBounds > maxBounds)
          currentBounds = Math.ceil(driverPath?.length / (Math.ceil(dividend) * 2));
      }

      var bounds = new window.google.maps.LatLngBounds();
      for (let i = 0; i < driverPath?.length; i += currentBounds) {
        const [lat, lng] = driverPath[i]?.LL?.split(",")?.map(Number);
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      }

      const [lastLat, lastLng] = driverPath[driverPath?.length - 1]?.LL?.split(",")?.map(Number);
      bounds.extend(new window.google.maps.LatLng(lastLat, lastLng));

      map.fitBounds(bounds);
    }

    // const flightPlanCoordinates = driverPath;
    const flightPlanCoordinates = driverPath?.map(point => {
      const [lat, lng] = point.LL.split(",")?.map(coord => parseFloat(coord));
      return { lat, lng };
    });

    const flightPathBorder = new window.google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "black",
      strokeOpacity: 1.0,
      strokeWeight: 6,
    });

    const flightPath = new window.google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: "#00b0ff",
      strokeOpacity: 1.0,
      strokeWeight: 5,
    });

    flightPath.setMap(map);
    flightPathBorder.setMap(map);

    const infoWindow = new window.google.maps.InfoWindow();
    const pickupLatLng = convertLatLng(RIDER_DATA?.ActualPickupLatLng)|| convertLatLng(RIDER_DATA?.PickupLatLng)
    const pickupName = RIDER_DATA?.ActualPickupName || RIDER_DATA?.PickupAddress

    const dropLatLng = convertLatLng(RIDER_DATA?.ActualDropOffLatLng) || convertLatLng(RIDER_DATA?.DropOffLatLng)
    const dropName = RIDER_DATA?.ActualDropOffName || RIDER_DATA?.DropOffAddress


    const marker1 = new window.google.maps.Marker({
      position:     
        // RIDER_DATA?.TripStatus?.toLowerCase() === "accepted"
        //   ? {
        //       lat: +RIDER_DATA?.PickupLatLng?.split(",")[0],
        //       lng: +RIDER_DATA?.PickupLatLng?.split(",")[1],
        //     }
        //   : {
        //       // lat: +RIDER_DATA?.ActualPickupLatLng?.split(",")[0],
        //       // lng: +RIDER_DATA?.ActualPickupLatLng?.split(",")[1],
        //       lat: +RIDER_DATA?.PickupLatLng?.split(",")[0],
        //       lng: +RIDER_DATA?.PickupLatLng?.split(",")[1],
        //     },
        new window.google.maps.LatLng(pickupLatLng),
      map,
      myTitle:
        // RIDER_DATA?.TripStatus?.toLowerCase() === "accepted"
        //   ? RIDER_DATA?.PickupAddress
        //   : RIDER_DATA?.ActualPickupName,
        pickupName,
      icon: startPoint,
      optimized: false,
    });
    const marker2 = new window.google.maps.Marker({
      position:
        // RIDER_DATA?.TripStatus?.toLowerCase() !== "ended"
        //   ? {
        //       lat: +RIDER_DATA?.DropOffLatLng?.split(",")[0],
        //       lng: +RIDER_DATA?.DropOffLatLng?.split(",")[1],
        //     }
        //   : {
        //       // lat: +RIDER_DATA?.ActualDropOffLatLng?.split(",")[0],
        //       // lng: +RIDER_DATA?.ActualDropOffLatLng?.split(",")[1],
        //       lat: +RIDER_DATA?.DropOffLatLng?.split(",")[0],
        //       lng: +RIDER_DATA?.DropOffLatLng?.split(",")[1],
        //     },
        new window.google.maps.LatLng(dropLatLng),
      map,
      myTitle:
        // RIDER_DATA?.TripStatus?.toLowerCase() !== "ended"
        //   ? RIDER_DATA?.DropOffAddress
        //   : RIDER_DATA?.ActualDropOffName,
        dropName,
      icon: endPoint,
      optimized: false,
    });

    marker1.addListener("mouseover", () => {
      infoWindow.close();
      infoWindow.setHeaderContent(marker1.myTitle);
      infoWindow.open(marker1.getMap(), marker1);
    });
    marker2.addListener("mouseover", () => {
      infoWindow.close();
      infoWindow.setHeaderContent(marker2.myTitle);
      infoWindow.open(marker2.getMap(), marker2);
    });
  }

  window.myInitMap = myInitMap;

  return <div id="map"></div>;
};

export default TripInfoMap;



// import React, { useEffect } from "react";
// import studentDropImage from "../../Assets/student_dummy_photo.png";
// import studentDummyImage from "../../Assets/new_student_marker.png";
// import startPoint from "../../Assets/Pin_icon_green50.png";
// import endPoint from "../../Assets/Pin_icon50.png";
// import carIcon from "../../Assets/live_car.png"; // Load your car icon image

// let minBounds = 1;
// let maxBounds = 500;
// let dividend = 10;

// const TripInfoMap = ({ RIDER_DATA, driverPath }) => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src =
//       "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=myInitMap&libraries=places&v=weekly";
//     script.async = true;
//     document.body.appendChild(script);

//     window.myInitMap = myInitMap;
//   }, []);

//   function myInitMap() {
//     const map = new window.google.maps.Map(document.getElementById("map"), {
//       zoom: 11,
//       center:
//         RIDER_DATA[0]?.TripStatus?.toLowerCase() !== "ended"
//           ? {
//               lat: +RIDER_DATA[0]?.pickupLatLng?.split(",")[0],
//               lng: +RIDER_DATA[0]?.pickupLatLng?.split(",")[1],
//             }
//           : {
//               lat: RIDER_DATA[0]?.actualPickupLatLng,
//               lng: RIDER_DATA[0]?.actualPickupLatLng,
//             },
//       disableDefaultUI: true,
//       fullscreenControl: true,
//       zoomControl: true,
//     });

//     if (driverPath) {
//       let currentBounds = Math.ceil(driverPath.length / dividend);
//       while (!(currentBounds < maxBounds && currentBounds >= minBounds)) {
//         if (currentBounds < minBounds)
//           currentBounds = Math.ceil(
//             driverPath?.length / (Math.ceil(dividend) / 2)
//           );
//         else if (currentBounds > maxBounds)
//           currentBounds = Math.ceil(
//             driverPath?.length / (Math.ceil(dividend) * 2)
//           );
//       }
//       var bounds = new window.google.maps.LatLngBounds();
//       for (let i = 0; i < driverPath?.length; i = i + currentBounds) {
//         bounds.extend(
//           new window.google.maps.LatLng(driverPath[i]?.lat, driverPath[i]?.lng)
//         );
//       }
//       bounds.extend(
//         new window.google.maps.LatLng(
//           driverPath[driverPath?.length - 1]?.lat,
//           driverPath[driverPath?.length - 1]?.lng
//         )
//       );
//       map.fitBounds(bounds);
//     }

//     const flightPlanCoordinates = driverPath;

//     const flightPathBorder = new window.google.maps.Polyline({
//       path: flightPlanCoordinates,
//       geodesic: true,
//       strokeColor: "black",
//       strokeOpacity: 1.0,
//       strokeWeight: 6,
//     });

//     const flightPath = new window.google.maps.Polyline({
//       path: flightPlanCoordinates,
//       geodesic: true,
//       strokeColor: "#00b0ff",
//       strokeOpacity: 1.0,
//       strokeWeight: 5,
//     });

//     flightPath.setMap(map);
//     flightPathBorder.setMap(map);

//     const infoWindow = new window.google.maps.InfoWindow();

//     const marker1 = new window.google.maps.Marker({
//       position:
//         RIDER_DATA[0]?.TripStatus?.toLowerCase() === "accepted"
//           ? {
//               lat: +RIDER_DATA[0].pickupLatLng?.split(",")[0],
//               lng: +RIDER_DATA[0].pickupLatLng?.split(",")[1],
//             }
//           : {
//               lat: +RIDER_DATA[0].actualPickupLatLng?.split(",")[0],
//               lng: +RIDER_DATA[0].actualPickupLatLng?.split(",")[1],
//             },
//       map,
//       myTitle:
//         RIDER_DATA[0]?.TripStatus?.toLowerCase() === "accepted"
//           ? RIDER_DATA[0].PickupAddress
//           : RIDER_DATA[0].actualPickupAddress,
//       icon: startPoint,
//       optimized: false,
//     });
//     const marker2 = new window.google.maps.Marker({
//       position:
//         RIDER_DATA[0]?.TripStatus?.toLowerCase() !== "ended"
//           ? {
//               lat: +RIDER_DATA[0].dropoffLatLng?.split(",")[0],
//               lng: +RIDER_DATA[0].dropoffLatLng?.split(",")[1],
//             }
//           : {
//               lat: +RIDER_DATA[0].actualDropoffLatLng?.split(",")[0],
//               lng: +RIDER_DATA[0].actualDropoffLatLng?.split(",")[1],
//             },
//       map,
//       myTitle:
//         RIDER_DATA[0]?.TripStatus?.toLowerCase() !== "ended"
//           ? RIDER_DATA[0].DropOffAddress
//           : RIDER_DATA[0].actualDropoffAddress,
//       icon: endPoint,
//       optimized: false,
//     });

//     marker1.addListener("mouseover", () => {
//       infoWindow.close();
//       infoWindow.setContent(marker1.myTitle);
//       infoWindow.open(marker1.getMap(), marker1);
//     });
//     marker2.addListener("mouseover", () => {
//       infoWindow.close();
//       infoWindow.setContent(marker2.myTitle);
//       infoWindow.open(marker2.getMap(), marker2);
//     });

//     animateCar(map, driverPath);
//   }

//   function animateCar(map, path) {
//     let carMarker = new window.google.maps.Marker({
//       position: path[0],
//       map: map,
//       icon: {
//         url: carIcon,
//         scaledSize: new window.google.maps.Size(30, 30), // Decreased the size
//       },
//       optimized: false,
//     });

//     let step = 0;
//     let numSteps = 3000; // Increase the number of steps for smoother animation
//     let timePerStep = 10; // Adjust the time per step for the speed of the car

//     function moveCar() {
//       step += 1;
//       if (step > numSteps) {
//         step = numSteps; // Ensure it doesn't go out of bounds
//       }
//       let t = step / numSteps;
//       let nextPos = interpolate(path, t);
//       carMarker.setPosition(nextPos);

//       if (step < numSteps) {
//         carMarker.setIcon({
//           url: carIcon,
//           scaledSize: new window.google.maps.Size(30, 30), // Ensure the size is consistent
//         });

//         const markerUrl = carMarker.getIcon().url;
//         const markerSrc = document.querySelector(`[src = "${markerUrl}"]`);
//         if (markerSrc) {
//           markerSrc.style.transform = `rotate(${calculateBearing(path, t)}deg)`;
//         }

//         window.requestAnimationFrame(moveCar);
//       }
//     }

//     function interpolate(path, t) {
//       let totalLength = path.length - 1;
//       let segmentIndex = Math.floor(t * totalLength);
//       let segmentT = t * totalLength - segmentIndex;

//       let start = path[segmentIndex];
//       let end = path[segmentIndex + 1];

//       let lat = (1 - segmentT) * start.lat + segmentT * end?.lat;
//       let lng = (1 - segmentT) * start.lng + segmentT * end?.lng;

//       return { lat, lng };
//     }

//     function calculateBearing(path, t) {
//       let totalLength = path.length - 1;
//       let segmentIndex = Math.floor(t * totalLength);
//       let start = path[segmentIndex];
//       let end = path[segmentIndex + 1];

//       if (!end) {
//         return 0; // If there is no next segment, no rotation is needed
//       }

//       let lat1 = start.lat * (Math.PI / 180);
//       let lon1 = start.lng * (Math.PI / 180);
//       let lat2 = end.lat * (Math.PI / 180);
//       let lon2 = end.lng * (Math.PI / 180);

//       let dLon = lon2 - lon1;
//       let y = Math.sin(dLon) * Math.cos(lat2);
//       let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
//       let brng = Math.atan2(y, x) * (180 / Math.PI);

//       return (brng + 360) % 360; // Normalize to 0-360 degrees
//     }

//     window.requestAnimationFrame(moveCar);
//   }

//   return <div id="map" style={{ height: "500px", width: "100%" }}></div>;
// };

// export default TripInfoMap;

