import React, { useEffect, useState } from "react";
import CorporateFilter from "./CorporateFilter";
import DateFilter from "./DateFilterByMUI";
import SearchFilter from "./SearchFilter";
import ExportFilter from "./ExportFilter";

const HeaderFilter = ({
  enableCorporateFilter,
  enableDateFilter,
  enableSearchFilter,
  enableExportFilter,
  // CorporateFilter props
  mainData,
  selectedCorporate,
  setSelectedCorporate,
  corporateData,
  setCorporateData,
  setFilteredData,
  // DateFilter props
  startDateValue,
  endDateValue,
  setStartDateValue,
  setEndDateValue,
  setIsRefereshData,
  // SearchFilter props
  searchValue,
  setSearchValue,
  setSearchFilteredData,
  styling,
  placeholder,
  data,
  // export Filter props
  onclick,

  // loading
  loading
}) => {
 
  
  return (
    <div style={{ display: "flex", gap: "15px", alignItems:'start', width:"100%" }}>
      <div  style={{ marginRight: "auto" }}>

      {(enableCorporateFilter && localStorage.getItem("roleId")=="1") &&(
        <CorporateFilter
        mainData={mainData}
        selectedCorporate={selectedCorporate}
        setSelectedCorporate={setSelectedCorporate}
        corporateData={corporateData}
        setCorporateData={setCorporateData}
        setFilteredData={setFilteredData}
        loading={loading}
        />
      )}
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems:'center'}}>

      {enableSearchFilter && (
        <SearchFilter
        data={data}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setFilteredData={setSearchFilteredData}
        loading={loading}
        placeholder={placeholder}

        // styling={{ marginTop: "10px" }}
        />
      )}

      {enableDateFilter && (
        <DateFilter
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setStartDateValue={setStartDateValue}
          setEndDateValue={setEndDateValue}
          setIsRefereshData={setIsRefereshData}
        loading={loading}

          />
        )}
        {enableExportFilter && (
          <ExportFilter onclick={onclick} />
        )}
        </div>
          </div>
  );
};

export default HeaderFilter;
