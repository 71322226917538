import React from "react";
import "./App.css";
import AppRoutes from "./routes/routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div style={{ height: "100%" }}>
      <AppRoutes /> 
      <ToastContainer />
    </div>
  );
}

export default App;
