// countries.js
export const CountriesCode = [
    {
      name: 'India',
      code: 'IN',
      isdCode: '+91',
      flag: 'https://flagcdn.com/in.svg',
    },
    {
      name: 'United States',
      code: 'US',
      isdCode: '+1',
      flag: 'https://flagcdn.com/us.svg',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
      isdCode: '+44',
      flag: 'https://flagcdn.com/gb.svg',
    },
    {
      name: 'Canada',
      code: 'CA',
      isdCode: '+1',
      flag: 'https://flagcdn.com/ca.svg',
    },
    {
      name: 'Australia',
      code: 'AU',
      isdCode: '+61',
      flag: 'https://flagcdn.com/au.svg',
    },
    {
      name: 'Germany',
      code: 'DE',
      isdCode: '+49',
      flag: 'https://flagcdn.com/de.svg',
    },
    {
      name: 'France',
      code: 'FR',
      isdCode: '+33',
      flag: 'https://flagcdn.com/fr.svg',
    },
    {
      name: 'China',
      code: 'CN',
      isdCode: '+86',
      flag: 'https://flagcdn.com/cn.svg',
    },
    {
      name: 'Japan',
      code: 'JP',
      isdCode: '+81',
      flag: 'https://flagcdn.com/jp.svg',
    },
    {
      name: 'Brazil',
      code: 'BR',
      isdCode: '+55',
      flag: 'https://flagcdn.com/br.svg',
    },
    {
      name: 'South Africa',
      code: 'ZA',
      isdCode: '+27',
      flag: 'https://flagcdn.com/za.svg',
    },
    {
      name: 'Mexico',
      code: 'MX',
      isdCode: '+52',
      flag: 'https://flagcdn.com/mx.svg',
    },
    {
      name: 'Russia',
      code: 'RU',
      isdCode: '+7',
      flag: 'https://flagcdn.com/ru.svg',
    },
    {
      name: 'Italy',
      code: 'IT',
      isdCode: '+39',
      flag: 'https://flagcdn.com/it.svg',
    },
    {
      name: 'Spain',
      code: 'ES',
      isdCode: '+34',
      flag: 'https://flagcdn.com/es.svg',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      isdCode: '+64',
      flag: 'https://flagcdn.com/nz.svg',
    },
    {
      name: 'Netherlands',
      code: 'NL',
      isdCode: '+31',
      flag: 'https://flagcdn.com/nl.svg',
    },
    {
      name: 'Sweden',
      code: 'SE',
      isdCode: '+46',
      flag: 'https://flagcdn.com/se.svg',
    },
    {
      name: 'Norway',
      code: 'NO',
      isdCode: '+47',
      flag: 'https://flagcdn.com/no.svg',
    },
    {
      name: 'Denmark',
      code: 'DK',
      isdCode: '+45',
      flag: 'https://flagcdn.com/dk.svg',
    },
    {
      name: 'Finland',
      code: 'FI',
      isdCode: '+358',
      flag: 'https://flagcdn.com/fi.svg',
    },
    // African countries
    {
      name: 'Nigeria',
      code: 'NG',
      isdCode: '+234',
      flag: 'https://flagcdn.com/ng.svg',
    },
    {
      name: 'Egypt',
      code: 'EG',
      isdCode: '+20',
      flag: 'https://flagcdn.com/eg.svg',
    },
    {
      name: 'Kenya',
      code: 'KE',
      isdCode: '+254',
      flag: 'https://flagcdn.com/ke.svg',
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      isdCode: '+251',
      flag: 'https://flagcdn.com/et.svg',
    },
    {
      name: 'Ghana',
      code: 'GH',
      isdCode: '+233',
      flag: 'https://flagcdn.com/gh.svg',
    },
    {
      name: 'Tanzania',
      code: 'TZ',
      isdCode: '+255',
      flag: 'https://flagcdn.com/tz.svg',
    },
    {
      name: 'Uganda',
      code: 'UG',
      isdCode: '+256',
      flag: 'https://flagcdn.com/ug.svg',
    },
    {
      name: 'Algeria',
      code: 'DZ',
      isdCode: '+213',
      flag: 'https://flagcdn.com/dz.svg',
    },
    {
      name: 'Sudan',
      code: 'SD',
      isdCode: '+249',
      flag: 'https://flagcdn.com/sd.svg',
    },
    {
      name: 'Morocco',
      code: 'MA',
      isdCode: '+212',
      flag: 'https://flagcdn.com/ma.svg',
    },
    {
      name: 'Angola',
      code: 'AO',
      isdCode: '+244',
      flag: 'https://flagcdn.com/ao.svg',
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      isdCode: '+258',
      flag: 'https://flagcdn.com/mz.svg',
    },
    {
      name: 'Gabon',
      code: 'GA',
      isdCode: '+241',
      flag: 'https://flagcdn.com/ga.svg',
    },
    {
      name: 'Botswana',
      code: 'BW',
      isdCode: '+267',
      flag: 'https://flagcdn.com/bw.svg',
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      isdCode: '+263',
      flag: 'https://flagcdn.com/zw.svg',
    },
    {
      name: 'Rwanda',
      code: 'RW',
      isdCode: '+250',
      flag: 'https://flagcdn.com/rw.svg',
    },
    {
      name: 'Senegal',
      code: 'SN',
      isdCode: '+221',
      flag: 'https://flagcdn.com/sn.svg',
    },
    {
      name: 'Mali',
      code: 'ML',
      isdCode: '+223',
      flag: 'https://flagcdn.com/ml.svg',
    },
    {
      name: 'Madagascar',
      code: 'MG',
      isdCode: '+261',
      flag: 'https://flagcdn.com/mg.svg',
    },
    {
      name: 'Ivory Coast',
      code: 'CI',
      isdCode: '+225',
      flag: 'https://flagcdn.com/ci.svg',
    },
    {
      name: 'Cameroon',
      code: 'CM',
      isdCode: '+237',
      flag: 'https://flagcdn.com/cm.svg',
    },
    {
      name: 'Zambia',
      code: 'ZM',
      isdCode: '+260',
      flag: 'https://flagcdn.com/zm.svg',
    },
    {
      name: 'Burundi',
      code: 'BI',
      isdCode: '+257',
      flag: 'https://flagcdn.com/bi.svg',
    },
    {
      name: 'Namibia',
      code: 'NA',
      isdCode: '+264',
      flag: 'https://flagcdn.com/na.svg',
    },
    {
      name: 'Malawi',
      code: 'MW',
      isdCode: '+265',
      flag: 'https://flagcdn.com/mw.svg',
    },
    {
      name: 'Gambia',
      code: 'GM',
      isdCode: '+220',
      flag: 'https://flagcdn.com/gm.svg',
    },
    {
      name: 'Liberia',
      code: 'LR',
      isdCode: '+231',
      flag: 'https://flagcdn.com/lr.svg',
    },
    {
      name: 'Togo',
      code: 'TG',
      isdCode: '+228',
      flag: 'https://flagcdn.com/tg.svg',
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      isdCode: '+232',
      flag: 'https://flagcdn.com/sl.svg',
    },
    {
      name: 'Congo - Brazzaville',
      code: 'CG',
      isdCode: '+242',
      flag: 'https://flagcdn.com/cg.svg',
    },
    {
      name: 'Congo - Kinshasa',
      code: 'CD',
      isdCode: '+243',
      flag: 'https://flagcdn.com/cd.svg',
    },
    {
      name: 'Somalia',
      code: 'SO',
      isdCode: '+252',
      flag: 'https://flagcdn.com/so.svg',
    },
    {
      name: 'Chad',
      code: 'TD',
      isdCode: '+235',
      flag: 'https://flagcdn.com/td.svg',
    },
    {
      name: 'Niger',
      code: 'NE',
      isdCode: '+227',
      flag: 'https://flagcdn.com/ne.svg',
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      isdCode: '+226',
      flag: 'https://flagcdn.com/bf.svg',
    },
    {
      name: 'Benin',
      code: 'BJ',
      isdCode: '+229',
      flag: 'https://flagcdn.com/bj.svg',
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      isdCode: '+240',
      flag: 'https://flagcdn.com/gq.svg',
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      isdCode: '+236',
      flag: 'https://flagcdn.com/cf.svg',
    },
    {
      name: 'Mauritania',
      code: 'MR',
      isdCode: '+222',
      flag: 'https://flagcdn.com/mr.svg',
    },
    {
      name: 'Eritrea',
      code: 'ER',
      isdCode: '+291',
      flag: 'https://flagcdn.com/er.svg',
    },
    {
      name: 'South Sudan',
      code: 'SS',
      isdCode: '+211',
      flag: 'https://flagcdn.com/ss.svg',
    },
    {
      name: 'Cape Verde',
      code: 'CV',
      isdCode: '+238',
      flag: 'https://flagcdn.com/cv.svg',
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      isdCode: '+253',
      flag: 'https://flagcdn.com/dj.svg',
    },
    {
      name: 'Lesotho',
      code: 'LS',
      isdCode: '+266',
      flag: 'https://flagcdn.com/ls.svg',
    },
    {
      name: 'Eswatini',
      code: 'SZ',
      isdCode: '+268',
      flag: 'https://flagcdn.com/sz.svg',
    },
    {
      name: 'Guinea',
      code: 'GN',
      isdCode: '+224',
      flag: 'https://flagcdn.com/gn.svg',
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      isdCode: '+245',
      flag: 'https://flagcdn.com/gw.svg',
    },
    {
      name: 'Comoros',
      code: 'KM',
      isdCode: '+269',
      flag: 'https://flagcdn.com/km.svg',
    },
    {
      name: 'São Tomé and Príncipe',
      code: 'ST',
      isdCode: '+239',
      flag: 'https://flagcdn.com/st.svg',
    },
    {
      name: 'Seychelles',
      code: 'SC',
      isdCode: '+248',
      flag: 'https://flagcdn.com/sc.svg',
    },
  ];
  