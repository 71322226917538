// CorporateFilter.js
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useHttp from "../../Hooks/use-http";
const CorporateFilter = ({
  selectedCorporate,
  setSelectedCorporate,
  corporateData,
  setCorporateData,
  mainData,
  setFilteredData,
  loading,
}) => {
  const { sendRequest } = useHttp();
  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
        },
      },
      corporateLists
    );
  }, [sendRequest]);

  const corporateLists = (data) => {
    const tempArr =
      data?.CorporateList?.map((cp) => ({
        cpName: cp?.CorporateName,
        cpId: cp?.CorporateID,
        dpId: cp?.DepartmentID,
        adminDptId:
          cp?.DepartmentID?.split(",")[
            cp?.DepartmentName?.toLowerCase()?.split(",").indexOf("admin")
          ] || "",
      })) || [];
    setCorporateData(tempArr);
  };

  const handleCorporateChange = (event, newValue) => {
    if (newValue) {
      const selectedData = corporateData.filter(
        (data) => data?.cpName === newValue
      );
      setSelectedCorporate(selectedData);
      if (selectedData.length === 0) {
        setFilteredData(mainData);
      } else {
        setFilteredData(
          mainData.filter((data) =>
            selectedData.some(
              (corp) =>
                (data.corporateName || data.CorporateName).toLowerCase() ===
                corp.cpName.toLowerCase()
            )
          )
        );
      }
    } else {
      setSelectedCorporate([]);
      setFilteredData(mainData);
    }
  };
  return (
    <div>
      <Autocomplete
        id="corporate-filter"
        disabled={loading == true}
        options={corporateData && corporateData?.map((data) => data?.cpName)}
        sx={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Corporate Name"
            label="Corporate Name"
          />
        )}
        onChange={(e, newValue) =>
          // corporateNameClickHandler(e, newValue)
          handleCorporateChange(e, newValue)
        }
        value={
          selectedCorporate && selectedCorporate.length > 0
            ? selectedCorporate[0].cpName
            : ""
        } // Use selectedCorporate state if available, otherwise use the provided value prop
      />
    </div>
  );
};

export default CorporateFilter;
