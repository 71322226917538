import React, { useEffect, useRef, useState } from "react";
import Records from "./Records";
import ReactPaginate from "react-paginate";
// import "./Route.css";
import { CSVLink } from "react-csv";
import AddDepartment from "./AddDepartment";
import { useHistory, useLocation } from "react-router-dom";
import useHttp from "../../Hooks/use-http";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";
// import { Route } from "react-router-dom";
// import AddRoute from "./AddRoute/RouteInfo";

const Department_DATA = [
  {
    id: 1,
    department_name: "Sales and Marketing",
    admin_name: "Jay Shah",
    admin_email: "jayshah9791@gmail.com",
    vehicle_category: "Basic",
  },
  {
    id: 2,
    department_name: "Little School Testing",
    admin_name: "Unnati Shah",
    admin_email: "unnati@gmail.com",
    vehicle_category: "Comfort",
  },
];

const TRIP_TITLE = [
  "Department Name",
  "Admin Name",
  "Admin Email",
  "Vehicle Category",
];

let myClick = false;
let prev_id = "1";
let departmentDetails = "";
let deptListFlag = 0;

function Routes() {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(7);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [filteredData, setFilteredData] = useState([]);
  const searchInputRef = useRef();
  // const [isAddRouteClicked, setIsAddRouteClicked] = useState();

  const history = useHistory();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("corporateId");

  const authenticateUser = (data) => {
    // console.log(data.DepartMentList);

    let department_data = [];
    if (data.DepartMentList) {
      for (let i = 0; i < data.DepartMentList.length; i++) {
        department_data.push({
          id: data.DepartMentList[i].DepartmentID,
          department_name: data.DepartMentList[i].DepartmentName,
          admin_name: data.DepartMentList[i].AdminName,
          admin_email: data.DepartMentList[i].AdminEmail,
          vehicle_category: data.DepartMentList[i].VehicalCategory,
        });
      }
    }
    departmentDetails = department_data;
    // console.log(department_data);
    setFilteredData(department_data);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    // alert("here");
    // if (deptListFlag > 0)
    sendRequest(
      {
        url: "/api/v1/Department/DepartmentList",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          corporateID: id
            ? id
            : localStorage.getItem("userType") === "AccountManager"
            ? ""
            : localStorage.getItem("corpId"),
        },
      },
      authenticateUser
    );
    deptListFlag++;
  }, [sendRequest, id]);

  const nPages = Math.ceil(filteredData.length / rowsPerPage);

  let fromRecords = 0;
  if (page === 0) fromRecords = 1;
  else fromRecords = page * rowsPerPage;
  let toRecords = 0;
  if (
    (myClick
      ? page * rowsPerPage - (filteredData.length % rowsPerPage)
      : page * rowsPerPage +
        rowsPerPage -
        (filteredData.length % rowsPerPage)) > filteredData.length
  )
    toRecords = filteredData.length;
  else toRecords = page * rowsPerPage;
  if (toRecords === 0) fromRecords = 0;
  if (page === nPages) toRecords = filteredData.length;

  const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
  const indexOfFirstRecord = page * rowsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const addDepartmentClickHandler = () => {
    // setIsAddRouteClicked(true);
    history.push("/departments/add-new");
  };

  const routeSearchHandler = (e) => {
    // if (e.target.value)
    setFilteredData(
      departmentDetails.filter(
        (data) =>
          data.department_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.admin_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.admin_email
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.vehicle_category
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase())
      )
    );
    // else setFilteredData(Department_DATA);
  };

  return (
    <div className="trips-details" id="trip-table">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "inline-block" }} className="title">
          Departments
        </div>
        {localStorage.getItem("userType") !== "AccountManager" && (
          <button
            onClick={addDepartmentClickHandler}
            style={{
              marginRight: "40px",
              padding: "7px 14px",
              backgroundColor: "rgba(34, 137, 203, 255)",
              color: "white",
              border: "rgba(34, 137, 203, 255)",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Add New Department
          </button>
        )}
      </div>
      <div className="table-container-routes">
        <div className="header">
          <div>
            <div onChange={routeSearchHandler} className="route-search">
              <input placeholder="Search" type="text" ref={searchInputRef} />
            </div>
            <CSVLink
              data={Department_DATA}
              className="export_csv"
              filename={"data.csv"}
            >
              Export
            </CSVLink>
          </div>
        </div>
        <Records
          data={currentRecords}
          headers={TRIP_TITLE}
          isLoading={isLoading}
        />
        <div>
        
          <CommonTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            filteredData={filteredData}
            styling={{ display: "flex", justifyContent: "flex-end" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Routes;
