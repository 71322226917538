import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';

const AdminDetails = ({ isNextClicked, setActiveStep, setIsNextClicked, setAllData, allData }) => {
    const [isError, setIsError] = useState();
    const [formData, setFormData] = useState({
        adminName: "",
        adminEmail: "",
        adminMoNumber: "",
        adminPhoto: "",
        adminPhotoName: ""
    });
    const adminPhotoInputRef = useRef();

    useEffect(() => {
        setFormData({ ...allData.adminDetails });
    }, []);

    useEffect(() => {
        if (isNextClicked) {
            Object.keys(formData).map((val, i) => {
                if (!String(formData[val])) {
                    setIsError(true);
                    setIsNextClicked(false);
                    return;
                }
                else if (i === Object.keys(formData).length - 1) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    setAllData(prev => ({ ...prev, adminDetails: formData }));
                }
            })
            setIsNextClicked(false);
        }
    }, [isNextClicked]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const adminPhotoChangeHandler = (e) => {
        if (["image/jpeg", "image/png"].includes(e.target.files[0].type)) {
            getBase64(e.target.files[0]).then(
                data => {
                    setFormData(prev => ({ ...prev, adminPhotoName: e.target.files[0].name, adminPhoto: data }));
                }
            );
        } else console.log("Invalid file");
    }

    return (
        <div style={{ display: "flex", alignSelf: "flex-start", justifyContent: "flex-start", flexWrap: "wrap", gap: "35px", padding: "10px" }}>
            <TextField id="standard-basic" value={formData.adminName} name='adminName' onChange={handleInputChange} label="Name" variant="standard" sx={{ width: "250px" }} />
            <TextField id="standard-basic" value={formData.adminEmail} name='adminEmail' onChange={handleInputChange} label="Emaild" variant="standard" sx={{ width: "250px" }} />
            <TextField id="standard-basic" value={formData.adminMoNumber} name='adminMoNumber' onChange={handleInputChange} label="Mobile No." variant="standard" sx={{ width: "250px" }} />
            <TextField id="standard-basic" value={formData.adminPhotoName} onClick={() => adminPhotoInputRef.current.click()} label="Photo" variant="standard" sx={{ width: "250px" }} />
            <input type='file' style={{ display: "none" }} onChange={adminPhotoChangeHandler} ref={adminPhotoInputRef} />
        </div>
    )
}

export default AdminDetails;