import React, { useEffect, useRef, useState } from "react";
import Records from "./Records";
import ReactPaginate from "react-paginate";
import "./Route.css";
import { CSVLink } from "react-csv";
import { useLocation, useHistory } from "react-router-dom";
import AddRoute from "./AddRoute/RouteInfo";
import useHttp from "../../Hooks/use-http";
import Message from "../../Modal/Message";
import loadingGif from "../../Assets/loading-gif.gif";
import { Autocomplete, Button, TextField } from "@mui/material";
import CorporateFilter from "../CommonComponent/CorporateFilter";
import HeaderFilter from "../CommonComponent/HeaderFilter";
import TestRouteInfo from "./TestAddRoute/TestRouteInfo";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";
// import TestRouteInfo from "./TestAddRoute/TestRouteInfo";

let myClick = false;
let prev_id = "1";
let routeListFlag = 0;
let route_details = [];

function Routes() {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(7);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [filteredData, setFilteredData] = useState([]);
  const searchInputRef = useRef();
  const [isAddRouteClicked, setIsAddRouteClicked] = useState();
  const [isNewUIRouteClicked, setIsNewUIRouteClicked] = useState();

  const [isRouteCreated, setIsRouteCreated] = useState();
  const [roleId, setRoleId] = useState(localStorage.getItem("roleId"));
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [corporatesData, setCorporatesData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const [toggleChangeAPICall, setToggleChangeAPICall] = useState(false);
  

  const routeCreationStatus = (data) => {
    setIsRouteCreated(data);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    const handleStorageChange = () => {
      setRoleId(localStorage.getItem("roleId"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const TRIP_TITLE = [
    "Route ID",
    "Route Name",
    roleId === "1" ? "Corporate Name" : null,
    "Timing",
    "Stops",
    // "City",
    // "Country",
    "Route Type",
  ].filter(Boolean);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("departmentId");

  const authenticateUser = (data) => {
    let route_list = [];
    let timings = {};
    if (data.RouteList) {
      for (let i = 0; i < data?.RouteList?.length; i++) {
        route_list.push({
          id: i + 1,
          route_id: data?.RouteList[i]?.RouteID,
          route: data?.RouteList[i]?.RouteName,
          city: data?.RouteList[i]?.City,
          country: data?.RouteList[i]?.Country,
          zone_price: data?.RouteList[i]?.ZonePrice,
          route_type: data?.RouteList[i]?.RouteTypeName,
          corporateName: data?.RouteList[i]?.CorporateName,
          timing: data?.RouteList[i]?.RouteTimes,
          stops: data?.RouteList[i]?.Stops,
          isDeactivated: data?.RouteList[i]?.IsDeactivated,
          corporateId: data?.RouteList[i]?.CorporateID,

        });
      }
    }
    route_details = route_list;
    setFilteredData(route_list);
  };
  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Route/GetRoutList",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          // corporateID: selectedCorporate ? selectedCorporate[0]?.cpId : "",
          // corporateID: selectedCorporate ? selectedCorporate[0]?.cpId : localStorage.getItem('corpId') ? localStorage.getItem('corpId') : "",
          corporateID:
            localStorage.getItem("roleId") == 1
              ? ""
              : localStorage.getItem("corpId"),

          departmentID: "",
        },
      },
      authenticateUser
    );
  }, [sendRequest,  toggleChangeAPICall]);
  // const indexOfLastRecord = currentPage * recordsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = filteredData?.slice(
  //   indexOfFirstRecord,
  //   indexOfLastRecord

  // );
  
  // const nPages = Math.ceil(filteredData?.length / recordsPerPage);

  // let fromRecords =
  //   currentPage === 1 ? 1 : (currentPage - 1) * recordsPerPage + 1;
  // let toRecords = Math.min(currentPage * recordsPerPage, filteredData?.length);
  // if (toRecords === 0) fromRecords = 0;

  const nPages = Math.ceil(filteredData.length / rowsPerPage);

  let fromRecords = 0;
  // if (currentPage === 1) fromRecords = 1;
  if (page === 0) fromRecords = 1;

  // else fromRecords = (currentPage - 1) * rowsPerPage;
  else fromRecords = (page) * rowsPerPage;

  let toRecords = 0;
  if (
    (myClick
      ? page * rowsPerPage - (filteredData.length % rowsPerPage)
      : page * rowsPerPage +
        rowsPerPage -
        (filteredData.length % rowsPerPage)) > filteredData.length
  )
    toRecords = filteredData.length;
  else toRecords = page * rowsPerPage;
  if (toRecords === 0) fromRecords = 0;
  if (page === nPages) toRecords = filteredData.length;
  // Calculate pagination
  const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
  const indexOfFirstRecord = page * rowsPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);


  const addRouteClickHandler = () => {
    setIsAddRouteClicked(true);
  };

  // for testing
  const newUIRouteClickHandler = () => {
    setIsNewUIRouteClicked(true);
  };

  const routeSearchHandler = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setFilteredData(
      route_details.filter(
        (data) =>
          data.route?.toLowerCase().includes(searchValue) ||
          data.route_id?.toLowerCase().includes(searchValue) ||
          data.city?.toLowerCase().includes(searchValue) ||
          data.country?.toLowerCase().includes(searchValue) ||
          data.zone_price?.toString()?.toLowerCase().includes(searchValue) ||
          data.route_type?.toLowerCase().includes(searchValue)
      )
    );
  };

  return (
    <React.Fragment>
      {isAddRouteClicked && <div className="add-route-fullcontainer"></div>}
      {/* {isNewUIRouteClicked && <div className="add-route-fullcontainer"></div>} */}

      <div className="trips-details" id="trip-table">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0px 10px",
          }}
        >
          <div className="title">shuttle routes</div>
          {localStorage.getItem("userType") !== "AccountManager" && (
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <button
                onClick={addRouteClickHandler}
                style={{
                  marginRight: "40px",
                  padding: "7px 14px",
                  backgroundColor: "rgba(34, 137, 203, 255)",
                  color: "white",
                  border: "rgba(34, 137, 203, 255)",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Add Route
              </button> */}

              <Button
                onClick={() => history.push("/addroute")}
                // onClick={newUIRouteClickHandler}
                variant="contained"
                size="small"
                sx={{
                  boxShadow: "none",
                  marginRight: "15px",
                  fontFamily: "Montserrat",
                }}
              >
                Add Route
              </Button>
            </div>
          )}
        </div>
        <div className="table-container-routes">
          <div className="header" style={{ alignItems: "center" }}>
            {localStorage.getItem("roleId") == "1" && (
              <div style={{ marginRight: "1%", marginTop:'-1%' }}>
                <CorporateFilter
                  // changeProps={handleCorporateChange}
                  selectedCorporate={selectedCorporate}
                  setSelectedCorporate={setSelectedCorporate}
                  mainData={route_details}
                  filteredData={filteredData}
                  setFilteredData={setFilteredData}
                  corporateData={corporatesData}
                  setCorporateData={setCorporatesData}
                />
                {/* <HeaderFilter
                enableCorporateFilter={true}
                // enableExportFilter={true}
                // enableSearchFilter={true}

                // Corporate Filter Props
                selectedCorporate={selectedCorporate}
                setSelectedCorporate={setSelectedCorporate}
                mainData={route_details}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                corporateData={corporatesData}
                setCorporateData={setCorporatesData}
                // Search Filter Props
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setSearchFilteredData={setFilteredData}
              /> */}
              </div>
            )}

            {/* <div onChange={routeSearchHandler} className="route-search"> */}
            {/* <input placeholder="Search" type="text" ref={searchInputRef} /> */}
            <TextField
              type="text"
              // value={searchValue}
              ref={searchInputRef}
              onChange={routeSearchHandler}
              placeholder="Search"
              label="Search"
              className="route-search-input"
              size="small"
              variant="standard"
              style={{ marginRight: "15px", marginTop: "-12px" }}
            />
            {/* </div> */}
            <div>
              <CSVLink
                data={route_details}
                className="export_csv"
                filename={"data.csv"}
              >
                Export
              </CSVLink>
            </div>
          </div>
          <Records
            data={currentRecords}
            headers={TRIP_TITLE}
            isLoading={isLoading}
            setToggleChangeAPICall={setToggleChangeAPICall}
          />
          <div>
            {/* <p>
              Showing {fromRecords} to {toRecords} of {filteredData?.length}{" "}
              entries
            </p> */}
            {/* <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => setCurrentPage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={nPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="page-num"
              previousLinkClassName="page-num"
              nextLinkClassName="page-num"
              activeLinkClassName="active"
            /> */}
             <CommonTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              filteredData={filteredData}
              styling={{ display: "flex", justifyContent: "flex-end" }}
            />
          </div>
        </div>
        {isAddRouteClicked && (
          <AddRoute
            routeCreationStatus={routeCreationStatus}
            setIsAddRouteClicked={setIsAddRouteClicked}
          />
        )}

        {/*for testing  */}
        {isNewUIRouteClicked && (
          <TestRouteInfo
            routeCreationStatus={routeCreationStatus}
            setIsNewUIRouteClicked={setIsNewUIRouteClicked}
          />
        )}

        {isRouteCreated &&
          (isRouteCreated === "Loading" ? (
            <img
              src={loadingGif}
              style={{ position: "absolute", top: "40%", left: "57%" }}
              alt="loading-gif"
            />
          ) : (
            <Message
              message={isRouteCreated}
              setIsRouteCreated={setIsRouteCreated}
              type={
                isRouteCreated === "Success"
                  ? "success"
                  : "error"
              }   
            />
          ))}
      </div>
    </React.Fragment>
  );
}

export default Routes;
