// AppRoutes.js

import React, { useCallback, useEffect, useState, lazy, Suspense } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { publicRoutes, authRoutes } from "./index"; // Import publicRoutes and authRoutes from index.js
import Login from "../Components/Home/Login";
import Header from "../Components/Header/Header";
import SideMenu from "../Components/Header/SideMenu";
import Unauthorized from "../Components/AddNewCorp/UnauthorizedUser/Unauthorized"; // Import the Unauthorized component
import Loading from "../Loading/Loading";
// Lazy load components
const LazyLogin = lazy(() => import("../Components/Home/Login"));
const LazyUnauthorized = lazy(() =>
  import("../Components/AddNewCorp/UnauthorizedUser/Unauthorized")
);
// Import other components to be lazy loaded in a similar way

const AppRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [roleId, setRoleId] = useState(null); // State to store roleId
  const history = useHistory();
  const location=useLocation()

  useEffect(() => {
    window.addEventListener("storage", function (e) {
      if (e.key === "login") {
        this.window.location.reload();
      }
    });
  }, []);
  useEffect(() => {
    const validRoutes = ['/addroute', '/addroute-stop'];
    if (!validRoutes.includes(location.pathname)) {
      sessionStorage.clear();
    }
  }, [location.pathname]);

  useEffect(() => {
    let status = localStorage.getItem("login");
    if (status === null) setIsLoggedIn(false);
    else status === "false" ? setIsLoggedIn(false) : setIsLoggedIn(true);

    // Get roleId from localStorage
    const storedRoleId = localStorage.getItem("roleId");
    setRoleId(storedRoleId);
  }, [history]);

  const loginHandler = useCallback((loggedValue) => {
    localStorage.setItem("login", true);
    history.push("/dashboard");
    setIsLoggedIn(loggedValue);
  }, []);

  useEffect(() => {
    if (window.screen.width >= 768) {
      const pathsElement = document.getElementsByClassName("paths")[0];
      if (pathsElement) {
        if (isSideMenuOpen) {
          pathsElement.style.width = "80%";
        } else {
          pathsElement.style.width = "100%";
        }
      }
    }
  }, [isSideMenuOpen]); // Run this effect whenever isSideMenuOpen changes

  const sideMenuHoverHandler = () => {
    setIsSideMenuOpen(true);
  };

  const sideMenuLeaveHandler = () => {
    setIsSideMenuOpen(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <Suspense fallback={<Loading datatable="true" />}>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/login" />
          </Route>
          <Route path="/login">
            {isLoggedIn ? (
              <Redirect to="/dashboard" />
            ) : (
              // <Login login={loginHandler} />
              <LazyLogin login={loginHandler} />
            )}
          </Route>
          {/* Only render the Login component if not logged in */}
          {!isLoggedIn && (
            <Route path="/">
              <LazyLogin login={loginHandler} />
            </Route>
          )}
        </Switch>
        {isLoggedIn && (
          <React.Fragment>
            <Header
              sideMenuOpen={sideMenuHoverHandler}
              setIsLoggedIn={setIsLoggedIn}
            />
            <div className="myContainer">
              <SideMenu
                sideMenuClose={sideMenuLeaveHandler}
                property={isSideMenuOpen}
              />
              <div className="paths">
                <Switch>
                  {/* Map over publicRoutes */}
                  {publicRoutes?.map((route, idx) => (
                    <Route key={idx} path={route.path} exact={route.exact}>
                      <route.component setIsLoggedIn={setIsLoggedIn} />
                    </Route>
                  ))}
                  {/* Map over authRoutes */}
                  {authRoutes?.map((route, idx) => (
                    <Route key={idx} path={route.path}>
                      {/* Check if the route is /new-registration and roleId is 1 */}
                      {(route.path === "/new-registration" ||
                        route.path === "/settings" ||
                        route.path === "/addpricing" ||
                      route.path === "/corporates"  ) &&
                      roleId !== "1" ? (
                        // <Unauthorized />
                        <LazyUnauthorized />
                      ) : (
                        <route.component />
                      )}
                    </Route>
                  ))}
                </Switch>
              </div>
            </div>
          </React.Fragment>
        )}
      </Suspense>
    </div>
  );
};

export default AppRoutes;
