import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useState } from "react";
import "./DataTable.css";

import Loading from "../../Loading/Loading";
import useHttp from "../../Hooks/use-http";
import TripInfoMap from "./TripInfoMap";
import { useHistory } from "react-router-dom";

export default function BookedRidersData(props) {
  const [ridersData, setRidersData] = useState([]);
  const [mapRidersData, setMapRidersData] = useState([]);
  const [driverPath, setDriverPath] = useState([]);
  const [totalData, setTotalData] = useState([]);

  const { isLoading, sendRequest } = useHttp();
  const history = useHistory();

  const ridersDataResponse = (data) => {
    setTotalData(data);
    // setRidersData(data?.TripdetailList);
    setRidersData(data?.TripRiders);
    // setMapRidersData(data?.TripdetailList);
    // setMapRidersData(data?.Tripdetails)
    setMapRidersData(data?.Tripdetails)

    // setDriverPath(data?.Triplatlong);
    setDriverPath(data?.TripLocations)
  };

  React.useEffect(() => {
    // sendRequest(
    //   {
    //     url: "/api/v1/Trips/AllTripsDetails",
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: {
    //       emailID: localStorage.getItem("user"),
    //       journeyID: props.journeyId ? props.journeyId : "",
    //       tripType: props.tripType ? props.tripType : "",
    //     },
    //   },
    //   ridersDataResponse
    // );
    sendRequest(
      {
        url: "/api/v1/Trips/GetTripDetails",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          EmailID: localStorage.getItem("user"),
          // driverEmailID: onTripDriverEmail,
          CorporateID: localStorage.getItem("corpId"),
          roleID: localStorage.getItem("roleId"),
          // Isall: 1,
          LocationCount: 9999,
          TripID: props.journeyId ? props.journeyId : "",
        },
      },
      ridersDataResponse
    );
  }, [sendRequest]);

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  

  return (
    <React.Fragment>
      {isLoading ? (
        <div style={{ marginTop: "-8px" }}>
          <Loading datatable="true" />
        </div>
      ) : (
        <React.Fragment>
          <TableContainer
            component={Paper}
            // sx={{
            //   fontSize: "10px",
            //   maxHeight: 250,
            //   marginLeft: "30",
            //   backgroundColor: "#f8fcfd",
            // }}
            sx={{
              fontSize: "10px",
              maxHeight: 250,
              // marginLeft: "30px", // Added 'px' unit
              backgroundColor: "#f8fcfd",
              overflowY: "auto", // Added to enable vertical scrolling
              "&::-webkit-scrollbar": {
                width: "10px", // Adjust the width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust scrollbar thumb color
                borderRadius: "10px", // Adjust scrollbar thumb border radius
              },
            }}
          >
            <Table
              sx={{ maxWidth: "150%" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "3%",
                      // paddingLeft: "5px",
                      // backgroundColor: "rgba(224, 224, 224, 0.7)",
                      backgroundColor: "#F0F6F9",
                      fontWeight: "bold",
                    }}
                  >
                    Sr. No.
                  </TableCell>

                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "9%",
                      fontWeight: "bold",

                      // paddingLeft: "10px",
                      // backgroundColor: "rgba(224, 224, 224, 0.9)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Rider Name
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "15%",
                      fontWeight: "bold",
                      // paddingLeft: "10px",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Pickup Location
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "15%",
                      fontWeight: "bold",
                      // paddingLeft: "10px",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Actual Pickup Location
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "10%",
                      fontWeight: "bold",
                      // paddingLeft: "10px",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Pickup Time
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "10%",
                      fontWeight: "bold",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Drop Location
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "10%",
                      fontWeight: "bold",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Actual Drop Location
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    sx={{
                      fontSize: "10px",
                      width: "12%",
                      fontWeight: "bold",
                      // backgroundColor: "rgba(224, 224, 224, 0.8)",
                      backgroundColor: "#F0F6F9",
                    }}
                  >
                    Drop Time
                  </TableCell>
                  {props.tripType.toLowerCase() === "shuttle" && (
                    <TableCell
                      padding="none"
                      align="center"
                      sx={{
                        fontSize: "10px",
                        width: "5%",
                        fontWeight: "bold",
                        paddingRight: "10px",

                        // backgroundColor: "rgba(224, 224, 224, 0.8)",
                        backgroundColor: "#F0F6F9",
                      }}
                    >
                      Status
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {ridersData?.map((rider, index) => (
                  <TableRow key={index} sx={{ padding: "3px" }}>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      {index + 1}
                      {"."}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      {rider.RiderName}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span>{rider.Pickup}</span>
                        <span style={{ color: "gray", fontSize: "10px" }}>
                          {rider.PickupLatLng
                            ? "( " + rider.PickupLatLng + " )"
                            : ""}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span>{rider.actualPickupAddress ?? "-"}</span>
                        <span style={{ color: "gray", fontSize: "10px" }}>
                          {rider.ActualPickupLatLng
                            ? "( " + rider.ActualPickupLatLng + " )"
                            : ""}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      {props.tripType.toLowerCase() === "shuttle"
                        ? rider.BoardedOn ?? "-"
                        : rider.StartedOn ?? "-"}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span>{rider.Drop != null ?  rider.Drop : "-"}</span>
                        <span style={{ color: "gray", fontSize: "10px" }}>
                          {rider.DropLatLng
                            ? "( " + rider.DropLatLng + " )"
                            : ""}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          color: "black",
                        }}
                      >
                        <span>{rider.actualDropoffAddress ?? "-"}</span>
                        <span style={{ color: "gray", fontSize: "10px" }}>
                          {rider.ActualPickupLatLng
                            ? "( " + rider.ActualPickupLatLng + " )"
                            : ""}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="none"
                      sx={{ fontSize: "10px" }}
                    >
                      {props.tripType.toLowerCase() === "shuttle"
                        ? formatDateTime(rider.ActualPickupTime) ?? "-"
                        : formatDateTime(rider.ActualDropTime) ?? "-"}
                    </TableCell>

                    {/* if trip type is shuttle then only show the status */}
                    {props.tripType.toLowerCase() === "shuttle" && (
                      <TableCell align="center" padding="none">
                        <div
                          style={{
                            fontSize: "10px",
                            fontWeight: "500",
                            // Add a dynamic inline style based on trip status
                            color:
                              props.tripType.toLowerCase() === "shuttle"
                                ? rider.ActualPickupLatLng === null &&
                                  rider.ActualDropLatLng === null
                                  ? "#1D85C6"
                                  : rider.ActualPickupLatLng === null &&
                                    rider.ActualDropLatLng !== null
                                  ? "#44B150"
                                  : (rider.ActualPickupLatLng !== null &&
                                      rider.ActualDropLatLng !== null) ||
                                    rider.TripStatus === "ENDED"
                                  ? "#D83434"
                                  : "gray"
                                : "gray", // Default color if trip type is not 'shuttle'
                            border: "1px solid",
                            borderRadius: "10px",
                            width: "auto",
                            padding: "2px",
                            textAlign: "center",
                            textTransform: "capitalize",
                            margin: "auto",
                            marginRight: "8px",
                          }}
                        >
                          {
                            props.tripType.toLowerCase() === "shuttle" &&
                              (rider.ActualPickupLatLng === null &&
                              rider.ActualDropLatLng === null
                                ? "Waiting"
                                : rider.ActualPickupLatLng !== null &&
                                  rider.ActualDropLatLng === null
                                ? "Boarded"
                                : (rider.ActualPickupLatLng !== null &&
                                    rider.ActualDropLatLng !== null) ||
                                  rider.TripStatus === "ENDED"
                                ? "Alighted"
                                : "-") // or any default value you prefer
                          }
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* For More Details --- Trip Details */}
          <h5
            style={{
              cursor: "pointer",
              color: "blue",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={() => {
              const tripId = props?.journeyId;
              const tripType = props?.tripType;
              // if (tripId) {
              //   history.push({
              //     pathname: `/trips/tripId=${tripId}`,
              //     search: `?triptype=${tripType}`, // You can still send the tripType in the URL
              //     state: { tripData: ridersData }, // Send the entire ridersData as state
              //   });
              // }

              if (tripId) {
                // Construct the URL with query parameters
                const url = `/trips/tripId=${tripId}?triptype=${tripType}`;

                // Open URL in a new tab
                window.open(url, "_blank");

                // Optionally, navigate within the app
                // history.push({
                //   pathname: url,
                //   state: { tripData: ridersData }, // Send ridersData as state
                // });
              }
            }}
            onMouseOver={(e) => {
              e.target.style.color = "#007BFF"; // Change color on hover
              e.target.style.textDecoration = "underline"; // Underline on hover
            }}
            onMouseOut={(e) => {
              e.target.style.color = "blue"; // Reset color
              e.target.style.textDecoration = "none"; // Remove underline
            }}
          >
          More Details
          </h5>
          <br />
          {mapRidersData?.length !== 0 && driverPath?.length !== 0 && (
            <TripInfoMap RIDER_DATA={mapRidersData} driverPath={driverPath} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
