import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Loading from "../../../Loading/Loading";
import editImage from "../../../Assets/editIcon.png";
import useHttp from "../../../Hooks/use-http";
import "./Record.css";
import Overtime from "./Overtime";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Tooltip,
  Radio,
  Checkbox,
  TextField,
} from "@mui/material";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import DefaultImage from "../../../Assets/default_image.jpg";
import { FormatDateTime } from "../../CommonComponent/DateFormat";
const Records = ({
  isLoading: parentLoading,
  data,
  headers,
  setIsDataFiltered,
  overtimeUpdateSuccessHandler,
}) => {
  const history = useHistory();
  // console.log(
  //   "full data",
  //   data.map((el) => el.completedTask)
  // );
  const [overTimeDetails, setOverTimeDetails] = useState(null);
  const [overtimeAlertShow, setOvertimeAlertShow] = useState(false);
  const [isOvertimeConfirmHandler, setOvertimeConfirmHandler] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Driver Info");

  // sorting part
  const handleSort = (column) => {
    if (orderBy === column && orderBy !== "" && order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(column);
  };

  if (orderBy !== "") {
    const sortedData = data.sort((a, b) => {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    });
  }

  const driverOvertimeApiResponse = (data) => {
    if (data?.Message?.toLowerCase() === "success") {
      overtimeUpdateSuccessHandler({
        type: "success",
        message:
          overTimeDetails.type.toLowerCase() === "accept"
            ? "Overtime accepted Successfully"
            : "Overtime rejected Successfully",
      });
    } else {
      overtimeUpdateSuccessHandler({
        type: "error",
        message: "Some error occured! Please try again later.",
      });
    }
    setOvertimeConfirmHandler(false);
  };

  const { isLoading, sendRequest } = useHttp();

  // const dateViewFormat = {
  //   year: "numeric",
  //   month: "short",
  //   day: "numeric",
  //   hour: "2-digit",
  //   minute: "2-digit",
  //   // second: "2-digit",
  // }
  // // Input: 2024-09-04T01:27:41.527
  // const formatDateTime = (timestamp) => {
  //   const date = new Date(timestamp);
  //   return date.toLocaleString("en-US", dateViewFormat);
  // };

  useEffect(() => {
    if (isOvertimeConfirmHandler)
      sendRequest(
        {
          url: "/api/v1/DriverList/UpdatetDriverOvertime",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            roleID: "1",
            shiftID: overTimeDetails.shiftId,
            overTime: overTimeDetails.overtime,
          },
        },
        driverOvertimeApiResponse
      );
  }, [sendRequest, isOvertimeConfirmHandler]);

  const acceptRejectIconClickHandler = (item, type, overtimeValue) => {
    setOverTimeDetails({
      driverName: item.driver_name,
      shiftId: item.shift_id,
      overtime: overtimeValue,
      type: type,
    });
    setOvertimeAlertShow(true);
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const [expandedPanel, setExpandedPanel] = useState(false);
  const handleRowClick = (clickedItem) => {
    // Check if the clicked item is a header
    setOrderBy("");
    const isHeaderClick = headers.includes(clickedItem.corporate);

    // If it's a header click, return without doing anything
    if (isHeaderClick) {
      return;
    }

    // Toggle expanded state of the row if it's not a header click
    setExpandedRow((prevExpandedRow) =>
      prevExpandedRow === clickedItem ? null : clickedItem
    );
    setExpandedPanel(true);
  };

  // For copying shiftId----------------
  const [copiedItemId, setCopiedItemId] = useState(null);
  const handleCopyClick = (event, shiftId) => {
    event.stopPropagation(); // Stop the event from propagating to the parent row
    navigator.clipboard.writeText(shiftId);
    setCopiedItemId(shiftId);
    setTimeout(() => setCopiedItemId(null), 2000); // Reset copiedItemId state after 2 seconds
  };

  const renderField = (field) => {
    const { FieldText, FieldType, ExtraDetails, Answer } = field;

    switch (FieldType) {
      case "INPUT":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <TextField value={Answer} disabled fullWidth variant="standard" />
          </div>
        );

      case "IMAGE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            {/* <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "10px" }}> */}

            {Answer !== null && Answer !== "" && Answer !== "null" ? (
              <a href={Answer} target="_blank" rel="noopener noreferrer">
                <img
                  src={Answer}
                  alt="Answer"
                  style={{ width: "100px", height: "100px" }}
                />
              </a>
            ) : (
              <a href={DefaultImage} target="_blank" rel="noopener noreferrer">
                <img
                  src={DefaultImage}
                  alt="Default"
                  style={{ width: "100px", height: "100px" }}
                />
              </a>
            )}
          </div>
          // </div>
        );

      case "MULTI_CHOICE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {ExtraDetails?.FieldValue.map((value, index) => (
                <div key={index}>
                  <Checkbox checked={Answer.includes(value.ValueID)} disabled />
                  {value.ValueText}
                </div>
              ))}
            </div>
          </div>
        );
      case "NUMBER":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div>
              {/* {ExtraDetails?.FieldValue.map((value, index)=>( */}
              <div>
                <TextField
                  value={Answer}
                  disabled
                  fullWidth
                  variant="standard"
                />
              </div>
              {/* ))} */}
            </div>
          </div>
        );

      case "SINGLE_CHOICE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {ExtraDetails?.FieldValue.map((value, index) => (
                <div key={index}>
                  <Radio checked={Answer === value.ValueID} disabled />
                  {value.ValueText}
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  function calculateTimeDifference(startTime, endTime) {
    // Parse the date strings into Date objects
    const startDate = new Date(startTime);
    let endDate;
    if (endTime == null || undefined) {
      endDate = new Date();
    } else {
      endDate = new Date(endTime);
    }

    // Calculate the difference in milliseconds
    const diffInMilliseconds = endDate - startDate;

    // Convert the difference from milliseconds to minutes
    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    // Return the formatted string like "12h 30m"
    return `${hours} h : ${minutes} m`;
  }

  function compareTimeDifferences(startTime1, endTime1, startTime2, endTime2) {
    const startDate1 = new Date(startTime1);
    const endDate1 = new Date(endTime1);
    const startDate2 = new Date(startTime2);
    const endDate2 = new Date(endTime2);

    const diff1 = endDate1 - startDate1; // Difference for shift_time
    const diff2 = endDate2 - startDate2; // Difference for shift_on_time

    // Return 'red' if second time difference is greater, otherwise 'green'
    return diff2 < diff1 ? "#D83434" : "#44B150";
  }

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          color: "rgba(34, 137, 203, 255)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={overtimeAlertShow}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are going to {overTimeDetails?.type}{" "}
            {overTimeDetails?.driverName}'s overtime. Are you sure you want to{" "}
            {overTimeDetails?.type}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOvertimeAlertShow(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setOvertimeAlertShow(false);
              setOvertimeConfirmHandler(true);
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none" style={{ width: "2%" }}></TableCell>
              {headers?.map((header) => {
                if (
                  localStorage.getItem("roleId") !== "1" &&
                  header === "Corporate Name"
                ) {
                  return null;
                }
                return (
                  <TableCell
                    key={header}
                    sx={{
                      py: 0,
                      px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderBy === header ? order : "asc"}
                      onClick={() => handleSort(header)}
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {header}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length === 0 &&
              !parentLoading && ( // Check if no sortedData and not loading
                <TableRow>
                  <TableCell colSpan={headers?.length + 1}>
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      No Data Available
                    </div>
                  </TableCell>
                </TableRow>
              )}
            {parentLoading ? ( // Check if loading
              <TableRow>
                <TableCell colSpan={headers?.length + 1}>
                  <Loading datatable="true" />
                </TableCell>
              </TableRow>
            ) : (
              data?.map((item, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    // onClick={(e) => handleRowClick(e, index)}
                    onClick={() => handleRowClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    {/* {item.completedTask !== null && ( */}
                    <TableCell padding="none">
                      <div
                        style={{
                          paddingLeft: "10px",
                          visibility:
                            item.completedTask !== null ? "visible" : "hidden",
                        }}
                      >
                        {expandedPanel === item.item ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </div>
                    </TableCell>
                    {/* )} */}

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      }}
                    >
                      {/* {item.shiftId} */}
                      {item.shiftId.substring(0, 8) + "..."}
                      <Tooltip
                        title={
                          copiedItemId === item.shiftId
                            ? "Copied!"
                            : "Copy Shift ID"
                        }
                        arrow
                      >
                        <IconButton
                          size="small"
                          onClick={(event) =>
                            handleCopyClick(event, item.shiftId)
                          }
                        >
                          <FileCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                        // fontWeight: 600,
                      }}
                    >
                      {item.driver_name}
                      <div>
                        <span style={{ color: "gray", fontSize: "small" }}>
                          {item?.model}
                        </span>
                      </div>
                      {/* <span>
                {data.routeName}
                <div style={{marginTop:'-4%'}}>

                <span style={{ color: "gray", fontSize:'small' }}>
                {data.routeType.charAt(0).toUpperCase() + data.routeType.slice(1)}{":"}
                </span> 
                <span style={{ color: "gray", fontSize:'small' }}>
                {" "}{data.routeTime} 
                </span> */}
                    </TableCell>
                    {localStorage.getItem("roleId") == "1" && (
                      <TableCell
                        sx={{
                          fontSize: "0.8rem",
                          p: 1,
                          px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                        }}
                      >
                        {item.corporateName}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") !== "1" ? 2 : 1,
                      }}
                    >
                      {item.shift_startTime || item.shift_endTime ? (
                        <>
                          <div>{FormatDateTime(item.shift_startTime)}</div>
                          <div>to</div>
                          <div>{FormatDateTime(item.shift_endTime)}</div>
                          <div style={{ color: "gray" }}>
                            (
                            {item.shift_startTime && item.shift_endTime
                              ? calculateTimeDifference(
                                  item.shift_startTime,
                                  item.shift_endTime
                                )
                              : ""}
                            )
                          </div>
                        </>
                      ) : (
                        <div>-</div>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      }}
                    >
                      <>
                        {item.shift_startedOn || item.shift_endedOn ? (
                          <>
                            <div>{FormatDateTime(item.shift_startedOn)}</div>
                            <div>to</div>
                            <div>
                              {item.shift_endedOn == null
                                ? "-"
                                : FormatDateTime(item.shift_endedOn)}
                            </div>
                            {/* <div style={{color:'gray'}}>
                                (
                                {item.shift_startedOn || item.shift_endedOn
                                  ? calculateTimeDifference(
                                      item.shift_startedOn,
                                      item.shift_endedOn
                                    )
                                  : ""}
                                )
                              </div> */}

                            <div
                              style={{
                                color: compareTimeDifferences(
                                  item.shift_startTime,
                                  item.shift_endTime,
                                  item.shift_startedOn,
                                  item.shift_endedOn
                                ),
                              }}
                            >
                              (
                              {item.shift_startedOn && item.shift_endedOn
                                ? calculateTimeDifference(
                                    item.shift_startedOn,
                                    item.shift_endedOn
                                  )
                                : ""}
                              )
                            </div>
                          </>
                        ) : (
                          <div>-</div>
                        )}
                      </>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      }}
                    >
                      <>
                        {item.startOtpEnable !== 1 &&
                        item.endOtpEnable !== 1 ? (
                          <div>Otp is not enabled</div>
                        ) : item.startOtpEnable === 1 &&
                          item.endOtpEnable !== 1 ? (
                          item.startOtp ? (
                            <>
                              <div>Start - {item.startOtp}</div>
                              <div>End Otp is not enabled</div>
                            </>
                          ) : (
                            <div>-</div>
                          )
                        ) : item.startOtpEnable !== 1 &&
                          item.endOtpEnable === 1 ? (
                          item.endOtp ? (
                            <>
                              <div>Start Otp is not enabled</div>
                              <div>End - {item.endOtp}</div>
                            </>
                          ) : (
                            <div>-</div>
                          )
                        ) : item.startOtpEnable === 1 &&
                          item.endOtpEnable === 1 ? (
                          item.startOtp || item.endOtp ? (
                            <>
                              <div>Start - {item.startOtp}</div>
                              <div>End - {item.endOtp}</div>
                            </>
                          ) : (
                            <div>-</div>
                          )
                        ) : (
                          <div>-</div>
                        )}
                      </>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {item.taskNames
                        ? item.taskNames.split(",").map((task, index) => (
                            <div key={index}>
                              {index + 1}. {task.trim()}
                            </div>
                          ))
                        : "-"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      }}
                    >
                      {/* {item.status ? item.status : "Expired"} */}

                      {new Date() > new Date(item.shift_startTime) ||
                      item.shift_endedOn ? (
                        item.status ? (
                          item.status
                        ) : (
                          "Expired"
                        )
                      ) : (
                        <img
                          onClick={() =>
                            history.push(
                              "/privatedrive/shift-creation?shiftId=" +
                                item.shift_id
                            )
                          }
                          style={{
                            width: "17px",
                            height: "17px",
                            cursor: "pointer",
                          }}
                          src={editImage}
                          alt="edit"
                          title="Click to edit details"
                        />
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontSize: "0.8rem",
                        p: 1,
                        px: localStorage.getItem("roleId") != "1" ? 2 : 1,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          // width:'max-content'
                        }}
                      >
                        {/* Assuming Overtime component is rendered here */}
                        {/* Pass necessary props to the Overtime component */}
                        <Overtime
                          item={item}
                          acceptRejectIconClickHandler={
                            acceptRejectIconClickHandler
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>

                  {expandedRow === item && item.completedTask !== null && (
                    <TableRow>
                      <TableCell colSpan={headers.length}>
                        <div
                          style={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "4px",
                            padding: "16px",
                            marginLeft: "5%",
                            width: "105%",
                          }}
                        >
                          <TableContainer
                            style={{
                              width: "100%",
                              fontSize: "0.5rem",
                            }}
                          >
                            <Table size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      py: 0,
                                      px: 2,
                                      fontWeight: "bold",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      width: "15%", // Adjust the width for Name column
                                    }}
                                  >
                                    Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      py: 0,
                                      px: 3,
                                      fontWeight: "bold",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      width: "25%", // Adjust the width for Validations column
                                    }}
                                  >
                                    Validations
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      py: 0,
                                      px: 5,
                                      fontWeight: "bold",
                                      color: "rgba(0, 0, 0, 0.87)",
                                      width: "55%", // Adjust the width for Fields column
                                    }}
                                  >
                                    Fields
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody component={Paper}>
                                {/* Only map over completedTask of the current parent row */}
                                {item?.completedTask?.map((task) => (
                                  <TableRow key={task.TaskhistoryID}>
                                    <TableCell>{task.TaskName}</TableCell>
                                    <TableCell>
                                      <div
                                        style={{ display: "flex", gap: "15px" }}
                                      >
                                        <div>
                                          <div>
                                            <strong>Required</strong>{" "}
                                          </div>
                                          <div
                                            style={{
                                              color: `${
                                                task.IsRequired !== true
                                                  ? "red"
                                                  : "green"
                                              }`,
                                            }}
                                          >
                                            {String(task.IsRequired)}
                                          </div>
                                        </div>

                                        <div>
                                          <div>
                                            <strong>OnStart</strong>{" "}
                                          </div>
                                          <div
                                            style={{
                                              color: `${
                                                task.IsOnStart !== true
                                                  ? "red"
                                                  : "green"
                                              }`,
                                            }}
                                          >
                                            {String(task.IsOnStart)}
                                          </div>
                                        </div>
                                        <div>
                                          <div>
                                            <strong>OnEnd</strong>
                                          </div>
                                          <div
                                            style={{
                                              color: `${
                                                task.IsOnEnd !== true
                                                  ? "red"
                                                  : "green"
                                              }`,
                                            }}
                                          >
                                            {String(task.IsOnEnd)}
                                          </div>
                                        </div>
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <div
                                        style={{
                                          maxHeight: "200px", // Adjust the height as needed
                                          overflowY: "auto", // Enable vertical scrolling
                                          padding: "10px", // Optional: to prevent content from being obscured by the scrollbar
                                          border: "1px solid #e0e0e0", // Optional: to visually separate the section
                                          borderRadius: "4px", // Optional: for rounded corners
                                          scrollbarWidth: "thin",
                                          width: "auto",
                                        }}
                                      >
                                        <ul
                                          style={{
                                            display: "grid",
                                            gridTemplateColumns:
                                              "repeat(2, 1fr)",
                                            gap: "20px",
                                          }}
                                        >
                                          {JSON.parse(task.TaskDetails)?.map(
                                            (field, index) => (
                                              <>
                                                <div key={index}>
                                                  {renderField(field)}
                                                </div>
                                              </>
                                            )
                                          )}
                                        </ul>
                                      </div>
                                      {/* <span style={{fontSize:'10px'}}>Modified On {task?.ModifiedOn} at {task?.Location}</span> */}
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <p style={{ fontSize: "10px" }}>
                                          Modified On{" "}
                                          {FormatDateTime(task?.ModifiedOn)} at{" "}
                                          <a
                                            href={`https://www.google.com/maps?q=${task?.Location}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {task?.Location}
                                          </a>
                                        </p>
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default Records;
