import React, { useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Records from "./Records";
import useHttp from "../../../Hooks/use-http";
import Modal from "../../../GeneratePDF/Modal";
import dayjs from "dayjs";
import { Padding, Search } from "@mui/icons-material";
import SearchFilter from "../../CommonComponent/SearchFilter";
import HeaderFilter from "../../CommonComponent/HeaderFilter";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TablePagination } from "@mui/material";
import CommonTablePagination from "../../CommonComponent/CommonTablePagination";


// const SHIFT_TITLE = [
//   // "",
//   "Shift ID",
//   "Driver Info",
//   // "Shift Date",
//   localStorage.getItem('roleId') == '1' ? "Corp Name" : null,
//   // "Start Time",
//   // "End Time",
//   "Scheduled Time",
//   // "Started On",
//   // "Ended On",
//   "Actual Time",
//   "Shift OTP",
//   "Assigned Task",
//   "Status",
//   "Overtime",
// ].filter(Boolean);

let myClick = false;
let prev_id = "1";

let tripListFlag = 0;
let total_shift_data = [];
let today = new Date()
  .getFullYear()
  .toString()
  .concat("-", new Date().getMonth() + 1, "-", new Date().getDate());
let startDate = today;
let endDate = today;

function Shifts(props) {  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let staffMoNumber = queryParams.get("staff");

  const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(7);
  const [filteredData, setFilteredData] = useState(total_shift_data);
  // const [mainData1, setMainData1]=useState(null)
  
  const [isDataFiltered, setIsDataFiltered] = useState(true);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [isSnackbarShow, setIsSnackbarShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const startDateRef = useRef();
  const endDateRef = useRef();
  const history = useHistory();
  const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
  const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));
  const [corporateData, setCorporateData]=useState([])
  const [selectedCorporateDetails, setSelectedCorporateDetails] = useState({
    cpName: "",
    cpId: "",
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [roleId, setRoleId] = useState(localStorage.getItem("roleId"));

  useEffect(() => {
    const handleStorageChange = () => {
      setRoleId(localStorage.getItem("roleId"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const SHIFT_TITLE = [
    "Shift ID",
    "Driver Info",
    roleId === "1" ? "Corp Name" : null,
    "Scheduled Time",
    "Actual Time",
    "Shift OTP",
    "Assigned Task",
    "Status",
    "",
    "Overtime",
  ].filter(Boolean);

  //   Function for the handlesearch
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };


  // console.log('sf', total_shift_data)
  // Filter out the data according to the Drivers
  useEffect(
    () => {
      // if (isDataFiltered === true) {
      let filteredShifts = total_shift_data.filter(
        (shift) =>
          shift.driver_name.toLowerCase().includes(searchValue.toLowerCase()) ||
          shift.shiftId.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData(filteredShifts);
    },
    //   }
    [searchValue]
  );

  const overtimeUpdateSuccessHandler = (details) => {
    // console.log(details);
    setIsDataFiltered(true);
    setIsSnackbarShow(details);
  };

  const authenticateUser = (data) => {
    let shift_list = [];
    
    if (data.DriverShiftList) {
      for (let i = 0; i < data.DriverShiftList.length; i++) {
        shift_list.push({
          id: i + 1,
          driver_name: data.DriverShiftList[i].DriverName,
          car_info:
            data.DriverShiftList[i].Model + "," + data.DriverShiftList[i].Color,
          shift_id: data.DriverShiftList[i].ShiftID,
          // shift_date: data.DriverShiftList[i].StartTime.split("T")[0],
          shift_startTime: data.DriverShiftList[i].StartTime.replace("T", " "),
          shift_endTime: data.DriverShiftList[i].EndTime.replace("T", " "),
          shift_startedOn: data.DriverShiftList[i].ShiftStartedOn?.replace(
            "T",
            " "
          ),
          shift_endedOn: data.DriverShiftList[i].ShiftEndedOn?.replace(
            "T",
            " "
          ),
          reporting_location: data.DriverShiftList[i].ReportingLocaiton,
          reporting_latLng: data.DriverShiftList[i].ReportingLL,
          end_location: data.DriverShiftList[i].ShiftEndLocation,
          end_latLng: data.DriverShiftList[i].ShiftEndLL,
          status: data.DriverShiftList[i].Status,
          corporate: data.DriverShiftList[i].CorporateName,
          overTime: data.DriverShiftList[i].OverTimeMin,
          approvedTime: data.DriverShiftList[i].ApprovedTime,
          taskNames: data.DriverShiftList[i].TaskNames,
          shiftId: data.DriverShiftList[i].ShiftID,
          completedTask: data.DriverShiftList[i].CompletedTask,
          corporateName: data.DriverShiftList[i].ParentCorporateName,
          startOtp: data.DriverShiftList[i].StartShiftOtp,
          endOtp: data.DriverShiftList[i].EndShiftOtp,
          startOtpEnable: data.DriverShiftList[i].IsStartShiftOtpEnable,
          endOtpEnable: data.DriverShiftList[i].IsEndShiftOtpEnable,
          model: data.DriverShiftList[i].Model,
        });
      }
    }
    total_shift_data = shift_list;
    setFilteredData(shift_list);
    setIsDataFiltered(false);
  };

  const { isLoading, sendRequest } = useHttp();
  const roleIdLocal = localStorage.getItem("roleId");
  useEffect(() => {
    if (isDataFiltered) {
      setFilteredData([]);
      sendRequest(
        {
          url: "/api/v1/DriverShift/GetDriverShiftDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            corporateID:
              roleIdLocal == "1"
                ? selectedCorporateDetails.cpId
                : localStorage.getItem("adminDepartmentID"),
            startDate: `${startDateValue.$d.getFullYear()}/${
            startDateValue.$d.getMonth() + 1
          }/${startDateValue.$d.getDate()}`,
          endDate: `${endDateValue.$d.getFullYear()}/${
            endDateValue.$d.getMonth() + 1
          }/${endDateValue.$d.getDate()}`,
            roleId: roleIdLocal,
            shiftID: "",
          },
        },
        authenticateUser
      );
    }
  }, [sendRequest, isDataFiltered, searchValue]);

  function formatToMMDDYYYYfromYYYYMMDD(inputDate) {
    var date = new Date(inputDate);
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  }

  // const indexOfLastRecord = currentPage * rowsPerPage;
  // const indexOfFirstRecord = indexOfLastRecord - rowsPerPage;
  // let currentRecords;

  // currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

  const nPages = Math.ceil(filteredData.length / rowsPerPage);

  let fromRecords = 0;
  // if (currentPage === 1) fromRecords = 1;
  if (page === 0) fromRecords = 1;

  // else fromRecords = (currentPage - 1) * rowsPerPage;
  else fromRecords = (page) * rowsPerPage;

  let toRecords = 0;
  if (
    (myClick
      ? page * rowsPerPage - (filteredData.length % rowsPerPage)
      : page * rowsPerPage +
        rowsPerPage -
        (filteredData.length % rowsPerPage)) > filteredData.length
  )
    toRecords = filteredData.length;
  else toRecords = page * rowsPerPage;
  if (toRecords === 0) fromRecords = 0;
  if (page === nPages) toRecords = filteredData.length;

  const handleExportButton=()=>{
    setIsExportButtonClicked(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

   // Calculate pagination
   const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
   const indexOfFirstRecord = page * rowsPerPage;
   const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
 
  return (
    <>
      <Snackbar
        open={isSnackbarShow}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setIsSnackbarShow(false)}
      >
        <Alert
          onClose={() => setIsSnackbarShow(false)}
          severity={isSnackbarShow.type}
          sx={{ width: "100%" }}
        >
          {isSnackbarShow.message}
        </Alert>
      </Snackbar>
      <div className="trips-details" id="trip-table">
        <div
          className="title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Private Driver Shifts</p>
          <Button
            onClick={() => 
              history.push("/privatedrive/shift-creation")}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              marginRight: "10px",
              fontFamily: "Montserrat",
            }}
          >
            Add New Shift
          </Button>
        </div>
        <div className="table-container">
          <div className="header">
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <HeaderFilter
              enableCorporateFilter={true}
              enableDateFilter={true}
              enableSearchFilter={true}
              enableExportFilter={true}

              // COrporate filter
              mainData={total_shift_data}
              selectedCorporate={selectedCorporateDetails}
              setSelectedCorporate={setSelectedCorporateDetails}
              corporateData={corporateData}
              setCorporateData={setCorporateData}
              setFilteredData={setFilteredData}
              filteredData={filteredData}

              // Date Filter
              startDateValue={startDateValue}
              endDateValue={endDateValue}
              setStartDateValue={setStartDateValue}
              setEndDateValue={setEndDateValue}
              setIsRefereshData={setIsDataFiltered}

              // search Filter
              data={total_shift_data}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setSearchFilteredData={setFilteredData}
              
              //Exports Filter 
              onclick={handleExportButton}
              loading={isLoading}

              />

               
              </LocalizationProvider>
            {/* <span
              className="export_csv"
              style={{ cursor: "pointer" }}
              onClick={() => setIsExportButtonClicked(true)}
            >
              Export
            </span> */}
          </div>
          <Records
            data={currentRecords}
            headers={SHIFT_TITLE}
            isLoading={isLoading}
            setIsDataFiltered={setIsDataFiltered}
            overtimeUpdateSuccessHandler={overtimeUpdateSuccessHandler}
          />
          {/* <div className="footer">
           */}
          <div>

            {/* <p>
              Showing {fromRecords} to {toRecords} of {filteredData.length}{" "}
              entries{" "}
            </p> */}
            {/* <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => setCurrentPage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={nPages}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="page-num"
              previousLinkClassName="page-num"
              nextLinkClassName="page-num"
              activeLinkClassName="active"
            /> */}
             {/* <TablePagination
              rowsPerPageOptions={[7, 15, 20]}
              component="div"
              count={filteredData?.length ?? "1"}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            /> */}
             <CommonTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              filteredData={filteredData}
              styling={{ display: "flex", justifyContent: "flex-end" }}
            />
          </div>
        </div>
        {isExportButtonClicked && (
          <>
            <Modal
              setIsExportButtonClicked={setIsExportButtonClicked}
              isPrivateDriver="1"
              isShift="1"
              type="shifts"
            />
            <div className="add-route-fullcontainer"></div>
          </>
        )}
      </div>
    </>
  );
}

export default Shifts;
