// import React, { useEffect, useState } from "react";
// import classes from "./SideMenu.module.css";
// import SideMenuData from "./SideMenuData";
// import { GrClose } from "react-icons/gr";
// import useHttp from "../../Hooks/use-http";

// let sideMenuFlag = 0;
// let flag = false;
// let prev_corp = "";
// let obj = {
//   dashboard: "<AiOutlineBarChart />",
// };

// const SideMenu = (props) => {
//   const [sideMenuData, setSideMenuData] = useState([]);
//   const { sendRequest } = useHttp();

//   const menuList = (data) => {
//     let menu = data?.MainMenuList?.reduce((acc, cur, index) => {
//       acc[index] = {
//         main: cur?.Description,
//       };
//       let arr = [];
//       for (let i = 0; i < data?.MenuList?.length; i++) {
//         if (cur?.MainModuleID === data?.MenuList[i]?.MainModuleID)
//           arr.push({
//             main: data?.MenuList[i]?.ModuleName,
//             url: data?.MenuList[i]?.MenuURL,
//           });
//       }
//       if (arr?.length === 1) acc[index].url = arr[0].url;
//       else acc[index].sub = arr;
//       arr = [];
//       return acc;
//     }, []);
//     setSideMenuData(menu);
//   };

//   useEffect(() => {
//     // if (sideMenuFlag > 0) {
//     sendRequest(
//       {
//         url: "/api/v1/Menu/GetUserMenuList",
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: {
//           emailID: localStorage.getItem("user"),
//           corporateID:
//             localStorage.getItem("roleId") === "1"
//               ? ""
//               : localStorage.getItem("corpId"),
//         },
//       },
//       menuList
//     );
//   }, []);

//   const changeMenuWidth = () => {
//     // alert(document.getElementById("mySidemenu")?.style?.width);
//     if (
//       window.screen.width < 450 &&
//       document.getElementById("mySidemenu").style.width !== null
//     ) {
//       if ((document.getElementById("mySidemenu").style.width = "100%"))
//         document.getElementById("mySidemenu").style.minWidth = "100%";
//     } else {
//       if (
//         (document.getElementById("mySidemenu").style.width =
//           "25%" && document.getElementById("mySidemenu").style.width !== null)
//       )
//         document.getElementById("mySidemenu").style.minWidth = "25%";
//     }
//     // The URL changed...
//     window.removeEventListener("popstate", changeMenuWidth);
//   };

//   if (
//     props.property &&
//     document.getElementById("mySidemenu").style.width !== null
//   ) {
//     if (
//       window.screen.width < 450 &&
//       document.getElementById("mySidemenu").style.width !== null
//     )
//       document.getElementById("mySidemenu").style.width = "100%";
//     else document.getElementById("mySidemenu").style.width = "20%";
//     // debugger;
//     window.addEventListener("popstate", changeMenuWidth);
//     flag = true;
//   } else {
//     if (flag) {
//       if (document.getElementById("mySidemenu")) {
//         document.getElementById("mySidemenu").style.minWidth = "0px";
//         document.getElementById("mySidemenu").style.width = "0px";
//       }
//     }
//   }

//   return (
//     <div className={classes.menuContainer} id="mySidemenu">
//       <div
//         className={classes.subMenu}
//         onMouseLeave={() => props.sideMenuClose()}
//       >
//         <div
//           className={classes.closeIcon}
//           onClick={() => props.sideMenuClose()}
//         >
//           <GrClose />
//         </div>
//         {/* {!sideMenuData[0] && (
//           <div style={{ textAlign: "center", marginTop: "20px" }}>
//             No Data Available
//           </div>
//         )} */}
//         {sideMenuData?.length === 0 && (
//           <div style={{ textAlign: "center", marginTop: "20px" }}>
//             No Data Available
//           </div>
//         )}
//         {/* <SideMenuData
//           menu={sideMenuData}
//           iconFlag={true}
//           sideMenuClose={props.sideMenuClose}
//         /> */}

//         {sideMenuData?.length > 0 && (
//           <SideMenuData
//             menu={sideMenuData}
//             iconFlag={true}
//             sideMenuClose={props.sideMenuClose}
//           />
//         )}
//         <p style={{ color: "gray", marginTop: "20%", fontSize: "10px" }}>
//           Last Updated on 09/13/2024
//         </p>
//       </div>
//     </div>
//   );
// };

// export default SideMenu;

import React, { useEffect, useState } from "react";
import classes from "./SideMenu.module.css";
import SideMenuData from "./SideMenuData";
import { GrClose } from "react-icons/gr";
import useHttp from "../../Hooks/use-http";

let flag = false;

const SideMenu = (props) => {
  const [sideMenuData, setSideMenuData] = useState([]);
  const { sendRequest } = useHttp();

  const menuList = (data) => {
    let menu = data?.MainMenuList?.reduce((acc, cur, index) => {
      acc[index] = {
        main: cur?.Description,
      };
      let arr = [];
      for (let i = 0; i < data?.MenuList?.length; i++) {
        if (cur?.MainModuleID === data?.MenuList[i]?.MainModuleID)
          arr.push({
            main: data?.MenuList[i]?.ModuleName,
            url: data?.MenuList[i]?.MenuURL,
          });
      }
      if (arr?.length === 1) acc[index].url = arr[0].url;
      else acc[index].sub = arr;
      arr = [];
      return acc;
    }, []);
    setSideMenuData(menu);
  };

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Menu/GetUserMenuList",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          corporateID:
            localStorage.getItem("roleId") === "1"
              ? ""
              : localStorage.getItem("corpId"),
        },
      },
      menuList
    );
  }, []);

  const changeMenuWidth = () => {
    const sideMenuElement = document.getElementById("mySidemenu");

    // Ensure the element exists before proceeding
    if (!sideMenuElement) {
      console.warn("SideMenu element not found.");
      return;
    }

    if (window.screen.width < 450) {
      sideMenuElement.style.width = "100%";
      sideMenuElement.style.minWidth = "100%";
    } else {
      sideMenuElement.style.width = "25%";
      sideMenuElement.style.minWidth = "25%";
    }
  };

  useEffect(() => {
    const sideMenuElement = document.getElementById("mySidemenu");

    // Ensure the element exists before trying to access it
    if (props.property && sideMenuElement) {
      if (window.screen.width < 450) {
        sideMenuElement.style.width = "100%";
      } else {
        sideMenuElement.style.width = "20%";
      }

      window.addEventListener("popstate", changeMenuWidth);
      flag = true;
    } else if (flag && sideMenuElement) {
      sideMenuElement.style.minWidth = "0px";
      sideMenuElement.style.width = "0px";
    }

    // Clean up event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", changeMenuWidth);
    };
  }, [props.property]);

  return (
    <div className={classes.menuContainer} id="mySidemenu">
      <div
        className={classes.subMenu}
        onMouseLeave={() => props.sideMenuClose()}
      >
        <div
          className={classes.closeIcon}
          onClick={() => props.sideMenuClose()}
        >
          <GrClose />
        </div>
        {sideMenuData?.length === 0 && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            No Data Available
          </div>
        )}
        {sideMenuData?.length > 0 && (
          <SideMenuData
            menu={sideMenuData}
            iconFlag={true}
            sideMenuClose={props.sideMenuClose}
          />
        )}
        <p style={{ color: "gray", marginTop: "20%", fontSize: "10px", display:'flex', alignItems:'flex-end' }}>
          Last Updated on 09/25/2024
        </p>
      </div>
    </div>
  );
};

export default SideMenu;
