import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Modal,
  Radio,
  TextField,
  Checkbox,
  TablePagination,
} from "@mui/material";
import AddNewTasks from "./AddNewTasks";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CORPORATEIDFROMLOCALSTORAGE,
  DEPARTMENTIDFROMLOCALSTORAGE,
  ROLEIDFROMLOCALSTORAGE,
  USEREMAILIDFROMLOCALSTORAGE,
} from "../../Constant";
import DefaultImage from "../../Assets/default_image.jpg";
import Loading from "../../Loading/Loading";
import { toast } from "react-toastify";
import useHttp from "../../Hooks/use-http";
import Config from "./Config";
import { useLocation } from "react-router-dom";
import SearchFilter from "../../Components/CommonComponent/SearchFilter";

const Settings = () => {
  const [value, setValue] = useState(0);
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);

  const [tasks, setTasks] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [selectedTask, setSelectedTask] = useState(null);
  const [getCorporatesFromCorporates, setGetCorporatesFromCorporates] =
    useState(null);

  const emailID = USEREMAILIDFROMLOCALSTORAGE;
  const [paginationData, setPaginationData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [mainData, setMainData] = useState(null);
  const { isLoading, sendRequest } = useHttp();
  const corporateID =
    localStorage.getItem("roleId") != "1" ? CORPORATEIDFROMLOCALSTORAGE : "";
  const departmentID = "";
  // const corporateID = CORPORATEIDFROMLOCALSTORAGE;
  // const departmentID = DEPARTMENTIDFROMLOCALSTORAGE;

  const roleID = ROLEIDFROMLOCALSTORAGE;
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state.data) {
      setGetCorporatesFromCorporates(location.state.data);
    }
  }, [location.state]);

  // const getDataFromCorporateList = location.state?.data;

  // this comment for new code try for api calling
  const getTaskList = async () => {
    try {
      sendRequest(
        {
          url: "/api/v1/ShiftTask/GetShiftTask",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID,
            corporateID,
            departmentID,
            roleID,
            taskID: "",
          },
        },
        (data) => {
          setTasks(data?.TaskList || []); // Update tasks state with fetched data
        },
        (error) => {
          toast.error("Error fetching tasks:", error); // Display error message using toast
          setTasks([]); // Set tasks state to empty array in case of error
        }
      );
    } catch (error) {
      toast.error("Error fetching tasks:", error); // Display error message using toast
      setTasks([]); // Set tasks state to empty array in case of error
    }
  };

  const handleOpenAddTaskModal = () => {
    setOpenAddTaskModal(true);
  };

  const handleCloseAddTaskModal = () => {
    setOpenAddTaskModal(false);
    setSelectedTask([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        sendRequest(
          {
            url: "/api/v1/ShiftTask/GetShiftTask",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: {
              emailID,
              corporateID,
              departmentID,
              roleID,
              taskID: "",
            },
          },
          (data) => {
            setLoader(false);
            setTasks(data?.TaskList); // Update tasks state with fetched data
          },
          (error) => {
            console.error("Error fetching tasks:", error);
            setLoader(false); // Set loader state to false in case of error
          }
        );
      } catch (error) {
        console.error("Error fetching tasks:", error);
        setLoader(false); // Set loader state to false in case of error
      }
    };

    fetchData();
  }, [sendRequest, emailID, corporateID, departmentID, roleID]);

  const parseTaskDetails = (taskDetails) => {
    if (!taskDetails) {
      return []; // Return an empty array if taskDetails is null or undefined
    }

    try {
      const parsedDetails = JSON.parse(taskDetails);
      if (Array.isArray(parsedDetails)) {
        return parsedDetails;
      } else if (typeof parsedDetails === "object" && parsedDetails !== null) {
        // In case TaskDetails is an object but not an array, return its values
        return Object.values(parsedDetails);
      } else {
        console.error("Invalid TaskDetails format:", taskDetails);
        return [];
      }
    } catch (error) {
      console.error("Error parsing TaskDetails:", error);
      return [];
    }
  };

  //try new api calling thing
  const handleEditTask = async (taskId) => {
    try {
      sendRequest(
        {
          url: "/api/v1/ShiftTask/GetShiftTask",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID,
            corporateID,
            departmentID,
            roleID,
            taskID: taskId, // Pass the task ID to fetch specific task details
          },
        },
        (taskDetails) => {
          // Pass the task details to AddNewTasks component
          setOpenAddTaskModal(true);
          setSelectedTask(taskDetails?.TaskList);
          setLoader(false);
        },
        (error) => {
          console.error("Error fetching task details:", error);
          setLoader(false);
        }
      );
    } catch (error) {
      console.error("Error fetching task details:", error);
      setLoader(false);
    }
  };

  const renderField = (field) => {
    if (!field) return null;
    const { FieldText, FieldType, ExtraDetails } = field;

    switch (FieldType) {
      case "INPUT":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <TextField
              value="Input Text"
              disabled
              fullWidth
              variant="standard"
            />
          </div>
        );

      case "IMAGE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div>
              <img
                src={DefaultImage}
                alt="default"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        );

      case "MULTI_CHOICE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {ExtraDetails?.FieldValue.map((value, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox
                    // checked={(value.ValueID)}
                    disabled
                  />
                  {value.ValueText}
                </div>
              ))}
            </div>
          </div>
        );
      case "NUMBER":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold", font: "bold" }}>
              {FieldText}
            </div>
            <div>
              {/* {ExtraDetails?.FieldValue.map((value, index)=>( */}
              <div>
                <TextField
                  value="Number"
                  disabled
                  fullWidth
                  variant="standard"
                />
              </div>
              {/* ))} */}
            </div>
          </div>
        );

      case "SINGLE_CHOICE":
        return (
          <div>
            <div style={{ color: "black", fontWeight: "bold" }}>
              {FieldText}
            </div>
            <div
              style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {ExtraDetails?.FieldValue.map((value, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Radio
                    // checked={value.ValueID}
                    disabled
                  />
                  {value.ValueText}
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };
  // const filterData = tasks?.filter(
  //   (el) => el.CorporateID === getCorporatesFromCorporates?.CorporateID
  // );
  // setMainData(filterData);
  // const paginatedData = filterData?.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );
  // setPaginationData(paginatedData);
  useEffect(() => {
    if (tasks && getCorporatesFromCorporates) {
      const filterData = tasks.filter(
        (el) => el.CorporateID === getCorporatesFromCorporates?.CorporateID
      );
      setMainData(filterData);
      setPaginationData(filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    }
  }, [tasks, getCorporatesFromCorporates, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1.5%",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: "18px",
            textTransform: "uppercase",
          }}
        >
          Tasks
        </span>
      </header>
      <div
        style={{
          margin: "1%",
          borderRadius: "5px",
          width: "calc(100% - 2%)",
          height: "90vh",

          margin: "auto",
          // backgroundColor: "white",
        }}
      >
        <Box sx={{ maxWidth: "100%" }}>
          {/* <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Driver Tasks" />
          <Tab label="Configs" />
        </Tabs> */}
          {/* {value === 0 && ( */}
          <div>
            <Box sx={{ width: "100%", borderRadius: "10px" }}>
              <Paper
                sx={{ width: "98%", margin: "auto", borderRadius: "10px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 2,
                    pb: 2,
                    borderRadius: "10px",
                  }}
                >
                  <Box component="h2" sx={{ m: 0 }}></Box>
                  <div style={{width:'24%', display:'flex', gap:'20px'}}>

                  <SearchFilter
                    setFilteredData={setPaginationData}
                    data={mainData}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    placeholder={"Task Name"}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      color: "white",
                      backgroundColor: "#44B150",
                      // border: "1px solid red",
                      ":hover": {
                        // border: "1px solid green",
                        backgroundColor: "#44B150",
                        opacity: "0.8",
                        color: "white",
                      },
                      width: "max-content",
                      marginTop: "17px",
                    }}
                    size="small"
                    onClick={handleOpenAddTaskModal}
                  >
                    Add Task
                  </Button>
                  </div>
                </Box>
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead
                      style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    >
                      <TableRow>
                        <TableCell style={{ minWidth: 100 }}>Name</TableCell>
                        {/* {localStorage.getItem("roleId") == "1" && (
                          <TableCell style={{ minWidth: 150 }}>
                            Corporate Name
                          </TableCell>
                        )} */}
                        <TableCell style={{ minWidth: 150 }}>
                          Required
                        </TableCell>
                        {/* <TableCell style={{ minWidth: 150 }}>On Start</TableCell>
                        <TableCell style={{ minWidth: 150 }}>On End</TableCell> */}
                        <TableCell style={{ minWidth: 400 }}>Fields</TableCell>
                        <TableCell style={{ minWidth: 100 }}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {loader ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <Loading datatable="true" />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : paginationData?.length === 0 ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            <div
                              style={{ textAlign: "center", marginTop: "10px" }}
                            >
                              No Data Available
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <TableBody>
                        {paginationData?.map((task) => (
                          <TableRow key={task.TaskID}>
                            <TableCell sx={{ width: "8%" }}>
                              {task.TaskName}
                            </TableCell>
                            {/* {localStorage.getItem("roleId") == "1" && (
                              <TableCell sx={{ width: "10%" }}>
                                {String(task.CorporateName)}
                              </TableCell>
                            )} */}
                            <TableCell sx={{ width: "22%" }}>
                              <div style={{ display: "flex", gap: "15px" }}>
                                <div>
                                  <div>
                                    <strong>Required</strong>{" "}
                                  </div>
                                  <div
                                    style={{
                                      color: `${
                                        task.IsRequired !== true
                                          ? "red"
                                          : "green"
                                      }`,
                                    }}
                                  >
                                    {String(task.IsRequired ? "Yes" : "No")}
                                  </div>
                                </div>

                                <div>
                                  <div>
                                    <strong>OnStart</strong>{" "}
                                  </div>
                                  <div
                                    style={{
                                      color: `${
                                        task.IsOnStart !== true
                                          ? "red"
                                          : "green"
                                      }`,
                                    }}
                                  >
                                    {String(task.IsOnStart ? "Yes" : "No")}
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <strong>OnEnd</strong>
                                  </div>
                                  <div
                                    style={{
                                      color: `${
                                        task.IsOnEnd !== true ? "red" : "green"
                                      }`,
                                    }}
                                  >
                                    {String(task.IsOnEnd ? "Yes" : "No")}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: "55%" }}>
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(2, 1fr)",
                                  gap: "20px",
                                }}
                              >
                                {Array.isArray(
                                  parseTaskDetails(task.TaskDetails)
                                ) &&
                                  parseTaskDetails(task.TaskDetails)?.map(
                                    (field, index) => (
                                      <div key={index}>
                                        {renderField(field)}
                                      </div>
                                    )
                                  )}
                              </div>
                            </TableCell>
                            <TableCell sx={{ width: "10%" }}>
                              <IconButton
                                aria-label="edit"
                                sx={{ color: "#1D85C6" }}
                                onClick={() => handleEditTask(task.TaskID)}
                              >
                                <EditIcon />
                              </IconButton>
                              {/* <IconButton
                                aria-label="delete"
                                disabled={true}
                                sx={{ color: "red" }}
                              >
                                <DeleteIcon disabled />
                              </IconButton> */}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
            <Modal
              open={openAddTaskModal}
              onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                  handleCloseAddTaskModal();
                }
              }}
              // onClose={handleCloseAddTaskModal}
              aria-labelledby="add-new-task-modal"
              aria-describedby="add-new-task-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  overflowX: "hidden",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxHeight: "80vh",
                  overflowY: "auto",
                  bgcolor: "background.paper",

                  zIndex: "1500",
                  boxShadow: 24,
                  borderRadius: 4,
                  p: 3,
                  // marginTop: "10px"
                  scrollbarWidth: "none" /* Firefox */,
                  // "&::-webkit-scrollbar": {
                  //   display: "none",
                  // },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                >
                  <Button
                    onClick={handleCloseAddTaskModal}
                    sx={{ fontSize: "20px", color: "white" }}
                  >
                    x
                  </Button>
                </Box>
                <AddNewTasks
                  selectedTask={selectedTask}
                  getTaskList={getTaskList}
                  onCloseModal={handleCloseAddTaskModal}
                  getCorporatesFromCorporates={getCorporatesFromCorporates}
                />
              </Box>
            </Modal>
          </div>
          {/* )} */}
          {value === 1 && (
            <div>
              {/* <Config corporateData={getDataFromCorporateList}/> */}
            </div>
          )}
        </Box>
        <TablePagination
          rowsPerPageOptions={[7, 15, 20]}
          component="div"
          count={mainData?.length ?? "1"}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Settings;
