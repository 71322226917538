import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../Loading/Loading";
// import Accordian from "./_Accordian";
import "./Records.css";
import editIcon from "../../Assets/editIcon.png";
import viewIcon from "../../Assets/eye.png";
import AddRoute from "./AddRoute/RouteInfo";
import Message from "../../Modal/Message";
import ViewRoute from "./ViewRoute/ViewRoute";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Tooltip,
  IconButton,
  Switch,
} from "@mui/material";
import useHttp from "../../Hooks/use-http";
import { toast } from "react-toastify";

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// const getTodayTiming = (timing) => {
//   const today = new Date().getDay(); // Get current day of the week (0-6)
//   const todayTime = timing?.find((time, index) => index === today);
//   const todayDate = new Date(todayTime);
//   return todayTime
//     ? `${dayNames[today]}: ${todayDate.toLocaleTimeString([], {
//         hour: "2-digit",
//         minute: "2-digit",
//       })}`
//     : "No timing available";
// };

const getTodayTiming = (timing) => {
  const today = new Date().getDay(); // Get current day of the week (0-6)
  const todayTime = timing?.find((time, index) => index === today);

  if (!todayTime) {
    return "No timing available";
  }

  const todayDate = new Date(todayTime);
  const year = todayDate.getFullYear();

  if (year === 1900) {
    return `${dayNames[today]}: Not Scheduled`;
  }

  return `${dayNames[today]}: ${todayDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const formatWeekTiming = (timing) => {
  return timing?.map((time, index) => {
    const date = new Date(time);
    const year = date.getFullYear();
    
    
    if (year === 1900) {
      return <div key={index}>{`${dayNames[index]}: Not Scheduled`}</div>;
    } else {
      return (
        <div key={index}>
          {`${dayNames[index]}: ${date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}`}
        </div>
      );
    }
  });
};

const formatStopNames = (stops) => {
  if (!stops || stops.length === 0) {
    return "No stops available";
  }

  const firstStop = stops[0];
  const restStops = stops?.map((stop) => <li key={stop}>{stop}</li>);

  return (
    <div>
      <span>{firstStop}</span>
      <Tooltip
        title={
          <div
            style={{ maxHeight: "250px", maxWidth: "350px", overflowY: "auto" }}
          >
            <ol style={{ padding: "15px", margin: 0 }}>{restStops}</ol>
          </div>
        }
        arrow
        placement="top"
        sx={{
          "& .MuiTooltip-tooltip": {
            maxWidth: "none", // Allow the tooltip to grow beyond the default width
            maxHeight: "none", // Allow the tooltip to grow beyond the default height
          },
        }}
      >
        <IconButton size="small">
          <InfoOutlinedIcon style={{ fontSize: 16 }} />
        </IconButton>
      </Tooltip>
    </div>
  );
};



const compareTimings = (a, b) => {
  const today = new Date().getDay(); // Get current day of the week (0-6)
  const aTodayTime = new Date(a.timing[today]);
  const bTodayTime = new Date(b.timing[today]);

  return aTodayTime - bTodayTime;
};
let routeId = "";

let routeName = "";
const Records = ({ isLoading, data, headers }) => {
  const [isEditRouteClicked, setIsEditRouteClicked] = useState(false);
  const [isEditRouteClickNewUI, setIsEditRouteClickNewUI]=useState(false);
  const [isViewRouteClicked, setIsViewRouteClicked] = useState(false);
  const [isRouteCreated, setIsRouteCreated] = useState();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [copiedItemId, setCopiedItemId] = useState(null);
  const [toggleStates, setToggleStates] = useState({});

  const { sendRequest } = useHttp();
  //   const handleSort = (event, column) => {
  //     console.log("handleSort called for column:", column);
  // event.stopPropagation()
  //     const isAsc = orderBy === column && order === "asc";
  //     setOrder(isAsc ? "desc" : "asc");
  //     setOrderBy(column);
  //   };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (header) => () => {
    handleSort(header);
  };

  const sortedData = data?.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });
  // const sortedData = data?.slice().sort((a, b) => {
  //   if (orderBy === "Timing") {
  //     return order === "asc" ? compareTimings(a, b) : compareTimings(b, a);
  //   } else {
  //     if (order === "asc") {
  //       return a[orderBy] > b[orderBy] ? 1 : -1;
  //     } else {
  //       return a[orderBy] < b[orderBy] ? 1 : -1;
  //     }
  //   }
  // });

  const routeCreationStatus = (data) => {
    setIsRouteCreated(data);
  };
  const history = useHistory();
  // const func = (val) => {
  //     if (val) {
  //         document.getElementById(val).click();
  //     }
  // }
  const editRouteClickHandler = (id) => {
    routeId = id;
    // setIsEditRouteClicked(true);
    setIsEditRouteClickNewUI(true)

  };

  const viewRouteClickHandler = (id, routeName) => {
    // routeId = e.target.parentElement.parentElement.children[0].innerText;
    // routeName = e.target.parentElement.parentElement.children[1].innerText;
    routeId = id;
    routeName = routeName;
    setIsViewRouteClicked(true);
  };

  const handleCopyClick = (event, shuttleId) => {
    event.stopPropagation(); // Ensure event propagation is stopped
    navigator.clipboard.writeText(shuttleId); // Verify clipboard write operation
    setCopiedItemId(shuttleId); // Check if copiedItemId is correctly set
    setTimeout(() => {
      setCopiedItemId(shuttleId); // Reset copiedItemId after timeout
    }, 1);
  };

  const handleSwitchChange = (event, data) => {
    const newChecked = event.target.checked;

    // Set toggle state for the specific row
    setToggleStates((prevStates) => ({
      ...prevStates,
      [data.route_id]: newChecked,
    }));

    // Determine the new isDeactivated value based on the newChecked value
    const newIsDeactivated = newChecked ? 0 : 1; // 0 means active, 1 means deactivated

    sendRequest(
      {
        url: "/api/v1/Route/EnableDisableShuttleRoute",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          apiActionTypeID: 0,
          apiDynamicFields: "string",
          apiOperatorID: "string",
          apiRequestID: "string",
          apiRoleID: "string",
          apiUniqueID: "string",
          apiOperatedOn: new Date().toISOString(),
          emailID: localStorage.getItem("user"),
          corporateID: data.corporateId,
          routeID: data.route_id,

          // isDeactivated: data.isDeactivated === 0 ? 1 : 0,
          isDeactivated: newIsDeactivated,
        },
      },
      (response) => {
        // let statusDeactivation =
        //   data.isDeactivated === 0 ? "Disabled" : "Enabled";
        let statusDeactivation =
          newIsDeactivated === 0 ? "Enabled" : "Disabled";

        if (response.Status === "000") {
          // setToggleChangeAPICall(true);

          toast.success(`${data.route} is successfully ${statusDeactivation} `);
        } else {
          toast.error(response.message);
        }
      }
    );
  };

  return (
    <React.Fragment>
      {data && data?.[0] ? (
        <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
          <Table sx={{ maxWidth: "100%" }}>
            <TableHead>
              <TableRow>
                {headers?.map((header, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      py: 0,
                      px: 2,
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    sortDirection={orderBy === header ? order : false}
                  >
                    <TableSortLabel
                      active={true}
                      direction={orderBy === header ? order : "asc"}
                      onClick={createSortHandler(header)}
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {header}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {localStorage.getItem("userType") !== "AccountManager" && (
                  <TableCell
                    sx={{
                      py: 0,
                      px: 4,
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    style={{ paddingLeft: "6%" }}
                  >
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedData?.map((myData) => (
                <TableRow key={myData.route_id}>
                  <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
                    {myData.route_id.substring(0, 8) + "..."}
                    <Tooltip
                      title={
                        copiedItemId === myData.route_id
                          ? "Copied!"
                          : "Copy Route ID"
                      }
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={(event) =>
                          handleCopyClick(event, myData?.route_id)
                        }
                      >
                        <FileCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 1 }}>
                    {myData.route}
                  </TableCell>
                  {localStorage.getItem("roleId") == "1" && (
                    <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
                      {myData.corporateName}
                    </TableCell>
                  )}
                  <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
                    <Tooltip
                      title={<div>{formatWeekTiming(myData?.timing)}</div>}
                      arrow
                      placement="top"
                    >
                      <span>{getTodayTiming(myData.timing)}</span>
                    </Tooltip>
                    <Tooltip
                      title={<div>{formatWeekTiming(myData?.timing)}</div>}
                      arrow
                      placement="top"
                    >
                      <IconButton size="small">
                        <InfoOutlinedIcon style={{ fontSize: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 1 }}>
                    {formatStopNames(myData.stops)}
                  </TableCell>
                  {/* <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
               {myData.city}
             </TableCell>
             <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
               {myData.country}
             </TableCell> */}
                  <TableCell sx={{ fontSize: "0.8rem", p: 1, px: 2 }}>
                    {myData.route_type?.toLowerCase() === "picking"
                      ? "Pickup"
                      : "Drop"}
                  </TableCell>
                  {localStorage.getItem("userType") !== "AccountManager" && (
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        alignItems: "center",
                        border: "none",
                        // height: "20px",
                      }}
                    >
                      <img
                        onClick={() => editRouteClickHandler(myData.route_id)}
                        className="edit-route"
                        src={editIcon}
                      />
                      <img
                        // onClick={viewRouteClickHandler}
                        onClick={() =>
                          viewRouteClickHandler(myData.route_id, myData.route)
                        }
                        className="edit-route"
                        src={viewIcon}
                      />
                      <Switch
                        //  checked={toggle === null ? (myData.isDeactivated === 0 ?  true : false) : toggle}
                        checked={
                          toggleStates[myData.route_id] === undefined
                            ? myData.isDeactivated === 0
                            : toggleStates[myData.route_id]
                        }
                        onChange={(event) => handleSwitchChange(event, myData)}
                        name="activeSwitch"
                        color="primary"
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <React.Fragment>
          <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
            <Table sx={{ maxWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  {headers?.map((header) => (
                    <TableCell
                      key={header}
                      sx={{
                        py: 0,
                        px: 2,
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                  {localStorage.getItem("userType") !== "AccountManager" && (
                    <TableCell
                      sx={{
                        py: 0,
                        px: 2,
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          {isLoading ? (
            <Loading datatable="true" />
          ) : (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              No Data Available
            </div>
          )}
        </React.Fragment>
      )}
      {(isEditRouteClicked || isViewRouteClicked) && (
        <div className="background"></div>
      )}
      {/* {isEditRouteClicked && (
        <AddRoute
          routeCreationStatus={routeCreationStatus}
          routeId={routeId}
          setIsAddRouteClicked={setIsEditRouteClicked}
        />
      )} */}
      {
        isEditRouteClickNewUI && (
          history.push({
            pathname: '/addroute',
            state: { routeId: routeId }
          })
        )
      }
      {isViewRouteClicked && (
        <ViewRoute
          routeId={routeId}
          
          routeName={routeName}
          setIsViewRouteClicked={setIsViewRouteClicked}
        />
      )}
      {isRouteCreated && (
        <Message
          type={isRouteCreated}
          message={
            "Route name " +
            sessionStorage.getItem("routeName") +
            " has been Successfully edited"
          }
        />
      )}
    </React.Fragment>
  );
};

export default Records;
