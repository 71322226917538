import React from 'react';
import unImage from "../../../Assets/not_allowed_image.png";

const Unauthorized = () => {
    return (
        <div style={{ width: "100%" }}>
            <img src={unImage} alt="unauthorized_image" style={{width: "100%", height: "90%"}} />
        </div>
    )
}

export default Unauthorized;
