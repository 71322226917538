import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactComponent as AddSign } from "../../Assets/Plus_sign.svg";
import { ReactComponent as MinusSign } from "../../Assets/Minus_sign.svg";

import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  CORPORATEIDFROMLOCALSTORAGE,
  DEPARTMENTIDFROMLOCALSTORAGE,
  ROLEIDFROMLOCALSTORAGE,
  USEREMAILIDFROMLOCALSTORAGE,
} from "../../Constant";
import useHttp from "../../Hooks/use-http";
import { useTheme } from "@emotion/react";

const AddNewTasks = ({
  selectedTask,
  getTaskList,
  onCloseModal,
  getCorporatesFromCorporates,
}) => {
  const [taskName, setTaskName] = useState("");
  const [required, setRequired] = useState(false);
  const [onStart, setOnStart] = useState(false);
  const [onEnd, setOnEnd] = useState(false);
  const [fields, setFields] = useState([]);
  const [showFields, setShowFields] = useState(false);

  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [corporateData, setCorporateData] = useState([]);

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (selectedTask) {
      setSelectedCorporate(selectedTask?.[0]?.CorporateID || "");
      const selectedCorporateName = selectedTask?.[0]?.CorporateName || null;
      const selectedCorporateId = selectedTask?.[0]?.CorporateID || null;
      setSelectedCorporate(
        selectedCorporateName && selectedCorporateId
          ? { label: selectedCorporateName, value: selectedCorporateId }
          : null
      );

      setTaskName(selectedTask?.[0]?.TaskName || "");
      setRequired(selectedTask?.[0]?.IsRequired || false);
      setOnStart(selectedTask?.[0]?.IsOnStart || false);
      setOnEnd(selectedTask?.[0]?.IsOnEnd || false);
      // Parse and set fields if taskDetails has TaskDetails
      if (selectedTask?.[0]?.TaskDetails) {
        try {
          const parsedFields = JSON.parse(selectedTask?.[0]?.TaskDetails);

          setFields(parsedFields || []);
          setShowFields(true);
        } catch (error) {
          console.error("Error parsing TaskDetails:", error);
        }
      }
    }
  }, [selectedTask]);

  const handleAddField = () => {
    setFields([
      ...fields,
      {
        FieldID: "",
        FieldText: "",
        FieldType: "",
        Required: false,
        ExtraDetails: {
          // FromValue: "1",
          FieldValue: [],
        },
      },
    ]);
    setShowFields(true);
  };

  const handleFieldChange = (index, property, value) => {
    const updatedFields = [...fields];
    updatedFields[index][property] = value;
    setFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleAddOption = (index) => {
    const updatedFields = [...fields];
    const optionsCount = updatedFields[index]?.ExtraDetails?.FieldValue.length;
    const newItem = optionsCount + 1; // Calculate the new Item value
    updatedFields[index]?.ExtraDetails?.FieldValue.push({
      Item: newItem,
      ValueID: "",
      ValueText: "",
    });
    setFields(updatedFields);
  };

  // const handleRemoveOption = (fieldIndex, optionIndex) => {
  //   const updatedFields = [...fields];
  //   updatedFields[fieldIndex]?.ExtraDetails?.FieldValue.splice(optionIndex, 1);
  //   setFields(updatedFields);
  // };
  const handleRemoveOption = (fieldIndex, optionIndex) => {
    const updatedFields = [...fields];
    updatedFields[fieldIndex].ExtraDetails.FieldValue.splice(optionIndex, 1);

    // Update Item numbers after removing an option
    updatedFields[fieldIndex]?.ExtraDetails?.FieldValue?.forEach(
      (option, index) => {
        option.Item = index + 1;
      }
    );

    setFields(updatedFields);
  };

  const handleCancel = () => {
    setTaskName("");
    setRequired(false);
    setOnStart(false);
    setOnEnd(false);
    setFields([]);
    setShowFields(false);
  };

  // const handleSubmit = () => {
  //   const emailID = USEREMAILIDFROMLOCALSTORAGE;
  //   // const corporateID = selectedCorporate?.value;
  //   const corporateID = getCorporatesFromCorporates?.CorporateID;

  //   const departmentID = DEPARTMENTIDFROMLOCALSTORAGE;
  //   const roleID = ROLEIDFROMLOCALSTORAGE;

  //   // Check if localStorage data is available
  //   if (!emailID || !corporateID || !departmentID || !roleID) {
  //     alert("Unable to retrieve required data from localStorage.");
  //     return;
  //   }

  //   const isFieldFilled = fields?.some((field) => {
  //     const { FieldType, ExtraDetails } = field;
  //     const isTypeFilled =
  //       FieldType === "INPUT" ||
  //       FieldType === "MULTILINE_INPUT" ||
  //       FieldType === "NUMBER" ||
  //       FieldType === "DATE" ||
  //       FieldType === "TIME" ||
  //       FieldType === "IMAGE" ||
  //       (FieldType === "SINGLE_CHOICE" &&
  //         ExtraDetails?.FieldValue.length > 0) ||
  //       (FieldType === "MULTI_CHOICE" && ExtraDetails?.FieldValue.length > 0);

  //     if (isTypeFilled) {
  //       if (
  //         FieldType === "NUMBER" ||
  //         FieldType === "DATE" ||
  //         FieldType === "TIME"
  //       ) {
  //         // Check if FieldID and FieldText are not empty
  //         return field.FieldID.trim() !== "" && field.FieldText.trim() !== "";
  //       } else if (FieldType === "IMAGE") {
  //         // Check if FieldID, FieldText, and FromValue are not empty
  //         return (
  //           field.FieldID.trim() !== "" &&
  //           field.FieldText.trim() !== "" &&
  //           field.ExtraDetails &&
  //           field.ExtraDetails.FromValue !== ""
  //         );
  //       } else {
  //         // For other field types, only check if FieldID and FieldText are not empty
  //         return field.FieldID.trim() !== "" && field.FieldText.trim() !== "";
  //       }
  //     } else {
  //       return false;
  //     }
  //   });

  //   const areOptionsFilled = fields.every((field) => {
  //     const { FieldType, ExtraDetails } = field;
  //     if (FieldType === "SINGLE_CHOICE" || FieldType === "MULTI_CHOICE") {
  //       return ExtraDetails.FieldValue.every(
  //         (option) =>
  //           option.ValueID.trim() !== "" && option.ValueText.trim() !== ""
  //       );
  //     } else {
  //       return true; // Non-radio/checkbox fields are considered filled
  //     }
  //   });

  //   if (taskName.trim() !== "" && isFieldFilled && areOptionsFilled) {
  //     const containsSpaces = fields?.some(
  //       (field) =>
  //         /\s/.test(field.FieldID) ||
  //         field.ExtraDetails.FieldValue?.some((option) =>
  //           /\s/.test(option.ValueID)
  //         )
  //     );

  //     if (!containsSpaces) {
  //       let requestData;
  //       if (selectedTask?.length === 0) {
  //         requestData = {
  //           emailID,
  //           corporateID,
  //           departmentID,
  //           roleID,
  //           TotalFields: fields.length,
  //           TaskId: "",
  //           TaskName: taskName,
  //           isOnEnd: onEnd ? "1" : "0",
  //           isOnStart: onStart ? "1" : "0",
  //           isrequired: required ? "1" : "0",
  //           taskDetails: JSON.stringify(
  //             fields?.map((field, index) => {
  //               const { FieldType, ExtraDetails, ...rest } = field;
  //               return {
  //                 FieldType,
  //                 DisplayOrder: index + 1,
  //                 ExtraDetails:
  //                   FieldType === "SINGLE_CHOICE" ||
  //                   FieldType === "MULTI_CHOICE"
  //                     ? { FieldValue: ExtraDetails.FieldValue }
  //                     : FieldType === "IMAGE"
  //                     ? {
  //                         FromValue:
  //                           ExtraDetails.FromValue === undefined
  //                             ? ""
  //                             : ExtraDetails.FromValue,
  //                       }
  //                     : ExtraDetails,
  //                 ...rest,
  //               };
  //             })
  //           ),
  //         };
  //       } else {
  //         requestData = {
  //           emailID,
  //           corporateID,
  //           departmentID,
  //           roleID,
  //           TotalFields: fields.length,
  //           TaskId: selectedTask ? selectedTask?.[0]?.TaskID : "",
  //           TaskName: taskName,
  //           isOnEnd: onEnd ? "1" : "0",
  //           isOnStart: onStart ? "1" : "0",
  //           isrequired: required ? "1" : "0",
  //           taskDetails: JSON.stringify(
  //             fields?.map((field, index) => {
  //               const { FieldType, ExtraDetails, ...rest } = field;
  //               return {
  //                 FieldType,
  //                 DisplayOrder: index + 1,
  //                 ExtraDetails:
  //                   FieldType === "SINGLE_CHOICE" ||
  //                   FieldType === "MULTI_CHOICE"
  //                     ? { FieldValue: ExtraDetails.FieldValue }
  //                     : FieldType === "IMAGE"
  //                     ? {
  //                         FromValue:
  //                           ExtraDetails.FromValue === undefined
  //                             ? ""
  //                             : ExtraDetails.FromValue,
  //                       }
  //                     : ExtraDetails,
  //                 ...rest,
  //               };
  //             })
  //           ),
  //         };
  //       }
  //       sendRequest(
  //         {
  //           url: "/api/v1/ShiftTask/AddEditShiftTask",
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: requestData,
  //         }
  //       )

  //         .then((response) => response.json())
  //         .then((data) => {
  //           getTaskList();
  //           // Handle success response
  //           handleSuccess();
  //           onCloseModal();
  //         })
  //         .catch((error) => {
  //           console.error("Error:", error);
  //           handleError();
  //           // Handle error
  //         });
  //     } else {
  //       alert("FieldID and ValueID should not contain spaces.");
  //     }
  //   } else {
  //     alert("Please fill all required fields before submitting.");
  //   }
  // };

  const handleSubmit = () => {
    const emailID = USEREMAILIDFROMLOCALSTORAGE;
    const corporateID = getCorporatesFromCorporates?.CorporateID;
    const departmentID = DEPARTMENTIDFROMLOCALSTORAGE;
    const roleID = ROLEIDFROMLOCALSTORAGE;

    // Check if localStorage data is available
    if (!emailID || !corporateID || !departmentID || !roleID) {
      alert("Unable to retrieve required data from localStorage.");
      return;
    }

    const isFieldFilled = fields?.some((field) => {
      const { FieldType, ExtraDetails } = field;
      const isTypeFilled =
        FieldType === "INPUT" ||
        FieldType === "MULTILINE_INPUT" ||
        FieldType === "NUMBER" ||
        FieldType === "DATE" ||
        FieldType === "TIME" ||
        FieldType === "IMAGE" ||
        (FieldType === "SINGLE_CHOICE" &&
          ExtraDetails?.FieldValue.length > 0) ||
        (FieldType === "MULTI_CHOICE" && ExtraDetails?.FieldValue.length > 0);

      if (isTypeFilled) {
        if (
          FieldType === "NUMBER" ||
          FieldType === "DATE" ||
          FieldType === "TIME"
        ) {
          // Check if FieldID and FieldText are not empty
          return field.FieldID.trim() !== "" && field.FieldText.trim() !== "";
        } else if (FieldType === "IMAGE") {
          // Check if FieldID, FieldText, and FromValue are not empty
          return (
            field.FieldID.trim() !== "" &&
            field.FieldText.trim() !== "" &&
            field.ExtraDetails &&
            field.ExtraDetails.FromValue !== ""
          );
        } else {
          // For other field types, only check if FieldID and FieldText are not empty
          return field.FieldID.trim() !== "" && field.FieldText.trim() !== "";
        }
      } else {
        return false;
      }
    });

    const areOptionsFilled = fields.every((field) => {
      const { FieldType, ExtraDetails } = field;
      if (FieldType === "SINGLE_CHOICE" || FieldType === "MULTI_CHOICE") {
        return ExtraDetails.FieldValue.every(
          (option) =>
            option.ValueID.trim() !== "" && option.ValueText.trim() !== ""
        );
      } else {
        return true; // Non-radio/checkbox fields are considered filled
      }
    });

    if (taskName.trim() !== "" && isFieldFilled && areOptionsFilled) {
      const containsSpaces = fields?.some(
        (field) =>
          /\s/.test(field.FieldID) ||
          field.ExtraDetails.FieldValue?.some((option) =>
            /\s/.test(option.ValueID)
          )
      );

      if (!containsSpaces) {
        let requestData;
        if (selectedTask?.length === 0) {
          requestData = {
            emailID,
            corporateID,
            departmentID,
            roleID,
            TotalFields: fields.length,
            TaskId: "",
            TaskName: taskName,
            isOnEnd: onEnd ? "1" : "0",
            isOnStart: onStart ? "1" : "0",
            isrequired: required ? "1" : "0",
            taskDetails: JSON.stringify(
              fields?.map((field, index) => {
                const { FieldType, ExtraDetails, ...rest } = field;
                return {
                  FieldType,
                  DisplayOrder: index + 1,
                  ExtraDetails:
                    FieldType === "SINGLE_CHOICE" ||
                    FieldType === "MULTI_CHOICE"
                      ? { FieldValue: ExtraDetails.FieldValue }
                      : FieldType === "IMAGE"
                      ? {
                          FromValue:
                            ExtraDetails.FromValue === undefined
                              ? ""
                              : ExtraDetails.FromValue,
                        }
                      : ExtraDetails,
                  ...rest,
                };
              })
            ),
          };
        } else {
          requestData = {
            emailID,
            corporateID,
            departmentID,
            roleID,
            TotalFields: fields.length,
            TaskId: selectedTask ? selectedTask?.[0]?.TaskID : "",
            TaskName: taskName,
            isOnEnd: onEnd ? "1" : "0",
            isOnStart: onStart ? "1" : "0",
            isrequired: required ? "1" : "0",
            taskDetails: JSON.stringify(
              fields?.map((field, index) => {
                const { FieldType, ExtraDetails, ...rest } = field;
                return {
                  FieldType,
                  DisplayOrder: index + 1,
                  ExtraDetails:
                    FieldType === "SINGLE_CHOICE" ||
                    FieldType === "MULTI_CHOICE"
                      ? { FieldValue: ExtraDetails.FieldValue }
                      : FieldType === "IMAGE"
                      ? {
                          FromValue:
                            ExtraDetails.FromValue === undefined
                              ? ""
                              : ExtraDetails.FromValue,
                        }
                      : ExtraDetails,
                  ...rest,
                };
              })
            ),
          };
        }
        sendRequest(
          {
            url: "/api/v1/ShiftTask/AddEditShiftTask",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(requestData),
            body: requestData,
          },

          (responseData) => {
            // console.log("Response from server:", responseData);
            if (responseData.Status === "000") {
              getTaskList();
              handleSuccess();
              onCloseModal();
            } else {
              // Show error message using React Toastify
              handleError();
              // toast.error(
              //   responseData.Message ||
              //     "An error occurred while adding/editing the shift task"
              // );
            }
          }
        );
        // getTaskList();
        // handleSuccess();
        // onCloseModal();
        // toast.success("Shift task added/edited successfully");

        // handleError();
        // toast.error(data.Message || "An error occurred while adding/editing the shift task");
      } else {
        alert("FieldID and ValueID should not contain spaces.");
      }
    } else {
      alert("Please fill all required fields before submitting.");
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(fields);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Update display order
    items?.forEach((item, index) => {
      item.DisplayOrder = index + 1;
    });

    setFields(items);
  };

  // handle the success and error message after submit the form using toastify
  const handleSuccess = () => {
    toast.success("Task submitted successfully!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleError = () => {
    toast.error("Error submitting task. Please try again!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
        },
      },
      corporateLists
    );
  }, [sendRequest]);

  const corporateLists = (data) => {
    const tempArr =
      data?.CorporateList?.map((cp) => ({
        cpName: cp?.CorporateName,
        cpId: cp?.CorporateID,
        adminDptId:
          cp?.DepartmentID.split(",")[
            cp?.DepartmentName.toLowerCase().split(",").indexOf("admin")
          ] || "",
      })) || [];
    setCorporateData(tempArr);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "rgba(34, 137, 203, 255)",
          color: "white",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: isMobile ? "-16px" : "-24px",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Typography variant="h7" component="div">
          {selectedTask?.length === 0 || selectedTask === null
            ? "ADD TASK"
            : "EDIT TASK"}
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                <div style={{ marginTop: "4%" }}>
                  <Autocomplete
                    options={
                      corporateData &&
                      corporateData?.map((data) => ({
                        label: data.cpName,
                        value: data.cpId,
                      }))
                    }
                    sx={{ width: 185 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search Corporate Name"
                        label="Corporate Name"
                      />
                    )}
                    disabled
                    onChange={(event, value) => setSelectedCorporate(value)} // Set selectedCorporate to the selected cpId
                    defaultValue={getCorporatesFromCorporates?.Name} // Use the selected cpId
                    // getOptionLabel={(option) =>
                    //   option.label || ""  // Display the label of the selected option
                    // }
                    // isOptionEqualToValue={(option, value) =>
                    //   option.value === value.value  // Compare the values to determine equality
                    // }
                  />
                </div>
              </TableCell>
              <TableCell align="left">
                <TextField
                  label="Task Name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  variant="standard"
                  margin="normal"
                  fullWidth
                />
              </TableCell>

              <TableCell colSpan={2} align="center" padding="none">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={onStart}
                        onChange={(e) => setOnStart(e.target.checked)}
                      />
                    }
                    label="OnStart"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={onEnd}
                        onChange={(e) => setOnEnd(e.target.checked)}
                      />
                    }
                    label="OnEnd"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={required}
                        onChange={(e) => setRequired(e.target.checked)}
                      />
                    }
                    label="Required"
                  />
                </div>
              </TableCell>
              {/* <TableCell align="right"></TableCell> */}
              <TableCell align="right">
                <IconButton style={{ visibility: "hidden" }}>
                  <MinusSign />
                </IconButton>

                <IconButton
                  style={{
                    visibility: fields.length === 0 ? "visible" : "hidden",
                  }}
                  // disabled={taskName.length === 0}
                  onClick={handleAddField}
                >
                  <AddSign />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {showFields && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="fields">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <Table>
                    <TableBody>
                      {fields?.map((field, index) => (
                        <React.Fragment key={index}>
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging
                                    ? "#f0f0f0"
                                    : "white",
                                }}
                              >
                                <TableCell>
                                  <TextField
                                    label="Field ID"
                                    value={field.FieldID}
                                    variant="standard"
                                    onChange={(e) =>
                                      handleFieldChange(
                                        index,
                                        "FieldID",
                                        e.target.value
                                          .replace(/[^a-zA-Z0-9]/g, "")
                                          .toUpperCase()
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ pattern: "[A-Z0-9]*" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Field Text (Label)"
                                    value={field.FieldText}
                                    variant="standard"
                                    onChange={(e) =>
                                      handleFieldChange(
                                        index,
                                        "FieldText",
                                        e.target.value
                                      )
                                    }
                                    fullWidth
                                    margin="normal"
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl
                                    fullWidth
                                    margin="normal"
                                    variant="standard"
                                    sx={{ minWidth: 120 }}
                                  >
                                    <InputLabel>Field Type</InputLabel>
                                    <Select
                                      value={field.FieldType}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          index,
                                          "FieldType",
                                          e.target.value
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxWidth: 120,
                                          },
                                        },
                                      }}
                                    >
                                      <MenuItem value="INPUT">Input</MenuItem>
                                      <MenuItem value="NUMBER">Number</MenuItem>
                                      <MenuItem value="DATE">Date</MenuItem>
                                      <MenuItem value="MULTI_CHOICE">
                                        Multi Choice
                                      </MenuItem>
                                      <MenuItem value="IMAGE">Image</MenuItem>
                                      <MenuItem value="SINGLE_CHOICE">
                                        Single Choice
                                      </MenuItem>
                                      <MenuItem value="TIME">Time</MenuItem>
                                      <MenuItem value="MULTILINE_INPUT">
                                        Multiline Input
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={field.Required}
                                        onChange={(e) =>
                                          handleFieldChange(
                                            index,
                                            "Required",
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label="Required"
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {/* <Button
                                  variant="outlined"
                                  startIcon={<DeleteIcon />}
                                  color="secondary"
                                  onClick={() => handleRemoveField(index)}
                                >
                                  Delete
                                </Button> */}

                                  {index === fields.length - 1 ? (
                                    <>
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <MinusSign />
                                      </IconButton>
                                      <IconButton
                                        // disabled={taskName.length === 0}
                                        onClick={handleAddField}
                                      >
                                        {/* <DeleteIcon /> */}
                                        <AddSign />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <>
                                      <IconButton
                                        onClick={() => handleRemoveField(index)}
                                      >
                                        <MinusSign />
                                      </IconButton>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                          {/* Additional Inputs */}
                          {field.FieldType === "NUMBER" && (
                            <>
                              {field?.ExtraDetails && field?.ExtraDetails == {}}
                              <TableRow>
                                <TableCell />

                                <TableCell>
                                  <TextField
                                    label="Minimum Value"
                                    type="number"
                                    variant="standard"
                                    value={field?.ExtraDetails?.MinValue}
                                    onChange={(e) =>
                                      handleFieldChange(index, "ExtraDetails", {
                                        ...field.ExtraDetails,
                                        MinValue: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    margin="normal"
                                    // style={{ marginRight: "16px" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    label="Maximum Value"
                                    type="number"
                                    value={field?.ExtraDetails?.MaxValue}
                                    variant="standard"
                                    onChange={(e) =>
                                      handleFieldChange(index, "ExtraDetails", {
                                        ...field.ExtraDetails,
                                        MaxValue: e.target.value,
                                      })
                                    }
                                    fullWidth
                                    margin="normal"
                                    // style={{ marginRight: "16px" }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                          {field.FieldType === "DATE" && (
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <TextField
                                  label="Minimum Date"
                                  type="text"
                                  onFocus={(e) => (e.target.type = "date")}
                                  onBlur={(e) => (e.target.type = "text")}
                                  value={field.ExtraDetails.MinValue}
                                  variant="standard"
                                  onChange={(e) =>
                                    handleFieldChange(index, "ExtraDetails", {
                                      ...field.ExtraDetails,
                                      MinValue: e.target.value,
                                    })
                                  }
                                  fullWidth
                                  margin="normal"
                                  // style={{ marginRight: "16px" }}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label="Maximum Date"
                                  type="text"
                                  onFocus={(e) => (e.target.type = "date")}
                                  onBlur={(e) => (e.target.type = "text")}
                                  value={field.ExtraDetails.MaxValue}
                                  variant="standard"
                                  onChange={(e) =>
                                    handleFieldChange(index, "ExtraDetails", {
                                      ...field.ExtraDetails,
                                      MaxValue: e.target.value,
                                    })
                                  }
                                  fullWidth
                                  margin="normal"
                                  // style={{ marginRight: "16px" }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                          {field.FieldType === "TIME" && (
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <TextField
                                  label="Minimum Time"
                                  type="text"
                                  onFocus={(e) => (e.target.type = "time")}
                                  onBlur={(e) => (e.target.type = "text")}
                                  value={field.ExtraDetails.MinValue}
                                  variant="standard"
                                  onChange={(e) =>
                                    handleFieldChange(index, "ExtraDetails", {
                                      ...field.ExtraDetails,
                                      MinValue: e.target.value,
                                    })
                                  }
                                  fullWidth
                                  margin="normal"
                                  // style={{ marginRight: "16px" }}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  label="Maximum Time"
                                  type="text"
                                  onFocus={(e) => (e.target.type = "time")}
                                  onBlur={(e) => (e.target.type = "text")}
                                  value={field.ExtraDetails.MaxValue}
                                  variant="standard"
                                  onChange={(e) =>
                                    handleFieldChange(index, "ExtraDetails", {
                                      ...field.ExtraDetails,
                                      MaxValue: e.target.value,
                                    })
                                  }
                                  fullWidth
                                  margin="normal"
                                  // style={{ marginRight: "16px" }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                          {(field.FieldType === "SINGLE_CHOICE" ||
                            field.FieldType === "MULTI_CHOICE") && (
                            <>
                              {field?.ExtraDetails?.FieldValue?.length === 0 &&
                                field?.ExtraDetails?.FieldValue?.push({
                                  Item: 1,
                                  ValueID: "",
                                  ValueText: "",
                                })}

                              {field?.ExtraDetails?.FieldValue?.map(
                                (option, optionIndex) => (
                                  <TableRow key={optionIndex}>
                                    <TableCell />
                                    <TableCell>
                                      <TextField
                                        label="Value ID"
                                        value={option?.ValueID}
                                        variant="standard"
                                        onChange={(e) => {
                                          const updatedOptions = [
                                            ...field?.ExtraDetails?.FieldValue,
                                          ];
                                          updatedOptions[optionIndex].ValueID =
                                            e.target.value
                                              .replace(/[^a-zA-Z0-9]/g, "")
                                              .toUpperCase();
                                          handleFieldChange(
                                            index,
                                            "ExtraDetails",
                                            {
                                              FieldValue: updatedOptions,
                                            }
                                          );
                                        }}
                                        fullWidth
                                        margin="normal"
                                        inputProps={{ pattern: "[A-Z0-9]*" }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        label="Value Text"
                                        value={option?.ValueText}
                                        variant="standard"
                                        onChange={(e) => {
                                          const updatedOptions = [
                                            ...field?.ExtraDetails?.FieldValue,
                                          ];
                                          updatedOptions[
                                            optionIndex
                                          ].ValueText = e.target.value;
                                          handleFieldChange(
                                            index,
                                            "ExtraDetails",
                                            {
                                              FieldValue: updatedOptions,
                                            }
                                          );
                                        }}
                                        fullWidth
                                        margin="normal"
                                        // style={{
                                        //   marginRight: "8px",
                                        // }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      {field.ExtraDetails.FieldValue.length ===
                                      1 ? (
                                        <IconButton
                                          onClick={() => handleAddOption(index)}
                                        >
                                          <AddSign />
                                        </IconButton>
                                      ) : field.ExtraDetails.FieldValue.length >
                                          1 &&
                                        optionIndex ===
                                          field.ExtraDetails.FieldValue.length -
                                            1 ? (
                                        <>
                                          <IconButton
                                            onClick={() =>
                                              handleRemoveOption(
                                                index,
                                                optionIndex
                                              )
                                            }
                                          >
                                            <MinusSign />
                                          </IconButton>

                                          <IconButton
                                            onClick={() =>
                                              handleAddOption(index)
                                            }
                                          >
                                            <AddSign />
                                          </IconButton>
                                        </>
                                      ) : (
                                        <IconButton
                                          onClick={() =>
                                            handleRemoveOption(
                                              index,
                                              optionIndex
                                            )
                                          }
                                        >
                                          <MinusSign />
                                        </IconButton>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </>
                          )}

                          {field?.FieldType === "IMAGE" && (
                            <TableRow>
                              <TableCell />
                              <TableCell colSpan={4}>
                                <RadioGroup
                                  value={field?.ExtraDetails?.FromValue}
                                  defaultValue={1}
                                  variant="standard"
                                  onChange={(e) =>
                                    handleFieldChange(index, "ExtraDetails", {
                                      ...field.ExtraDetails,
                                      FromValue: e.target.value,
                                    })
                                  }
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "10%",
                                    }}
                                  >
                                    <FormControlLabel
                                      value="1"
                                      control={<Radio />}
                                      label="All"
                                    />
                                    <FormControlLabel
                                      value="2"
                                      control={<Radio />}
                                      label="Front Camera"
                                    />
                                    <FormControlLabel
                                      value="3"
                                      control={<Radio />}
                                      label="Back Camera"
                                    />
                                    <FormControlLabel
                                      value="4"
                                      control={<Radio />}
                                      label="Gallery"
                                    />
                                  </div>
                                </RadioGroup>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            mr: 2,
            backgroundColor: "#2289CB",
            color: "white",
            ":hover": {
              backgroundColor: "#2289CB",
              opacity: 0.8,
            },
            width: isMobile ? "100%" : "auto",
          }}
          onClick={handleCancel}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          // color="primary"
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#44B150",
            color: "white",
            ":hover": {
              backgroundColor: "#44B150",
              opacity: 0.8,
            },
            width: isMobile ? "100%" : "auto",
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default AddNewTasks;
