import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Button,
  Typography,
  Box,
  CircularProgress,
  Autocomplete,
  FormControl,
  InputLabel,
} from "@mui/material";
import useHttp from "../../../Hooks/use-http";
import TestTimingsInfo from "./TestTimingsInfo";
// import TimingsInfo from "../AddRoute/TimingsInfo";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import TestStopInfo from "./TestStopInfo";
import { useLocation } from "react-router-dom";
import ShiftMap from "../../PrivateDriver/Create Shift/ShiftMap";
import {svgMarkerForEndLocation,
  svgMarkerForStartLocation } from "../../../Constant"
let error = {
  routeName: "",
  routeType: "",
  shuttleType: "",
};
let startLocationMarker;
let endLocationMarker;
let startLatLng;
let endLatLng;
let marker;
let map;

let defaultShuttleTimings = "";
const center = {
  lat: 23.0225,
  lng: 72.5714,
};

function TestRouteInfo(props) {
  const routeNameInputRef = useRef();
  const routeTypeSelectRef = useRef();

  const shuttleTypeSelectRef = useRef();
  const [isError, setIsError] = useState(error);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [showStopInfo, setShowStopInfo] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [selectCorporateInEdit, setSelectCorporateInEdit] = useState(null);
  const [selectedCorporateName, setSelectedCorporateName] = useState("");
  const [routeName, setRouteName] = useState(
    sessionStorage.getItem("routeName")
      ? sessionStorage.getItem("routeName")
      : ""
  );
  const [isNextClicked, setIsNextClicked]=useState(false)
  const [shuttleTypeState, setShuttleTypeState] = useState(
    sessionStorage.getItem("shuttleType") || ""
  );
  const [routeType, setRouteType] = useState(
    sessionStorage.getItem("routeType") || ""
  );

  const location = useLocation();

  const { routeId } = location.state || {}; // Destructure routeId from state

  //  let selectCorporateInEdit;
  const handleRouteNameChange = (event) => {
    const { value } = event.target;
    setRouteName(value);
    sessionStorage.setItem("routeName", value); // Store in sessionStorage
    setIsError((prev) => ({ ...prev, routeName: "" })); // Clear error if any
  };

  const authenticateUser = (data) => {
    sessionStorage.setItem("routeDetails", JSON.stringify(data?.RouteDetails));
    defaultShuttleTimings = data?.ShuttleTiming;
    let routeName = data?.Route?.[0]?.RouteName;
    setSelectCorporateInEdit(data?.Route?.[0]?.CorporateID);
    setSelectedCorporateName(data?.Route?.[0]?.CorporateID);
    let routeType =
      data?.Route?.[0]?.RouteType?.toLowerCase() === "picking"
        ? "Pickup"
        : "Drop";
    let shuttleType = data?.Route?.[0]?.ShuttleTypeName;

    setTimeout(() => {
      // routeNameInputRef.current.value = routeName;
      let shuttleTypeValues;
      setRouteName(routeName);
      // routeTypeSelectRef.current.value = routeType;
      // shuttleTypeSelectRef.current.value = shuttleType;
      setRouteType(routeType);
      if (shuttleType) {
        shuttleType = shuttleType.toLowerCase();
        if (shuttleType === "basic") shuttleTypeValues = 1;
        else if (shuttleType === "comfort") shuttleTypeValues = 2;
        else if (shuttleType === "comfort plus") shuttleTypeValues = 3;
        else if (shuttleType === "busbuddy") shuttleTypeValues = 4;
      }
      setShuttleTypeState(shuttleTypeValues);
      // if (routeId) {
      //   routeTypeSelectRef.current.setAttribute("disabled", "disabled");
      // }
    });
    sessionStorage.setItem("routeName", routeName);
    sessionStorage.setItem("routeType", routeType);

    if (shuttleType) {
      shuttleType = shuttleType.toLowerCase();
      if (shuttleType === "basic") sessionStorage.setItem("shuttleType", 1);
      else if (shuttleType === "comfort")
        sessionStorage.setItem("shuttleType", 2);
      else if (shuttleType === "comfort plus")
        sessionStorage.setItem("shuttleType", 3);
      else if (shuttleType === "busbuddy")
        sessionStorage.setItem("shuttleType", 4);
    }
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (routeId) {
      sendRequest(
        {
          url: "/api/v1/Route/GetRouteDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            routeID: routeId,
          },
        },
        authenticateUser
      );
    } else {
      // const routeName = sessionStorage.getItem("routeName") || "";
      const routeType = sessionStorage.getItem("routeType") || "Route Type";
      const shuttleType = sessionStorage.getItem("shuttleType");

      let shuttleTypeText = "Shuttle Type";
      switch (parseInt(shuttleType)) {
        case 1:
          shuttleTypeText = "Basic";
          break;
        case 2:
          shuttleTypeText = "Comfort";
          break;
        case 3:
          shuttleTypeText = "Comfort plus";
          break;
        case 4:
          shuttleTypeText = "BusBuddy";
          break;
        default:
          shuttleTypeText = "Shuttle Type";
      }

      // routeNameInputRef.current.value = routeName;
      // setRouteName(routeName);
      routeTypeSelectRef.current.value = routeType;
      shuttleTypeSelectRef.current.value = shuttleTypeText;
      setIsMapLoading(false);
    }
  }, [sendRequest, routeId]);

  const routeNameChangeHandler = () => {
    if (routeNameInputRef?.current?.value) {
      sessionStorage.setItem("routeName", routeNameInputRef?.current?.value);
      setIsError((prev) => ({ ...prev, routeName: "Route Name is Required" }));
    }
  };

  // const routeTypeChangeHandler = () => {
  //   if (routeTypeSelectRef.current?.value !== "Route Type") {
  //     sessionStorage.setItem("routeType", routeTypeSelectRef.current?.value);
  //     setIsError((prev) => ({ ...prev, routeType: "" }));
  //   }
  // };
  const routeTypeChangeHandler = () => {
    const type = routeTypeSelectRef.current.value;
    if (type === "Route Type" || !type) {
      setIsError((prev) => ({ ...prev, routeType: "Route type is required" }));
    } else {
      setIsError((prev) => ({ ...prev, routeType: "" }));
      sessionStorage.setItem("routeType", type);
    }
  };
  const handleRouteTypeChange = (event) => {
    setRouteType(event.target.value);
    sessionStorage.setItem("routeType", event.target.value);
  };

  // const shuttleTypeChangeHandler = () => {
  //   if (shuttleTypeSelectRef.current?.value !== "Shuttle Type") {
  //     if (shuttleTypeSelectRef.current?.value.toLowerCase() === "basic")
  //       sessionStorage.setItem("shuttleType", "1");
  //     else if (shuttleTypeSelectRef.current?.value.toLowerCase() === "comfort")
  //       sessionStorage.setItem("shuttleType", "2");
  //     else if (
  //       shuttleTypeSelectRef.current?.value.toLowerCase() === "comfort plus"
  //     )
  //       sessionStorage.setItem("shuttleType", "3");
  //     else if (shuttleTypeSelectRef.current?.value.toLowerCase() === "busbuddy")
  //       sessionStorage.setItem("shuttleType", "4");
  //     setIsError((prev) => ({ ...prev, shuttleType: "" }));
  //   }
  // };

  const handleMapLoad = () => {
    setIsMapLoading(false);
  };

  // const { sendRequest } = useHttp();
  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
        },
      },
      corporateLists
    );
  }, [sendRequest]);

  const corporateLists = (data) => {
    const tempArr =
      data?.CorporateList?.map((cp) => ({
        cpName: cp?.CorporateName,
        cpId: cp?.CorporateID,
        dpId: cp?.DepartmentID,
        adminDptId:
          cp?.DepartmentID?.split(",")[
            cp?.DepartmentName?.toLowerCase()?.split(",").indexOf("admin")
          ] || "",
      })) || [];
    setCorporateData(tempArr);
  };

  useEffect(() => {
    setSelectedCorporateName(
      localStorage.getItem("roleId") == 1 &&
        (routeId == null || routeId === "" || routeId === undefined)
        ? ""
        : localStorage.getItem("roleId") != 1
        ? localStorage.getItem("cpName")
        : getCorporateNameById(selectCorporateInEdit)
    );
  }, [corporateData, routeId, selectCorporateInEdit]);

  const handleCorporateChange = (e, newValue) => {
    const selectedCorporate = corporateData.find(
      (corp) => corp.cpName === newValue
    );
    if (selectedCorporate) {
      sessionStorage.setItem("selectedCorporateId", selectedCorporate.cpId);
      setSelectedCorporateName(selectedCorporate.cpName);
    }
  };
  const getCorporateNameById = (id) => {
    const selectedCorporate = corporateData.find((corp) => corp.cpId === id);
    return selectedCorporate ? selectedCorporate.cpName : "";
  };

  const handleShuttleTypeChange = (event) => {
    const { value } = event.target;
    setShuttleTypeState(value);
    sessionStorage.setItem("shuttleType", value);
    setIsError((prev) => ({ ...prev, shuttleType: "" })); // Clear error if any
  };

  const shuttleTypeChangeHandler = () => {
    const shuttleTypeValue = shuttleTypeSelectRef?.current?.value;

    if (
      shuttleTypeValue !== "Shuttle Type" &&
      typeof shuttleTypeValue === "string"
    ) {
      let shuttleTypeValueLowerCase = shuttleTypeValue.toLowerCase();
      let shuttleTypeNumber = "";

      switch (shuttleTypeValueLowerCase) {
        case "basic":
          shuttleTypeNumber = "1";
          break;
        case "comfort":
          shuttleTypeNumber = "2";
          break;
        case "comfort plus":
          shuttleTypeNumber = "3";
          break;
        case "busbuddy":
          shuttleTypeNumber = "4";
          break;
        default:
          shuttleTypeNumber = "";
          break;
      }

      setShuttleTypeState(shuttleTypeNumber); // Update state with the corresponding number
      sessionStorage.setItem("shuttleType", shuttleTypeNumber); // Store the number in sessionStorage
      setIsError((prev) => ({ ...prev, shuttleType: "" })); // Clear error if any
    }
  };

  useEffect(() => {
    // if (!isNextClicked) {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
    script.async = true;

    document.body.appendChild(script);
    // }
  }, [isNextClicked]);

  function initMap() {
    // console.log("init");
    map = new window.google.maps.Map(document.getElementById("shift-map"), {
      center: { lat: 23.0225, lng: 72.5714 },
      zoom: 11,
      disableDefaultUI: true,
      fullscreenControl: true,
      zoomControl: true,
    });
    // background-color: rgb(226, 44, 29);

    startLocationMarker = new window.google.maps.Marker({
      map: map,
      animation: window.google.maps.Animation.DROP,
      icon: svgMarkerForStartLocation,
      // icon: LocationOnIcon,
    });

    endLocationMarker = new window.google.maps.Marker({
      map: map,
      animation: window.google.maps.Animation.DROP,
      icon: svgMarkerForEndLocation,
    });

    let inputs = [
      document.getElementById("pac-input"),
      document.getElementById("end-location-input"),
    ];
    let autocomplete = inputs?.map((input) => {
      return new window.google.maps.places.Autocomplete(input, {
        componentRestrictions: { country: ["in"] },
      });
    });
    autocomplete?.forEach((autocomp, index) => {
      autocomp.bindTo("bounds", map);
      autocomp.addListener("place_changed", function () {
        let place = autocomp.getPlace();
        if (!place.geometry || !place.geometry.location) {
          console.log("Place not found");
          return;
        }
        if (index === 0) {
          startLocationMarker.setPosition(place.geometry.location);
          startLocationMarker?.setVisible(true);
          // startLocationMarker.setIcon(
          //   "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          // );
        } else {
          endLocationMarker.setPosition(place.geometry.location);
          endLocationMarker?.setVisible(true);
        }
        // debugger;

        // adjust the zoom so that both the start and end point cover---
        if (
          startLocationMarker?.getPosition()?.lat() &&
          endLocationMarker?.getPosition()?.lat()
        ) {
          var bounds = new window.google.maps.LatLngBounds();

          startLatLng = new window.google.maps.LatLng(
            startLocationMarker?.getPosition()?.lat(),
            startLocationMarker?.getPosition()?.lng()
          );
          endLatLng = new window.google.maps.LatLng(
            endLocationMarker?.getPosition()?.lat(),
            endLocationMarker?.getPosition()?.lng()
          );

          bounds.extend(startLatLng);
          bounds.extend(endLatLng);
          map.fitBounds(bounds);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(18);
        }
        // --------------------
      });
    });
  }
  window.initMap = initMap;

  

  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        sx={{ ml: 3, mt: 3 }}
      >
        <b>{routeId ? "Edit Shuttle Route" : "Shuttle Route Creation"}</b>
      </Typography>
      {showStopInfo === false && (
        <Container
          maxWidth="98%"
          sx={{ mt: 1, mb: 4 }}
          style={{
            width: "97%",
            backgroundColor: "white",
            padding: "0px 10px 16px 0px",
            borderRadius: "20px",
            // paddingTop: "2%",
            paddingBottom: "2%",
            background: "#F3F6F9",
            height: "75vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "1rem",
              height: "75vh",
              // padding: "3%, 5%",
              background: "#F3F6F9",
            }}
          >
            {/* Box 1: Form Components */}
            <div
              style={{
                padding: "2%",
                borderRadius: "10px",
                background: "white",
                width: "calc(38% - 1rem)",
                paddingLeft: "2%",
              }}
            >
              <Box>
                <div>
                  <Autocomplete
                    id="corporate-filter"
                    options={
                      corporateData &&
                      corporateData?.map((data) => data?.cpName)
                    }
                    sx={{ width: "100%", mb: 2 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Search Corporate Name"
                        label="Corporate Name"
                      />
                    )}
                    onChange={handleCorporateChange}
                    disabled={
                      !(
                        localStorage.getItem("roleId") == 1 &&
                        (routeId == null || "" || undefined)
                      )
                    }
                    // Use selectedCorporate state if available, otherwise use the provided value prop
                    // value={
                    //   localStorage.getItem("roleId") == 1 &&
                    //   (routeId == null || "" || undefined)
                    //     ? ""
                    //     : localStorage.getItem("roleId") != 1
                    //     ? localStorage.getItem("cpName")
                    //     : getCorporateNameById(selectCorporateInEdit)
                    // }

                    value={selectedCorporateName}
                  />
                </div>

                {/* <TextField
                  label="Route Name"
                  variant="standard"
                  fullWidth
                  inputRef={routeNameInputRef}
                  onChange={routeNameChangeHandler}
                  error={!!isError.routeName}
                  helperText={isError.routeName}
                  sx={{ mb: 2 }}
                /> */}
                <TextField
                  label="Route Name"
                  variant="standard"
                  fullWidth
                  value={routeName}
                  onChange={handleRouteNameChange} // Use state updater
                  error={!!isError.routeName}
                  helperText={isError.routeName}
                  sx={{ mb: 2 }}
                />
                <div style={{ marginTop: "10px" }}>
                  <FormControl fullWidth>
                    <InputLabel>Shuttle Type</InputLabel>
                    <Select
                      label="Shuttle Type"
                      value={shuttleTypeState && shuttleTypeState}
                      onChange={handleShuttleTypeChange}
                      onBlur={shuttleTypeChangeHandler}
                      inputRef={shuttleTypeSelectRef}
                      variant="standard"
                    >
                      <MenuItem value="Shuttle Type" disabled>
                        Shuttle Type
                      </MenuItem>
                      <MenuItem value={1}>Basic</MenuItem>
                      <MenuItem value={2}>Comfort</MenuItem>
                      <MenuItem value={3}>Comfort Plus</MenuItem>
                      <MenuItem value={4}>BusBuddy</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <TestTimingsInfo
                  routeId={routeId}
                  setIsError={setIsError}
                  defaultShuttleTimings={defaultShuttleTimings}
                  routeType={routeType}
                  nextWizard={true}
                  backWizard={false}

                  setIsNextClicked={setIsNextClicked}
                  // backClickHandler={backClickHandler}
                  setIsAddRouteClicked={props.setIsAddRouteClicked}
                  routeName={routeNameInputRef}
                  routeNameChangeHandler={routeNameChangeHandler}
                  setShowStopInfo={setShowStopInfo}
                  showStopInfo={showStopInfo}
                  routeTypeProps={routeTypeSelectRef}
                  routeTypeChangeHandler={routeTypeChangeHandler}
                  handleRouteTypeChange={handleRouteTypeChange}
                />
              </Box>
            </div>

            {/* Box 2: Map Component */}
            <Box
              sx={{
                width: "calc(62% - 1rem)",
                height: "auto",
                backgroundColor: "#f0f0f0",
                position: "relative",
                borderRadius: "10px",
              }}
            >
              {/* {isMapLoading && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  <CircularProgress />
                </Box>
              )} */}
              {/* <LoadScript
                googleMapsApiKey="AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs"
                libraries={["places"]}
                onLoad={handleMapLoad}
              >
                <GoogleMap
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  zoom={12}
                  center={center}
                  onLoad={handleMapLoad}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript> */}
              {!isNextClicked && (

              <ShiftMap />
              )}
            </Box>
          </Box>
        </Container>
      )}
      <div style={{ marginTop: "-40px" }}>
        {showStopInfo && (
          <TestStopInfo
            routeCreationStatus={props.routeCreationStatus}
            setShowStopInfo={setShowStopInfo}
            routeId={routeId}
            setIsNextClicked={setIsNextClicked}

            selectCorporateInEdit={selectCorporateInEdit}
          />
        )}
      </div>
    </>
  );
}

export default TestRouteInfo;
