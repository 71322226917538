import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Loading from "../../../Loading/Loading";
import Accordian from "./Accordian";
import useHttp from "../../../Hooks/use-http";
import Message from "../../../Modal/Message";
import EditDriver from "./EditDriver";
import "./Records.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

let bookingData = {
  driverName: "",
  driverCarModel: "",
  companyCost: "",
  driverCost: "",
  isDisableDriverField: false,
  modalHeader: "",
};
const Records = (props) => {
  const [bookingCancellationId, setBookingCancellationId] = useState();
  const [isBookingCancelClicked, setIsBookingCancelClicked] = useState(false);
  const [editDriverBookingId, setEditDriverBookingId] = useState(false);
  const [bookingRequestCancalStatus, setBookingRequestCancalStatus] =
    useState();
  const [isSaveEditDriverClicked, setIsSaveDriverClicked] = useState(false);
  const [editDriverRequestStatus, setEditDriverRequestStatus] = useState(false);
  const cancelNoteInputRef = useRef();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Booking Id");

  const { isLoading, sendRequest } = useHttp();

  // sorting part
  const handleSort = (column) => {
    if (orderBy === column && order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(column);
  };

  const sortedData = props?.data.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  const cancallationStatusHandler = (data) => {
    if (data.Message.toLowerCase() === "success")
      setBookingRequestCancalStatus("success");
    else setBookingRequestCancalStatus("fail");
  };

  const assignDriverResponse = (data) => {
    bookingData = {};
    setEditDriverRequestStatus(data.Message.toLowerCase());
    setIsSaveDriverClicked(false);
  };

  useEffect(() => {
    if (isBookingCancelClicked) {
      setBookingCancellationId("");
      setIsBookingCancelClicked(false);
      sendRequest(
        {
          url: "/api/v1/ScheduleBooking/CancelScheduleTrip",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            status: "CANCELLED",
            bookingID: bookingCancellationId,
            cancelNotes: cancelNoteInputRef.current.value,
          },
        },
        cancallationStatusHandler
      );
    }
  }, [sendRequest, isBookingCancelClicked]);

  useEffect(() => {
    if (isSaveEditDriverClicked)
      sendRequest(
        {
          url: "/api/v1/DriverList/EditAssignedDriver",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            amount: "0.00",
            roleID: localStorage.getItem("roleId"),
            status: "ACCEPTED",
            bookingID: bookingData.bookingId,
            driverEmailID: bookingData.driverEmail,
            sendSms: "1",
            companyCost: bookingData.companyCost,
            driverCost: bookingData.driverCost,
          },
        },
        assignDriverResponse
      );
  }, [sendRequest, isSaveEditDriverClicked]);

  const editDriverFun = (selectedDriverEmail, companyCost, driverCost) => {
    bookingData.driverEmail = selectedDriverEmail;
    bookingData.companyCost = companyCost;
    bookingData.driverCost = driverCost;
    setEditDriverBookingId("");
    setIsSaveDriverClicked(true);
  };

  const bookingDataHandler = (data) => {
    bookingData.driverName = data.driverName ?? "";
    bookingData.driverCarModel = data.driverCarModel ?? "";
    bookingData.driverEmail = data.driverEmail ?? "";
    bookingData.isDisableDriverField = data.isDisableDriverField ?? "";
    bookingData.companyCost = data.companyCost ?? "";
    bookingData.driverCost = data.driverCost ?? "";
    bookingData.modalHeader = data.modalHeader ?? "";
    bookingData.bookingId = data.bookingId;
    setEditDriverBookingId(data.bookingId);
  };

  const func = (val) => {
    if (val) {
      document.getElementById(val)?.click();
    }
  };

  return (
    // <React.Fragment>
    //     {props.data[0] ?
    //         <table className="table" id="my-table">
    //             <thead>
    //                 <tr>
    //                     {localStorage.getItem("roleId") == 1 &&
    //                         <th>Corporate Name</th>
    //                     }
    //                     {props.headers?.map((data) => (
    //                         <th style={{ width: "auto" }} scope="col">{data}</th>
    //                     ))}
    //                 </tr>
    //             </thead>
    //             <tbody>
    //                 {props?.data?.map((item, index) => (
    //                     <Accordian
    //                         formyRender={func}
    //                         id={index}
    //                         bookingId={item.bookingId}
    //                         guestName={item.guestName}
    //                         guestMobile={item.guestMobile}
    //                         pickupDate={item.pickupDate}
    //                         pickupTime={item.pickupTime}
    //                         dropDate={item.dropDate}
    //                         dropTime={item.dropTime}
    //                         vehicleType={item.vehicleType}
    //                         status={item.status}
    //                         pickupLocation={item.pickupLocation}
    //                         dropLocation={item.dropLocation}
    //                         driverName={item.driverName}
    //                         driverCarNumber={item.driverCarNumber}
    //                         driverCarModel={item.driverCarModel}
    //                         bookingDate={item.bookingDate}
    //                         cancelNotes={item.cancelNotes}
    //                         setBookingCancellationId={setBookingCancellationId}
    //                         setEditDriverBookingId={setEditDriverBookingId}
    //                         bookingType={item.bookingType}
    //                         coprorateId={item.coprorateId}
    //                         corporateName={item.corporateName}
    //                         driverCost={item.driverCost}
    //                         companyCost={item.companyCost}
    //                         bookingDataHandler={bookingDataHandler}
    //                         driverEmailId={item.driverEmailId}
    //                         tripOtp={item.tripOtp}
    //                     />
    //                 ))}
    //             </tbody>
    //         </table> :
    //         <React.Fragment>
    //             <table className="table" id="my-table" >
    //                 <thead>
    //                     <tr>
    //                         {props.headers.map((data) => (
    //                             <th style={{ width: "auto" }} >{data}</th>
    //                         ))}
    //                         <th>Actions</th>
    //                     </tr>
    //                 </thead>
    //             </table>
    //             {props.isLoading ? <Loading datatable="true" /> :
    //                 <div style={{ textAlign: "center", marginTop: "10px" }}>No Data Available</div>
    //             }
    //         </React.Fragment>
    //     }
    //     {editDriverBookingId &&
    //         <React.Fragment>
    //             <div className="overlay"></div>
    //             <EditDriver bookingData={bookingData} setEditDriverBookingId={setEditDriverBookingId} editDriverFun={editDriverFun} />
    //         </React.Fragment>
    //     }
    //     {bookingCancellationId &&
    //         <React.Fragment>
    //             <div className="overlay"></div>
    //             <div className="cancelBookingModal">
    //                 <header>
    //                     <span>Cancel Schedule Booking</span>
    //                     <span onClick={() => setBookingCancellationId("")} style={{ cursor: "pointer" }} >X</span>
    //                 </header>
    //                 <main>
    //                     <textarea ref={cancelNoteInputRef} placeholder="Enter Cancellation Reason" />
    //                 </main>
    //                 <footer>
    //                     <button onClick={() => setIsBookingCancelClicked(true)} >Save</button>
    //                 </footer>
    //             </div>
    //         </React.Fragment>
    //     }
    //     {isLoading &&
    //         <Backdrop
    //             sx={{ color: 'rgba(34, 137, 203, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //             open={isLoading}
    //         >
    //             <CircularProgress color="inherit" />
    //         </Backdrop>
    //     }
    //     {bookingRequestCancalStatus && <Message type={bookingRequestCancalStatus} message="Your booking has been cancelled successfully" />}
    //     {editDriverRequestStatus && <Message type={editDriverRequestStatus} message="Data is saved successfully" />}
    // </React.Fragment>
    <React.Fragment>
      <TableContainer component={Paper} sx={{ width: "100%" }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {props?.headers?.map((data) => {
                if (
                  data === "Corporate Name" &&
                  localStorage.getItem("roleId") !== "1"
                ) {
                  return null; // Skip rendering "Corporate Name" header
                }

                return (
                  <TableCell
                    key={data}
                    align="left"
                    sx={{
                      py: 0,
                      px: 4,
                      fontWeight: "bold",
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === data}
                      direction={orderBy === data ? order : "asc"}
                      onClick={() => handleSort(data)}
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {data}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.isLoading ? (
              <TableRow>
                <TableCell colSpan={props.headers.length + 1}>
                  <Loading datatable="true" />
                </TableCell>
              </TableRow>
            ) : sortedData?.length > 0 ? (
              sortedData?.map((item, index) => (
                <Accordian
                  key={index}
                  formyRender={func}
                  id={index}
                  bookingId={item.bookingId}
                  guestName={item.guestName}
                  guestMobile={item.guestMobile}
                  pickupDate={item.pickupDate}
                  pickupTime={item.pickupTime}
                  dropDate={item.dropDate}
                  dropTime={item.dropTime}
                  vehicleType={item.vehicleType}
                  status={item.status}
                  pickupLocation={item.pickupLocation}
                  dropLocation={item.dropLocation}
                  driverName={item.driverName}
                  driverCarNumber={item.driverCarNumber}
                  driverCarModel={item.driverCarModel}
                  bookingDate={item.bookingDate}
                  cancelNotes={item.cancelNotes}
                  setBookingCancellationId={setBookingCancellationId}
                  setEditDriverBookingId={setEditDriverBookingId}
                  bookingType={item.bookingType}
                  coprorateId={item.coprorateId}
                  corporateName={item.corporateName}
                  driverCost={item.driverCost}
                  companyCost={item.companyCost}
                  bookingDataHandler={bookingDataHandler}
                  driverEmailId={item.driverEmailId}
                  tripOtp={item.tripOtp}
                />
              ))
            ) : (
              <TableRow>
                <TableCell
                  style={{ textAlign: "center", marginTop: "10px" }}
                  colSpan={props.headers.length + 1}
                >
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {sortedData.length === 0 && (
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        No Data Available
      </div>
    )} */}
      {editDriverBookingId && (
        <React.Fragment>
          <div className="overlay"></div>
          <EditDriver
            bookingData={bookingData}
            setEditDriverBookingId={setEditDriverBookingId}
            editDriverFun={editDriverFun}
          />
        </React.Fragment>
      )}
      {bookingCancellationId && (
        <React.Fragment>
          <div className="overlay"></div>
          <div className="cancelBookingModal">
            <header>
              <span>Cancel Schedule Booking</span>
              <span
                onClick={() => setBookingCancellationId("")}
                style={{ cursor: "pointer" }}
              >
                X
              </span>
            </header>
            <main>
              <textarea
                ref={cancelNoteInputRef}
                placeholder="Enter Cancellation Reason"
              />
            </main>
            <footer>
              <button onClick={() => setIsBookingCancelClicked(true)}>
                Save
              </button>
            </footer>
          </div>
        </React.Fragment>
      )}
      {props.isLoading && (
        <Backdrop
          sx={{
            color: "rgba(34, 137, 203, 255)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {bookingRequestCancalStatus && (
        <Message
          type={bookingRequestCancalStatus}
          message="Your booking has been cancelled successfully"
        />
      )}
      {editDriverRequestStatus && (
        <Message
          type={editDriverRequestStatus}
          message="Data is saved successfully"
        />
      )}
    </React.Fragment>
  );
};

export default Records;
