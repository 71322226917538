import React, { useEffect, useRef, useState } from "react";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import ReactDOMServer from "react-dom/server";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import littleImageForHeader from "../../../Assets/Little_logo.jpg";
import littleImage from "../../../Assets/unnamed.png";
import { ReactComponent as QrBorder } from "../../../Assets/Border_Updates.svg";
import "./QrCode.css";
import useHttp from "../../../Hooks/use-http";

const QrCode = () => {
  const [driverData, setDriverData] = useState([]);

  const driverList = (data) => {
    setTimeout(() => window.print(), 2000);
    setDriverData(data.DriverList[0]);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/DriverList/GetDriverDetails",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          driverEmailID: window.location.href.split("drivers/")[1],
        },
      },
      driverList
    );
  }, [sendRequest]);

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          color: "rgba(34, 137, 203, 255)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          position: "absolute",
          top: "0",
          zIndex: "999",
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "space-evenly",
          paddingTop: "10px",
        }}
      >
        {[0, 1].map((val) => (
          <div id="jay" style={{ height: "486px", width: "300px" }}>
            <QrBorder />
            <div
              style={{
                display: "flex",
                width: "300px",
                height: "486px",
                justifyContent: "space-evenly",
                flexDirection: "column",
                alignItems: "center",
                position: "absolute",
                top: "0",
              }}
            >
              <img
                style={{ width: "100px", height: "80px", objectFit: "contain" }}
                src={littleImageForHeader}
              />
              <img
                style={{
                  height: "25%",
                  width: "40%",
                  borderRadius: "50%",
                  objectFit: "fill",
                }}
                src={driverData?.DriverImage}
              />
              <p style={{ fontWeight: "bold", fontSize:'large' }}>{driverData.DriverName}</p>
              <div style={{marginTop:'20px'}}>

              <QRCode
                value={driverData.UniqueNumber}
                renderAs="svg"
                imageSettings={{
                    src: littleImage,
                    height: "25",
                    width: "25",
                    excavate: true,
                }}
              
                
                />
              {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}> */}
              <p style={{ fontWeight: "bold", margin:'inherit', marginLeft:'25%' }}>{driverData.UniqueNumber}</p>
                </div>

              <p style={{ fontSize: "12px" }}>
                Scan for a
                <span style={{ color: "rgba(42, 149, 69, 255)" }}> l</span>
                <span style={{ color: "rgba(34, 137, 203, 255)" }}>i</span>
                <span style={{ color: "rgba(42, 149, 69, 255)" }}>t</span>
                <span style={{ color: "rgba(245, 174, 48, 255)" }}>t</span>
                <span style={{ color: "rgba(42, 149, 69, 255)" }}>l</span>
                <span style={{ color: "rgb(226, 44, 29)" }}>e </span>
                better ride
              </p>
              {/* </div> */}
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default QrCode;
