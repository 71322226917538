import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../../GeneratePDF/Modal.css";
import useHttp from "../../Hooks/use-http";
import "./extraKmStyles.css";
import { toast } from "react-toastify";
import { FormControl, InputLabel } from "@mui/material";

let drivers = [];
let riders = [];
let reportURLs = {
  trips: "Report/ShuttleTripReport",
  shifts: "DriverShift/DriverShiftDetailsReport",
  bookingRequests: "ScheduleBooking/GetBookingRequestDetailsReport",
  scheduleTrips: "ScheduleBooking/ScheduleTripReport",
};
let driverAndRiderURLs = {
  shuttle: "ShuttleTrips/GetShuttleDriverList",
  schedule: "DriverList/GetPrivateDriverList",
  private: "DriverList/GetPrivateDriverList",
};
let date = new Date();
const ExtraKm = (props) => {
  const [corporatesData, setCorporatesData] = useState([]);
  const [selectedCoroparte, setSelectedCorporate] = useState({});
  const [modules, setModules] = useState([]);
  const [driverAndRiderData, setDriverAndRiderData] = useState({});
  const [selectedModule, setSelectedModule] = useState("adhoc");
  const [startedOnValue, setStartedOnValue] = useState(dayjs(new Date()));
  const [endedOnValue, setEndedOnValue] = useState(dayjs(new Date()));
  const [kilometers, setKilometers] = useState("");
  const [driverName, setDriverName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [tripid, setTripid] = useState(null);
  const [detailsByTripId, setDetailsByTripId] = useState(null);
  const [loading, setLoading] = useState(false); // State variable for loading indicator

  let userEmailId = localStorage.getItem("user");

  useEffect(() => {
    if (detailsByTripId) {
      // Populate form fields with data
      setDriverName(detailsByTripId.DriverName || "");
      setStartedOnValue(detailsByTripId.StartedOn || "");
      setEndedOnValue(detailsByTripId.EndedOn || "");
      setKilometers(detailsByTripId.ActualTripDistance || "");
      setSelectedOption(detailsByTripId.TripType || "");
    }
  }, [detailsByTripId]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getDetailsByTripId = (data) => {
    setDetailsByTripId(data.TripdetailList?.[0]);

    if (detailsByTripId) {
      const startedOnFormatted = detailsByTripId.StartedOn
        ? dayjs(detailsByTripId.StartedOn, "MM/DD/YYYY HH:mm").toDate()
        : null;

      setDetailsByTripId({
        ...detailsByTripId,
        StartedOn: startedOnFormatted,
      });
    }

    setSelectedCorporate({
      CorporateID: data.TripdetailList?.[0].CorporateID,
      CorporateName: data.TripdetailList?.[0].CorporateName,
    });
  };

  const getCorporateList = (data) => {
    setCorporatesData(data.CorporateList);
    // console.log(data.CorporateList);
  };

  const getDriverAndRiderData = (data) => {
    let obj = {
      driverList: data.PrivetDriverlist,
      riderList: data.RidersList,
    };
    setDriverAndRiderData(obj);
    // console.log(data);
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    let modules = "";
    if (localStorage.getItem("roleId") === "1")
      modules = selectedCoroparte?.EnabledModule?.split(",");
    else modules = localStorage.getItem("enabledModule").split(",");
    setModules(modules);
  }, [selectedCoroparte]);

  useEffect(() => {
    if (localStorage.getItem("roleId") === "1")
      sendRequest(
        {
          url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
          },
        },
        getCorporateList
      );
    else selectedCoroparte.CorporateID = localStorage.getItem("corpId");
  }, [sendRequest]);

  // useEffect(() => {
  //   if (selectedModule)
  //     sendRequest(
  //       {
  //         url: "/api/v1/" + driverAndRiderURLs[selectedModule],
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: {
  //           emailID: localStorage.getItem("user"),
  //           roleID: localStorage.getItem("roleId"),
  //           corporateID:
  //             localStorage.getItem("roleId") === "1"
  //               ? selectedCoroparte.CorporateID
  //               : localStorage.getItem("corpId"),
  //           isRider: "1",
  //           isDriver: "1",
  //         },
  //       },
  //       getDriverAndRiderData
  //     );
  // }, [selectedModule]);
  const handleEditClick = () => {
    // Check if tripId is filled
    if (tripid) {
      // Send POST request
      setLoading(true);
      sendRequest(
        {
          url: "/api/v1/Trips/AllTripsDetails",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: userEmailId,
            journeyID: tripid,
          },
        },
        (data) => {
          setLoading(false); // Stop loading indicator when data fetching completes
          getDetailsByTripId(data);
        }
      );
    }
  };

  const handleSubmit = () => {
    if (
      !selectedCoroparte.CorporateID ||
      !driverName ||
      !startedOnValue ||
      !endedOnValue ||
      !kilometers ||
      !selectedOption
    ) {
      toast.error("Please fill in all fields.");
      return; // Exit early if any field is empty
    }
    // Check if selectedCorporate is not null before using it
    if (selectedCoroparte) {
      // Construct payload
      const startedOnFormatted =
        dayjs(startedOnValue).format("MM/DD/YYYY HH:mm");
      const endedOnFormatted = dayjs(endedOnValue).format("MM/DD/YYYY HH:mm");
      const tripDateFormatted = dayjs(startedOnValue).format("MM/DD/YYYY");

      const payload = {
        corporateID: selectedCoroparte.CorporateID,
        emailID: userEmailId,
        driverName: driverName, // Using selected driver name from state
        startTime: startedOnFormatted, // Started On value
        endTime: endedOnFormatted, // Ended On value
        tripType: selectedOption,
        tripDate: tripDateFormatted,
        tripID: tripid || "",
        kilometers: kilometers, // Kilometer value from detailsByTripId state
      };
      sendRequest(
        {
          url: "/api/v1/AdhocDrivers/AddEditAdhocDrivers",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        },
        (responseData) => {
          // console.log("Response from server:", responseData);
          if (responseData.Status === "000") {
            // Show success message using React Toastify
            toast.success("Submission successful");
            props.setIsRefereshData(true);
            // Close modal and reset fields
            props.setIsAddKmButtonClicked(false);
            handleReset();
          } else {
            // Show error message using React Toastify
            toast.error(responseData.Message || "Submission unsuccessful");
          }
        }
      );
    } else {
      console.error("No selected corporate found."); // Handle the case where no corporate is selected
    }
  };

  const handleReset = () => {
    // Reset all state variables to their initial values
    setSelectedCorporate({});
    setDriverAndRiderData({});
    setSelectedModule("adhoc");

    setStartedOnValue("");
    setEndedOnValue("");
    setKilometers("");
    setDriverName("");
    setSelectedOption("");
    setTripid("");
    setDetailsByTripId(null);
  };

  // This is for because the data filled in the field should not reflect in other module
  useEffect(() => {
    if (selectedModule === "adhoc" || selectedModule === "tripId") {
      setSelectedCorporate({});

      setTripid("");
      setDriverName("");
      setStartedOnValue("");
      setEndedOnValue("");
      setSelectedOption("");

      setKilometers("");
      setDetailsByTripId(null);
    }
  }, [selectedModule]);

  return (
    <div className="extraKm-container" style={{ zIndex: "999" }}>
      <header>
        <span>Update Kilometer</span>
        <CloseIcon
          style={{ cursor: "pointer" }}
          onClick={() => props.setIsAddKmButtonClicked(false)}
        />
      </header>
      <div className="extraKm-subContainer">
        <main>
          <React.Fragment>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "5%",
                  //   justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="adhoc"
                    name="tripType"
                    value="adhoc"
                    checked={selectedModule === "adhoc"}
                    onChange={(e) => setSelectedModule(e.target.value)}
                  />
                  <label htmlFor="adhoc">Adhoc</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="tripId"
                    name="tripType"
                    value="tripId"
                    checked={selectedModule === "tripId"}
                    onChange={(e) => setSelectedModule(e.target.value)}
                  />
                  <label htmlFor="tripId">TripId</label>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                  {/* <label style={{ marginBottom: "5px" }}>Trip ID</label> */}
                  {/* <br /> */}
                  <TextField
                    label="Trip Id"
                    value={tripid}
                    onChange={(e) => setTripid(e.target.value)}
                    size="small"
                    style={{ width: "310px" }}
                  />
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleEditClick}
                    size="small"
                    style={{ paddingTop: 0.2, paddingBottom: 0.2 }}
                    disabled={selectedModule === "adhoc"}
                  >
                    Get Details
                  </Button>
                </div>
              </div>
              <hr style={{ marginTop: '5%' }} />
              <div>
                {loading && <div className="loader"></div>}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="form-row">
                    <Autocomplete
                      id="corporate-select"
                      options={corporatesData}
                      getOptionLabel={(option) => option.CorporateName}
                      value={
                        selectedCoroparte.CorporateID
                          ? corporatesData.find(
                            (corp) =>
                              corp.CorporateID ===
                              selectedCoroparte.CorporateID
                          )
                          : null
                      }
                      disabled={selectedModule === "tripId"}
                      onChange={(event, newValue) => {
                        setSelectedCorporate(newValue ? newValue : {}); // Add null check here
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Corporate*"
                          variant="standard"
                          disabled={selectedModule === "tripId"}
                        />
                      )}
                    />
                  </div>

                  <div className="form-row">
                    <TextField
                      label="Driver Name"
                      value={driverName}
                      onChange={(e) => setDriverName(e.target.value)}
                      disabled={selectedModule === "tripId"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </LocalizationProvider>


                <div
                  className="form-row"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <div className="form-row"  style={{ width: '100%' }}>
                    <TextField
                      label="Started On*"
                      type="datetime-local" // Use type="datetime-local" for combined date and time input
                      value={dayjs(startedOnValue).format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => setStartedOnValue(e.target.value)}
                      disabled={selectedModule === "tripId"}
                      inputProps={{
                        max: dayjs().format("YYYY-MM-DDTHH:mm"), // Disable future and today's date
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="form-row"  style={{ width: '100%' }}>
                    <TextField
                      label="Ended On*"
                      type="datetime-local" // Use type="datetime-local" for combined date and time input
                      value={dayjs(endedOnValue).format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => setEndedOnValue(e.target.value)}
                      disabled={selectedModule === "tripId"}
                      inputProps={{
                        max: dayjs().format("YYYY-MM-DDTHH:mm"), // Disable future and today's date
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                </div>

                {/* Kilometers */}
                <div style={{ display: 'flex', gap: '10px' }}>

                  {/* New Select Field Below Driver Name */}
                  <div className="form-row" style={{ width: '100%' }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedOption}
                        label="Type"
                        onChange={handleSelectChange}
                        disabled={selectedModule === "tripId"}
                      >
                        <MenuItem value="Private">Private</MenuItem>
                        <MenuItem value="Shuttle">Shuttle</MenuItem>
                        <MenuItem value="Schedule">Schedule</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="form-row" style={{width:'100%'}}>
                    <TextField
                      label="Actual Trip Kilometers*"
                      type="number"
                      value={kilometers}
                      onChange={(e) => setKilometers(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <footer>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleReset}
                    style={{
                      backgroundColor: "#EDBD1A",
                      color: "black",
                      marginLeft: "10px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "red";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "orange";
                    }}
                  >
                    Reset
                  </Button>
                </footer>
              </div>
            </div>

            {/* Other fields */}
          </React.Fragment>
        </main>
      </div>
    </div>
  );
};

export default ExtraKm;
