import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';

const TripDetails = () => {
  const location = useLocation();
  const tripData = location.state?.tripData;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        maxWidth: '900px',
        margin: '0 auto',
        backgroundColor: '#f9f9f9'
      }}
    >
        <h3>Trip Details</h3>
        <br/>
      <Card sx={{ width: '100%', padding: 2, marginBottom: 2 }}>
        <Typography variant="h6">Driver Name: {tripData?.[0]?.DriverName}</Typography>
        <Typography variant="body1">Corporate: {tripData?.[0]?.CorporateName}</Typography>
        <Typography variant="body1">Trip Type: {tripData?.[0]?.TripType}</Typography>
      </Card>

      {tripData?.map((rider, index) => (
        <Card key={index} sx={{ width: '100%', padding: 2, marginBottom: 2 }}>
          <Typography variant="h6">Rider {index + 1}</Typography>
          <Typography variant="body1">Rider Name: {rider?.RiderName}</Typography>
          <Typography variant="body1">Pickup Address: {rider?.PickupAddress}</Typography>
          <Typography variant="body1">Drop Location: {rider?.DropLocation}</Typography>
          <Typography variant="body1">Contact: {rider?.ContactNumber}</Typography>
        </Card>
      ))}
    </Box>
  );
};

export default TripDetails;

