import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";


const DeleteDriver = ({
  selectedCorporate,
  setSelectedCorporate,
  driverData,
  isAddDeletePrivateDrivesClicked,
  setIsDeleteDialogOpen,
  onCloseModal,
  selectedDriverType,
  setSelectedDriverType,
  setIsApiCall,
  setAnchorEl
}) => {
  const [filteredCorporateData, setFilteredCorporateData] = useState([]);
  const [availableDriverTypes, setAvailableDriverTypes] = useState([]);


  useEffect(() => {
    if (driverData?.CorporateName) {
      // Determine the available driver types
      const driverTypes = Array.from(new Set(driverData.Corporates.map(c => c.DriverType)));
      setAvailableDriverTypes(driverTypes);
      // setSelectedDriverType(driverTypes.length === 1 ? driverTypes[0] : null);
      if (driverTypes.length === 1) {
        setSelectedDriverType(driverTypes[0]);
      } else if (driverTypes.length === 2 && driverTypes.includes("Private")) {
        setSelectedDriverType("Private");
      } else {
        setSelectedDriverType(null);
      }
    }
  }, [driverData]);

  useEffect(() => {
    if (selectedDriverType) {
      const filteredData = driverData.Corporates.filter(c => c.DriverType === selectedDriverType);
      setFilteredCorporateData(filteredData);
    }
  }, [selectedDriverType, driverData]);

  const handleCorporateChange = (event, newValue) => {
    const selected = filteredCorporateData.find(
      (corporate) => corporate.CorporateName === newValue
    );
    setSelectedCorporate(selected ? [selected] : []);
  };

  const handleDriverTypeChange = (event) => {
    setSelectedDriverType(event.target.value);
  };

  const handleDeleteDriver = () => {
    isAddDeletePrivateDrivesClicked(true);

    setTimeout(() => setIsDeleteDialogOpen(false), 10000);
    setIsApiCall(true)
    setAnchorEl(null)
    // toast.success("Removed successfully");
    onCloseModal();
  };

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "rgba(34, 137, 203, 255)",
          color: "white",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "-24px",
          flexDirection: "row",
        }}
      >
        <Typography variant="h7" component="div">
          REMOVE DRIVER
        </Typography>
      </Box>
      <Card sx={{ display: "flex", marginTop: "10%" }}>
        <CardMedia
          component="img"
          style={{
            height: "100px",
            width: "24%",
            borderRadius: "50%",
            padding: "4px",
          }}
          image={driverData.DriverImage}
          alt="Driver Image"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent>
            <Typography component="div" variant="h6">
              {driverData?.DriverName}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              {driverData?.DriverEmailID}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              {driverData?.CarModel}{" "}{driverData?.CarNumber}
            </Typography>
          </CardContent>
        </Box>
      </Card>

      {availableDriverTypes.length > 0 && (
        <FormControl component="fieldset" sx={{ marginTop: '10px' }}>
          <FormLabel component="legend">Driver Type</FormLabel>
          <RadioGroup
            value={selectedDriverType}
            onChange={handleDriverTypeChange}
          >
            <div style={{display:'flex', gap:'30%'}}>

            {availableDriverTypes.includes('Private') && (
              <FormControlLabel value="Private" control={<Radio />} label="Private" />
            )}
            {availableDriverTypes.includes('Shuttle') && (
              <FormControlLabel value="Shuttle" control={<Radio />} label="Shuttle" />
            )}
            </div>
          
          </RadioGroup>
        </FormControl>
      )}

      <Autocomplete
        id="corporate-filter"
        options={
          filteredCorporateData &&
          filteredCorporateData?.map((data) => data?.CorporateName)
        }
        sx={{ width: 250 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Search Corporate Name"
            label="Corporate Name"
          />
        )}
        onChange={(e, newValue) => handleCorporateChange(e, newValue)}
        value={
          selectedCorporate && selectedCorporate?.length > 0
            ? selectedCorporate[0]?.CorporateName
            : ""
        }
      />

      <button
        style={{
          backgroundColor: "#88BCDF",
          color: "white",
          border: "none",
          borderRadius: "4px",
          padding: "8px 14px",
          fontSize: "12px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          marginTop: "10px",
          marginLeft: '80%',
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = "#2289CB")}
        onMouseOut={(e) => (e.target.style.backgroundColor = "#88BCDF")}
        onClick={handleDeleteDriver}
        disabled={!selectedDriverType || !selectedCorporate.length}
      >
        REMOVE
      </button>
    </div>
  );
};

export default DeleteDriver;

