import { TextField } from "@mui/material";
import React from "react";

const SearchFilter = ({
  searchValue,
  setSearchValue,
  data,
  setFilteredData,
  styling,
  loading,
  placeholder
}) => {

  const handleSearchInputChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchValue(searchQuery);
  };

  React.useEffect(() => {
    const filterData = () => {
      if (!searchValue) {
        setFilteredData(data); // Reset to original data if search is empty
        return;
      }

      const filteredTrips = data.filter((trip) =>
        (
          trip.DriverName?.toLowerCase().includes(searchValue) ||
          trip.driver_name?.toLowerCase().includes(searchValue) ||
          trip.DriverTripID?.toLowerCase().includes(searchValue) ||
          trip.CorporateName?.toLowerCase().includes(searchValue) ||
          trip.CorporateID?.toLowerCase().includes(searchValue) ||
          trip.Name?.toLowerCase().includes(searchValue) ||
          trip.TaskName?.toLowerCase().includes(searchValue) || 
          trip.CarModel?.toLowerCase().includes(searchValue) || 

          trip.CarNumber?.toLowerCase().includes(searchValue) 


        ) 
      );

      setFilteredData(filteredTrips);
    };

    filterData(); // Call the filtering function
  }, [searchValue, data, setFilteredData]);

  return (
    <>
      <TextField
        label='Search'
        type="text"
        value={searchValue}
        onChange={handleSearchInputChange}
        placeholder={(localStorage.getItem('roleId')=="1" && placeholder) || "Search"}
        className="route-search-input"
        size="small"
        variant="standard"
        disabled={loading}
        sx={styling}
      />
    </>
  );
};

export default SearchFilter;
