import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../Loading/Loading";
import Accordian from "./Accordian";
import "./Records.css";
import Message from "../../Modal/Message";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import editImage from "../../Assets/editIcon.png";
import tripImage from "../../Assets/tripsIcon.png";
import transferImage from "../../Assets/Transfer.png";
import disableImage from "../../Assets/Disable.png";
import useHttp from "../../Hooks/use-http";
import { useEffect } from "react";
import TransferStaff from "./TransferStaff";
import { useRef } from "react";

let staffName = "";
let staffMoNumber = "";
let dptName = "";
const Records = ({ isLoading, data, headers }) => {
  const [isStudentDisbaleClicked, setIsStudentDisableClicked] = useState(false);
  const [isStaffDisbleClicked, setIsStaffDisableClicked] = useState(false);
  const [isStaffTransferClicked, setIsStaffTransferClicked] = useState(false);
  const [isResponse, setIsResponse] = useState();
  const history = useHistory();
  const [orderBy, setOrderBy] = useState("Name");
  const [order, setOrder] = useState("asc");


  const handleSort = (column) => {
    if (orderBy === column && order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(column);
  };
  const sortedData = data.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] > b[orderBy] ? 1 : -1;
    } else {
      return a[orderBy] < b[orderBy] ? 1 : -1;
    }
  });

  const authenticateUser = (data) => {
    setIsResponse(data.Message ? data.Message.toLowerCase() : data);
    setIsStudentDisableClicked(false);
    setIsStaffDisableClicked(false);
  };

  const { sendRequest } = useHttp();

  useEffect(() => {
    if (isStaffDisbleClicked)
      sendRequest(
        {
          url: "/api/v1/Staff/SuspendStaff",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            mobileNumber: staffMoNumber,
          },
        },
        authenticateUser
      );
  }, [sendRequest, isStaffDisbleClicked]);

  const subListClickHandler = (e, row) => {
    // staffMoNumber = e.target.parentElement.parentElement.children[1]?.innerText;
    // staffName = e.target.parentElement.parentElement.children[0]?.innerText;
    staffMoNumber=row?.mobile_no;
    staffName=row?.name;
    if (e.target.alt === "disable") {
      setIsStudentDisableClicked(true);
    } else if (e.target.alt === "transfer") {
      // dptName = e.target.parentElement.parentElement.children[2]?.innerText;
      dptName= row?.department;
      setIsStaffTransferClicked(true);
    } else if (e.target.alt === "trips") {
      history.push(`/trips?staff=${staffMoNumber}`);
    } else if (e.target.alt === "edit") {
      window.open(
        `https://students.little.global/?name=${row?.corporateName}&corpId=${row?.corporateId}&edit=${staffMoNumber}`
      );
    }
  };
  return (
    <TableContainer component={Paper} sx={{ maxWidth: "100%" }}>
      <Table sx={{ maxWidth: "100%" }}>
        <TableHead>
          <TableRow>
            {headers?.map((header) => (
              <TableCell
                key={header}
                sx={{
                  py: 0,
                  px: 3,
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 0.87)",
                  w: 0,
                }}
              >
                <TableSortLabel
                  active={orderBy === header}
                  direction={orderBy === header ? order : "asc"}
                  onClick={() => handleSort(header)}
                  sx={{ fontSize: "0.8rem" }}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell
              sx={{
                py: 0,
                px: 4,
                fontWeight: "bold",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                <Loading datatable="true" />
              </TableCell>
            </TableRow>
          ) : sortedData?.length > 0 ? (
            sortedData?.map((row) => (
              <TableRow key={row.id}>
                <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                  <div>{row.name}</div>
                  <div style={{ fontSize: "10px" }}>({row.mobile_no})</div>
                </TableCell>

                {/* <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                  {row.mobile_no}
                </TableCell> */}
                {localStorage.getItem("roleId") == "1" && (
                  <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                    {row.corporateName}
                  </TableCell>
                )}

                <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                  {row.department}
                </TableCell>
                <TableCell sx={{ fontSize: "0.8rem", py: 1, px: 3 }}>
                  {row.pickupAddress ? (
                    <>
                      {" "}
                      <a
                        href={`https://www.google.com/maps?q=${row?.pickupLL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                        }}
                      >
                        {row.pickupAddress}
                      </a>
                      <br />(
                      <span style={{ color: "gray" }}>
                        {row.pickupRouteName
                          ? row.pickupRouteName
                          : "Not Assigned"}
                      </span>
                      )
                    </>
                  ) : (
                    "-"
                  )}
                </TableCell>
                {/* <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                  {row.pickupRouteName ? row.pickupRouteName : "-"}
                </TableCell> */}
                <TableCell sx={{ fontSize: "0.8rem", py: 1, px: 3 }}>
                  {row.dropAddress ? (
                    <>
                      {" "}
                      <a
                        href={`https://www.google.com/maps?q=${row?.dropLL}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                        }}
                      >
                        {row.dropAddress}
                      </a>
                      <br />(
                      <span style={{ color: "gray" }}>
                        {row.dropRouteName ? row.dropRouteName : "Not Assigned"}
                      </span>
                      )
                    </>
                  ) : (
                    "-"
                  )}
                </TableCell>
                {/* <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3 }}>
                  {row.dropRouteName ? row.dropRouteName : "-"}
                </TableCell> */}
                <TableCell sx={{ fontSize: "0.8rem", p: 0, px: 3, alignItems:'left' }}>
                  <div
                    style={{
                      color: row.status === "Active" ? "#44B150" : "#D83434",
                      border: `1px solid ${
                        row.status === "Active" ? "#44B150" : "#D83434"
                      }`,
                      borderRadius: "10px",
                      // width: "45%",
                      padding: "0px",
                      textAlign: "center",
                      textTransform: "capitalize",
                      margin: "auto",
                    }}
                  >
                    {row.status}
                  </div>
                </TableCell>
                {localStorage.getItem("userType") !== "AccountManager" && (
                  <TableCell
                    id={row.id}
                    onClick={(e)=>subListClickHandler(e, row)}
                    className="staff-data"
                  >
                    <img
                      src={editImage}
                      alt="edit"
                      title="Click to edit department Details"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={tripImage}
                      alt="trips"
                      title="Click to see trip"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={transferImage}
                      alt="transfer"
                      title="Click to see Transfer staff to another department"
                      sx={{ fontSize: "0.8rem" }}
                    />
                    <img
                      src={disableImage}
                      alt="disable"
                      title="Click to disable staff member"
                      sx={{ fontSize: "0.8rem" }}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={headers.length + 1}>
                No Data Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isStudentDisbaleClicked && (
        <React.Fragment>
          <div className="background"></div>
          <div className="alert_studentDisble">
            <header>
              <span>Alert</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setIsStudentDisableClicked(false)}
              >
                X
              </span>
            </header>
            <hr />
            <br />
            <main>
              <p>You are going to disable the staff {staffName}</p>
            </main>
            <footer>
              <span>Do you want to proceed?</span>
              <div>
                <button onClick={() => setIsStaffDisableClicked(true)}>
                  Yes
                </button>
                <button onClick={() => setIsStudentDisableClicked(false)}>
                  No
                </button>
              </div>
            </footer>
          </div>
        </React.Fragment>
      )}
      {isStaffTransferClicked && (
        <TransferStaff
          setIsResponse={setIsResponse}
          dptName={dptName}
          staffName={staffName}
          staffmobile={staffMoNumber}
          setIsStaffTransferClicked={setIsStaffTransferClicked}
        />
      )}
      {isResponse && (
        <Message
          type={isResponse.message ? isResponse.message : isResponse}
          message={
            isResponse.message
              ? staffName +
                " has been successfully transferred to " +
                isResponse.data
              : "Staff " + staffName + " disabled successfully"
          }
        />
      )}
    </TableContainer>
  );
};

export default Records;
