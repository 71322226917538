import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import QRCode from "react-qr-code";

import DataTable from "./DataTable";
import "./EditDriver.css";
import useHttp from "../../Hooks/use-http";
import { SUPERADMINBYROLEID } from "../../Constant";

// let corporatesData = [];

Array.prototype.includesWithIndex = function (str) {
  for (let i = 0; i < this.length; i++) {
    if (this[i].toLowerCase().includes(str.toLowerCase())) return i;
  }
};
const EditDriver = () => {
  const [filteredCorporates, setFilteredCorporates] = useState([]);
  const [cpPrivateDriverData, setCpPrivateDriverData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const [selectedCp, setSelectedCp] = useState(null);
  const [corporateData, setCorporateData] = useState([]);
  const [driverType, setDriverType] = useState(
    "private"
    // localStorage
    //   .getItem("enabledModule")
    //   .toLowerCase()
    //   .split(",")
    //   .includes("private") || localStorage.getItem("roleId") === "1"
    //   ? "private"
    //   : "shuttle"
  );

  // const corporateNameClickHandler = (e, corporateDetails) => {
  //   let selectedData = corporatesData.filter(
  //     (data) => data?.cpName === corporateDetails
  //   );
  //   if (corporateDetails) {
  //     setSelectedCp(selectedData[0]);
  //     setFilteredCorporates([]);
  //   } else setSelectedCp({});
  //   setIsDataLoading(true);
  //   setIsApiCall(true);
  //   setCpPrivateDriverData([]);
  // };

  const driversData = (data) => {
    setCpPrivateDriverData(data.Driverlist);
    setIsDataLoading(false);
    setIsApiCall(false);
  };

  // const coroprateLists = (data) => {
  //   let tempArr = [];
  //   data?.CorporateList?.forEach((cp, i) => {
  //     tempArr[i] = {};
  //     tempArr[i].cpName = cp.CorporateName;
  //     tempArr[i].cpId = cp.CorporateID;
  //     tempArr[i].adminDptId =
  //       cp.DepartmentID?.split(",")[
  //         cp.DepartmentName.toLowerCase().split(",").includesWithIndex("admin")
  //       ];
  //   });
  //   corporatesData = tempArr;
  // };

  const { isLoading, sendRequest } = useHttp();
  const isSuperAdmin = localStorage.getItem("roleId");
 // Trigger API initially on mount
 useEffect(() => {
  setIsApiCall(true); // Set to true on component mount
}, []);
  useEffect(() => {
    if (isApiCall) {
    const updateUrl =
      driverType === "private"
        ? "/api/v1/DriverList/GetPrivateDriverList"
        : "/api/v1/ShuttleTrips/GetShuttleDriverList";
    sendRequest(
      {
        // url: updateUrl,
        url: "/api/v1/ShuttleTrips/GetAllDriverWithRiderList",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          roleID: localStorage.getItem("roleId"),
          corporateID: selectedCp?.cpId ? selectedCp.cpId : "",
          isDriver: "1",
          isRider: "0",
          typeID: "1",
          // driverType === "all" ? "1" : driverType === "private" ? "2" : "3",
        },
      },
      driversData
    );
    }
  }, [sendRequest, isApiCall, isSuperAdmin]);

  // useEffect(() => {
  //   sendRequest(
  //     {
  //       url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: {
  //         emailID: localStorage.getItem("user"),
  //       },
  //     },
  //     coroprateLists
  //   );
  // }, [sendRequest]);

  return (
    <div
      className="privateDriver-addEdit"
      style={{ margin: "2%", borderRadius: "10px" }}
    >
      <DataTable
        driverType={driverType}
        setDriverType={setDriverType}
        privateDrivers={cpPrivateDriverData ?? []}
        setIsApiCall={setIsApiCall}
        dataLoading={isLoading}
        selectedCorporateDetails={selectedCp}
        setSelectedCorporateDetails={setSelectedCp}
        // corporateNameClickHandler={corporateNameClickHandler}
        filteredCorporates={filteredCorporates}
        setFilteredCorporates={setFilteredCorporates}
        corporateData={corporateData}
        setCorporateData={setCorporateData}
        isApiCall={isApiCall}
      />
    </div>
  );
};

export default EditDriver;
