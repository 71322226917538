import React, { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { CSVLink } from "react-csv";
import { Route, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Tooltip from "@mui/material/Tooltip";

import Records from "./Records";
import useHttp from "../../Hooks/use-http";
import UploadBulkStaffData from "./UploadBulkStaffData";
import CorporateFilter from "../CommonComponent/CorporateFilter";
import {
  Autocomplete,
  Box,
  Modal,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";

const STAFF_TITLE = [
  "Name",
  "Department",
  // "Pickup Address",
  // "Pickup Route",
  "Pickup Address (Route)",

  // "Drop Address",
  // "Drop Route",
  "Drop Address (Route)",
  "Status",
];
const STAFF_TITLE_WITH_CORPORATE = [
  "Name",
  "Corporate Name",
  "Department",
  "Pickup Address (Route)",
  // "Pickup Route",
  "Drop Address (Route)",
  // "Drop Route",
  "Status",
]; 

let myClick = false;
let prev_id = "1";
let staffListFlag = 0;
let staff_details = [];
function Routes() {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [recordsPerPage] = useState(7);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [filteredData, setFilteredData] = useState([]);
  const [isUploadFileClicked, setIsUploadFileClicked] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [corporateData, setCorporateData] = useState([]);
  const [headers, setHeaders] = useState(STAFF_TITLE);
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedCorporateDetails, setSelectedCorporateDetails] = useState({
    cpName: "",
    cpId: "",
  });

  const searchInputRef = useRef();

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("departmentId");

  // console.log("myID", id);

  const authenticateUser = (data) => {
    // console.log(data);
    let staff_data = [];
    if (data.StaffList) {
      for (let i = 0; i < data.StaffList.length; i++) {
        staff_data.push({
          id: i + 1,
          name: data.StaffList[i].OfficialName,
          mobile_no: data.StaffList[i].MobileNumber,
          // superviser_name: data.StaffList[i].SupervisorName,

          
          department: data.StaffList[i].DepartmentName,
          status: data.StaffList[i].Status,
          corporateName: data.StaffList[i].CorporateName,
          pickupAddress: data.StaffList[i].PickupAddress,
          pickupRouteName: data.StaffList[i].PickupRouteName,
          pickupLL: data.StaffList[i].PickupLL,
          dropAddress: data.StaffList[i].DropAddress,
          dropRouteName: data.StaffList[i].DropRouteName,
          dropLL: data.StaffList[i].DropLL,
          corporateId:data.StaffList[i].CorporateId,
        });
      }
    }
    staff_details = staff_data;
    setFilteredData(staff_data);
  };

  useEffect(() => {
    if (localStorage.getItem("roleId") === "1") {
      setHeaders(STAFF_TITLE_WITH_CORPORATE);
    } else {
      setHeaders(STAFF_TITLE);
    }
  }, []);

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    // if (staffListFlag > 0)
    sendRequest(
      {
        url: "/api/v1/Staff/GetStaffList",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          corporateID: id ? id : "",
        },
      },
      authenticateUser
    );
    staffListFlag++;
  }, [sendRequest, id, isRequest]);

  const nPages = Math.ceil(filteredData.length / rowsPerPage);

  let fromRecords = 0;
  if (page === 0) fromRecords = 1;
  else fromRecords = page * rowsPerPage;
  let toRecords = 0;
  if (
    (myClick
      ? page * rowsPerPage - (filteredData.length % rowsPerPage)
      : page * rowsPerPage +
        rowsPerPage -
        (filteredData.length % rowsPerPage)) > filteredData.length
  )
    toRecords = filteredData.length;
  else toRecords = page * rowsPerPage;
  if (toRecords === 0) fromRecords = 0;
  if (page === nPages) toRecords = filteredData.length;

  const routeSearchHandler = (e) => {
    // if (e.target.value)
    setFilteredData(
      staff_details.filter(
        (data) =>
          data.name?.toLowerCase().includes(e.target.value.toLowerCase()) ||
          data.mobile_no
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.superviser_name
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.department
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          data.status?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    // else setFilteredData(TRIP_DATA);
  };
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleCloseAddTaskModal = () => {
    setModalOpen(false);
    // setSelectedCorporate([]);
  };

  const handleCorporateChange = (event, newValue) => {
    if (newValue) {
      const selectedData = corporateData.filter(
        (data) => data?.cpName === newValue
      );
      setSelectedCorporateDetails(selectedData[0] || { cpName: "", cpId: "" });
    } else {
      setSelectedCorporateDetails({ cpName: "", cpId: "" });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate pagination
  const indexOfLastRecord = page * rowsPerPage + rowsPerPage;
  const indexOfFirstRecord = page * rowsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  return (
    <>
      <UploadBulkStaffData
        setIsRequest={setIsRequest}
        isUploadFileClicked={isUploadFileClicked}
        setIsUploadFileClicked={setIsUploadFileClicked}
      />
      <div className="trips-details" id="trip-table">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "inline-block" }} className="title">
            Staff Members
          </div>
          {localStorage.getItem("userType") !== "AccountManager" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                margin: "10px 20px",
              }}
            >
              {/* <Button
                size="small"
                onClick={() =>
                  window.open(
                    `https://students.little.global/?name=${localStorage.getItem(
                      "cpName"
                    )}&corpId=${localStorage.getItem("corpId")}`
                  )
                }
                variant="contained"
              >
                
                  Add Staff
              </Button> */}
              <Button
                size="small"
                onClick={() => {
                  if (localStorage.getItem("roleId") == "1") {
                    handleOpenModal();
                  } else {
                    window.open(
                      `https://students.little.global/?name=${localStorage.getItem(
                        "cpName"
                      )}&corpId=${localStorage.getItem("corpId")}`
                    );
                  }
                }}
                variant="contained"
              >
                Add Staff
              </Button>
              <Tooltip title="Bulk Upload" arrow>
                <UploadFileIcon
                  onClick={() => setIsUploadFileClicked(true)}
                  sx={{ cursor: "pointer" }}
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="table-container-routes">
          <div className="header">
            <div style={{ display: "flex", gap: "10px", alignItems: "end" }}>
              {localStorage.getItem("roleId") == "1" && (
                <CorporateFilter
                  selectedCorporate={selectedCorporateDetails}
                  setSelectedCorporate={setSelectedCorporateDetails}
                  corporateData={corporateData}
                  setCorporateData={setCorporateData}
                  mainData={staff_details}
                  setFilteredData={setFilteredData}
                />
              )}

              <div onChange={routeSearchHandler} className="route-search">
                <input placeholder="Search" type="text" ref={searchInputRef} />
              </div>
              <CSVLink
                data={staff_details}
                className="export_csv"
                filename={"data.csv"}
              >
                Export
              </CSVLink>
            </div>
          </div>
          <Records
            data={currentRecords}
            headers={headers}
            isLoading={isLoading}
          />
          <div>
            <CommonTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              filteredData={filteredData}
              styling={{ display: "flex", justifyContent: "flex-end" }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            overflowX: "hidden",
            transform: "translate(-50%, -50%)",
            width: "30%",
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            // bgcolor: "rgba(34, 137, 203, 255)",
            // border: "2px solid",
            zIndex: "1500",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(34, 137, 203, 255)",
              color: "white",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "-24px",
              flexDirection: "row",
            }}
          >
            <Typography variant="h7" component="div">
              SELECT CORPORATE
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Button
              // variant="contained"
              // color="white"
              onClick={handleCloseAddTaskModal}
              sx={{ fontSize: "20px", color: "white" }}
            >
              x
            </Button>
            {/* <IconButton  onClick={handleCloseAddTaskModal}>
              <Cross style={{ width: 50, height: 50 }} />
              x
            </IconButton> */}
          </Box>
          {/* <h2 id="modal-title">Select Corporate</h2> */}
          <div style={{ marginTop: "10%" }}>
            <Autocomplete
              options={corporateData.map((data) => data.cpName)}
              // sx={{ width: 200 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Corporate"
                  variant="standard"
                  placeholder="Search Corporate Name"
                />
              )}
              onChange={handleCorporateChange}
              value={selectedCorporateDetails.cpName}
            />
            <Button
              onClick={() => {
                const url = `https://students.little.global/?name=${selectedCorporateDetails.cpName}&corpId=${selectedCorporateDetails.cpId}`;
                // window.location.href = url;
                window.open(url, "_blank");
                setModalOpen(false);
              }}
              disabled={
                !selectedCorporateDetails.cpName ||
                !selectedCorporateDetails.cpId
              }
              variant="contained"
              sx={{ mt: 2 }}
            >
              Add Staff
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Routes;
