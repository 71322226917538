import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import useHttp from "../../Hooks/use-http";

let autocomplete;
const COUNTRY = ["India"];
const Enabled_modules = ["Private", "Shuttle", "Schedule"];
const Shuttle_types = ["Student", "Employee"];
const CorporateDetails = ({
  isNextClicked,
  setActiveStep,
  setIsNextClicked,
  setAllData,
  allData,
}) => {
  const [isError, setIsError] = useState();
  const [salesList, setSalesList] = useState([]);
  const [opeationsList, setOperationsList] = useState([]);
  const [formData, setFormData] = useState({
    country: "",
    cpName: "",
    cpLogo: "",
    cpLogoName: "",
    accountManager: "",
    salesExecutive: "",
    cpAddress: "",
    cpLatLng: "",
    creditPeriod: "",
    contractStartDate: "",
    contractEndDate: "",
    enabledModules: [],
    shuttleType: [],
  });
  const cpLogoInputRef = useRef();
  const cpAddressInputRef = useRef(); 


  const staffDataResponse = (data) => {
    // console.log(data.StaffList);
    setSalesList(
      data.StaffList.filter(
        (val) => val.DepartmentName.toLowerCase() === "sales"
      )
    );
    setOperationsList(
      data.StaffList.filter(
        (val) => val.DepartmentName.toLowerCase() === "operations"
      )
    );
  };

  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Staff/GetLittleStaffDetails",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
        },
      },
      staffDataResponse
    );
  }, [sendRequest]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isNextClicked) {
      Object.keys(formData).map((val, i) => {
        if (!String(formData[val])) {
          setIsError(true);
          setIsNextClicked(false);
          return;
        } else if (i === Object.keys(formData).length - 2) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setAllData((prev) => ({ ...prev, cpDetails: formData }));
        }
      });
      setIsNextClicked(false);
    }

    // return () => setAllData(prev => ({ ...prev, cpDetails: formData }));
  }, [isNextClicked]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDHdkmGjsfNqasFs6m9CooShFZsqWHcdUs&callback=initMap&libraries=places&v=weekly";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  function initMap() {
    autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementsByClassName("pacInput")[0],
      {
        componentRestrictions: { country: ["in"] },
      }
    );
  }
  window.initMap = initMap;

  const cpLogoChangeHandler = (e) => {
    if (["image/jpeg", "image/png"].includes(e.target.files[0].type)) {
      getBase64(e.target.files[0]).then((data) => {
        setFormData((prev) => ({
          ...prev,
          cpLogoName: e.target.files[0].name,
          cpLogo: data,
        }));
      });
    } else console.log("Invalid file");
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const cpAddressInputBlurHandler = (e) => {
    if (e.target.value) {
      setTimeout(() => {
        setFormData((prev) => ({
          ...prev,
          cpAddress: e.target.value,
          cpLatLng:
            autocomplete.getPlace()?.geometry?.location?.lat() +
            "," +
            autocomplete.getPlace()?.geometry?.location?.lng(),
        }));
      }, 2000);
    }
  };

  return (
    <>
      <FormControl
        variant="standard"
        sx={{ m: 1, width: 250 }}
        error={isError && !formData.country}
      >
        <InputLabel id="country-select-standard-label">Country</InputLabel>
        <Select
          labelId="country-select-standard-label"
          id="country-select-standard"
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          label="Country"
        >
          {COUNTRY.map((val) => (
            <MenuItem value={val}>{val}</MenuItem>
          ))}
        </Select>
        {isError && !formData.country && (
          <FormHelperText>Invalid Data</FormHelperText>
        )}
      </FormControl>
      <TextField
        id="standard-basic"
        name="cpName"
        ref={cpAddressInputRef}
        onChange={handleInputChange}
        value={formData.cpName}
        label="Name"
        error={isError && !formData.cpName}
        helperText={isError && !formData.cpName && "Invalid Data"}
        variant="standard"
        sx={{ width: "250px" }}
      />
      <TextField
        id="standard-basic"
        name="cpLogoName"
        value={formData.cpLogoName}
        label="Logo"
        variant="standard"
        error={isError && !formData.cpLogoName}
        helperText={isError && !formData.cpLogoName && "Invalid Data"}
        sx={{ width: "250px" }}
        onClick={() => cpLogoInputRef.current.click()}
      />
      <input
        type="file"
        ref={cpLogoInputRef}
        onChange={cpLogoChangeHandler}
        style={{ display: "none" }}
      />
      <FormControl
        variant="standard"
        sx={{ m: 1, width: 250 }}
        error={isError && !formData.accountManager}
      >
        <InputLabel id="account-manager-select-standard-label">
          Account Manager
        </InputLabel>
        <Select
          labelId="account-manager-select-standard-label"
          id="account-manager-select-standard"
          value={formData.accountManager}
          name="accountManager"
          onChange={handleInputChange}
          label="Account Manager"
        >
          {opeationsList.map((val) => (
            <MenuItem value={val.EmailID}>{val.EmpName}</MenuItem>
          ))}
        </Select>
        {isError && !formData.accountManager && (
          <FormHelperText>Invalid Data</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ m: 1, width: 250 }}
        error={isError && !formData.salesExecutive}
      >
        <InputLabel id="demo-simple-select-standard-label">
          Sales Executive
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="salesExecutive"
          onChange={handleInputChange}
          value={formData.salesExecutive}
          label="Country"
        >
          {salesList.map((val) => (
            <MenuItem value={val.EmailID}>{val.EmpName}</MenuItem>
          ))}
        </Select>
        {isError && !formData.salesExecutive && (
          <FormHelperText>Invalid Data</FormHelperText>
        )}
      </FormControl>
      <TextField
        id="standard-basic"
        name="cpAddress"
        onBlur={cpAddressInputBlurHandler}
        defaultValue={formData.cpAddress}
        error={isError && !formData.cpAddress}
        helperText={isError && !formData.cpAddress && "Invalid Data"}
        inputProps={{ className: "pacInput" }}
        sx={{ width: "250px" }}
        label="Address"
        variant="standard"
      />
      <TextField
        id="standard-basic"
        label="Credit Period"
        name="creditPeriod"
        onChange={handleInputChange}
        error={isError && !formData.creditPeriod}
        helperText={isError && !formData.creditPeriod && "Invalid Data"}
        value={formData.creditPeriod}
        sx={{ width: "250px" }}
        variant="standard"
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{
            textField: {
              variant: "standard",
              error: isError && !formData.contractStartDate,
              helperText:
                isError && !formData.contractStartDate && "Invalid Data",
            },
          }}
          label="Contract Start Date"
          value={formData.contractStartDate || null}
          sx={{ width: "250px" }}
          name="creditPeriod"
          onChange={(newData) =>
            setFormData((prev) => ({
              ...prev,
              contractStartDate: newData && dayjs(newData),
            }))
          }
          error={true}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          slotProps={{
            textField: {
              variant: "standard",
              error: isError && !formData.contractEndDate,
              helperText:
                isError && !formData.contractEndDate && "Invalid Data",
            },
          }}
          label="Contract End Date"
          value={formData.contractEndDate || null}
          sx={{ width: "250px" }}
          name="contractEndDate"
          onChange={(newData) =>
            setFormData((prev) => ({
              ...prev,
              contractEndDate: newData && dayjs(newData),
            }))
          }
        />
      </LocalizationProvider>
      <FormControl
        variant="standard"
        sx={{ m: 1, width: 250 }}
        error={isError && !String(formData.enabledModules)}
      >
        <InputLabel id="demo-simple-select-standard-label">
          Enabled Modules
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          multiple
          value={Array.from(formData.enabledModules ?? "")}
          onChange={(e) => {
            const value = e.target.value;
            setFormData((prev) => ({
              ...prev,
              enabledModules:
                typeof value === "string" ? value.split(",") : value,
            }));
          }}
          renderValue={(selected) => selected?.join(", ")}
          label="Enabled Modules"
        >
          {Enabled_modules.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={formData.enabledModules?.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {isError && !formData.contractStartDate && (
          <FormHelperText>Invalid Data</FormHelperText>
        )}
      </FormControl>
      {formData.enabledModules?.includes("Shuttle") && (
        <FormControl variant="standard" sx={{ m: 1, width: 250 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Shuttle for
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            multiple
            value={Array.from(formData.shuttleType ?? "")}
            onChange={(e) => {
              const value = e.target.value;
              setFormData((prev) => ({
                ...prev,
                shuttleType:
                  typeof value === "string" ? value.split(",") : value,
              }));
            }}
            renderValue={(selected) => selected?.join(", ")}
            label="Shuttle for"
          >
            {Shuttle_types.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={formData.shuttleType?.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default CorporateDetails;
