import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Timesign } from "../../../Assets/time.svg";
import { BiRightArrow } from "react-icons/bi";
import "../../CustomCssFile.css";
import DeleteIcon from "@mui/icons-material/Delete";

const TIMINGS = {
  monday: "",
  tuesday: "",
  wednesday: "",
  thursday: "",
  friday: "",
  saturday: "",
  sunday: "",
};

let dayName = "";
const TestTimingsInfo = (props) => {
  const timeInputRef = useRef();
  const [isDayTimeChange, setIsDayTimeChange] = useState({ dayname: "" });
  const [selectedDay, setSelectedDay] = useState("");
  const [isTimingChange, setIsTimingChange] = useState();
  const [isNextClicked, setIsNextClicked] = useState();
  const [isError, setIsError] = useState("");
  const timeInputRefs = useRef({});
  const history = useHistory();
  useEffect(() => {
    if (props.defaultShuttleTimings) {
      let obj = {
        1: "sunday",
        2: "monday",
        3: "tuesday",
        4: "wednesday",
        5: "thursday",
        6: "friday",
        7: "saturday",
      };
      for (let i = 0; i < props.defaultShuttleTimings.length; i++) {
        TIMINGS[obj[props.defaultShuttleTimings[i].WeekDay]] =
          props.defaultShuttleTimings[i]?.StartTime?.split("T")[1];
      }
      // console.log(TIMINGS);
      setIsTimingChange((prev) => !prev);
    }
  }, [props.defaultShuttleTimings]);

  const nextClickHandler = () => {
    if (
      TIMINGS.monday ||
      TIMINGS.tuesday ||
      TIMINGS.wednesday ||
      TIMINGS.thursday ||
      TIMINGS.friday ||
      TIMINGS.saturday ||
      TIMINGS.sunday
    ) {
      sessionStorage.setItem("routeValue", "0");
      // props.nextWizard("StopInfo");
      // history.push("/addroute-stop");
      props.setShowStopInfo(true);
      setIsNextClicked(true);
      setIsError("");
    } else {
      setIsError("Please set the timing for atleast one day");
      if (
        !sessionStorage.getItem("routeName") ||
        sessionStorage.getItem("routeName") === ""
      ) {
        props.setIsError((prev) => ({
          ...prev,
          routeName: "Route Name is required",
        }));
      }
    }
    props.setIsNextClicked(true)
  };
  const backClickHandler = () => {
    props.backClickHandler("TimingInfo");
    props.setIsNextClicked(false);
  };

  const timeChangeHandler = (day) => {
    TIMINGS[day] = timeInputRefs.current[day].value;
    setIsTimingChange((prev) => !prev);
    setIsError("");
  };

  sessionStorage.setItem(
    "timings",
    JSON.stringify({
      monday: TIMINGS.monday,
      tuesday: TIMINGS.tuesday,
      wednesday: TIMINGS.wednesday,
      thursday: TIMINGS.thursday,
      friday: TIMINGS.friday,
      saturday: TIMINGS.saturday,
      sunday: TIMINGS.sunday,
    })
  );

  const dayTimeChangeHandler = (dayName) => {
    TIMINGS[dayName.toLowerCase()] = timeInputRef.current.value;
  };

  const dayButtonClickHandler = (e) => {
    // dayName = e.target.innerText;
    // setIsDayTimeChange((prev) => ({ ...prev, dayname: dayName }));

    setSelectedDay(e);
  };
  // const disableDayClickHandler = () => {
  //   TIMINGS[dayName.toLowerCase()] = "";
  //   setIsTimingChange((prev) => !prev);
  // };

  const disableDayClickHandler = (day) => {
    TIMINGS[day] = "";
    setIsTimingChange((prev) => !prev);
  };

  return (
    <>
      {props.showStopInfo === false && (
        <React.Fragment>
          {!isNextClicked && (
            <div className="timingsInfo-container">
              {isError && <p className="error">{isError}</p>}

              <div
                style={{
                  width: "58%",
                  display: "grid",
                  gridTemplateColumns: "repeat(2,1fr)",
                  gap: "30px",
                }}
              >
                {/* RouteType Select Field */}

                <div style={{ marginTop: "10%" }}>
                  <FormControl fullWidth>
                    <InputLabel>Route Type</InputLabel>
                    <Select
                      label="Route Type"
                      value={props?.routeType}
                      onChange={props?.handleRouteTypeChange}
                      onBlur={props?.routeTypeChangeHandler}
                      inputRef={props?.routeTypeProps}
                      variant="standard"
                    >
                      <MenuItem value="Route Type" disabled>
                        Route Type
                      </MenuItem>
                      <MenuItem value="Pickup">Pickup</MenuItem>
                      <MenuItem value="Drop">Drop</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TextField
                  label={`${
                    props.routeType == null || undefined ? "" : props.routeType
                  } Time`}
                  type="time"
                  variant="standard"
                  fullWidth
                  // inputRef={timeInputRef}
                  inputRef={(el) => (timeInputRefs.current["all"] = el)}
                  // onChange={timeChangeHandler}
                  onChange={() => {
                    Object.keys(TIMINGS).forEach((day) => {
                      TIMINGS[day] = timeInputRefs.current["all"].value;
                    });
                    setIsTimingChange((prev) => !prev);
                    setIsError("");
                  }}
                  error={!!isError.routeType}
                  helperText={isError.routeType}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.54)",
                      transform: "scale(0.75)",
                      transformOrigin: "top left",
                      position: "absolute",
                      top: "8px",
                      left: "14px",
                    },
                  }}
                  inputProps={{
                    defaultValue: "",
                    style: {
                      border: "none",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                      borderRadius: "0",
                      backgroundColor: "transparent",
                      fontSize: "16px",
                      lineHeight: "1.4375em",
                      // padding: "6px 0",
                      width: "calc(100% - 28px)", // Account for label margins
                      outline: "none",
                      transition:
                        "border-bottom-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                      marginTop: "20px", // To make space for the label
                      paddingLeft: "14px", // Align with the label
                    },
                  }}
                  sx={{ mb: 2 }}
                />
              </div>
              {/* <br /> */}
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridColumnGap: "25px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  // width:'55%'
                }}
              >
                {Object.keys(TIMINGS)?.map((day) => (
                  <>
                    <div
                      key={day}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        onClick={() => dayButtonClickHandler(day)}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "190px",
                          border: "1px solid gray",
                          padding: "4px 8px",
                          borderRadius: "5px",
                        }}
                      >
                        <span
                          className="font-medium"
                          style={{ textTransform: "capitalize" }}
                        >
                          {day}
                        </span>
                        <span
                          style={{
                            marginLeft: "auto",
                            // borderLeft: "1px solid gray",
                            height: "20px",
                          }}
                        ></span>
                        {selectedDay === day ? (
                          <>
                            <TextField
                              type="time"
                              inputRef={(el) =>
                                (timeInputRefs.current[day] = el)
                              }
                              value={TIMINGS[day] || ""}
                              onChange={() => timeChangeHandler(day)}
                              variant="standard"
                              inputProps={{
                                style: {
                                  fontSize: "14px",
                                  // marginLeft: "5px",
                                  // marginRight: "-5px",
                                },
                                step: 1,
                                shrink: true,
                              }} // font size of input text
                              InputLabelProps={{
                                style: { fontSize: "14px", shrink: true },
                              }} // font size of input label
                              sx={{
                                // width: "auto",
                                minWidth: "80px",
                                padding: 0,
                                "& .MuiInput-input": { padding: 0 },
                                "& .MuiInput-underline:before": {
                                  borderBottom: "none",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottom: "none",
                                },
                                "& input[type='time']::-webkit-datetime-edit, & input[type='time']::-webkit-clear-button, & input[type='time']::-webkit-inner-spin-button, & input[type='time']::-webkit-calendar-picker-indicator":
                                  {
                                    margin: 0,
                                    padding: 0,
                                    // fontSize: "14px",
                                  },
                              }}
                            />
                          </>
                        ) : TIMINGS[day] ? (
                          <span
                            className="font-medium"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "10px",
                            }}
                          >
                            {TIMINGS[day]}{" "}
                            <Timesign style={{ marginLeft: "5px" }} />
                          </span>
                        ) : (
                          <span
                            className="no-time"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "10px",
                            }}
                          >
                            --:-- <Timesign />
                          </span>
                        )}
                      </div>
                      <div
                        style={{
                          width: "20px", // Fixed width for the delete icon container
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {selectedDay === day && (
                          // <span
                          //   onClick={() => disableDayClickHandler(day)}
                          //   style={{
                          //     color: "red",
                          //     cursor: "pointer",
                          //     // marginLeft: "200px",
                          //     fontSize: "25px",
                          //   }}
                          // >
                          //   &times;
                          // </span>

                          <DeleteIcon
                            style={{
                              cursor: "pointer",
                              transition: "color 0.3s",
                              color:
                                selectedDay === day ? "inherit" : "transparent",
                              fontSize: "20px",
                            }}
                            onMouseOver={(e) => (e.target.style.color = "red")}
                            onMouseOut={(e) =>
                              (e.target.style.color = "inherit")
                            }
                            onClick={() => disableDayClickHandler(day)}
                          />
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <>
                <div>
                  <button
                    style={{
                      backgroundColor: "rgba(34, 137, 203, 255)",
                      width: "80px",
                      color: "white",
                      border: "1px solid rgba(34, 137, 203, 255)",
                      padding: "0px 15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      marginTop:'3%',
                      marginLeft:'50%'

                    }}
                    onClick={nextClickHandler}
                  >
                    NEXT
                  </button>
                </div>
              </>
            </div>
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default TestTimingsInfo;
