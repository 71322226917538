import { IconButton, Tooltip } from '@mui/material'
import React from 'react'
import InfoIcon from "@mui/icons-material/Info";


const CustomInfoIcon = ({message}) => {
  return (
    <>
       <Tooltip
            title={message}
            placement="top"
            style={{ marginTop: "auto" }}
          >
            <IconButton sx={{ p: 0.5 }}>
              <InfoIcon fontSize="small" style={{ width: "0.8em" }} />
            </IconButton>
          </Tooltip>
    </>
  )
}

export default CustomInfoIcon
