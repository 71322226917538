import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  IconButton,
  Button,
  TablePagination,
  Menu,
  MenuItem,
  Box,
  Modal,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import DefaultImage from "../../Assets/default_image.jpg";
import useHttp from "../../Hooks/use-http";
import { useHistory } from "react-router-dom";
import Loading from "../../Loading/Loading";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import Config from "../Settings/Config";
import SearchFilter from "../CommonComponent/SearchFilter";
import CustomInfoIcon from "../CommonComponent/CustomInfoIcon";
import CreateAdminModal from "./CreateAdminModal";
import SettingsIcon from "@mui/icons-material/Settings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";

const headers = [
  "Corporate",
  "Department",
  "Address",
  "Contract Date",
  "Managers",
  "Enable Modules",
  //   "Admin",
  "Action",
];
const headerWidths = {
  Corporate: "15%",
  Department: "20%",
  Address: "20%",
  "Contract Date": "10%",
  "Account Manager": "15%",
  "Enable Modules": "13%",
  // "Admin": "6%",
  Action: "7%",
};

// Helper function for stable sorting
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  const valueA = getOrderValue(a, orderBy);
  const valueB = getOrderValue(b, orderBy);

  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

function ascendingComparator(a, b, orderBy) {
  const valueA = getOrderValue(a, orderBy);
  const valueB = getOrderValue(b, orderBy);

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
}

function getOrderValue(item, orderBy) {
  switch (orderBy) {
    case "Corporate":
      return `${item.Name} ${item.CorporateLogo} ${item.CorporateID}`;
    // Add cases for other columns if needed
    case "Contract Date":
      return `${item.ContractStartDate} ${item.ContractEndDate}`;

    case "Managers":
      return `${item.AccountManagerID} ${item.SalesExecutive} ${item.InvoiceContactEmail} ${item.InvoiceContactName}`;
    default:
      return item[orderBy] || ""; // Default to empty string
  }
}

const CorporateRecords = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Corporate ID");
  const [corporateData, setCorporateData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const { sendRequest, isLoading } = useHttp();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [openConfigModal, setConfigModal] = useState(false);
  const [search, setSearch] = useState("");
  const [isCreateAdmin, setIsCreateAdmin] = useState(false);

  useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Corporate/GetCorporates", // Updated URL
        method: "POST", // Updated method
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
          roleID: localStorage.getItem("roleID"), // Assuming roleID is needed
          corporateID: localStorage.getItem("corporateID"), // Assuming corporateID is needed
        },
      },
      corporateList // Assuming setCorporateData will update the corporate data state
    );
    // setLoading(false)
  }, [sendRequest]);

  const corporateList = (data) => {
    setCorporateData(data?.CorporateList || []);
    // setLoading(false);
  };
  const handleSort = (column) => {
    if (orderBy === column && orderBy !== "" && order === "asc") {
      setOrder("desc");
    } else {
      setOrder("asc");
    }
    setOrderBy(column);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const sortedData = stableSort(
  //   corporateData,
  //   order === "asc"
  //     ? (a, b) => ascendingComparator(a, b, orderBy)
  //     : (a, b) => descendingComparator(a, b, orderBy)
  // );

  // const paginatedData = sortedData.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );
  // setFilteredData(paginatedData);
  useEffect(() => {
    const sortedData = stableSort(
      corporateData,
      order === "asc"
        ? (a, b) => ascendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, orderBy)
    );

    const paginatedData = sortedData.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    setFilteredData(paginatedData);
  }, [corporateData, order, orderBy, page, rowsPerPage]);

  const handleMenuClick = (event, corporate) => {
    setAnchorEl(event.currentTarget);
    setSelectedCorporate(corporate);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedCorporate(null);
  };
  const handleOpenAddTaskModal = () => {
    setConfigModal(true);
  };

  const handleCloseAddTaskModal = () => {
    setConfigModal(false);
    // setSelectedCorporate([]);
  };

  const handleOpenCreateAdminClick = () => {
    setIsCreateAdmin(true);
  };
  const handleCloseCreateAdminClick = () => {
    setIsCreateAdmin(false);
  };

  const handleConfigClick = () => {
    // history.push({
    //   pathname: "/settings",
    //   state: { data: selectedCorporate },
    // });
    handleOpenAddTaskModal();
    handleMenuClose();
  };
  const handleAddPricingClick = () => {
    history.push({
      pathname: "/addpricing",
      state: { data: selectedCorporate },
    });
  };
  const handleCreateAdminClick = () => {
    handleOpenCreateAdminClick();
    handleMenuClose();
  };

  const handleSettingClick = () => {
    history.push({
      pathname: "/settings",
      state: { data: selectedCorporate },
    });
  };

  const renderAdmins = (admins) => {
    if (!admins) return "-";
    const adminList = admins.split(", ");
    if (adminList.length === 1) return adminList[0];

    const firstAdmin = adminList[0];
    const otherAdmins = adminList;

    return (
      <div>
        {firstAdmin}
        <CustomInfoIcon
          message={
            <div style={{ paddingLeft: "16px" }}>
              <ol>
                {otherAdmins.map((admin, index) => (
                  <li key={index}>{admin}</li>
                ))}
              </ol>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div
        className="title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          margin: "1% 0%",
        }}
      >
        <strong style={{ marginLeft: "2%" }}>
          <h3>Corporates</h3>
        </strong>
        <div style={{ display: "flex", gap: "20px", marginRight: "16px" }}>
          <SearchFilter
            data={corporateData}
            setSearchValue={setSearch}
            searchValue={search}
            // filteredData={filteredData}
            setFilteredData={setFilteredData}
          />
          <Button
            onClick={() => history.push("/new-registration")}
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              marginRight: "10px",
              fontFamily: "Montserrat",
            }}
          >
            Add Corporate
          </Button>
        </div>
      </div>
      {/* <Backdrop
        sx={{
          color: "rgba(34, 137, 203, 255)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}

      <TableContainer
        component={Paper}
        style={{ width: "96%", margin: "auto", borderRadius: "15px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {headers?.map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    py: 0,
                    px: 1,
                    fontWeight: "bold",
                    color: "rgba(0, 0, 0, 0.87)",
                    width: headerWidths[header],
                  }}
                >
                  <TableSortLabel
                    active={true}
                    direction={orderBy === header ? order : "asc"}
                    onClick={() => handleSort(header)}
                    sx={{ fontSize: "0.8rem" }}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headers?.length + 1}>
                  <Loading datatable="true" />
                </TableCell>
              </TableRow>
            ) : filteredData?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers?.length + 1}>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    No Data Available
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              filteredData?.map((item, index) => (
                <React.Fragment key={index}>
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <img
                            src={
                              item.CorporateLogo
                                ? item.CorporateLogo
                                : DefaultImage
                            }
                            alt="Admin"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </div>

                        <div>
                          {item.Name} <br />
                          <span style={{ fontSize: "10px" }}>
                            {" "}
                            ({item.CorporateID ? item.CorporateID : "-"}){" "}
                          </span>
                        </div>
                      </div>
                    </TableCell>

                    {/* <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      
 
                      {item.Departments?.map((dep, index) => (
                        <div key={dep.CorporateID || Math.random()}>
                          <div style={{ display: "flex", gap: "2px" }}>
                            <div>{index + 1}.</div>
                            <div>
                              <div>{dep.Name ? dep.Name : "-"}</div>
                              <div style={{ fontSize: "10px" }}>
                                ({dep.CorporateID ? dep.CorporateID : "-"})
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TableCell> */}

                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", gap: "2px" }}>
                          <div>{1}.</div>
                          <div>
                            <div>{item.Departments[0].Name}</div>
                            <div style={{ fontSize: "10px" }}>
                              ({item.Departments[0].CorporateID})
                            </div>
                          </div>
                        </div>
                        {item.Departments.length > 1 && (
                          <CustomInfoIcon
                            style={{ margin: "auto" }}
                            message={item.Departments?.map((dep, index) => (
                              <div key={dep.CorporateID || Math.random()}>
                                <div style={{ display: "flex", gap: "2px" }}>
                                  <div>{index + 1}.</div>
                                  <div>
                                    <div>{dep.Name ? dep.Name : "-"}</div>
                                    <div style={{ fontSize: "10px" }}>
                                      ({dep.CorporateID ? dep.CorporateID : "-"}
                                      )
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      {item?.Address && item?.CorporateLatlong ? (
                        <a
                          href={`https://www.google.com/maps?q=${item.CorporateLatlong}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "blue",
                          }}
                        >
                          {item?.Address}
                        </a>
                      ) : (
                        item?.Address || "-"
                      )}
                    </TableCell>

                    {/* <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                        
                      {item.Address ? item.Address : "-"}
                    </TableCell> */}
                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      <div style={{ alignItems: "center", marginLeft: "8px" }}>
                        {item.ContractStartDate ? item.ContractStartDate : "-"}
                      </div>
                      <div style={{ alignItems: "center", marginLeft: "8px" }}>
                        to
                      </div>
                      <div style={{ alignItems: "center", marginLeft: "8px" }}>
                        {item.ContractEndDate ? item.ContractEndDate : "-"}
                      </div>
                      {/* {" "}to {" "}  */}
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      <strong>Account:</strong>{" "}
                      {item.AccountManagerID ? item.AccountManagerID : "-"}
                      <br />
                      <strong>Sales:</strong>{" "}
                      {item.SalesExecutive ? item.SalesExecutive : "-"} <br />
                      <div>
                        <strong>Admin:</strong>{" "}
                        {item.Admins ? renderAdmins(item.Admins) : "-"}
                        <br />
                      </div>
                    </TableCell>
                    <TableCell sx={{ fontSize: "0.8rem", p: 1 }}>
                      {item.EnabledModule ? item.EnabledModule : "-"}
                    </TableCell>

                    {/* <TableCell>
                      <Tooltip title="Edit">
                        <IconButton
                          //  onClick={handleEdit}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          // onClick={handleDelete}
                          color="secondary"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell> */}
                    <TableCell>
                      <IconButton
                        onClick={(event) => handleMenuClick(event, item)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        sx={{
                          "& .MuiPaper-root": {
                            boxShadow: "none",
                            borderRadius: "10px",
                            border: "1px solid #ccc",
                          },
                        }}
                      >
                        {/* <MenuItem onClick={handleConfigClick}>
                        
                          Configuration
                        </MenuItem> */}
                        <MenuItem onClick={handleConfigClick}>
                          <ListItemIcon>
                            <SettingsIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Configuration</ListItemText>
                        </MenuItem>
                        {/* <MenuItem onClick={handleAddPricingClick}>
                          Pricing Structure
                        </MenuItem> */}
                        <MenuItem onClick={handleAddPricingClick}>
                          <ListItemIcon>
                            <AttachMoneyIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Pricing Structure</ListItemText>
                        </MenuItem>
                        {/* <MenuItem onClick={handleSettingClick}>Tasks</MenuItem> */}
                        <MenuItem onClick={handleSettingClick}>
                          <ListItemIcon>
                            <AssignmentIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Tasks</ListItemText>
                        </MenuItem>
                        {/* <MenuItem onClick={handleCreateAdminClick}>
                          Create Admin
                        </MenuItem> */}
                        <MenuItem onClick={handleCreateAdminClick}>
                          <ListItemIcon>
                            <PersonAddIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Create Admin</ListItemText>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Configuration----------- */}
      <Modal
        open={openConfigModal}
        // onClose={handleCloseAddTaskModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseAddTaskModal();
          }
        }}
        aria-labelledby="add-new-task-modal"
        aria-describedby="add-new-task-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            overflowX: "hidden",
            transform: "translate(-50%, -50%)",
            width: "70%",
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            // bgcolor: "rgba(34, 137, 203, 255)",
            // border: "2px solid",
            zIndex: "1500",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Button
              // variant="contained"
              // color="white"
              onClick={handleCloseAddTaskModal}
              sx={{ fontSize: "20px", color: "white" }}
            >
              x
            </Button>
            {/* <IconButton  onClick={handleCloseAddTaskModal}>
              <Cross style={{ width: 50, height: 50 }} />
              x
            </IconButton> */}
          </Box>
          <Config
            corporateData={selectedCorporate && selectedCorporate}
            setCorporateData={setCorporateData}
            // getTaskList={getTaskList}
            onCloseModal={handleCloseAddTaskModal}
          />
        </Box>
      </Modal>

      {/* Create Admin------------- */}
      <Modal
        open={isCreateAdmin}
        // onClose={handleCloseAddTaskModal}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseCreateAdminClick();
          }
        }}
        aria-labelledby="add-new-task-modal"
        aria-describedby="add-new-task-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            overflowX: "hidden",
            transform: "translate(-50%, -50%)",
            width: "60%",
            maxHeight: "95vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            // bgcolor: "rgba(34, 137, 203, 255)",
            // border: "2px solid",
            zIndex: "1500",
            boxShadow: 24,
            borderRadius: 4,
            p: 3,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <Button
              // variant="contained"
              // color="white"
              onClick={handleCloseCreateAdminClick}
              sx={{ fontSize: "20px", color: "white" }}
            >
              x
            </Button>
            {/* <IconButton  onClick={handleCloseAddTaskModal}>
              <Cross style={{ width: 50, height: 50 }} />
              x
            </IconButton> */}
          </Box>
          <CreateAdminModal
            corporateData={selectedCorporate && selectedCorporate}
            setCorporateData={setCorporateData}
            // getTaskList={getTaskList}
            onCloseModal={handleCloseCreateAdminClick}
          />
        </Box>
      </Modal>
      {/* <TablePagination
        rowsPerPageOptions={[7, 15, 20]}
        component="div"
        count={corporateData?.length ?? "1"}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <CommonTablePagination
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        filteredData={corporateData}
        styling={{ display: "flex", justifyContent: "flex-end" }}
      />
    </React.Fragment>
  );
};

export default CorporateRecords;
