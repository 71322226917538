import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import Autocomplete from "@mui/material/Autocomplete";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { visuallyHidden } from "@mui/utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelIcon from "@mui/icons-material/Cancel";
import { FileCopy as FileCopyIcon } from "@mui/icons-material";

import useHttp from "../../Hooks/use-http";
import RidersData from "./RidersData";
import "./DataTable.css";
import Modal from "../../GeneratePDF/Modal";

import ExtraKm from "./ExtraKm";

import HeaderFilter from "../CommonComponent/HeaderFilter";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";
import { useHistory } from "react-router-dom";
import TripDetails from "./TripDetails";

let selectedDriverEmailId = "";
let changedDateTime = {
  date: "",
  time: "",
};
const RiderName = "VehicaleModel";
let dateTimeChangeFlag = 0;
let routeId = "";
let lineId = "";

function descendingComparator(a, b, orderBy) {
  const extractNumericValue = (durationString) => {
    if (typeof durationString !== "string") {
      return 0; // Return a default value or handle the case where durationString is not a string
    }

    const numericString = durationString.replace(/[^\d.-]/g, ""); // Remove non-numeric characters
    return parseFloat(numericString);
  };

  const aValue = extractNumericValue(a[orderBy]);
  const bValue = extractNumericValue(b[orderBy]);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "DriverTripID",
    numeric: false,
    disablePadding: true,
    label: "Trip Id",
    // width: "10%",
  },
  {
    id: "DriverName",
    numeric: true,
    disablePadding: false,
    label: "Driver Details",
    width: "11%",
  },

  ...(localStorage.getItem("roleId") == "1"
    ? [
        {
          id: "CorporateName",
          numeric: true,
          disablePadding: false,
          label: "Corporate",
          width: "10.8%",
        },
      ]
    : []),
  {
    id: "TripType",
    numeric: true,
    disablePadding: false,
    label: "Type",
    width: "7%",
  },
  {
    id: RiderName,
    numeric: false,
    disablePadding: false,
    label: "Riders",
    // width: "10%",
  },
  {
    id: "StartedOn",
    numeric: false,
    disablePadding: false,
    label: "Started On",
    // width: "10%",
  },
  {
    id: "EndedOn",
    numeric: true,
    disablePadding: false,
    label: "Ended On",
    // width: "10%",
  },

  {
    id: "TotalTripTime",
    numeric: true,
    disablePadding: false,
    label: "Duration",
    // width: "10%",
  },
  {
    id: "TripDistance",
    numeric: true,
    disablePadding: false,
    label: "Distance",
  },
  {
    id: "TripStatus",
    numeric: true,
    disablePadding: false,
    label: "Status",
    // width: "9%",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" style={{ width: "2%" }}></TableCell>
        {headCells.map((headCell, i) => {
          if (
            localStorage.getItem("roleId") !== "1" &&
            headCell.label === "Corporate"
          ) {
            return null;
          }
          return (
            <TableCell
              key={headCell.id}
              align={"center"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                fontWeight: "bold",
                width: headCell.width,
                padding: "5px 0",
              }}
            >
              <TableSortLabel
                // active={orderBy === headCell.id}
                active={true}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  // debugger;
  const { selected, tripsData, setFilteredData } = props;
  const [searchValue, setSearchValue] = useState("");
  const [corporateData, setCorporateData] = useState([]);

  const [showPopup, setShowPopup] = useState(false);

  const handleAddKMClick = () => {
    setShowPopup(true);
  };

  return (
    <Toolbar
      sx={{
        minHeight: "50px",
      }}
      variant="dense"
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        ></Typography>
      )}

      {selected.length > 0 ? (
        <React.Fragment>
          <Tooltip title="Edit">
            <IconButton>
              <EditIcon onClick={() => props.setIsEditIconClicked(true)} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton>
              <CancelIcon
                onClick={() => props.setIsCancelBookingDialogOpen(true)}
              />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // gap: "15px",
              width: "132%",
              textAlign: "center",
              minWidth: "min-content",
            }}
          >
            <HeaderFilter
              enableCorporateFilter={true}
              enableDateFilter={true}
              enableSearchFilter={true}
              corporateData={corporateData}
              setCorporateData={setCorporateData}
              selectedCorporate={props.selectedCorporateDetails}
              setSelectedCorporate={props.setSelectedCorporateDetails}
              setFilteredData={setFilteredData}
              mainData={tripsData}
              //  Search
              setSearchFilteredData={setFilteredData}
              data={tripsData}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              styling={{ marginTop: "10px" }}
              // Date
              startDateValue={props.startDateValue}
              endDateValue={props.endDateValue}
              setStartDateValue={props.setStartDateValue}
              setEndDateValue={props.setEndDateValue}
              setIsRefereshData={props.setIsRefereshData}
              loading={props.dataLoading}
            />

            <Button
              variant="outlined"
              sx={{
                color: "#1D85C6",
                border: "1px solid #1D85C6",
                alignSelf: "center",
                ":hover": { border: "1px solid #1D85C6" },
                marginTop: "17px",
              }}
              size="small"
              onClick={() => props.setIsExportButtonClicked(true)}
            >
              Export
            </Button>
            {localStorage.getItem("roleId") == "1" && (
              <div style={{ width: "18%", marginLeft: "8px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    color: "white",
                    backgroundColor: "#44B150",
                    // border: "1px solid red",
                    ":hover": {
                      border: "1px solid green",
                      backgroundColor: "#44B150",
                      opacity: "0.8",
                      color: "white",
                    },
                    width: "max-content",
                    marginTop: "17px",
                  }}
                  size="small"
                  // style={{    width: "-webkit-fill-available"}}
                  onClick={() => props.setIsAddKmButtonClicked(true)}
                >
                  Update KM
                </Button>
              </div>
            )}
          </div>
        </LocalizationProvider>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EditDriver({
  tripsData,
  selectedCorporateDetails,
  setSelectedCorporateDetails,
  dataLoading,
  setIsRefereshData,
  driversList,
  setStartDateValue,
  setEndDateValue,
  startDateValue,
  endDateValue,
  setTripsData,
  isSuperAdmin,
}) {
  const [order, setOrder] = React.useState("asc");
  const [filteredData, setFilteredData] = useState(tripsData);

  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [isCancelBookingDialogOpen, setIsCancelBookingDialogOpen] =
    useState(false);
  const [isEditRouteBookingClicked, setIsEditRouteBookingClicked] =
    useState(false);
  const [isEditRouteBookingResponse, setIsEditRouteBookingResponse] =
    useState(false);
  const [isAddDriverClicked, setIsAddDriverClicked] = useState(false);
  const [allDriversList, setAllDriversList] = useState([]);
  const [isFillterIconClicked, setIsFilterIconClicked] = useState(false);
  const [isEditIconClicked, setIsEditIconClicked] = useState(false);
  const [expandedPanel, setExpandedPanel] = useState("");
  const [isTimeEdited, setIsTimeEdited] = useState(false);
  const [isConfirmCancelBookingClicked, setIsConfirmCancelBookingClicked] =
    useState(false);
  const [isCancelBookingResponse, setIsCancelBookingResponse] = useState(false);
  const [isExportButtonClicked, setIsExportButtonClicked] = useState(false);
  const [isAddKmButtonClicked, setIsAddKmButtonClicked] = useState(false);
  const [totalDistance, setTotalDistance] = useState(null);
  const [startedTripCount, setStartedTripCount] = useState(null);
  const [endedTripCount, setEndedTripCount] = useState(null);
  const history = useHistory();

  const { isLoading, sendRequest } = useHttp();

  const editRouteBookingResponse = (data) => {
    if (data.Message.toLowerCase() === "success") {
      setIsRefereshData(true);
      setIsEditRouteBookingResponse({
        status: "success",
        message: "Booking Edited Successfully",
      });
      selectedDriverEmailId = "";
      setSelected([]);
    } else
      setIsEditRouteBookingResponse({
        status: "error",
        message: data.SystemMessage,
      });
    setIsEditRouteBookingClicked(false);
  };

  const driverListDetails = (data) => {
    if (data?.DriverList) {
      setAllDriversList(
        data.DriverList
        //   data.DriverList.map(driver => {
        //   return {
        //     details: driver.DriverName + " - " + driver.Model + "(" + driver.Number + ")",
        //     emailId: driver.DriverEmailID
        //   }
        // }
        // )
      );
    }
  };

  const bookingCancelBookingResponse = (data) => {
    if (data.Message.toLowerCase() === "success") {
      setIsRefereshData(true);
      setIsCancelBookingResponse({
        status: "success",
        message: "Booking Cancelled Successfully",
      });
      selectedDriverEmailId = "";
      setSelected([]);
    } else
      setIsCancelBookingResponse({
        status: "error",
        message: data.SystemMessage,
      });
    setIsConfirmCancelBookingClicked(false);
  };

  React.useEffect(() => {
    let editbookings = "";
    if (selectedDriverEmailId) {
      if (selected.length > 0)
        editbookings = selected?.reduce((acc, cur) => {
          acc.push({
            RouteID: cur?.split(" ")[0],
            LineID: cur?.split(" ")[1],
            DriverEmailID: selectedDriverEmailId,
            ShuttleTime: "",
          });
          return acc;
        }, []);
      else {
        editbookings = [
          {
            RouteID: routeId,
            LineID: lineId,
            DriverEmailID: selectedDriverEmailId,
            ShuttleTime: "",
          },
        ];
      }
    } else {
      editbookings = [
        {
          RouteID: routeId,
          LineID: lineId,
          DriverEmailID: "",
          ShuttleTime: changedDateTime.date + " " + changedDateTime.time,
        },
      ];
    }
    if (isEditRouteBookingClicked) {
      sendRequest(
        {
          url: "/api/v1/ShuttleBooking/EditRouteBooking",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            editbookings: JSON.stringify(editbookings),
          },
        },
        editRouteBookingResponse
      );
    }
  }, [sendRequest, isEditRouteBookingClicked]);

  React.useEffect(() => {
    if (isConfirmCancelBookingClicked) {
      let cancelledbookings = selected.reduce((acc, cur) => {
        acc.push({
          RouteID: cur?.split(" ")[0],
          LineID: cur?.split(" ")[1],
        });
        return acc;
      }, []);
      sendRequest(
        {
          url: "/api/v1/ShuttleBooking/CancelledRouteBooking",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            cancelledbookings: JSON.stringify(cancelledbookings),
            riderTripID: "",
          },
        },
        bookingCancelBookingResponse
      );
    }
  }, [sendRequest, isConfirmCancelBookingClicked]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelected = tripsData?.map((n) => n.DriverEmailID);
      const newSelected = filteredData?.map((n) => n.DriverEmailID);

      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = (event, name) => {
    // debugger;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the l ast page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredData, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredData, tripsData, isSuperAdmin]
  );


  // Calculate the total Distance=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const tDistance = filteredData?.reduce((acc, trip) => {
    const distance = trip?.TripDistance
      ? parseFloat(trip?.TripDistance.split(" ")[0])
      : 0;
    return acc + distance;
  }, 0);

  const formattedTotalDistance = tDistance?.toFixed(2);

  const tripCounts = filteredData?.reduce(
    (acc, trip) => {
      if (trip?.TripStatus === "STARTED") {
        acc.started += 1;
      } else if (trip?.TripStatus === "ENDED") {
        acc.ended += 1;
      }
      return acc;
    },
    { started: 0, ended: 0 }
  );

  const startedTrips = tripCounts?.started;
  const endedTrips = tripCounts?.ended;

  React.useEffect(() => {
    setTotalDistance(formattedTotalDistance);
    setStartedTripCount(startedTrips);
    setEndedTripCount(endedTrips);
  }, [filteredData]);

  // For copying tripId----------------
  const [copiedItemId, setCopiedItemId] = useState(null);

  const handleCopyClick = (event, tripId) => {
    event.stopPropagation(); // Stop the event from propagating to the parent row
    navigator.clipboard.writeText(tripId);
    setCopiedItemId(tripId);
    setTimeout(() => setCopiedItemId(null), 2000); // Reset copiedItemId state after 2 seconds
  };

  const getRiderInfo = (riderNames) => {
    const namesArray = riderNames
      ?.split(",")
      .filter((name) => name.trim() !== "");
    if (namesArray?.length === 0) {
      return "No riders";
    }

    const firstName = namesArray?.[0];
    const othersCount = namesArray?.length;
    if (namesArray?.length === 1) {
      return `${firstName}`;
    } else if (namesArray?.length > 1) {
      // return `${firstName}\n+ ${othersCount} others`;
      return (
        <>
          {firstName}
          <br /> ({othersCount} Riders){" "}
        </>
      );
    }
  };

  return (
    <React.Fragment>
      {(isEditRouteBookingResponse || isCancelBookingResponse) && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() =>
            isEditRouteBookingResponse
              ? setIsEditRouteBookingResponse(false)
              : setIsCancelBookingResponse(false)
          }
        >
          <Alert
            onClose={() =>
              isEditRouteBookingResponse
                ? setIsEditRouteBookingResponse(false)
                : setIsCancelBookingResponse(false)
            }
            severity={
              isEditRouteBookingResponse
                ? isEditRouteBookingResponse.status
                : isCancelBookingResponse.status
            }
            sx={{ width: "100%", backgroundColor: "rgba(42, 149, 69, 255)" }}
          >
            {isEditRouteBookingResponse?.status
              ? isEditRouteBookingResponse.message
              : isCancelBookingResponse.message}
          </Alert>
        </Snackbar>
      )}
      {isLoading && (
        <Backdrop
          sx={{
            color: "rgba(34, 137, 203, 255)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Dialog
        open={isEditIconClicked}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select a driver that you want to add for booking
          </DialogContentText>
          <Autocomplete
            id="tags-standard"
            options={driversList}
            getOptionLabel={(driver) =>
              driver?.driverName + " (" + driver.driverCarModel + ")"
            }
            onChange={(e, newValue) =>
              (selectedDriverEmailId = newValue.driverEmailId)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Driver Name"
                placeholder="Search Driver"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditIconClicked(false)}>Cancel</Button>
          <Button
            onClick={() => {
              changedDateTime = "";
              setIsEditIconClicked(false);
              setIsEditRouteBookingClicked(true);
            }}
            autoFocus
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTimeEdited}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the timing?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsTimeEdited(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsTimeEdited(false);
              setIsEditRouteBookingClicked(true);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isCancelBookingDialogOpen}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel the bookings for the selected dates?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsCancelBookingDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsConfirmCancelBookingClicked(true);
              setIsCancelBookingDialogOpen(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "97%", margin: "1.5%", borderRadius: "10px" }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "10px" }}>
          <EnhancedTableToolbar
            setIsExportButtonClicked={setIsExportButtonClicked}
            setIsAddKmButtonClicked={setIsAddKmButtonClicked}
            setIsRefereshData={setIsRefereshData}
            setStartDateValue={setStartDateValue}
            setEndDateValue={setEndDateValue}
            startDateValue={startDateValue}
            endDateValue={endDateValue}
            setIsEditIconClicked={setIsEditIconClicked}
            selected={selected}
            setIsFilterIconClicked={setIsFilterIconClicked}
            setIsAddDriverClicked={setIsAddDriverClicked}
            setIsCancelBookingDialogOpen={setIsCancelBookingDialogOpen}
            selectedCorporateDetails={selectedCorporateDetails}
            tripsData={tripsData}
            setTripsData={setTripsData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            setSelectedCorporateDetails={setSelectedCorporateDetails}
            dataLoading={dataLoading}
          />

          <TableContainer>
            <Table
              sx={{ minWidth: 750, fontFamily: "Montserrat" }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={filteredData?.length}
                selectedCorporateDetails={selectedCorporateDetails}
              />
              {dataLoading ? (
                <TableBody>
                  <TableRow padding="none">
                    <TableCell colSpan={9} align="center" padding="none">
                      <CircularProgress size="2rem" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    const isItemSelected = isSelected(row?.DriverTripID);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <React.Fragment>
                        <TableRow
                          hover
                          // role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!dateTimeChangeFlag)
                              setExpandedPanel(
                                expandedPanel === row?.DriverTripID
                                  ? ""
                                  : row?.DriverTripID
                              );
                          }}
                          // onClick={() => {
                          //   if (!dateTimeChangeFlag) {
                          //     const tripId = row?.DriverTripID;
                          //     const tripType = row?.TripType;
                          //     if (tripId) {
                          //       // Pass triptype as query parameter
                          //       history.push(`/trips/${tripId}?triptype=${tripType}`);
                          //     }
                          //   }
                          // }}
                        >
                          <TableCell padding="none">
                            <div style={{ paddingLeft: "10px" }}>
                              {expandedPanel === row?.DriverTripID ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            className="tableCellStyle"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                          >
                            {row?.DriverTripID.substring(0, 8) + "..."}

                            <Tooltip
                              title={
                                copiedItemId === row?.DriverTripID
                                  ? "Copied!"
                                  : "Copy Trip ID"
                              }
                              arrow
                            >
                              <IconButton
                                size="small"
                                onClick={(event) =>
                                  handleCopyClick(event, row?.DriverTripID)
                                }
                              >
                                <FileCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            <p>{row?.DriverName}</p>
                            <p style={{ fontSize: "75%", color: "gray" }}>
                              {row?.VehicaleModel}, {row?.VehicaleNumber}
                            </p>
                          </TableCell>
                          {localStorage.getItem("roleId") == "1" && (
                            <TableCell
                              align="center"
                              padding="none"
                              className="tableCellStyle"
                            >
                              <p>
                                {row?.CorporateName ? row?.CorporateName : "-"}
                              </p>
                            </TableCell>
                          )}

                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {row?.TripType}
                          </TableCell>

                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {row?.RiderName
                              ? getRiderInfo(row?.RiderName)
                              : "-"}
                            {/* {row.RiderName && (getRiderInfo(row?.RiderName)) &&
                            firstName br
                            } */}
                          </TableCell>

                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {row?.StartedOn}
                            {/* <div>
                              <span>{row.StartedOn?.split("T")[0]}</span>

                              <span>{row.StartedOn?.split("T")[1]}</span>
                            </div> */}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {row?.EndedOn ? (
                              <div>
                                <span>{row?.EndedOn?.split("T")[0]}</span>
                                <span>{row?.EndedOn?.split("T")[1]}</span>
                              </div>
                            ) : (
                              "-"
                            )}
                          </TableCell>

                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {/* {parseInt(row?.TotalTripTime?.split(' ')[0]){"Km"} ?? "-"} */}
                            {row?.TotalTripTime ?? "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            {row?.TripDistance ?? "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            padding="none"
                            className="tableCellStyle"
                          >
                            <div className={row?.TripStatus.toLowerCase()}>
                              {row?.TripStatus.toLowerCase()}
                            </div>
                          </TableCell>
                        </TableRow>
                        {expandedPanel === row?.DriverTripID && (
                          <TableRow>
                            <TableCell
                              colSpan={11}
                              align="center"
                              sx={{ backgroundColor: "#f8fcfd" }}
                            >
                              <RidersData
                                journeyId={row?.DriverTripID}
                                tripType={row?.TripType}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })}
                  {!visibleRows && (
                    <TableRow>
                      <TableCell
                        sx={{ fontFamily: "Montserrat" }}
                        colSpan={9}
                        align="center"
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[7, 15, 20]}
            component="div"
            count={filteredData?.length ?? "1"}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <Box
            display="flex"
            // justifyContent="flex-end"
            sx={{
              paddingRight: "16px",
              marginTop: "8px",
              alignContent: "center",
              alignItems: "center",
              display:'flex',
              justifyContent:'space-between'
            }}
          >
             <Typography
              variant="subtitle1"
              sx={{
                margin: "initial", 
                marginLeft: "2%",
                // display:'flex',
                // justifyContent:'flex-start' 
              }}
            >
              <strong style={{ fontSize: "0.875rem", color: "green" }}>
                Started:{" "}
              </strong>
              <span style={{ fontSize: "0.8rem" }}>{startedTripCount}</span>{" "}
              <strong style={{ fontSize: "0.875rem"}}>| {" "}</strong>
              <strong style={{ fontSize: "0.8rem", color: "red" }}>
                Ended: {" "}
              </strong>
              <span style={{ fontSize: "0.8rem" }}>{endedTripCount}</span>
              <strong style={{ fontSize: "0.875rem" }}>
                {" "}
                | Distance:
              </strong>
              <span style={{ fontSize: "0.8rem" }}> {totalDistance} Km</span>
            </Typography>
             <CommonTablePagination
              page={page}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              setPage={setPage}
              filteredData={filteredData}
              styling={{ display: "flex", justifyContent: "flex-end" }}
            />
           
          </Box>
        </Paper>
      </Box>
      <Backdrop
        sx={{ color: "rgba(34, 137, 203, 255)", zIndex: "10" }}
        open={isExportButtonClicked}
      ></Backdrop>
      {isExportButtonClicked && (
        <Modal
          setIsExportButtonClicked={setIsExportButtonClicked}
          type="trips"
        />
      )}

      {/* This is for Add new Km */}
      <Backdrop
        sx={{ color: "rgba(34, 137, 203, 255)", zIndex: "10" }}
        open={isAddKmButtonClicked}
      ></Backdrop>
      {isAddKmButtonClicked && (
        <ExtraKm
          setIsAddKmButtonClicked={setIsAddKmButtonClicked}
          setIsRefereshData={setIsRefereshData}
          type="trips"
        />
      )}
    </React.Fragment>
  );
}
