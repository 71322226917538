import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./Message.css";
import TickmarkImage from "../Assets/Tickmark.png";
import ErrorImage from "../Assets/Error.png";
import { Button } from "@mui/material";

const Message = (props) => {
  const history = useHistory();
  useEffect(() => {
    if (props.flag)
      document.getElementsByClassName("container-success-msg")[0].style.top =
        "0";
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <div className="container-success-msg">
      <div className="success-sub-container">
        <div className="success-msg">
          <img
            src={
              props.type.toLowerCase() === "success"
                ? TickmarkImage
                : ErrorImage
            }
          />
          <p className="data-save">
            {props.type.toLowerCase() === "success"
              ? props.message === "Success"
                ? "Route Created Successfully"
                : props.message
              : props.driveErrorMessage
              ? props.driveErrorMessage
              : "Some Error Occured. Please Try Again Later"}
          </p>
        </div>
        <hr />
        <div
          className="footer"
          style={{
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0",
          }}
        >
          {/* <button
            className={props.type.toLowerCase() === "success" ? "" : "error"}
            onClick={() =>
              props.url ? history.push(props.url) : window.location.reload()
            }
            
          >
            OK
          </button> */}
          <button
            className={props.type.toLowerCase() === "success" ? "" : "error"}
            onClick={
              props.showAddAnotherButton
                ? props.onOk
                : () =>
                    props.url
                      ? history.push(props.url)
                      : window.location.reload()
            }
          >
            OK
          </button>
          {props.showAddAnotherButton && (
            <button
              // variant="contained"
              // size="small"
              style={{
                boxShadow: "none",
                marginLeft: "10px",
                fontFamily: "Montserrat",
                background:'#1D85C6'
              }}
              // className="add-another-button"
              onClick={props.onAddAnother}
              // style={{ marginLeft: "10px" }}
            >
              {/* Add Another  */}
              {props.showAddAnotherButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
