import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import Autocomplete from "@mui/material/Autocomplete";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";

import FilterListIcon from "@mui/icons-material/FilterList";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { visuallyHidden } from "@mui/utils";
import { useState } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";

import useHttp from "../../Hooks/use-http";
import QrCode from "./QrCode";
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import CorporateFilter from "../CommonComponent/CorporateFilter";
import SearchFilter from "../CommonComponent/SearchFilter";
import DeleteDriver from "./DeleteDriver";
import { LocalHospitalTwoTone } from "@mui/icons-material";
import { toast } from "react-toastify";
import CommonTablePagination from "../CommonComponent/CommonTablePagination";
import ChangeVehicle from "./ChangeVehicle";

let selectedDriversEmailId = [];
let corporatesData = [];

let opeationType = "";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "DriverName",
    numeric: false,
    disablePadding: true,
    label: "Driver Name",
    width: "12%",
  },
  {
    id: "Partner",
    numeric: false,
    disablePadding: true,
    label: "Partner",
    width: "12%",
  },
  {
    id: "Vehicle",
    numeric: false,
    disablePadding: true,
    label: "Vehicle",
    width: "15%",
  },
  {
    id: "DriverType",
    numeric: false,
    disablePadding: true,
    label: "Driver Type",
    width: "12%",
  },
  {
    id: "VehicleType",
    numeric: false,
    disablePadding: true,
    label: "Vehicle Type",
    width: "12%",
  },
  // {
  //   id: "IsOnline",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Online Status",
  //   // width: "10%",
  // },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
    width: "18%",
  },
  {
    id: "UniqueNumber",
    numeric: true,
    disablePadding: false,
    label: "QR Code",
    width: "12%",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" style={{ width: "2%" }}>
          {props?.selectedCorporateDetails?.cpName &&
            props.driverType !== "all" && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            )}
        </TableCell>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", width: headCell?.width || "auto" }}
          >
            <TableSortLabel
              style={{}}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {localStorage.getItem("roleId") == "1" && (
          // !selectedCorporateDetails?.cpName && (
          <>
            <TableCell
              key="CpName"
              align={"left"}
              padding={"normal"}
              sortDirection={orderBy === "CpName" ? order : false}
              sx={{ fontWeight: "bold" }}
            >
              <TableSortLabel
                active={orderBy === "CpName"}
                direction={orderBy === "CpName" ? order : "asc"}
                onClick={createSortHandler("CpName")}
              >
                {"Corporates"}

                {orderBy === "CpName" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            {/* <TableCell
            // key="CpName"
            align={"left"}
            padding={"normal"}
            // sortDirection={orderBy === "CpName" ? order : false}
            sx={{ fontWeight: "bold" }}
          >
            <TableSortLabel
              
            >
              {"Actions"}
              
             
            </TableSortLabel>
          </TableCell> */}
          </>
        )}
        {localStorage.getItem("roleId") == "1" && (
          <TableCell
            key="Actions"
            align="center"
            padding="normal"
            sx={{ fontWeight: "bold" }}
          >
            Actions
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    selected,
    visibleRows,
    setFilteredData,
    privateDrivers,
    setSelectedCorporateDetails,
    selectedCorporateDetails,
    corporateData,
    setCorporateData,
  } = props;
  const enabledModule = localStorage.getItem("enabledModule");
  const enabledModuleArr = enabledModule.split(",");

  const { isLoading, sendRequest } = useHttp();

  // const handleDriverTypeChange = (event) => {
  //   props.setDriverType(event.target.value);
  // };

  const [searchValue, setSearchValue] = useState("");

  //   Function for the handlesearch
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <Toolbar
      sx={{
        display: "flex", // Set display to flex
        alignItems: "center", // Align items vertically center
        justifyContent: "space-between", // Space between items
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected?.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {selected?.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected?.length} selected
        </Typography>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}></div>
      )}

      <SearchFilter
        data={privateDrivers}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setFilteredData={setFilteredData}
        styling={{ marginLeft: "auto" }}
        placeholder={"Driver / Corporate"}
      />
      {selected?.length > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon onClick={() => props.setIsDeleteDialogOpen(true)} />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {localStorage.getItem("roleId") == "1" && (
            <>
              <Button
                style={{
                  // width: "200px",
                  // height: "35px",
                  marginLeft: "10px",
                  "@media (max-width: 768px)": {
                    width: "120px",
                    height: "30px",
                  },
                }}
                onClick={
                  (e) =>
                    e.target.innerText?.toLowerCase().includes("register") &&
                    window.open("https://drivers.little.bz")
                  // : props.setIsAddDriverClicked(true)
                }
                variant="contained"
                // disabled={
                //   !props.selectedCorporateDetails?.[0]?.cpName &&
                //   props.driverType !== "all"
                // }
              >
                {/* {props.driverType === "all"
                ? "Register Driver"
                : props.driverType === "private"
                ? "Add Private Driver"
                : "Add Shuttle Driver"} */}
                Register Driver
              </Button>

              <Button
                style={{
                  // width: "150px",
                  // height: "35px",
                  marginLeft: "10px",
                  "@media (max-width: 768px)": {
                    width: "120px",
                    height: "30px",
                  },
                }}
                onClick={(e) => props.setIsAddDriverClicked(true)}
                variant="contained"
                // disabled={
                //   !props.selectedCorporateDetails?.[0]?.cpName &&
                //   props.driverType !== "all"
                // }
              >
                Add Driver
              </Button>
            </>
          )}
        </>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EditDriver({
  privateDrivers,
  selectedCorporateDetails,
  dataLoading,
  setIsApiCall,
  driverType,
  setDriverType,
  corporateNameClickHandler,
  filteredCorporates,
  setFilteredCorporates,
  setSelectedCorporateDetails,
  corporateData,
  setCorporateData,
  isApiCall,
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("DriverName");
  const [filteredData, setFilteredData] = useState(privateDrivers);

  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [isAddDeletePrivateDrivesClicked, setIsAddDeletePrivateDriversClicked] =
    useState(false);
  const [
    isAddEditPrivateDriverOperationSuccess,
    setIsAddEditPrivateDriverOperationSuccess,
  ] = useState(false);
  const [isAddDriverClicked, setIsAddDriverClicked] = useState(false);
  const [allDriversList, setAllDriversList] = useState([]);
  const [openedDriverQr, setOpenedDriverQr] = useState();
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openChangeVehicleModal, setChangeVehicleModal] = useState(false);

  const [selectCorporateOnDelete, setSelectCorporateOnDelete] = useState([]);
  const [selectedDriverType, setSelectedDriverType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [changeVehicleDriverData, setChangeVehicleDriverData] = useState(null);

  const { isLoading, sendRequest } = useHttp();

  // Delete Modal
  const handleOpenDeleteDriverModal = () => {
    setDeleteModal(true);
    setIsDeleteDialogOpen(true);
    setDriverData(driverData);
  };

  const handleCloseDeleteDriverModal = () => {
    setDeleteModal(false);
    // setDriverData(null);
  };

  // Change Vehicle Modal
  const handleOpenChangeVehicleModal = () => {
    // setDeleteModal(true);
    setChangeVehicleModal(true);
    // setIsDeleteDialogOpen(true);
    // setDriverData(driverData);
    setChangeVehicleDriverData(changeVehicleDriverData);
  };

  const handleCloseChangeVehicleModal = () => {
    setChangeVehicleModal(false);
    // setDriverData(null);
    // setChangeVehicleDriverData(null)
  };

  const addDeletePrivateDriverResponse = (data) => {
    const isSuccess = data.Message === "Success";
    setIsAddEditPrivateDriverOperationSuccess(isSuccess);

    if (isSuccess) {
      setIsApiCall(true);
      setIsDeleteDialogOpen(false);
      setIsAddDriverClicked(false);
      // toast.success(`Drivers have been ${opeationType === "add" ? "added" : "removed"} successfully`);
    }
    // else {
    //   toast.error("Some error occurred");
    // }

    setIsAddDeletePrivateDriversClicked(false);
    setSelected([]);
    selectedDriversEmailId = "";
  };

  const driverSelectHandler = (e, driverData) => {
    selectedDriversEmailId = driverData.reduce((acc, curr) => {
      acc.push(curr.DriverEmailID);
      return acc;
    }, []);
  };

  const driverListDetails = (data) => {
    if (data?.DriverList) {
      setAllDriversList(data.DriverList);
    }
  };
  React.useEffect(() => {
    if (isAddDriverClicked) {
      // setIsDeleteDialogOpen(false);
      sendRequest(
        {
          url: "/api/v1/DriverList/GetDriverList",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            roleID: localStorage.getItem("roleId"),
            corporateID: "",
          },
        },
        driverListDetails
      );
    }
  }, [sendRequest, isAddDriverClicked]);

  const allDepartmentIDs = selectedCorporateDetails?.[0]?.dpId.split(",");
  const firstDepartmentID = allDepartmentIDs ? allDepartmentIDs[0] : "";

  React.useEffect(() => {
    if (isAddDeletePrivateDrivesClicked) {
      opeationType = isDeleteDialogOpen ? "delete" : "add";
      // opeationType = selectCorporateOnDelete ? "delete" : "add";

      const corporateID = isDeleteDialogOpen
        ? selectCorporateOnDelete?.[0]?.CorporateID ||
          selectedCorporateDetails?.[0]?.cpId ||
          "defaultCorporateID"
        : selectedCorporateDetails?.[0]?.cpId || "defaultCorporateID";

      const departmentID = isDeleteDialogOpen
        ? selectCorporateOnDelete?.[0]?.DepartmentID ||
          firstDepartmentID ||
          "defaultDepartmentID"
        : firstDepartmentID || "defaultDepartmentID";

      const updateAddDriverButton = isDeleteDialogOpen
        ? selectCorporateOnDelete &&
          selectCorporateOnDelete.length > 0 &&
          selectCorporateOnDelete[0].DriverType !== "Shuttle"
          ? "/api/v1/DriverList/AddEditPrivateDriver"
          : "/api/v1/ShuttleTrips/AddEditShuttleDriver"
        : driverType === "private"
        ? "/api/v1/DriverList/AddEditPrivateDriver"
        : "/api/v1/ShuttleTrips/AddEditShuttleDriver";
      // debugger;
      sendRequest(
        {
          url: updateAddDriverButton,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            emailID: localStorage.getItem("user"),
            roleID: localStorage.getItem("roleId"),
            // corporateID: isDeleteDialogOpen ? selectCorporateOnDelete?.CorporateID :  selectedCorporateDetails?.[0]?.cpId,
            corporateID: corporateID,
            // departmentID: selectedCorporateDetails?.[0]?.dpId,
            // departmentID:  isDeleteDialogOpen ? selectCorporateOnDelete?.DepartmentID : firstDepartmentID,
            departmentID: departmentID,
            isAdd: isDeleteDialogOpen ? 0 : 1,
            driverEmailID: isDeleteDialogOpen
              ? // ? selected.join(",")
                driverData?.DriverEmailID
              : selectedDriversEmailId?.join(","),
          },
        },

        addDeletePrivateDriverResponse
      );
    }
  }, [sendRequest, isAddDeletePrivateDrivesClicked]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = privateDrivers?.map((n) => n.DriverEmailID);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredData?.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredData, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredData]
  );

  const handleDriverTypeChange = (event) => {
    setDriverType(event.target.value);
  };

  React.useEffect(() => {
    sendRequest(
      {
        url: "/api/v1/Corporate/GetAllDepartmentByCorporate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          emailID: localStorage.getItem("user"),
        },
      },
      corporateLists
    );
  }, [sendRequest]);

  const corporateLists = (data) => {
    const tempArr =
      data?.CorporateList?.map((cp) => ({
        cpName: cp?.CorporateName,
        cpId: cp?.CorporateID,
        dpId: cp?.DepartmentID,
        adminDptId:
          cp?.DepartmentID?.split(",")[
            cp?.DepartmentName?.toLowerCase()?.split(",").indexOf("admin")
          ] || "",
      })) || [];
    setCorporateData(tempArr);
  };

  const handleCorporateChange = (event, newValue) => {
    if (newValue) {
      const selectedData = corporateData.filter(
        (data) => data?.cpName === newValue
      );
      setSelectedCorporateDetails(selectedData);
    } else {
      setSelectedCorporateDetails([]);
    }
  };

  const handleMenuClick = (event, corporate) => {
    setAnchorEl(event.currentTarget);
    setChangeVehicleDriverData(corporate);
    setDriverData(corporate);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // setSelectedCorporate(null);
    setChangeVehicleDriverData(null);
  };
  return (
    <React.Fragment>
      {isAddEditPrivateDriverOperationSuccess && (
        <Snackbar
          open={isAddEditPrivateDriverOperationSuccess !== null}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={() => setIsAddEditPrivateDriverOperationSuccess(null)}
        >
          <Alert
            onClose={() => setIsAddEditPrivateDriverOperationSuccess(null)}
            severity={
              isAddEditPrivateDriverOperationSuccess ? "success" : "error"
            }
            sx={{
              width: "100%",
              backgroundColor: isAddEditPrivateDriverOperationSuccess
                ? "rgba(42, 149, 69, 255)"
                : "rgba(255, 69, 69, 255)",
            }}
          >
            {isAddEditPrivateDriverOperationSuccess
              ? `Drivers have been ${
                  opeationType === "add" ? "added" : "removed"
                } successfully`
              : "Some error occurred"}
          </Alert>
        </Snackbar>
      )}
      {/* {isLoading && (
        <Backdrop
          sx={{
            color: "rgba(34, 137, 203, 255)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>  
      )} */}

      {/* <Dialog open={isAddDriverClicked && !isLoading}>
       */}
      <Dialog open={isAddDriverClicked}>
        <DialogTitle>
          {/* {driverType === "private"
            ? "Add Private Driver"
            : "Add Shuttle Driver"} */}
          Add Driver
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select a driver that you want to add in{" "}
            {selectedCorporateDetails?.cpName}
          </DialogContentText>

          <FormControl style={{ width: "100%", marginTop: "16px" }}>
            <RadioGroup value={driverType} onChange={handleDriverTypeChange}>
              <div style={{ display: "flex", gap: "40%" }}>
                <FormControlLabel
                  value="private"
                  control={<Radio />}
                  label="Private"
                />

                <FormControlLabel
                  value="shuttle"
                  control={<Radio />}
                  label="Shuttle"
                />
              </div>
            </RadioGroup>
          </FormControl>

          <Autocomplete
            id="corporate-filter"
            // disabled={loading == true}

            options={
              corporateData && corporateData?.map((data) => data?.cpName)
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search Corporate Name"
                label="Corporate Name"
              />
            )}
            onChange={(e, newValue) =>
              // corporateNameClickHandler(e, newValue)
              handleCorporateChange(e, newValue)
            }
            value={
              selectedCorporateDetails && selectedCorporateDetails.length > 0
                ? selectedCorporateDetails[0].cpName
                : ""
            } // Use selectedCorporate state if available, otherwise use the provided value prop
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={allDriversList}
            getOptionLabel={(driver) =>
              driver.DriverName +
              " - " +
              driver.Model +
              "(" +
              driver.Number +
              ")"
            }
            onChange={(e, newValue) => driverSelectHandler(e, newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Driver Name"
                placeholder="Search Driver"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              selectedDriversEmailId = "";
              setIsAddDriverClicked(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => setIsAddDeletePrivateDriversClicked(true)}>
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      {
        openedDriverQr && <QrCode openedDriverQr={openedDriverQr} />
        // </div>
      }
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2, borderRadius: "10px" }}>
          <EnhancedTableToolbar
            selected={selected}
            setIsAddDriverClicked={setIsAddDriverClicked}
            setIsDeleteDialogOpen={setIsDeleteDialogOpen}
            selectedCorporateDetails={selectedCorporateDetails}
            setSelectedCorporateDetails={setSelectedCorporateDetails}
            setDriverType={setDriverType}
            driverType={driverType}
            setIsApiCall={setIsApiCall}
            filteredCorporates={filteredCorporates}
            setFilteredCorporates={setFilteredCorporates}
            visibleRows={visibleRows}
            setFilteredData={setFilteredData}
            privateDrivers={privateDrivers}
            corporateData={corporateData}
            setCorporateData={setCorporateData}
          />

          <TableContainer>
            <Table
              sx={{ minWidth: 750, fontFamily: "Montserrat" }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={privateDrivers?.length}
                selectedCorporateDetails={selectedCorporateDetails}
                driverType={driverType}
              />
              {dataLoading ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    const isItemSelected = isSelected(row.DriverEmailID);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.DriverEmailID}
                        selected={isItemSelected}
                        // sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="none">
                          {selectedCorporateDetails?.cpName &&
                            driverType !== "all" && (
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.DriverEmailID)
                                }
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            )}
                        </TableCell>

                        <TableCell
                          // component="th"
                          sx={{ fontFamily: "Montserrat" }}
                          id={labelId}
                          scope="row"
                          padding="none"
                          align="center"
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={row.DriverImage}
                              style={{
                                borderRadius: "50%",
                                objectFit: "cover",
                                width: "45px",
                                height: "45px",
                                marginRight: "10px", // Add margin for spacing
                              }}
                            />
                            <div>
                              <>
                                <p>{row?.DriverName}</p>
                                <p style={{ fontSize: "75%", color: "gray" }}>
                                  {row?.DriverEmailID}
                                </p>
                              </>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          <>
                            {row.PartnerEmailId !== null ||
                            row?.PartnerEmailId !== null ? (
                              <div>
                                <p>{row?.PartnerName}</p>
                                <p style={{ fontSize: "75%", color: "gray" }}>
                                  {row?.PartnerEmailId}
                                </p>
                              </div>
                            ) : (
                              <div>-</div>
                            )}
                          </>
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          <div>
                            <p>{row.CarModel}</p>
                            <p style={{ fontSize: "75%", color: "gray" }}>
                              {row.CarNumber}
                            </p>
                          </div>
                        </TableCell>

                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          {row.DriverType ? row.DriverType : "-"}
                        </TableCell>

                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          {row.VehicleTypes}
                        </TableCell>
                        {/* <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          {row.IsOnline === true ? "Online" : "Offline"}
                        </TableCell> */}

                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                        >
                          {row.Status}
                        </TableCell>
                        <TableCell
                          sx={{ fontFamily: "Montserrat" }}
                          align="center"
                          onClick={() =>
                            window.open(
                              window.location.origin +
                                "/drivers/" +
                                row.DriverEmailID
                            )
                          }
                        >
                          {/* <TableCell sx={{ fontFamily: "Montserrat" }} align="center" onClick={() => window.open("http://localhost:3000/dashboard")} > */}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ display: "flex", gap: "5px" }}>
                              <DownloadIcon />
                              {`( ${row.UniqueNumber})`}
                            </div>
                          </div>
                        </TableCell>
                        {localStorage.getItem("roleId") == "1" && (
                          <>
                            <TableCell
                              sx={{ fontFamily: "Montserrat" }}
                              align="center"
                            >
                              {row.CorporateName}
                            </TableCell>

                            <TableCell>
                              <IconButton
                                onClick={(event) => handleMenuClick(event, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                sx={{
                                  "& .MuiPaper-root": {
                                    boxShadow: "none",
                                    borderRadius: "10px",
                                    border: "1px solid #ccc",
                                  },
                                }}
                              >
                                <MenuItem onClick={handleOpenDeleteDriverModal}>
                                  <ListItemIcon>
                                    {/* <SettingsIcon fontSize="small" /> */}
                                    <DeleteIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Remove</ListItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={handleOpenChangeVehicleModal}
                                >
                                  <ListItemIcon>
                                    {/* <SettingsIcon fontSize="small" /> */}
                                    <EditIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>Change Vehicle</ListItemText>
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
                  {(visibleRows?.length === 0 ||
                    filteredData?.length === 0) && (
                    <TableRow>
                      <TableCell
                        sx={{ fontFamily: "Montserrat" }}
                        colSpan={6}
                        align="center"
                      >
                        No Data Available
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {/* Modal for remove driver from corporate */}
          <Modal
            open={openDeleteModal}
            // onClose={handleCloseAddTaskModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleCloseDeleteDriverModal();
              }
            }}
            aria-labelledby="add-new-task-modal"
            aria-describedby="add-new-task-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                overflowX: "hidden",
                transform: "translate(-50%, -50%)",
                width: "30%",
                maxHeight: "80vh",
                overflowY: "auto",
                bgcolor: "background.paper",
                // bgcolor: "rgba(34, 137, 203, 255)",
                // border: "2px solid",
                zIndex: "1500",
                boxShadow: 24,
                borderRadius: 4,
                p: 3,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 8,
                }}
              >
                <Button
                  // variant="contained"
                  // color="white"
                  onClick={handleCloseDeleteDriverModal}
                  sx={{ fontSize: "20px", color: "white" }}
                >
                  x
                </Button>
              </Box>
              <DeleteDriver
                selectedCorporate={selectCorporateOnDelete}
                setSelectedCorporate={setSelectCorporateOnDelete}
                driverData={driverData}
                isAddDeletePrivateDrivesClicked={
                  setIsAddDeletePrivateDriversClicked
                }
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                isDeleteDialogOpen={isDeleteDialogOpen}
                selectedDriverType={selectedDriverType}
                setSelectedDriverType={setSelectedDriverType}
                setIsApiCall={setIsApiCall}
                // getTaskList={getTaskList}
                onCloseModal={handleCloseDeleteDriverModal}
                setAnchorEl={setAnchorEl}
              />
            </Box>
          </Modal>

          {/* Modal for Change vehicle for the drivers */}
          <Modal
            open={openChangeVehicleModal}
            // onClose={handleCloseAddTaskModal}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleCloseChangeVehicleModal();
              }
            }}
            aria-labelledby="add-new-task-modal"
            aria-describedby="add-new-task-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                overflowX: "hidden",
                transform: "translate(-50%, -50%)",
                width: "30%",
                maxHeight: "80vh",
                overflowY: "auto",
                bgcolor: "background.paper",
                // bgcolor: "rgba(34, 137, 203, 255)",
                // border: "2px solid",
                zIndex: "1500",
                boxShadow: 24,
                borderRadius: 4,
                p: 3,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 4,
                  right: 8,
                }}
              >
                <Button
                  // variant="contained"
                  // color="white"
                  onClick={handleCloseChangeVehicleModal}
                  sx={{ fontSize: "20px", color: "white" }}
                >
                  x
                </Button>
              </Box>
              <ChangeVehicle
                driverData={changeVehicleDriverData}
                setIsApiCall={setIsApiCall}
                setAnchorEl={setAnchorEl}
                onCloseModal={handleCloseChangeVehicleModal}
              />
            </Box>
          </Modal>

          <CommonTablePagination
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            filteredData={filteredData}
            styling={{ display: "flex", justifyContent: "flex-end" }}
          />
        </Paper>
      </Box>
    </React.Fragment>
  );
}
