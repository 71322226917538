import React, { useState } from "react";
import { CgMenuMotion } from "react-icons/cg";

import classes from "./Header.module.css";
import adminAlternative from "../../Assets/adminAlternative.png";
import littleLogo from "../../Assets/Little_logo.jpg";
import loadingGif from "../../Assets/loading-gif.gif";
import { useHistory } from "react-router-dom";
import ChangePassword from "../Dashboard/ChangePassword";
import { GrClose } from "react-icons/gr";
import useHttp from "../../Hooks/use-http";
import { useEffect } from "react";
import EditProfile from "./EditProfile";
import Message from "../../Modal/Message";
import Notification from "./Notification";

const Nav = (props) => {
  const history = useHistory();
  const [isAdminPhotoClicked, setIsAdminPhotoClicked] = useState(false);
  const [isEditProfileClicked, setIsEditProfileClicked] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
 
  const corporateImage=localStorage.getItem("corporateImage")
  const adminImage=localStorage.getItem("userImage")



  // const headerInfoResponse = (data) => {
  //   if (data) {
  //     if (data.CorporateImage) setCorporateImage(data.CorporateImage[0].Image);
  //     if (data.AdminImage) setAdminImage(data.AdminImage[0].Image);
  //   }
  // };

//   function getLocalStorageSize() {
//     let total = 0;
//     for (let key in localStorage) {
//         if (localStorage.hasOwnProperty(key)) {
//             total += key.length + localStorage.getItem(key).length;
//         }
//     }
//     // The total size is in characters; to convert it to bytes:
//     let totalBytes = new Blob(Object.values(localStorage)).size;
//     return totalBytes;
// }

// console.log(`Total localStorage size: ${getLocalStorageSize()} bytes`);




  const sideMenuClickHandler = () => {
    props.sideMenuOpen();
  };

  const adminPhotoClickHandler = () => {
    setIsAdminPhotoClicked((prev) => !prev);
  };

  const editProfileClickHandler = () => {
    setIsEditProfileClicked(true);
    setIsAdminPhotoClicked(false);
  };
  setTimeout(() => {
    document.getElementById("a")?.addEventListener("click", () => {
      document.body.style.overflow = "hidden";
    });
  });

  const logoutHandler = () => {
    // Clear all localStorage items related to login session
    localStorage.removeItem("userType");
    localStorage.removeItem("user");
    localStorage.removeItem("adminName");
    localStorage.removeItem("roleId");
    localStorage.removeItem("adminDepartmentID");
    localStorage.removeItem("corpId");
    localStorage.removeItem("cpName");
    localStorage.removeItem("enabledModule");
    localStorage.removeItem("corporateAddress");
    localStorage.removeItem("corporateImage");
    localStorage.removeItem("userImage");
    localStorage.removeItem("shiftStartOTPFromLocalStorage");
    localStorage.removeItem("shiftEndOTPFromLocalStorage");



    localStorage.setItem("login", false);
    history.push("/login");
    sessionStorage.removeItem("splashFlag");
    props.setIsLoggedIn(false);
  };
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents infinite loop in case adminAlternative also fails
    e.target.src = adminAlternative;
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.sub}>
          <CgMenuMotion
            className={classes.menuIcon}
            onMouseEnter={sideMenuClickHandler}
          />
          <img
            src={littleLogo}
            alt=""
            className={classes.logo}
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/dashboard")}
          />
        </div>
        <div className={classes.orgDetails}>
          {localStorage.getItem("userType") !== "AccountManager" && (
            <React.Fragment>
              {/* {isLoading ? (
                <div style={{ height: "80px" }}>
                  <img
                    style={{ marginTop: "25px", height: "25px" }}
                    src={loadingGif}
                    alt=""
                    className={classes.logo}
                  />
                </div>
              ) : ( */}
              <div>
                {localStorage.getItem("roleId") !== "1" && (
                  <img
                    src={corporateImage}
                    alt="logo"
                    className={classes.logo}
                  />
                )}
              </div>
              {/* )} */}
            </React.Fragment>
          )}
          <Notification
            setIsAdminPhotoClicked={setIsAdminPhotoClicked}
            isAdminPhotoClicked={isAdminPhotoClicked}
          />
          <div>
            <img


              src={adminImage ? adminImage : adminAlternative}
              alt="Admin"
              className={classes.adminPhoto}
              onClick={adminPhotoClickHandler}
              onError={handleImageError}
            />
          </div>
        </div>
        {isAdminPhotoClicked && (
          <React.Fragment>
            <div
              className={classes.backdrop}
              onClick={() => setIsAdminPhotoClicked(false)}
            ></div>
            <div className={classes.adminPanel}>
              <div className={classes.header}>
                <p className={classes.adminName}>
                  {localStorage.getItem("adminName")}
                </p>
                <p className={classes.adminOrg}>
                  {localStorage.getItem("roleId") !== "1"
                    ? `Admin of ${localStorage.getItem("cpName")}`
                    : "Super Admin"}
                </p>
              </div>
              <p
                className={classes.changePassword}
                onClick={editProfileClickHandler}
                id="a"
              >
                Edit Profile
              </p>
              <hr />
              <p
                className={classes.logout}
                // onClick={() => {
                //   localStorage.setItem("login", false);
                //   history.push("/login");
                //   sessionStorage.removeItem("splashFlag");
                //   props.setIsLoggedIn(false);
                //   // window.location.reload();
                // }}
                onClick={logoutHandler}
              >
                Logout
              </p>
            </div>
          </React.Fragment>
        )}
        {/* </div> */}
      </div>
      {isEditProfileClicked && (
        <EditProfile
          setIsResponse={setIsResponse}
          setIsEditProfileClicked={setIsEditProfileClicked}
          adminName={localStorage.getItem("adminName")}
          adminOrg={localStorage.getItem("cpName")}
          adminPhoto={adminImage ? adminImage : adminAlternative}
        />
      )}
      {isResponse && (
        <Message
          type={isResponse}
          message="Admin details has been updated successfully"
        />
      )}
    </React.Fragment>
  );
};

export default Nav;
