import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import useHttp from '../../Hooks/use-http';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const departmentsData = ["Sales", "Operations"];
const Staff = () => {
    const [data, setData] = useState([]);
    const [isAddStaffClicked, setIsAddStaffClicked] = useState(false);
    const [isSaveStaffDetailsClicked, setIsStaffDetailsClicked] = useState(false);
    const [isAddNewStaffDataLoading, setIsAddNewStaffDataLoading] = useState(false);
    const [staffDataLoading, setStaffDataLoading] = useState(true);
    const [newStaffDataLoadingSuccess, setNewSatffDataLoadingSuccess] = useState({ status: "", message: "" });
    const [newStaffData, setNewStaffData] = useState({
        name: "",
        email: "",
        department: "",
        password: ""
    });
    const { isLoading, sendRequest } = useHttp();

    const addNewStaffResponse = (data) => {
        let temp = {
            status: data.Message.toLowerCase() === "success" ? "success" : "error",
            message: data.Message.toLowerCase() === "success" ? "Staff Added Successfully" : "Some Error Occured"
        }
        setNewSatffDataLoadingSuccess(temp);
        setIsAddNewStaffDataLoading(false);
        setStaffDataLoading(true);
    }

    const inputFieldChangeHandler = (e) => {
        setNewStaffData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    useEffect(() => {
        if (isSaveStaffDetailsClicked) {
            setIsAddStaffClicked(false);
            setIsAddNewStaffDataLoading(true);
            sendRequest({
                url: "/api/v1/Staff/AddEditLittleStaffDetails",
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    emailID: localStorage.getItem("user"),
                    departmentName: newStaffData.department,
                    mobileNumber: "",
                    name: newStaffData.name,
                    password: newStaffData.password,
                    staffEmail: newStaffData.email
                }
            }, addNewStaffResponse);
        }
    }, [isSaveStaffDetailsClicked, sendRequest]);

    const staffDataResponse = (data) => {
        setData(data.StaffList);
        setStaffDataLoading(false);
    }

    useEffect(() => {
        sendRequest({
            url: "/api/v1/Staff/GetLittleStaffDetails",
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                emailID: localStorage.getItem("user")
            }
        }, staffDataResponse);
    }, [sendRequest, staffDataLoading]);

    return (
        <div>
            <Snackbar open={newStaffDataLoadingSuccess?.status === "success"} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={() => setNewSatffDataLoadingSuccess(false)} >
                <Alert onClose={() => setNewSatffDataLoadingSuccess(false)} severity={newStaffDataLoadingSuccess?.status} sx={{ width: '100%', backgroundColor: "rgba(42, 149, 69, 255)" }}>
                    {newStaffDataLoadingSuccess?.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: 'rgba(34, 137, 203, 255)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAddNewStaffDataLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={isAddStaffClicked}
            // onClose={handleClose}
            >
                <DialogTitle>Add Staff</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        In case of Operation staff, please enter the password as they will be able to access the portal.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Name"
                        type="name"
                        fullWidth
                        variant="standard"
                        onChange={inputFieldChangeHandler}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                        onChange={inputFieldChangeHandler}
                    />
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="account-manager-select-standard-label">Department</InputLabel>
                        <Select
                            labelId="account-manager-select-standard-label"
                            id="account-manager-select-standard"
                            value={newStaffData.department}
                            name='department'
                            onChange={inputFieldChangeHandler}
                            label="Department"
                        >
                            {departmentsData.map(val => <MenuItem value={val}>{val}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {newStaffData.department?.toLowerCase() === "operations" &&
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            fullWidth
                            variant="standard"
                            onChange={inputFieldChangeHandler}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAddStaffClicked(false)}>Cancel</Button>
                    <Button onClick={() => setIsStaffDetailsClicked(true)}>Save</Button>
                </DialogActions>
            </Dialog>
            <header style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "20px" }}>
                <h3>Little Staff</h3>
                <Button variant="contained" onClick={() => setIsAddStaffClicked(prev => !prev)} >Add Staff</Button>
            </header>
            <main style={{ margin: "20px" }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Emp Name</TableCell>
                                <TableCell align="center">Emp Email</TableCell>
                                <TableCell align="center">Department</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {staffDataLoading &&
                                <tr style={{ textAlign: "center", padding: "10px" }}>
                                    <div style={{ width: "100%", position: "absolute", padding: "5px" }}>
                                        <CircularProgress />
                                    </div>
                                </tr>
                            }
                            {data?.map((row) => (
                                <TableRow
                                    key={row.EmpName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{row.EmpName}</TableCell>
                                    <TableCell align="center">{row.EmailID}</TableCell>
                                    <TableCell align="center">{row.DepartmentName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </main>
        </div>
    )
}

export default Staff;