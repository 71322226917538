export const SUPERADMINBYROLEID=localStorage.getItem("roleId") === "1"
export const USEREMAILIDFROMLOCALSTORAGE=localStorage.getItem("user")
export const CORPORATEIDFROMLOCALSTORAGE=localStorage.getItem("corpId")
export const DEPARTMENTIDFROMLOCALSTORAGE=localStorage.getItem("adminDepartmentID")
export const ROLEIDFROMLOCALSTORAGE=localStorage.getItem("roleId")
export const CORPORATEADDRESSBYID=localStorage.getItem("corporateAddress")

export const carIcon = "https://littleimages.blob.core.windows.net/merchantdocuments/KENYA/EFE7059A-44E8-491B-9CB7-876EC2DBF14E"

export const svgMarkerForStartLocation = {
    path:"M149.333333,7.10542736e-15 C231.807856,7.10542736e-15 298.666667,66.8588107 298.666667,149.333333 C298.666667,176.537017 291.413333,202.026667 278.683512,224.008666 C270.196964,238.663333 227.080238,313.32711 149.333333,448 C71.5864284,313.32711 28.4697022,238.663333 19.9831547,224.008666 C7.25333333,202.026667 2.84217094e-14,176.537017 2.84217094e-14,149.333333 C2.84217094e-14,66.8588107 66.8588107,7.10542736e-15 149.333333,7.10542736e-15 Z M149.333333,85.3333333 C113.987109,85.3333333 85.3333333,113.987109 85.3333333,149.333333 C85.3333333,184.679557 113.987109,213.333333 149.333333,213.333333 C184.679557,213.333333 213.333333,184.679557 213.333333,149.333333 C213.333333,113.987109 184.679557,85.3333333 149.333333,85.3333333 Z",
      fillColor: "rgba(42, 149, 69, 255)",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.1,
    //   anchor: new window.google.maps.Point(0, 10),
    };
    
   export  const svgMarkerForEndLocation = {
      path:"M149.333333,7.10542736e-15 C231.807856,7.10542736e-15 298.666667,66.8588107 298.666667,149.333333 C298.666667,176.537017 291.413333,202.026667 278.683512,224.008666 C270.196964,238.663333 227.080238,313.32711 149.333333,448 C71.5864284,313.32711 28.4697022,238.663333 19.9831547,224.008666 C7.25333333,202.026667 2.84217094e-14,176.537017 2.84217094e-14,149.333333 C2.84217094e-14,66.8588107 66.8588107,7.10542736e-15 149.333333,7.10542736e-15 Z M149.333333,85.3333333 C113.987109,85.3333333 85.3333333,113.987109 85.3333333,149.333333 C85.3333333,184.679557 113.987109,213.333333 149.333333,213.333333 C184.679557,213.333333 213.333333,184.679557 213.333333,149.333333 C213.333333,113.987109 184.679557,85.3333333 149.333333,85.3333333 Z",
      fillColor: "rgb(226, 44, 29)",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.1,
    //   anchor: new window.google.maps.Point(0, 10),
    };
    