// import React, { useEffect, useState } from "react";
// import dayjs from "dayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import DateFilter from "./DateFilter"; // Assuming DateFilter component is in a separate file
// import { IconButton, InputAdornment, TextField } from "@mui/material";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// const shortcutsItems = [
//   //   {
//   //     label: "This Week",
//   //     getValue: () => {
//   //       const today = dayjs();
//   //       return [today.startOf("week"), today.endOf("week")];
//   //     },
//   //   },
//   //   {
//   //     label: "Last Week",
//   //     getValue: () => {
//   //       const today = dayjs();
//   //       const prevWeek = today.subtract(7, "day");
//   //       return [prevWeek.startOf("week"), prevWeek.endOf("week")];
//   //     },
//   //   },
//   // {
//   //   label: "Last Day",
//   //   getValue: () => {
//   //     const today = dayjs();
//   //     return [today.subtract(1, "day"), today];
//   //   },
//   // },
//   // {
//   //   label: "Last 3 Days",
//   //   getValue: () => {
//   //     const today = dayjs();
//   //     return [today.subtract(3, "day"), today];
//   //   },
//   // },
//   {
//     label: "Last 7 Days",
//     getValue: () => {
//       const today = dayjs();
//       return [today.subtract(7, "day"), today];
//     },
//   },
//   {
//     label: "Current Month",
//     getValue: () => {
//       const today = dayjs();
//       // return [today.startOf("month"), today.endOf("month")];
//       return [today.startOf("month"), today];

//     },
//   },
//   //   {
//   //     label: "Next Month",
//   //     getValue: () => {
//   //       const today = dayjs();
//   //       const startOfNextMonth = today.endOf("month").add(1, "day");
//   //       return [startOfNextMonth, startOfNextMonth.endOf("month")];
//   //     },
//   //   },
//   {
//     label: "Last Month",
//     getValue: () => {
//       const today = dayjs();
//       const prevMonth = today.subtract(1, "month");
//       return [prevMonth.startOf("month"), prevMonth.endOf("month")];
//     },
//   },
//   // {
//   //   label: "Last  2 Months",
//   //   getValue: () => {
//   //     const today = dayjs();
//   //     return [today.subtract(2, "month"), today];
//   //   },
//   // },

//   //   { label: "Reset", getValue: () => [null, null] },
// ];

// const DateRangePickerWithFilter = ({
//   startDateValue,
//   endDateValue,
//   setStartDateValue,
//   setEndDateValue,
//   setIsRefereshData,
//   loading,
// }) => {
//   const [selectedDates, setSelectedDates] = useState([dayjs(), dayjs()]);
//   const [isOpen, setIsOpen] = useState(false);

//   useEffect(() => {
//     const hideLicenseKeyMessage = () => {
//       const licenseKeyElement = document.querySelector(
//         'div[style*="pointer-events: none"][style*="z-index: 100000"]'
//       );
//       if (licenseKeyElement) {
//         licenseKeyElement.style.display = "none";
//       }
//     };

//     hideLicenseKeyMessage();

//     // Clean up on component unmount
//     return () => {
//       const licenseKeyElement = document.querySelector(
//         'div[style*="pointer-events: none"][style*="z-index: 100000"]'
//       );
//       if (licenseKeyElement) {
//         licenseKeyElement.style.display = "block";
//       }
//     };
//   }, []);

//   const toggleDatePicker = () => {
//     setIsOpen(loading === true && false);
//     setIsOpen(!isOpen);
//   };

//   const handleShortcutSelect = (dates) => {
//     setSelectedDates(dates);
//     // setIsOpen(false); // Close calendar after selecting shortcut
//     // handleApply(); // Trigger apply after selecting shortcut
//   };

//   const handleApply = () => {
//     if (selectedDates[0] && selectedDates[1]) {
//       // Make API call with selectedDates[0] and selectedDates[1]
//       console.log("API call with dates:", selectedDates);
//       setStartDateValue(selectedDates[0] && selectedDates[0]);
//       setEndDateValue(selectedDates[1] && selectedDates[1]);
//       setIsRefereshData(true);
//       setIsOpen(false);
//       // Example:
//       // fetch(`your-api-endpoint?startDate=${selectedDates[0].toISOString()}&endDate=${selectedDates[1].toISOString()}`)
//       //   .then(response => response.json())
//       //   .then(data => {
//       //     // Handle API response
//       //   })
//       //   .catch(error => {
//       //     console.error('Error fetching data:', error);
//       //   });
//     }
//   };
//   const formatSelectedDates = () => {
//     if (selectedDates[0] && selectedDates[1]) {
//       return `${selectedDates[0].format("DD MMM")} - ${selectedDates[1].format(
//         "DD MMM"
//       )}`;
//     }
//     return "Date";
//   };

//   const handleReset = () => {
//     const today = dayjs();
//     setSelectedDates([today, today]);
//     setStartDateValue(today);
//     setEndDateValue(today);
//     // setIsRefereshData(true);
//     // setIsOpen(false);
//   };

//   return (
//     <div style={{ position: "relative" }}>
//       {/* <input
//         type="text"
//         value={formatSelectedDates()}
//         onClick={toggleDatePicker}
//         disabled={loading === true}
//         readOnly
//         style={{
//           backgroundColor: "#fff",
//           border: "1px solid #ccc",
//           borderRadius: "8px",
//           padding: "6px 10px",
//           fontSize: "14px",
//           cursor: "pointer",
//           marginTop: "8px",
//           marginRight: "10px",
//           width: "80%",
//           boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//           transition: "border-color 0.3s, box-shadow 0.3s",
//         }}
//       />
//         <CalendarTodayIcon onClick={toggleDatePicker} style={{ cursor: "pointer" }} /> */}
//       <div style={{ marginRight: "10px", marginTop: "5px" }}>
//         <TextField
//           label="Date"
//           value={formatSelectedDates()}
//           onClick={toggleDatePicker}
//           disabled={loading}
//           fullWidth
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton onClick={toggleDatePicker}>
//                   <CalendarMonthIcon />
//                 </IconButton>
//               </InputAdornment>
//             ),
//             style: {
//               fontSize: "14px",
//               cursor: "pointer",
//               transition: "border-color 0.3s, box-shadow 0.3s",
//             },
//             readOnly: true,
//           }}
//           variant="standard"
//         />
//       </div>

//       {isOpen && (
//         <div
//           style={{
//             position: "absolute",
//             top: "100%",
//             right: "30%",
//             zIndex: 1000,
//             backgroundColor: "white",
//             border: "1px solid #ccc",
//             borderRadius: "10px",
//             boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
//             padding: "15px 10px",
//             height: "auto",
            
//           }}
//         >
//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//             {/* Calendar component */}

//             <StaticDateRangePicker
//               value={selectedDates}
//               onChange={(newValue) => {
//                 setSelectedDates(newValue);
//                 setIsRefereshData(false); // Optionally trigger refresh on date change
//               }}
//               slotProps={{
//                 shortcuts: {
//                   items: shortcutsItems,
//                   onSelect: handleShortcutSelect,
//                 },
//                 actionBar: { actions: [] },
//               }}
//               calendars={1}
//             />
//           </LocalizationProvider>
//           <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "8px", marginTop:'10px' }}>
//             {shortcutsItems?.map((shortcut, index) => (
//               <div style={{ display: "flex" }}>
//                 <button
//                   key={index}
//                   onClick={() => handleShortcutSelect(shortcut.getValue())}
//                   style={{
//                     backgroundColor: "#f0f0f0",
//                     border: "1px solid #ccc",
//                     borderRadius: "10px",
//                     padding: "4px",
//                     marginBottom: "8px",
//                     cursor: "pointer",
//                     width: "100%",
//                     fontSize: "12px",
//                     textAlign: "center",

//                   }}
//                 >
//                   {shortcut.label}
//                 </button>
//               </div>
//             ))}
//           </div>
//           <div style={{ display: "flex", gap: "20px", justifyContent:'center' }}>
//             <button
//               style={{
//                 backgroundColor: "#88BCDF",
//                 color: "white",
//                 border: "none",
//                 borderRadius: "4px",
//                 padding: "8px 14px",
//                 fontSize: "12px",
//                 cursor: "pointer",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 transition: "background-color 0.3s ease",
//                 marginBottom: "-8px",
//               }}
//               onClick={handleReset}
//               disabled={loading}
//             >
//               RESET
//             </button>
//             <button
//               style={{
//                 backgroundColor: "#69C173",
//                 color: "white",
//                 border: "none",
//                 borderRadius: "4px",
//                 padding: "8px 14px",
//                 fontSize: "12px",
//                 cursor: "pointer",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 transition: "background-color 0.3s ease",
//                 marginBottom: "-8px",
//               }}
//               onClick={handleApply}
//               disabled={loading}
//             >
//               APPLY
//             </button>
//           </div>

//           {/* Uncomment this section if you want to include DateFilter and Apply button */}
//           {/* <div style={{ marginTop: '20px' }}>
//             <DateFilter
//               startDateValue={selectedDates[0]}
//               endDateValue={selectedDates[1]}
//               setStartDateValue={(newValue) => {
//                 setSelectedDates([newValue, selectedDates[1]]);
//               }}
//               setEndDateValue={(newValue) => {
//                 setSelectedDates([selectedDates[0], newValue]);
//               }}
//               setIsRefreshData={setIsRefreshData}
//               loading={loading}
//             />
            
//           </div> */}
//         </div>
//       )}

//       {/* Style to hide the license key message */}
//       <style>{`
      
//          .MuiPickersLayout-root {
//           height: 56vh !important;
         
//         }
//           .css-wrq4gc-MuiPickersToolbar-root-MuiDateRangePickerToolbar-root {
//           padding: 0px !important;
//             }
//          .MuiPickersLayout-toolbar {
//           display: none !important; /* Hide toolbar */
//         }
//           .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
//           height: 0px !important;
//           max-width: 95px !important;
        
//           padding: 15px 5px !important;
        
//           }
//          .css-1qleo33-MuiList-root {
//           margin-top: 48px !important;
//           display: none !important;
          
//           }
//           .css-1mp10gb {
//           display: none !important
//           }
         
//        div[style*="pointer-events: none"][style*="z-index: 100000"] {
//           display: none !important;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default DateRangePickerWithFilter;

import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import dayjs from "dayjs";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const shortcutsItems = [
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day").toDate(), today.toDate()];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month").toDate(), today.toDate()];
    },
  },
  {
    label: "Last Month",
    getValue: () => {
      const today = dayjs();
      const prevMonth = today.subtract(1, "month");
      return [prevMonth.startOf("month").toDate(), prevMonth.endOf("month").toDate()];
    },
  },
];

const DateRangePickerWithFilter = ({
  startDateValue,
  endDateValue,
  setStartDateValue,
  setEndDateValue,
  setIsRefereshData,
  loading,
}) => {
  const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef=useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(() => {
    const hideLicenseKeyMessage = () => {
      const licenseKeyElement = document.querySelector(
        'div[style*="pointer-events: none"][style*="z-index: 100000"]'
      );
      if (licenseKeyElement) {
        licenseKeyElement.style.display = "none";
      }
    };

    hideLicenseKeyMessage();

    return () => {
      const licenseKeyElement = document.querySelector(
        'div[style*="pointer-events: none"][style*="z-index: 100000"]'
      );
      if (licenseKeyElement) {
        licenseKeyElement.style.display = "block";
      }
    };
  }, []);

  const toggleDatePicker = () => {
    setIsOpen(loading === true && false);
    setIsOpen(!isOpen);
  };

  const handleShortcutSelect = (dates) => {
    setSelectedDates(dates);
  };

  const handleApply = () => {
    if (selectedDates[0] && selectedDates[1]) {
      setStartDateValue(dayjs(selectedDates[0]));
      setEndDateValue(dayjs(selectedDates[1]));
      setIsRefereshData(true);
      setIsOpen(false);
    }
  };

  const formatSelectedDates = () => {
    if (selectedDates[0] && selectedDates[1]) {
      return `${dayjs(selectedDates[0]).format("DD MMM")} - ${dayjs(selectedDates[1]).format(
        "DD MMM"
      )}`;
    }
    return "Date";
  };

  const handleReset = () => {
    const today = new Date();
    setSelectedDates([today, today]);
    setStartDateValue(today);
    setEndDateValue(today);
  };

  const handleCalendarChange = (dates) => {
    if (dates.length === 2) {
      setSelectedDates(dates);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ marginRight: "10px", marginTop: "5px" }}>
        <TextField
          label="Date"
          value={formatSelectedDates()}
          onClick={toggleDatePicker}
          disabled={loading}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleDatePicker}>
                  <CalendarMonthIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              fontSize: "14px",
              cursor: "pointer",
              transition: "border-color 0.3s, box-shadow 0.3s",
            },
            readOnly: true,
          }}
          variant="standard"
        />
      </div>

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            right: "30%",
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "10px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            padding: "15px 10px",
            height: "auto",
          }}
          ref={calendarRef}

        >
          <Calendar
            selectRange={true}
            onChange={handleCalendarChange}
            value={selectedDates}
          // ref={calendarRef}

          />
          <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "8px", marginTop: '10px' }}>
            {shortcutsItems?.map((shortcut, index) => (
              <div style={{ display: "flex" }} key={index}>
                <button
                  onClick={() => handleShortcutSelect(shortcut.getValue())}
                  style={{
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    padding: "4px",
                    marginBottom: "8px",
                    cursor: "pointer",
                    width: "100%",
                    fontSize: "12px",
                    textAlign: "center",
                  }}
                >
                  {shortcut.label}
                </button>
              </div>
            ))}
          </div>
          <div style={{ display: "flex", gap: "20px", justifyContent: 'center' }}>
            <button
              style={{
                backgroundColor: "#88BCDF",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "8px 14px",
                fontSize: "12px",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                transition: "background-color 0.3s ease",
                marginBottom: "-8px",
              }}
              onClick={handleReset}
              disabled={loading}
            >
              RESET
            </button>
            <button
              style={{
                backgroundColor: "#69C173",
                color: "white",
                border: "none",
                borderRadius: "4px",
                padding: "8px 14px",
                fontSize: "12px",
                cursor: "pointer",
                alignItems: "center",
                justifyContent: "center",
                transition: "background-color 0.3s ease",
                marginBottom: "-8px",
              }}
              onClick={handleApply}
              disabled={loading}
            >
              APPLY
            </button>
          </div>
        </div>
      )}

      {/* Style to hide the license key message */}
      <style>{`
        .react-calendar__tile--range,
        .react-calendar__tile--rangeStart,
        .react-calendar__tile--rangeEnd {
          background: #44B150 !important;
          color: #fff !important;
          border-radius: 50% !important;
        }
          .react-calendar {
          border: none !important;
          }
      `}</style>
    </div>
  );
};

export default DateRangePickerWithFilter;
