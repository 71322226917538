import Login from "../Components/Home/Login";
import Dashboard from "../Components/Dashboard/Main";
import ShuttleTrips from "../Components/Trips/Trips";
import PrivateTrips from "../Components/PrivateDriver/PrivateTrips";
import Departments from "../Components/Departments/Departments";
import DriversData from "../Components/Live Tracking/DriversData";
import LittleStaff from "../Components/LittleStaff/Staff";
import AddDepartment from "../Components/Departments/AddDepartment";
import DriverData from "../Components/Live Tracking/DriversData";
import ShuttleStops from "../Components/Shuttle/Stops/Stops";
import EditDriver from "../Components/PrivateDriver/Drivers/EditDriver";
import ShuttleDrivers from "../Components/Shuttle/Drivers/EditDriver";
import ShiftCreation from "../Components/PrivateDriver/Create Shift/ShiftCreation";
import Shifts from "../Components/PrivateDriver/Shifts/Shifts";
import Staff from "../Components/Staff/Staff";
import Admins from "../Components/Admins/Admins";
import Trips from "../Components/CommonTrips/Trips";
import Support from "../Components/Support/Support";
import Routes from "../Components/Routes/Route";
import Stops from "../Components/Routes/Stops";
import DocumentsUpload from "../Components/Documents Upload/DocumentsUpload";
import DriverList from "../Components/Drivers/EditDriver";
import Booking from "../Components/ScheduleBooking/New Booking/NewBooking";
import ScheduleTrips from "../Components/ScheduleBooking/Trips/ScheduleTrips";
import PreviousBookings from "../Components/ScheduleBooking/Previous Bookings/PreviousBookings";
import BookingDetails from "../Components/ScheduleBooking/BookingDetails";
import Bookings from "../Components/Shuttle/Route Booking/Bookings";
import NewBooking from "../Components/Shuttle/Route Booking/NewBooking";
import QrCode from "../Components/PrivateDriver/Drivers/QrCode";
import Settings from "../Components/Settings/Settings";
import NewRegistration from "../Components/AddNewCorp/NewRegistration";
import AddPricingStructure from "../Components/PricingStructure/AddPricingStructure";
import AddNewTasks from "../Components/Settings/AddNewTasks";
import CorporateRecords from "../Components/Corporates/CorporateRecord";
import TestRouteInfo from "../Components/Routes/TestAddRoute/TestRouteInfo";
import StopInfo from "../Components/Routes/AddRoute/StopInfo";
import TestStopInfo from "../Components/Routes/TestAddRoute/TestStopInfo";
import TripDetails from "../Components/CommonTrips/TripDetails";



const authRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/shuttle/trips", component: ShuttleTrips },
  { path: "/privatedrive/trips", component: PrivateTrips },
  { path: "/live-tracking", component: DriversData },
  { path: "/little-staff", component: LittleStaff },
  { path: "/departments/add-new", component: AddDepartment },
  { path: "/departments", component: Departments },
  { path: "/privatedrive/livemap", component: DriverData },
  { path: "/shuttle/stops", component: ShuttleStops },
  { path: "/privatedrive/drivers", component: EditDriver },
  { path: "/shuttle/drivers", component: ShuttleDrivers },
  { path: "/privatedrive/shift-creation", component: ShiftCreation },
  { path: "/privatedrive/shifts", component: Shifts },
  { path: "/edit", component: AddDepartment },
  { path: "/staffs", component: Staff },
  { path: "/admins", component: Admins },
  { path: "/trips/:tripId", component: TripDetails },
  { path: "/trips", component: Trips},
  { path: "/support", component: Support },
  { path: "/routes", component: Routes },
  { path: "/routes/:routeId", component: Stops},
  { path: "/addroute", component: TestRouteInfo},
  { path: "/addroute-stop", component: TestStopInfo},

  { path: "/documents", component: DocumentsUpload},
  { path: "/schedule-booking/new booking", component: Booking},
  { path: "/schedule-booking/trips", component: ScheduleTrips},
  { path: "/schedule-booking/bookings", component: PreviousBookings},
  { path: "/schedule-booking/previous bookings/details", component: BookingDetails},
  { path: "/shuttle/bookings", component: Bookings},
  { path: "/shuttle/new-booking", component: NewBooking},
  { path: "/drivers/:emailId", component: QrCode},
  { path: "/drivers", component: DriverList},
  { path: "/settings", component: Settings},
  { path: "/new-registration", component: NewRegistration},
  { path: "/addpricing", component: AddPricingStructure},
  { path: "/corporates", component: CorporateRecords},
  



];
const publicRoutes = [{ path: "/login", component: Login }];

// const superAdminRoutes=[{path: '/new-registration', component: NewRegistration}]

export { authRoutes, publicRoutes };
