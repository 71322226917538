export const ExtractLatLng = (latLngString) => {
    if (!latLngString) return null;
  
    const [lat, lng] = latLngString.split(",");
    return {
      lat: +lat,
      lng: +lng,
    };
  };

  
  