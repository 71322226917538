import "./NewRegistration.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CorporateDetails from "./CorporateDetails";
import AdminDetails from "./AdminDetails";
import InvoiceDetails from "./InvoiceDetails";
import useHttp from "../../Hooks/use-http";

const steps = ["Corporate details", "Admin details", "Invoice details"];

const NewRegistration = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isNextClicked, setIsNextClicked] = React.useState(false);
  const [allData, setAllData] = React.useState({});

  const { isLoading, sendRequest } = useHttp();

  React.useEffect(() => {
    if (activeStep === 3) {
      sendRequest(
        {
          url: "/api/v1/Corporate/AddCorporateRegV2",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            accountManagerID: allData.cpDetails.accountManager,
            adminContactNo: allData.adminDetails.adminMoNumber,
            adminEmail: allData.adminDetails.adminEmail,
            adminImage: allData.adminDetails.adminPhoto,
            adminName: allData.adminDetails.adminName,
            contractEnddate: allData.cpDetails.contractEndDate,
            contractstartdate: allData.cpDetails.contractStartDate,
            corporateID: "",
            corporateLatlong: allData.cpDetails.cpLatLng,
            corporateLogo: allData.cpDetails.cpLogo,
            corporatename: allData.cpDetails.cpName,
            corporateType: "",
            countryID: allData.cpDetails.country,
            creditPeriod: allData.cpDetails.creditPeriod,
            emailID: localStorage.getItem("user"),
            enabledModule: allData.cpDetails.enabledModules.join(),
            invoiceContactEmail: allData.invoiceDetails.invoiceEmail,
            invoiceContactName: allData.invoiceDetails.invoiceName,
            newRecord: 1,
            password: allData.adminDetails.adminMoNumber,
            roleID: localStorage.getItem("roleId"),
            salesExecutives: allData.cpDetails.salesExecutive,
            shuttlefor: allData.cpDetails.shuttleType.join(),
          },
        },
        corporateRegResponse
      );
    }
  }, [activeStep]);

  const corporateRegResponse = (data) => {
    // console.log(data);
  };

  const handleNext = () => {
    setIsNextClicked(true);
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="corp-reg-container">
      <h3 style={{ padding: "10px 0 0 10px" }}>Corporate Registration</h3>
      <div className="corp-reg-sub-container">
        <Box sx={{ width: "100%", height: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  width: "100%",
                  margin: "0 40px",
                  padding: "10px",
                  height: "70%",
                }}
              >
                {activeStep === 0 && (
                  <CorporateDetails
                    setActiveStep={setActiveStep}
                    setAllData={setAllData}
                    allData={allData}
                    isNextClicked={isNextClicked}
                    setIsNextClicked={setIsNextClicked}
                  />
                )}
                {activeStep === 1 && (
                  <AdminDetails
                    setActiveStep={setActiveStep}
                    setAllData={setAllData}
                    allData={allData}
                    isNextClicked={isNextClicked}
                    setIsNextClicked={setIsNextClicked}
                  />
                )}
                {activeStep === 2 && (
                  <InvoiceDetails
                    setActiveStep={setActiveStep}
                    setAllData={setAllData}
                    allData={allData}
                    isNextClicked={isNextClicked}
                    setIsNextClicked={setIsNextClicked}
                  />
                )}
              </div>
              <div style={{ margin: "20px 40px" }}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, fontSize: "16px" }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleNext} sx={{ fontSize: "16px" }}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </div>
            </React.Fragment>
          )}
        </Box>
      </div>
    </div>
  );
};

export default NewRegistration;
