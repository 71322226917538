import React, { useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const Overtime = ({ item, acceptRejectIconClickHandler }) => {
  const [overtimeValue, setOvertimeValue] = useState(
    item.approvedTime ?? item.overTime ?? "-"
  );
  // Update overtimeValue when item prop changes
  useEffect(() => {
    setOvertimeValue(item.approvedTime ?? item.overTime ?? "-");
  }, [item]);

  // Handle change in TextField
  const handleChange = (e) => {
    setOvertimeValue(e.target.value);
  };
  
  return (
    <>
      <TextField
        id="standard-basic"
        variant="standard"
        value={overtimeValue}
        // onChange={(e) => setOvertimeValue[(e.target.value)]}
        onChange={handleChange}
        InputProps={{ disableUnderline: true }}
        disabled={
          item.approvedTime === null || item.overTime === "-" 
        }
        sx={{ width: "50%   ", borderBottom: "none" }}
        defaultValue={item.approvedTime !== null ? "-" : item.overTime}
      />
      {item.approvedTime === null && item.overTime !== null && (
        <div>
          <CheckCircleOutlineIcon
            style={{ color: "rgba(42, 149, 69, 255)", cursor: "pointer" }}
            onClick={() =>
              acceptRejectIconClickHandler(item, "accept", overtimeValue)
            }
          />
          <HighlightOffIcon
            style={{ color: "rgb(226, 44, 29)", cursor: "pointer" }}
            onClick={() => acceptRejectIconClickHandler(item, "reject", "0:0")}
          />
        </div>
      )}
    </>
  );
};

export default Overtime;
